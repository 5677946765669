import { ReactNode } from "react";
import style from "./concurrentTasksTemplate.module.scss";

interface ConcurrentTasksTemplateProps {
  children: ReactNode;
}

export default function ConcurrentTasksTemplate({
  children,
}: ConcurrentTasksTemplateProps) {
  return <div className={style["concurrent-tasks"]}>{children}</div>;
}
