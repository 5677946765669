import { toast } from "react-toastify";
import { IValidationError } from "../types";
import { IQuote } from "../types/accounting.types";

export async function fetchQuote(id?: string): Promise<IQuote | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/quote/${id}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.quote;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchQuoteList(
  query?: string
): Promise<{ quotes: IQuote[]; totalCount: number } | null> {
  try {
    let queryUrl = query ? query : "";
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/quote` + queryUrl,
      {
        credentials: "include",
      }
    );
    const data = await response.json();

    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function addQuote(
  quote: IQuote,
  sendEmail: boolean = false
): Promise<{ id: string } | IValidationError[] | null> {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/quote`, {
      headers: new Headers({ "Content-Type": "application/json" }),
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ quote, sendEmail }),
    });
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    toast.error(error);
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateQuote(
  quote: IQuote,
  updateLogistic: boolean = false,
  sendEmail: boolean = false
): Promise<IQuote | IValidationError[] | null> {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/quote`, {
      headers: new Headers({ "Content-Type": "application/json" }),
      method: "PUT",
      credentials: "include",
      body: JSON.stringify({ quote, updateLogistic, sendEmail }),
    });
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function deleteQuote(
  id?: string,
  signal?: AbortSignal
): Promise<void | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/quote/${id}`,
      {
        method: "DELETE",
        credentials: "include",
        signal: signal,
      }
    );
    if (response.status >= 400) {
      throw new Response(JSON.stringify(response), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
