import styles from "./modalCrossSellMiniature.module.scss";
import { ICrossSellLogistic } from "../../../../../../types/logistic.types";
import WorkshopId from "../../../../../workshopId/workshopId";
import clsx from "clsx";
import { AddShoppingCartOutlined } from "@mui/icons-material";

interface CrossSellMiniatureProps {
  className?: string;
  crossSell?: ICrossSellLogistic;
  handleOnClick: () => void;
  isSelected?: boolean;
}

export default function CrossSellMiniature({
  className,
  crossSell,
  handleOnClick,
  isSelected,
}: CrossSellMiniatureProps) {
  return (
    <div
      onClick={handleOnClick}
      className={clsx(
        styles["cross-sell-miniature"],
        className,
        isSelected && styles["selected"]
      )}
    >
      <div className={styles["cross-sell-number"]}>
        {crossSell?.crossSellItems?.length || 0}
      </div>
      <div className={styles["cross-sell-miniature-left"]}>
        <div className={styles["title"]}>Cross-selling</div>
        <AddShoppingCartOutlined className={styles["icon"]} />
        <WorkshopId
          workshopId={crossSell?.workshopId || ""}
          size={"xxx-small"}
          type={"cross-sell"}
        />
      </div>
    </div>
  );
}
