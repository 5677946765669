import Select from "../../../../components/Select/Select";
import { IShippingMethod } from "../../../../types/accounting.types";
import {
  SHIPPING_METHOD_CHRONOPOST_2SHOP,
  SHIPPING_METHOD_CHRONOPOST_PRO,
} from "../../../../utils/shippingMethod.init";
import style from "./selectShippingMethod.module.scss";

interface SelectShippingMethodProps {
  document: string | null;
  shippingMethod: IShippingMethod | null;
  setShippingMethod: (value: IShippingMethod | null) => void;
}

export default function SelectShippingMethod({
  document,
  shippingMethod,
  setShippingMethod,
}: SelectShippingMethodProps) {
  const SHIPPING_METHOD_LIST = [
    SHIPPING_METHOD_CHRONOPOST_2SHOP,
    SHIPPING_METHOD_CHRONOPOST_PRO,
  ];

  return (
    <>
      <label>2 - Sélectionnez une méthode de livraison</label>
      <Select
        className={style["select-shipping-method"]}
        doTranslate={false}
        optionsList={SHIPPING_METHOD_LIST.map((sp) => sp.shippingService)}
        placeholder="Méthodes de livraison..."
        setValue={handleSetShippingMethod}
        value={shippingMethod?.shippingService || ""}
      />
    </>
  );

  function handleSetShippingMethod(value: string) {
    if (document) {
      return window.alert(
        'Vous ne pouvez pas faire de modifications. Appuyer sur "Génerer Nouveau Bon" ou annuler le bon actuel.'
      );
    }
    setShippingMethod(
      SHIPPING_METHOD_LIST.find((sp) => value === sp?.shippingService) || null
    );
  }
}
