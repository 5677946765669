import { ILogistic } from "../types/logistic.types";
import { IProCart } from "../types/proCart.types";
import { ITrackerPro } from "../types/trackerPro.type";

export async function addTrackerPro(): Promise<string> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker-pro/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.id;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function addTrackingProEvent(
  eventMessage: string,
  trackId: string,
  eventType: string,
  eventDoc: any
): Promise<string> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker-pro/add-event/${trackId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "PUT",
        credentials: "include",
        body: JSON.stringify({
          eventMessage,
          eventType,
          eventDoc,
        }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.id;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateTrackerProState(
  trackId: string,
  state: string
): Promise<string> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker-pro/${trackId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "PUT",
        credentials: "include",
        body: JSON.stringify({
          state,
        }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.id;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getTrackerPro(trackId: string): Promise<ITrackerPro> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker-pro/${trackId}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getTrackersProList(
  filters: any
): Promise<{ totalCount: number; trackersList: ITrackerPro[] }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker-pro/`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        credentials: "include",
        body: JSON.stringify(filters),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function generateLogisticFromCartPro({
  trackId,
  logistic,
  shippingProId,
  toUpdateCart,
  toAddCart,
}: {
  trackId: string;
  logistic: ILogistic;
  shippingProId: string;
  toUpdateCart: IProCart;
  toAddCart?: IProCart;
}) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker-pro/generate-logistic-from-cart/${trackId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          logistic,
          shippingProId,
          toUpdateCart,
          toAddCart,
        }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function cartProCheckout({
  trackId,
  logistic,
  shippingProId,
  toUpdateCart,
  toAddCart,
}: {
  trackId: string;
  logistic: ILogistic;
  shippingProId: string;
  toUpdateCart: IProCart;
  toAddCart?: IProCart;
}) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker-pro/cart-pro-checkout/${trackId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          logistic,
          shippingProId,
          toUpdateCart,
          toAddCart,
        }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchLogisticFromTrackerProId(
  trackId: string
): Promise<ILogistic | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker-pro/${trackId}/logistic`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
