import { IQuote } from "../../../types/accounting.types";
import styles from "./item.module.scss";
import { Delete, Edit, Launch, Send } from "@mui/icons-material";
import translate from "../../../translation";
import getStatusColor from "../../../translation/statusColor";
import QuoteModal from "../../../components/modals/quoteModal/quoteModal";
import { useModalComponent } from "../../../context/modalComponent.context";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import CenterCell from "../../../components/CenterCell/CenterCell";
import State from "../../../components/StateItem/State";
import clsx from "clsx";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";

export default function Item({
  data,
  onDelete,
  handleSendQuote,
  zIndex,
}: {
  data: IQuote;
  onDelete: (id?: string) => void;
  handleSendQuote: (value: IQuote) => void;
  zIndex: number;
}) {
  const { setModalIsOpen, setModalChildren } = useModalComponent();

  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Launch />,
      name: "Ouvrir",
      onClick: () => openQuoteModal(),
    },
    {
      icon: <Edit />,
      name: "Modifier",
      to: `/quotes/edit/${data.id}`,
    },
    {
      icon: <Delete />,
      name: "Supprimer",
      onClick: () => onDelete(data.id),
    },
  ];

  if (data.state === "QUOTE_PENDING") {
    MORE_TOOLS_OPTIONS.unshift({
      icon: <Send />,
      name: "Envoyer",
      onClick: () => handleSendQuote(data),
    });
  }

  return (
    <tr className={styles["item"]}>
      <td
        className={clsx(styles["item-cell"], styles["item-date"])}
        title={`${data?.sentAt && new Date(data?.sentAt).toLocaleString()}`}
      >
        {data.sentAt
          ? new Date(data.sentAt).toLocaleDateString()
          : "indisponible"}
      </td>
      <td
        className={clsx(styles["item-cell"], styles["item-date"])}
        title={`${
          data?.repliedAt && new Date(data?.repliedAt).toLocaleString()
        }`}
      >
        {data.repliedAt
          ? new Date(data.repliedAt).toLocaleDateString()
          : "indisponible"}
      </td>
      <td className={clsx(styles["item-cell"], styles["item-date"])}>
        {data.contact?.displayname}
      </td>
      <td className={clsx(styles["item-cell"], styles["item-date"])}>
        <CenterCell>{data.maximumDurationDays}</CenterCell>
      </td>
      <td className={clsx(styles["item-cell"])}>
        {Math.floor((data.total || 0) * 100) / 100 + " €"}
      </td>
      <td className={`${styles["item-cell"]} ${styles["status-box"]}`}>
        <CenterCell>
          <State state={data.state} />
        </CenterCell>
      </td>
      <td className={`${styles["item-cell"]} ${styles["tools"]}`}>
        <ProtectedComponent roles={["ROLE_ADMIN"]}>
          <CenterCell>
            <MoreTools options={MORE_TOOLS_OPTIONS} />
          </CenterCell>
        </ProtectedComponent>
      </td>
    </tr>
  );

  function openQuoteModal() {
    if (data.id) {
      setModalIsOpen(true);
      setModalChildren(<QuoteModal id={data.id} />);
    }
  }
}
