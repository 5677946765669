import style from "./loadingSpinner.module.scss";

interface LoadingSpinnerProps {
  color?: string;
  className?: string;
}
export default function LoadingSpinner({
  color = "gold",
  className,
}: LoadingSpinnerProps) {
  return (
    <div className={style["loading-spinner"]}>
      <div className={`${style.load} ${color}`}>
        <div className={`${style["line"]} ${className ? className : ""}`}></div>
        <div className={`${style["line"]} ${className ? className : ""}`}></div>
        <div className={`${style["line"]} ${className ? className : ""}`}></div>
      </div>
    </div>
  );
}
