import { useLoaderData, useParams } from "react-router-dom";
import { ILogistic } from "../../../types/logistic.types";
import style from "./edit.module.scss";
import Create from "../create/create";
import { fetchLogistic } from "../../../requests/logistic";
import useLogout from "../../../hooks/useLogout";
import { useQuery } from "react-query";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";

export async function loader({ params }: { params: any }) {
  try {
    const logistics = await fetchLogistic({ params });
    return logistics;
  } catch (error: any) {
    throw error;
  }
}

export default function Edit() {
  const { id } = useParams();
  const { data, isFetching } = useQuery(
    "cart",
    () => fetchLogistic({ params: { id: id } }),
    { refetchOnWindowFocus: false }
  );

  if (isFetching) {
    return <LoadingSpinner color="gold" />;
  }

  return <>{data && <Create logistic={data} />}</>;
}
