import React, { ReactNode, useEffect, useState } from "react";
import useClickOutside from "../../hooks/useClickOutSide";
import { createPortal } from "react-dom";
import styles from "./dropDownPortal.module.scss";

interface DropDownPortalProps<T> {
  onClickOutside: () => void;
  expand: boolean;
  buttonRef: React.MutableRefObject<T | null>;
  children: ReactNode;
  offset?: { top: number; left: number };
  className?: string;
}

export default function DropDownPortal<T>({
  buttonRef,
  children,
  className,
  expand,
  offset,
  onClickOutside,
}: DropDownPortalProps<T>) {
  const dropdownRef = useClickOutside<HTMLDivElement>(onClickOutside);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: -1000,
    left: -1000,
  });

  useEffect(() => {
    if (buttonRef.current && dropdownRef.current) {
      const buttonRect = (buttonRef.current as any).getBoundingClientRect();
      const dropdownWidth = dropdownRef.current.offsetWidth;

      setDropdownPosition({
        top: buttonRect.bottom + window.scrollY,
        left:
          buttonRect.left + window.scrollX - dropdownWidth + buttonRect.width,
      });
    }
  }, [expand]);

  if (!expand) {
    return <></>;
  }

  return (
    <div className={styles["drop-down-portal-wrapper"]}>
      {createPortal(
        <div
          className={className}
          ref={dropdownRef}
          style={{
            position: "absolute",
            top: dropdownPosition.top + (offset ? offset.top : 0),
            left: dropdownPosition.left + (offset ? offset.left : 0),
            zIndex: 2001,
          }}
        >
          {children}
        </div>,
        document.body
      )}
    </div>
  );
}
