import { useLoaderData } from "react-router-dom";
import { ILogistic } from "../../../types/logistic.types";
import { fetchLogistic } from "../../../requests/logistic";
import useLogout from "../../../hooks/useLogout";
import style from "./read.module.scss";
import { authloader } from "../..";

export async function loader({ params }: { params: any }) {
  try {
    const logistics = await fetchLogistic({ params });
    authloader();
    return logistics;
  } catch (error: any) {
    throw error;
  }
}
export default function Read() {
  const data: ILogistic | null = useLoaderData() as ILogistic;

  useLogout(data);
  return <div className={style["read-container"]}></div>;
}
