import { ReactElement, createContext, useContext, useState } from "react";

interface ConfirmModalContextProps {
  confirmModalIsOpen: boolean;
  setConfirmModalIsOpen: (value: boolean) => void;
  confirmModalChildren: ReactElement | undefined;
  setConfirmModalChildren: (modalChildren: ReactElement | undefined) => void;
}

const defaultContextProps: ConfirmModalContextProps = {
  confirmModalIsOpen: false,
  setConfirmModalIsOpen: (value: boolean) => {},
  confirmModalChildren: undefined,
  setConfirmModalChildren: (value: ReactElement | undefined) => {},
};

const ConfirmModalContext =
  createContext<ConfirmModalContextProps>(defaultContextProps);

export default function ConfirmModalProvider(props: any) {
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState<boolean>(false);
  const [confirmModalChildren, setConfirmModalChildren] = useState<
    ReactElement | undefined
  >();

  return (
    <ConfirmModalContext.Provider
      value={
        {
          confirmModalIsOpen,
          setConfirmModalIsOpen,
          confirmModalChildren,
          setConfirmModalChildren,
        } as ConfirmModalContextProps
      }
    >
      {props.children}
    </ConfirmModalContext.Provider>
  );
}

export function useConfirmModal() {
  const context = useContext(ConfirmModalContext);
  if (context === undefined) {
    throw new Error(
      "useConfirmModal must be used within a ConfirmModalProvider"
    );
  }
  return context;
}
