import translate from "../../translation";
import style from "./statusItem.module.scss";
import getStatusColor from "../../translation/statusColor";
import { translateTrackingState } from "../../utils/tracking.init";

interface StateProps {
  state: string;
  isGeneral?: boolean;
}

export default function State({ state, isGeneral }: StateProps) {
  return (
    <div className={style["item-element"]}>
      <div
        className={style["state-container"]}
        style={{
          backgroundColor: isGeneral ? getStatusColor(state) : "transparent",
          color: isGeneral ? "#ffffff" : getStatusColor(state),
          borderColor: getStatusColor(state),
        }}
      >
        {isGeneral ? translateTrackingState(state) : translate(state)}
      </div>
    </div>
  );
}
