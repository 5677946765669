import Widget from "../../newWidget/widget";
import style from "./qualityControl.module.scss";
import { Rule } from "@mui/icons-material";

export default function QualityControl() {
  // const { isLoading, error, data } = useQuery("quality-control-widget", () =>
  //   fetchLogisticList("?limit=5&status=LOGISTIC_WORKSHOP")
  // );

  // function qualityControl(): number {
  //   let toControlProducts = 0;
  //   data?.logisticsList?.forEach((logistic: ILogistic) => {
  //     logistic.productsList.forEach((product: IProduct) => {
  //       if (product.status !== "WORKSHOP_COMPLETE") {
  //         toControlProducts++;
  //       }
  //     });
  //   });

  //   return toControlProducts;
  // }

  return (
    <Widget to={"quality-control"}>
      <div className={style["icon-container"]}>
        <Rule className={style["icon"]} />
      </div>
      <div>Contrôle qualité</div>
    </Widget>
  );
}
