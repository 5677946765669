import styles from "./menuItem.module.scss";
import { Link, useLocation } from "react-router-dom";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import clsx from "clsx";
import { useHideSideBar } from "../../../context/hideSideBar.context";
import { KeyboardTabOutlined } from "@mui/icons-material";
import MenuItemContainer from "../MenuItemContainer/MenuItemContainer";
import { useGetPath } from "../../../utils/utils";

interface MenuItemProps {
  link: string;
  title: string;
  Logo: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  SelectedLogo: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  onClick?: () => void;
}

export default function MenuItem({
  link,
  title,
  Logo,
  SelectedLogo,
  onClick,
}: MenuItemProps) {
  const { hideSide } = useHideSideBar();
  const isSelected = useGetPath(title);

  return (
    <MenuItemContainer title={title}>
      <Link to={link} className={styles["menu-item-link"]} onClick={onClick}>
        <div className={styles["menu-item-icon-container"]}>
          {isSelected ? (
            <SelectedLogo className={styles["menu-item-icon"]} />
          ) : (
            <Logo className={styles["menu-item-icon"]} />
          )}
        </div>
        <p
          className={clsx(
            styles["menu-item-title"],
            hideSide && styles["hide"]
          )}
        >
          {title}
        </p>
      </Link>
    </MenuItemContainer>
  );
}

export function ShowMenu() {
  const { hideSide, setHideSide } = useHideSideBar();
  return (
    <MenuItemContainer title={"Agrandir"} onClick={handleExpandMenu}>
      <div className={styles["menu-item-link"]}>
        <div className={styles["menu-item-icon-container"]}>
          {hideSide ? (
            <KeyboardTabOutlined className={styles["menu-item-icon"]} />
          ) : (
            <KeyboardTabOutlined
              className={clsx(styles["menu-item-icon"], styles["reverse"])}
            />
          )}
        </div>
        <p
          className={clsx(
            styles["menu-item-title"],
            hideSide && styles["hide"]
          )}
        >
          {"Réduire"}
        </p>
      </div>
    </MenuItemContainer>
  );

  function handleExpandMenu() {
    setHideSide(!hideSide);
  }
}
