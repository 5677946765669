import { AddCircleOutline } from "@mui/icons-material";
import style from "./addProductSection.module.scss";
import ProductMiniature from "../../../../components/miniature/newProductMiniature/ProductMiniature";
import { IProduct } from "../../../../types/logistic.types";

interface AddProductSectionProps {
  productsList: IProduct[];
  handleAddNewProduct: () => void;
  handleConfirmDeleteProduct: (index: number) => void;
  handleOpenProductModal: (product: IProduct, index: number) => void;
}

export default function AddProductSection({
  productsList,
  handleAddNewProduct,
  handleConfirmDeleteProduct,
  handleOpenProductModal,
}: AddProductSectionProps) {
  return (
    <>
      <div>{`Articles ${
        productsList.length > 0 ? `(${productsList.length})` : ""
      }`}</div>
      <div className={style["products-list-container"]}>
        <div className={style["products-list"]}>
          <div
            className={style["add-new-product-miniature"]}
            onClick={handleAddNewProduct}
          >
            <AddCircleOutline className={style["icon"]} />
          </div>
          {productsList.map((product, index: number) => {
            return (
              <ProductMiniature
                className={style["product-miniature"]}
                key={index}
                handleDelete={() => handleConfirmDeleteProduct(index)}
                handleOnClick={() => handleOpenProductModal(product, index)}
                product={product}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}
