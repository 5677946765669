import { Delete, Edit, Key } from "@mui/icons-material";
import { ILicense } from "../../../types/license.types";
import styles from "./item.module.scss";
import { useMutation } from "react-query";
import { generateLicenseKey } from "../../../requests/licenses";
import { useModalComponent } from "../../../context/modalComponent.context";
import LicenseKeysModal from "../../../components/modals/licenseKeysModal/licenseKeysModal";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../../../utils/toast.options";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import clsx from "clsx";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import CenterCell from "../../../components/CenterCell/CenterCell";

interface ItemProps {
  data: ILicense;
  onDelete: (id: string) => void;
}

export default function Item({ data, onDelete }: ItemProps) {
  const { setModalChildren, setModalIsOpen } = useModalComponent();

  const generateKeyMutation = useMutation(generateLicenseKey, {
    onSuccess: (data) => {
      console.log(data);
      if (data?.redirections) {
        setModalChildren(<LicenseKeysModal redirections={data.redirections} />);
        setModalIsOpen(true);
      }
    },
    onError: (error) => {
      console.log(error);
      toast.error("Une erreur est survenue", TOAST_ERROR_OPTIONS);
    },
  });

  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Edit />,
      name: "Ouvrir",
      to: `./edit/${data.id}`,
    },
    {
      icon: <Key />,
      name: "Clés",
      onClick: () => handleGenerateKey(),
    },
    {
      icon: <Delete />,
      name: "Supprimer",
      onClick: () => handleDelete(),
    },
  ];

  return (
    <tr className={styles["item"]}>
      <td className={styles["item-cell"]}>
        {data.contact?.organization} - {data.contact?.displayname}
      </td>
      <td className={styles["item-cell"]}>{(data.shopsList || []).length}</td>
      <td className={styles["item-cell"]}>
        {(data.monthlyPrice || 0) +
          (data.shopsList || []).reduce(
            (prev, cur) => prev + (cur.monthlyPrice || 0),
            0
          )}{" "}
        €
      </td>
      <td className={clsx(styles["item-cell"], styles["tools"])}>
        <ProtectedComponent roles={["ROLE_ADMIN"]}>
          <CenterCell>
            <MoreTools options={MORE_TOOLS_OPTIONS} />
          </CenterCell>
        </ProtectedComponent>
      </td>
    </tr>
  );

  function handleDelete() {
    if (
      window.confirm(
        "Êtes-vous sûre de vouloir supprimer définitivement cette license ?"
      )
    ) {
      data.id && onDelete(data.id);
    }
  }

  function handleGenerateKey() {
    if (data?.id) {
      generateKeyMutation.mutate({ id: data.id });
    }
  }
}
