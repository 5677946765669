import { useEffect, useState } from "react";
import styles from "./printButton.module.scss";
import ProgressionBar from "../ProgressionBar/ProgressionBar";
import Button from "../Button/Button";
import { PrintState } from "../../context/printer.context";
import { PrintOutlined } from "@mui/icons-material";

interface PrintButtonProps {
  handleOnPrint: () => void;
  printProgress: number;
  printState: PrintState;
}

export default function PrintButton({
  handleOnPrint,
  printProgress,
  printState,
}: PrintButtonProps) {
  const [showProgressionBar, setShowProgressionBar] = useState<boolean>(false);

  useEffect(() => {
    if (!printProgress) {
      setShowProgressionBar(false);
    }
  }, [printProgress]);

  return (
    <>
      <Button onClick={handleOnPrint} disabled={printState !== "idle"}>
        <PrintOutlined />
      </Button>
      {showProgressionBar && <ProgressionBar percentage={printProgress} />}
    </>
  );
}
