import { useEffect, useState } from "react";

export function useBackButton() {
  const [isBack, setIsBack] = useState(false);

  function handleEvent(e: PopStateEvent) {
    // e.preventDefault();
    setIsBack(true);
  }
  useEffect(() => {
    window.addEventListener("popstate", handleEvent);
    return () => window.removeEventListener("popstate", handleEvent);
  }, []);

  useEffect(() => {
    function handlePressEscape(e: KeyboardEvent) {
      if (e.key === "Escape") {
        setIsBack(true);
      }
    }

    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", handlePressEscape);

    return () => {
      document.body.style.overflow = "scroll";
      document.removeEventListener("keydown", handlePressEscape);
    };
  }, []);

  return isBack;
}
