import style from "./error-page.module.scss";
import SplitPage from "../components/splitPage/splitPage.component";
import SideBar from "../components/splitPage/sideBar.component";
import { useState } from "react";
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error: any = useRouteError();
  const [hideSide, setHideSide] = useState(true);

  console.error("ERROR_PAGE", error);
  return (
    <div id="error-page">
      <div className={style["layout"]}>
        {/* {error.status !== 200 && (
          <SplitPage>
            <SideBar hideSide={hideSide} setHideSide={setHideSide} />
          </SplitPage>
        )} */}
        <div className={style["outlet"]}>
          <div className={style["container"]}>
            <h1 className={style["title"]}>
              {error.status} {error.statusText}
            </h1>
            {error.data && <p className={style["body"]}>{error.data}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}
