import { Outlet } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import SplitPage from "./components/splitPage/splitPage.component";
import style from "./App.module.scss";
import HideSideBarProvider, {
  useHideSideBar,
} from "./context/hideSideBar.context";
import WebCamCaptureProvider from "./context/webCamCapture.context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ModalComponentProvider, {
  useModalComponent,
} from "./context/modalComponent.context";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { isDesktop } from "react-device-detect";
import { TouchBackend } from "react-dnd-touch-backend";
import ImageViewerProvider, {
  useImageViewer,
} from "./context/imageViewer.context";
import CustomRouter from "./routes";
import AuthProvider from "./context/auth.context";
import ConfirmModalProvider, {
  useConfirmModal,
} from "./context/confirmationModalContext";
import PrinterProvider from "./context/printer.context";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import TopBar from "./components/splitPage/topBar/topBar.component";
// import ModalContainer from "../modalContainer/modalContainer";
import ConfirmationModal from "./components/confirmationModal/ConfirmationModal";
import ImageViewer from "./components/ImageViewer/ImageViewer";
import ModalContainer from "./components/modalContainer/modalContainer";
import clsx from "clsx";

export function Layout() {
  const { hideSide } = useHideSideBar();
  const { viewerIsOpen } = useImageViewer();
  const { confirmModalIsOpen } = useConfirmModal();
  const { modalIsOpen } = useModalComponent();

  return (
    <div className={style["layout"]}>
      {modalIsOpen && <ModalContainer />}
      {confirmModalIsOpen && <ConfirmationModal />}
      {viewerIsOpen && <ImageViewer />}
      <TopBar />
      <NavigationBar />
      <div className={clsx(style["outlet"], hideSide && style["outlet-small"])}>
        <Outlet />
      </div>
    </div>
  );
}

export default function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <DndProvider backend={isDesktop ? HTML5Backend : TouchBackend}>
          <ImageViewerProvider>
            <ModalComponentProvider>
              <PrinterProvider>
                <ConfirmModalProvider>
                  <WebCamCaptureProvider>
                    <HideSideBarProvider>
                      <ToastContainer />
                      <CustomRouter />
                      <div id="modal-root"></div>
                    </HideSideBarProvider>
                  </WebCamCaptureProvider>
                </ConfirmModalProvider>
              </PrinterProvider>
            </ModalComponentProvider>
          </ImageViewerProvider>
        </DndProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}
