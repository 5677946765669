import { isDesktop } from "react-device-detect";
import style from "./validateButton.module.scss";
import PictureLoadingSpinner from "../loadingSpinner/pictureloadingSpinner";

interface ValidateButtonProps {
  className?: string;
  error?: string | null;
  handleOnClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  title: string;
  isLoading?: boolean;
  loadingSpinnerStyle?: string;
}

export default function ValidateButton({
  className,
  error,
  handleOnClick,
  title,
  isLoading,
  loadingSpinnerStyle,
}: ValidateButtonProps) {
  return (
    <>
      <button
        className={`${style["validate-button"]} ${isDesktop ? style["hover"] : style["active"]
          } ${className}`}
        onClick={isLoading ? () => { } : handleOnClick}
      >
        {isLoading ? (
          <PictureLoadingSpinner
            color="white"
            className={style["loading-spinner"]}
          />
        ) : (
          `${title}`
        )}
      </button>
      {error && <span className={style["error"]}>* {error}</span>}
    </>
  );
}
