import React from "react";
import style from "./loadingSpinner.module.scss";

interface PictureLoadingSpinnerProps {
  color?: string;
  className?: string;
}

export default function PictureLoadingSpinner({
  color,
  className,
}: PictureLoadingSpinnerProps) {
  return <div className={`${style["loader4"]} ${className ?? ""}`}></div>;
}
