import styles from "./errorWidget.module.scss";
import DashboardWidget from "../DashboardWidget";

export default function ErrorWidget() {
  return (
    <DashboardWidget className={styles["loading-widget"]}>
      <p>Un problème est survenue</p>
    </DashboardWidget>
  );
}
