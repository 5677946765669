export async function generateInvoicePro(invoiceProId?: string): Promise<any> {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/pennylanePro/generate-invoicePro`,
    {
      headers: new Headers({ "Content-Type": "application/json" }),
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ invoiceProId: invoiceProId }),
    }
  );
  if (response.status !== 200) {
    throw new Error();
  }
  return await response.json();
}

export async function finalizeInvoicePro(invoiceProId?: string): Promise<any> {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/pennylanePro/finalize-invoicePro`,
    {
      headers: new Headers({ "Content-Type": "application/json" }),
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ invoiceProId: invoiceProId }),
    }
  );
  if (response.status !== 200) {
    throw new Error();
  }
  return await response.json();
}
