import clsx from "clsx";
import translate from "../../translation";
import getStatusColor from "../../translation/statusColor";
import styles from "./state.module.scss";

interface StateProps {
  state?: string;
  className?: string;
}

export default function State({
  state = "indisponible",
  className,
}: StateProps) {
  const color = getStatusColor(state);
  return (
    <div
      className={clsx(styles["state-container"], className)}
      style={{ borderColor: color, color: color }}
    >
      {translate(state)}
    </div>
  );
}
