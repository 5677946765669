import styles from "./item.module.scss";
import { Delete, Edit, Launch } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import translate from "../../../translation";
import getStatusColor from "../../../translation/statusColor";
import { ICart } from "../../../types/booking.type";
import { useContext } from "react";
import { useModalComponent } from "../../../context/modalComponent.context";
import CartModal from "../../../components/modals/cartModal/cartModal";
import { useHideSideBar } from "../../../context/hideSideBar.context";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import CenterCell from "../../../components/CenterCell/CenterCell";
import State from "../../../components/StateItem/State";
import clsx from "clsx";

export default function Item({
  data,
  zIndex,
  onDelete,
}: {
  data: ICart;
  zIndex: number;
  onDelete: (id?: string) => void;
}) {
  const { setModalIsOpen, setModalChildren } = useModalComponent();

  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Launch />,
      name: "Ouvrir",
      onClick: openCartModal,
    },
    {
      icon: <Edit />,
      name: "Modifier",
      to: `/carts/edit/${data.id}`,
    },
    {
      icon: <Delete />,
      name: "Supprimer",
      onClick: () => onDelete(data.id),
    },
  ];

  return (
    <tr className={styles["item"]}>
      <td
        className={`${styles["item-cell"]} ${styles["item-date"]}`}
        title={`${
          data?.createdAt && new Date(data?.createdAt).toLocaleString()
        }`}
      >
        {data.createdAt
          ? new Date(data.createdAt).toLocaleDateString()
          : "indisponible"}
      </td>
      <td className={clsx(styles["item-cell"], styles["item-coord"])}>
        <div className={styles["coordinates"]}>
          <span>{data.contact?.displayname}</span>
          <span>{data.contact?.phone}</span>
        </div>
      </td>
      <td className={styles["item-cell"]}>{data.productsList.length}</td>
      <td className={clsx(styles["item-cell"], styles["status-box"])}>
        <CenterCell>
          <State state={data.state} />
        </CenterCell>
      </td>
      <td className={`${styles["item-cell"]} ${styles["tools"]}`}>
        <ProtectedComponent roles={["ROLE_ADMIN"]}>
          <CenterCell>
            <MoreTools options={MORE_TOOLS_OPTIONS} />
          </CenterCell>
        </ProtectedComponent>
      </td>
    </tr>
  );

  function openCartModal() {
    setModalIsOpen(true);
    data.id && setModalChildren(<CartModal id={data.id} />);
  }
}
