import React from "react";
import { authloader } from "../..";
import { getCrossSellItem } from "../../../requests/crossSelling";
import { useLoaderData } from "react-router-dom";
import { ICrossSell } from "../../../types/accounting.types";
import Create from "../create/create";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";

export async function loader({ params }: { params: any }) {
  try {
    const crossSellItem = await getCrossSellItem(params.slug);
    authloader();
    return crossSellItem;
  } catch (error: any) {
    throw error;
  }
}

export default function Edit() {
  const data: ICrossSell | null = useLoaderData() as ICrossSell;

  if (!data) {
    return <LoadingSpinner color="gold" />;
  }

  return <Create data={data} />;
}
