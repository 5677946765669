import { useEffect, useState } from "react";
import { queryObject } from "../types/query.types";
import { Query } from "react-query";

export default function useSaveQueryStorage(
  initialQuery: queryObject,
  name: string
): [queryObject, any] {
  const [query, setQuery] = useState<queryObject>(
    loadQueryStorage(initialQuery, name)
  );

  useEffect(() => {
    localStorage.setItem(`gp-management.${name}`, JSON.stringify(query));
  }, [query]);

  return [query, setQuery];
}

export function loadQueryStorage(initialQuery: queryObject, name: string) {
  const query = localStorage.getItem(`gp-management.${name}`);
  if (query && query !== "undefined") {
    return JSON.parse(query);
  }
  return initialQuery;
}
