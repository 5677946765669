import { Check, Close, Colorize } from "@mui/icons-material";
import style from "./freshNewColorPicker.module.scss";
import { KeyboardEvent, useEffect, useRef, useState } from "react";
import { COLOR_LIST, ColorType } from "../../translation/color";
import { Search } from "react-bootstrap-icons";
import { isDesktop } from "react-device-detect";
import useClickOutside from "../../hooks/useClickOutSide";

interface FreshNewColorPicker {
  className?: string;
  color?: string;
  handleColorChange: (value: string) => void;
}

export default function FreshNewColorPicker({
  className,
  color,
  handleColorChange,
}: FreshNewColorPicker) {
  const [colorHovered, setColorHovered] = useState<ColorType>(
    getInitialColor()
  );
  const [expand, setExpand] = useState<boolean>(false);
  const ref = useClickOutside<HTMLDivElement>(() => setExpand(false));

  return (
    <div
      className={`${style["color-picker"]} ${className ? className : ""}`}
      ref={ref}
    >
      <div className={style["color-picker-input"]} onClick={handleExpand}>
        <div className={style["color-name-container"]}>
          {color ? (
            <div className={style["color-name"]}>
              <span className={style["color-name"]}>{color}</span>
              <div className={style["reset-color"]} onClick={resetColor}>
                <Close />
              </div>
            </div>
          ) : (
            <span className={style["placeholder"]}>choisir une couleur</span>
          )}
        </div>
        <div className={style["color-picker-icon"]}>
          <Colorize />
        </div>
      </div>
      {expand && (
        <div className={style["color-picker-drop"]}>
          <div
            className={style["color-preview"]}
            style={{ backgroundColor: colorHovered.color }}
          >
            <span className={style["color-preview-name"]}>
              {colorHovered.name}
            </span>
          </div>
          <div className={style["color-list-container"]}>
            <div className={style["color-list"]}>
              {COLOR_LIST.filter(({ name }) =>
                name.match(new RegExp(`${color || ""}`, "i"))
              ).map((value: ColorType, index: number) => {
                return (
                  <div
                    className={`${style["color-box"]} ${
                      isDesktop
                        ? style["hover"]
                        : value === colorHovered
                        ? style["mobile-tablet-hovered"]
                        : ""
                    }`}
                    key={index}
                    style={{ backgroundColor: value.color }}
                    onMouseEnter={() => setColorHovered(value)}
                    onClick={() => handleClickColor(value)}
                  ></div>
                );
              })}
            </div>
          </div>
          <div className={style["color-picker-drop-input-container"]}>
            <input
              className={style["color-picker-drop-input"]}
              type="text"
              value={color}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <div className={style["search-icon-container"]}>
              <div className={style["search-icon"]}>
                <Search />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  function handleExpand() {
    setExpand(!expand);
  }

  function handleClickColor(value: ColorType) {
    if (isDesktop) {
      handleColorChange(value.name);
      setExpand(false);
    } else {
      if (value === colorHovered) {
        handleColorChange(value.name);
        setExpand(false);
      } else {
        setColorHovered(value);
      }
    }
  }

  function handleInputChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    handleColorChange(target.value);
  }

  function resetColor() {
    handleColorChange("");
  }

  function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      setExpand(false);
    }
  }

  function getInitialColor(): ColorType {
    const found = COLOR_LIST.find((c: ColorType) => c.name === color);
    if (found) {
      return found;
    }
    return {
      code: 2,
      name: "Incolore",
      color: "#f1ebd1",
    };
  }
}
