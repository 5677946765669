import { ChangeEvent, useRef, useState } from "react";
import styles from "./imageConversion.module.scss";
import clsx from "clsx";
import { useImageViewer } from "../../../context/imageViewer.context";
import Button from "../../../components/Button/Button";
import { Close, Upload } from "@mui/icons-material";
import Image from "../../../components/image/Image";
import { toast } from "react-toastify";
import { getFile, streamFile } from "../../../requests/file";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import Select from "../../../components/Select/Select";
import Input from "../../../components/inputs/Input/Input";
import { Link } from "react-router-dom";

interface UploadedImageResponse {
  imageUri: string;
  size: number;
}

const CONVERSION_FORMAT = ["avif", "webp", "png", "jpeg", "tiff"];

export default function ImageConversion() {
  const [fileList, setFileList] = useState<File[]>([]);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [inDropZone, setInDropZone] = useState<boolean>(false);
  const [uploadedImageList, setUploadedImageList] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadTime, setUploadTime] = useState<number>(0);
  const [quality, setQuality] = useState<string>("90");
  const [format, setFormat] = useState<string>("avif");
  const [resize, setResize] = useState<boolean>(false);
  const [transform, setTransform] = useState<boolean>(false);

  return (
    <div className={styles["image-conversion"]}>
      <div
        className={clsx(
          styles["upload-image"],
          inDropZone && styles["in-drop-zone"]
        )}
        onDragEnter={(e) => handleDragEnter(e)}
        onDragOver={(e) => handleDragOver(e)}
        onDragLeave={(e) => handleDragLeave(e)}
        onDrop={(e) => handleDrop(e)}
      >
        {/* <ImageFile /> */}
        <p className={styles["upload-image-text"]}>
          {"Glissez-déposez votre image ici, ou "}
          <span
            className={styles["upload-image-button"]}
            onClick={handleAddClick}
          >
            sélectionnez un fichier
          </span>
        </p>
        <input
          ref={hiddenFileInput}
          onChange={handleAddImage}
          type="file"
          hidden
        />
      </div>
      <div className={styles["file-list"]}>
        {fileList.map((file, index) => {
          return (
            <FileCard
              key={index}
              file={file}
              handleDeleteFile={() => handleDeleteFile(index)}
            />
          );
        })}
      </div>
      {fileList.length > 0 && (
        <div className={styles["upload-button-container"]}>
          <div>
            <label>Format de conversion</label>
            <Select
              value={format}
              optionsList={CONVERSION_FORMAT}
              setValue={(value) => setFormat(value)}
            />
          </div>
          <div>
            <label>{`Qualité (entre 1 et 100)`}</label>
            <Input value={quality} onChange={handleChangeQuality} />
          </div>
          <div>
            <label>{`Resize`}</label>
            <Input
              type={"checkbox"}
              checked={resize}
              onChange={() => setResize(!resize)}
            />
          </div>
          <div>
            <label>{`Transformer`}</label>
            <Input
              type={"checkbox"}
              checked={transform}
              onChange={() => setTransform(!transform)}
            />
          </div>
          <Button onClick={handleUploadImage}>
            UPLOAD <Upload />
          </Button>
        </div>
      )}
      {isLoading ? (
        <LoadingSpinner color={"gold"} />
      ) : (
        <>
          {uploadedImageList.length > 0 && (
            <div>
              {uploadedImageList.map((imageUri, index) => (
                <UploadedImagePreview key={index} imageUri={imageUri} />
              ))}
              <hr />
              {uploadTime > 0 && (
                <div className={styles["info-line"]}>
                  <p>Temps</p>
                  <p> {formatSeconds(uploadTime)}</p>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );

  async function handleUploadImage() {
    setIsLoading(true);
    setUploadTime(0);
    const startTime = performance.now();
    try {
      const imageUriList: string[] = [];
      for (let file of fileList) {
        const { message: imageUri } = await streamFile(
          `_upload-image-test.`,
          file
        );
        imageUriList.push(imageUri);
      }
      setUploadedImageList(imageUriList);
    } catch (e: any) {
      // console.debug(e);
      toast.error("Upload Image failed !");
    } finally {
      const endTime = performance.now();
      setUploadTime(endTime - startTime);
      setIsLoading(false);
    }
  }

  function handleAddImage(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target?.files || !e.target.files[0]) {
      return;
    }
    setFileList([...fileList, e.target.files[0]]);
  }

  function handleChangeQuality({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    if (isNaN(parseInt(target.value))) {
      return setQuality("");
    }
    const num = Number(target.value);
    if (num >= 1 && num <= 100) {
      return setQuality(target.value);
    }
    return setQuality("");
  }

  function handleAddClick() {
    hiddenFileInput?.current?.click();
  }

  function handleDeleteFile(index: number) {
    setFileList(fileList.filter((_, i) => i !== index));
    setUploadedImageList(uploadedImageList.filter((_, i) => i !== index));
  }

  // onDragEnter sets inDropZone to true
  function handleDragEnter(e: any) {
    e.preventDefault();
    e.stopPropagation();
    setInDropZone(true);
  }

  // onDragLeave sets inDropZone to false
  function handleDragLeave(e: any) {
    e.preventDefault();
    e.stopPropagation();
    setInDropZone(false);
  }

  // onDragOver sets inDropZone to true
  function handleDragOver(e: any) {
    e.preventDefault();
    e.stopPropagation();
    setInDropZone(true);

    // set dropEffect to copy i.e copy of the source item
    e.dataTransfer.dropEffect = "copy";
  }

  // onDrop sets inDropZone to false and adds files to fileList
  function handleDrop(e: any) {
    e.preventDefault();
    e.stopPropagation();

    setInDropZone(false);
    if (!e.dataTransfer.files || !e.dataTransfer.files[0]) {
      return;
    }
    setFileList([...fileList, e.dataTransfer.files[0]]);
  }
}

interface FileCardProps {
  file: File;
  handleDeleteFile: () => void;
}

function FileCard({ file, handleDeleteFile }: FileCardProps) {
  const { setViewerIsOpen, setPictureList, setIsBlob } = useImageViewer();
  return (
    <div className={styles["file-card"]}>
      <div className={styles["file-info"]}>
        <Image
          src={URL.createObjectURL(file)}
          alt={file.name}
          isBlob
          width={360}
          height={360}
          className={styles["preview-image"]}
          onClick={handleOpenImageViewer}
        />
        <div className={styles["info-line"]}>
          <p>Format du fichier</p>
          <p>{file.type}</p>
        </div>
        <div className={styles["info-line"]}>
          <p>Taille du fichier</p>
          <p>{formatBytes(file.size)}</p>
        </div>
      </div>
      <Button className={styles["delete-button"]} onClick={handleDeleteFile}>
        Supprimer
      </Button>
    </div>
  );

  function handleOpenImageViewer() {
    setPictureList([URL.createObjectURL(file)]);
    setIsBlob(true);
    setViewerIsOpen(true);
  }
}

function UploadedImagePreview({ imageUri }: { imageUri: string }) {
  const { setViewerIsOpen, setPictureList, setIsBlob } = useImageViewer();
  return (
    <div className={styles["file-info"]}>
      <Image
        src={imageUri}
        alt={imageUri}
        // isBlob
        width={360}
        height={360}
        className={styles["preview-image"]}
        onClick={handleOpenImageViewer}
      />
      {/* <p className={styles["file-name"]}>{imageInfo.imageUri}</p> */}
      {/* <div className={styles["info-line"]}>
        <p>Taille du fichier</p>
        <p>{formatBytes(imageInfo.size)}</p>
      </div> */}
      <div className={styles["info-line"]}>
        <p></p>
        <Button onClick={() => handleDownload(imageUri)}>Télécharger</Button>
      </div>
    </div>
  );

  async function handleDownload(filname: string) {
    try {
      let data = await getFile(filname);
      const blob = await data.blob();
      const downloadLink = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadLink;
      link.download = filname;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.debug(e);
    }
  }

  function handleOpenImageViewer() {
    setPictureList([imageUri]);
    setIsBlob(false);
    setViewerIsOpen(true);
  }
}

function formatBytes(bytes: number) {
  if (bytes < 0) {
    return "Invalid input";
  }

  const units = ["bytes", "KB", "MB", "GB", "TB"];
  let index = 0;

  while (bytes >= 1000 && index < units.length - 1) {
    bytes /= 1000;
    index++;
  }

  return `${bytes.toFixed(2)} ${units[index]}`;
}

function calculateCompressionPercentage(
  initialSize: number,
  convertedSize: number
) {
  if (initialSize <= 0) {
    return "Invalid initial size";
  }
  if (convertedSize < 0) {
    return "Invalid converted size";
  }

  const compressionAmount = initialSize - convertedSize;
  const compressionPercentage = (compressionAmount / initialSize) * 100;

  return `${compressionPercentage.toFixed(2)}%`;
}

function formatSeconds(milliseconds: number) {
  if (milliseconds < 0) {
    return "Invalid time";
  }
  const seconds = milliseconds / 1000;
  return `${seconds.toFixed(2)} seconds`;
}
