import { IDiscount } from "../../../../types/accounting.types";
import styles from "./discount.module.scss";

interface DiscountProps {
  discount: IDiscount | null | undefined;
}

export default function Discount({ discount }: DiscountProps) {
  return (
    <div className={styles["discount-container"]}>
      <div className={styles["title"]}>Codes Promos</div>
      <div className={styles["table-container"]}>
        {discount ? (
          <table>
            <tbody>
              <tr>
                <th>{"Code"}</th>
                <th>{"Valeur"}</th>
              </tr>
              <tr>
                <td>{discount.code}</td>
                <td>
                  {Math.floor(discount.value * 100) / 100}{" "}
                  {discount.isRate ? "%" : "€"}
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <div className={styles["not-found-message"]}>aucun code promo</div>
        )}
      </div>
    </div>
  );
}
