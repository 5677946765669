import Select from "../../../../components/Select/Select";
import { IShippingMethod } from "../../../../types/accounting.types";
import { IPeriod } from "../../../../types/shipping.types";
import { DEFAULT_BUSINESS_HOURS } from "../../../../utils/shippingMethod.init";
import style from "./selectDate.module.scss";

interface SelectDateProps {
  shippingDate: Date | null;
  setShippingDate: (shippingDate: Date | null) => void;
  shippingMethod: IShippingMethod;
  shippingPeriod: IPeriod | null;
  setShippingPeriod: (shippingPeriod: IPeriod | null) => void;
  shippingLabel: string | null;
  dueDate?: Date;
}

export default function SelectDate({
  shippingDate,
  setShippingDate,
  shippingMethod,
  shippingPeriod,
  setShippingPeriod,
  shippingLabel,
  dueDate,
}: SelectDateProps) {
  return (
    <div className={style["select-date"]}>
      <input
        className={style["date-input"]}
        type="date"
        value={shippingDate ? shippingDate.toISOString().substring(0, 10) : ""}
        onChange={handleChangeDate}
        min={dueDate?.toISOString().split("T")[0]}
      />
      {shippingDate &&
        ["INTERNAL", "DROP-OR-COLLECT"].includes(
          shippingMethod.shippingService
        ) && (
          <>
            <label>Sélectionnez un créneau de livraison</label>
            <Select
              className={style["select-shipping-method"]}
              optionsList={(
                shippingMethod.businessHours || DEFAULT_BUSINESS_HOURS
              ).map((bh) => `${bh.start} - ${bh.end}`)}
              placeholder="Créneaux de livraisons..."
              setValue={handleChangePeriod}
              value={
                shippingPeriod
                  ? `${new Date(shippingPeriod.start)
                      .toLocaleTimeString("fr-FR")
                      .substring(0, 5)} - ${new Date(shippingPeriod.end)
                      .toLocaleTimeString("fr-FR")
                      .substring(0, 5)}`
                  : ""
              }
            />
          </>
        )}
    </div>
  );

  function handleChangeDate({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    if (shippingLabel) {
      return window.alert(
        'Vous ne pouvez pas faire de modifications. Vous devez "Annuler" le bon chronopost actuel avant de pouvoir faire des modifications.'
      );
    }
    setShippingDate(new Date(target.value));
  }

  function handleChangePeriod(value: string) {
    if (shippingLabel) {
      return window.alert(
        'Vous ne pouvez pas faire de modifications. Vous devez "Annuler" le bon chronopost actuel avant de pouvoir faire des modifications.'
      );
    }
    if (!shippingDate) {
      return;
    }
    setShippingPeriod({
      start: new Date(
        shippingDate.toISOString().substring(0, 10) +
          "T" +
          value.substring(0, 5)
      ),
      end: new Date(
        shippingDate.toISOString().substring(0, 10) + "T" + value.substring(8)
      ),
    });
  }
}
