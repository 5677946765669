import { useDetailedLabel } from "./useDetailedLabel";
import { usePrinter } from "../../../context/printer.context";
import PrintButton from "../../../components/PrintButton/PrintButton";

interface RearLabelProps {
  strings: string[];
  workshopId: string;
  dueDate: Date | null;
}

export default function RearLabel({
  strings,
  workshopId,
  dueDate,
}: RearLabelProps) {
  const canvas = useDetailedLabel(
    strings,
    workshopId,
    dueDate
      ? "à faire avant le: " + new Date(dueDate).toLocaleDateString("fr-FR")
      : "-"
  );

  const { onPrint, printProgress, printState, connectionState } = usePrinter();

  return (
    <div>
      <div>
        <canvas ref={canvas} style={{ border: "1px solid black" }} />
      </div>
      {connectionState === "connected" && (
        <PrintButton
          handleOnPrint={handleOnPrint}
          printProgress={printProgress}
          printState={printState}
        />
      )}
    </div>
  );

  function handleOnPrint() {
    onPrint({ canvas: canvas.current });
  }
}
