import { QueryFunctionContext } from "react-query";

export interface ProductReportData {
  itemName: string;
  totalPrice: number;
  itemCount: number;
}

export interface ProductChartData {
  label: string;
  id: string;
  value: number;
}

export interface ProductReportResponse {
  data: ProductReportData[];
}

export interface ProductChartResponse {
  data: ProductChartData[];
}

export interface EstimateReportData {
  estimateCount: number;
  productCount: number;
  leatherCount: number;
  manShoesCount: number;
  womanShoesCount: number;
  sneakersShoesCount: number;
  climbingShoesCount: number;
  unknownCount: number;
  totalPrice: number;
}

export interface EstimateReportResponse {
  data: EstimateReportData;
}

export interface ReportRequest {
  dateFrom?: Date;
  dateTo?: Date;
  eventDescription: string;
  eventService: string;
  operationGroup?: string;
}

export interface ChartRequest {
  dateFrom?: Date;
  dateTo?: Date;
  eventDescription: string;
  eventService: string;
  operationGroup?: string;
  field: string;
}

export interface ProductsMonthlyChartData {
  month: string;
  unknown: number;
  shoes_man: number;
  shoes_woman: number;
  shoes_sneakers: number;
  shoes_climbing: number;
  leather_large: number;
}

export interface OperationsMonthlyChartData {
  month: string;
  [key: string]: any;
}

export interface ProductsMonthlyChartResponse {
  data: ProductsMonthlyChartData[];
}

export interface OperationsMonthlyChartResponse {
  data: OperationsMonthlyChartData[];
}

export async function getOperationsMonthlyReport({ from, to }: {from: Date, to: Date}): Promise<any> {
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "GET",
  };

  if (!from) {
    throw new Error("Missing 'from' parameter.");
  }
  if (!to) {
    throw new Error("Missing 'to' parameter.");
  }

  try {
    const query = new URLSearchParams({
      from: from.toISOString(),
      to: to.toISOString(),
    }).toString();

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/reports/table/monthly-operations?${query}&output=CSV`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getDeliveriesMonthlyReport({ from, to }: {from: Date, to: Date}): Promise<any> {
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "GET",
  };

  if (!from) {
    throw new Error("Missing 'from' parameter.");
  }
  if (!to) {
    throw new Error("Missing 'to' parameter.");
  }

  try {
    const query = new URLSearchParams({
      from: from.toISOString(),
      to: to.toISOString(),
    }).toString();

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/reports/table/monthly-deliveries?${query}&output=CSV`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getProductsMonthlyChart({
  queryKey,
}: QueryFunctionContext<
  [string, { year: string; valueKey: string }],
  any
>): Promise<OperationsMonthlyChartResponse> {
  const [_key, { year, valueKey }] = queryKey;
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "GET",
  };

  if (!year) {
    throw new Error("Missing year parameter.");
  }
  if (!valueKey) {
    throw new Error("Missing valueKey parameter.");
  }

  try {
    const query = new URLSearchParams({
      year,
      valueKey,
    }).toString();

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/reports/charts/monthly-products?${query}`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getOperationsMonthlyChart({
  queryKey,
}: QueryFunctionContext<
  [string, { year: string; valueKey: string }],
  any
>): Promise<OperationsMonthlyChartResponse> {
  const [_key, { year, valueKey }] = queryKey;
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "GET",
  };

  if (!year) {
    throw new Error("Missing year parameter.");
  }
  if (!valueKey) {
    throw new Error("Missing valueKey parameter.");
  }

  try {
    const query = new URLSearchParams({
      year,
      valueKey,
    }).toString();

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/reports/charts/monthly-operations?${query}`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getProductsChart({
  queryKey,
}: QueryFunctionContext<
  [string, ChartRequest],
  any
>): Promise<ProductChartResponse> {
  const [_key, { dateFrom, dateTo, eventDescription, eventService, field }] =
    queryKey;
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "GET",
  };

  if (!field) {
    throw new Error("Missing field parameter.");
  }

  if (!dateFrom || !dateTo) {
    throw new Error("Missing dateFrom or dateTo parameter.");
  }

  try {
    const query = new URLSearchParams({
      dateFrom: dateFrom.toISOString(),
      dateTo: dateTo.toISOString(),
      eventDescription,
      eventService,
      field,
    }).toString();

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/reports/charts/products?${query}`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getProductsReport({
  queryKey,
}: QueryFunctionContext<
  [string, ReportRequest],
  any
>): Promise<ProductReportResponse> {
  const [_key, { dateFrom, dateTo, eventDescription, eventService }] = queryKey;
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "GET",
  };

  if (!dateFrom || !dateTo) {
    throw new Error("Missing dateFrom or dateTo parameter.");
  }

  try {
    const query = new URLSearchParams({
      dateFrom: dateFrom.toISOString(),
      dateTo: dateTo.toISOString(),
      eventDescription,
      eventService,
    }).toString();

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/reports/products?${query}`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getOperationsReport({
  queryKey,
}: QueryFunctionContext<
  [string, ReportRequest],
  any
>): Promise<ProductReportResponse> {
  const [
    _key,
    { dateFrom, dateTo, eventDescription, eventService, operationGroup },
  ] = queryKey;
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "GET",
  };

  if (!dateFrom || !dateTo) {
    throw new Error("Missing dateFrom or dateTo parameter.");
  }
  if (!operationGroup) {
    throw new Error("Missing operationGroup parameter.");
  }

  try {
    const query = new URLSearchParams({
      dateFrom: dateFrom.toISOString(),
      dateTo: dateTo.toISOString(),
      eventDescription,
      eventService,
      operationGroup,
    }).toString();

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/reports/operations?${query}`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getEstimatesReport({
  queryKey,
}: QueryFunctionContext<
  [string, ReportRequest],
  any
>): Promise<EstimateReportResponse> {
  const [_key, { dateFrom, dateTo, eventDescription, eventService }] = queryKey;
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "GET",
  };

  if (!dateFrom || !dateTo) {
    throw new Error("Missing dateFrom or dateTo parameter.");
  }

  try {
    const query = new URLSearchParams({
      dateFrom: dateFrom.toISOString(),
      dateTo: dateTo.toISOString(),
      eventDescription,
      eventService,
    }).toString();

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/reports/estimates?${query}`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}
