import { createContext, useContext, useState } from "react";
import { LoginResponse } from "../requests/user";

interface AuthContextProps {
  uid: string | undefined;
  username: string | undefined;
  roles: string[] | undefined;
  isAuthenticated: boolean;
  registerUser: (data: LoginResponse) => void;
  unregisterUser: () => void;
  hasPermission: (...authorizedRoles: string[]) => boolean;
}

function loadStorage() {
  const user = localStorage.getItem("gp-management.user");
  if (user && user !== "undefined") {
    return JSON.parse(user);
  }
  return undefined;
}

function saveStorage(user: {
  uid: string;
  username: string;
  roles: string[];
  isAuthenticated: boolean;
}) {
  localStorage.setItem("gp-management.user", JSON.stringify(user));
}

function removeStorage() {
  localStorage.removeItem("gp-management.user");
}

const defaultContextProps: AuthContextProps = {
  uid: undefined,
  username: undefined,
  roles: undefined,
  isAuthenticated: false,
  registerUser: (data: LoginResponse) =>
    saveStorage({ ...data, isAuthenticated: !!data.uid }),
  unregisterUser: () => removeStorage,
  hasPermission: (...authorizedRoles: string[]) => false,
};

const AuthContext = createContext<AuthContextProps>(defaultContextProps);

export default function AuthProvider({ children }: React.PropsWithChildren) {
  const [user, setUser] = useState(loadStorage());

  function registerUser(data: LoginResponse) {
    const u = { ...data, isAuthenticated: !!data.uid };
    saveStorage(u);
    setUser(u);
  }

  function unregisterUser() {
    removeStorage();
    setUser(undefined);
  }

  function hasPermission(...authorizedRoles: string[]) {
    if (!user) {
      return false;
    }
    if (!user.isAuthenticated) {
      return false;
    }
    if (!user.roles) {
      return false;
    }
    return user.roles.some((role: string) => authorizedRoles.includes(role));
  }

  return (
    <AuthContext.Provider
      value={{
        ...user,
        registerUser,
        unregisterUser,
        hasPermission,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}
