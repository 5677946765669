import { ACRONYM_LEVEL, SLUG_TO_ACRONYM } from "../translation/operation";
import { IOperation } from "../types/accounting.types";

export function parseOperationsAcronym(
  operationList: IOperation[],
  operationflattenedList: string[]
) {
  const acronymList =
    operationflattenedList.map((accronym: string) => {
      return {
        acronym: accronym,
        level: ACRONYM_LEVEL.get(accronym),
      };
    }) || [];
  const operationsAcronym: string[][] = [];
  if (acronymList.length > 0) {
    for (let level = 0; level < 4; level++) {
      const line: string[] | null =
        acronymList.filter((a) => a.level === level).map((a) => a.acronym) ||
        null;
      if (line && line.length) {
        operationsAcronym.push(line);
      }
    }
  }
  const moreOperations = parseNonConventionalOperations(operationList).length;
  if (moreOperations) {
    return operationsAcronym.map((op, index, array) => {
      if (index === array.length - 1) {
        return [...op, `+${moreOperations}`];
      } else {
        return op;
      }
    });
  }
  return operationsAcronym;
}

export function parseNonConventionalOperations(operationList: IOperation[]) {
  const nonConventionalOperations: string[] =
    operationList
      .filter((op) => !op.canceled && !SLUG_TO_ACRONYM.get(op.slug))
      .map((op) => op.description) || [];
  return nonConventionalOperations;
}

export function getOperationsListAcronym(
  operationList: IOperation[]
): string[] {
  const slugList = operationList
    .filter((op) => !op.canceled && SLUG_TO_ACRONYM.get(op.slug))
    .map((op) => SLUG_TO_ACRONYM.get(op.slug)!);
  const flattenedList: string[] = slugList
    .flat(Infinity)
    .filter((item) => typeof item === "string") as string[];

  return [...new Set((flattenedList as string[]) || [])];
}
