import { useState } from "react";
import SelectDirection from "./SelectDirection/SelectDirection";
import style from "./Create.module.scss";
import SelectShippingMethod from "./SelectShippingMethod/SelectShippingMethod";
import { IShippingMethod } from "../../../types/accounting.types";
import {
  SHIPPING_METHOD_CHRONOPOST_2SHOP,
  SHIPPING_METHOD_CHRONOPOST_EUR,
  SHIPPING_METHOD_IN_STORE_DROP_OFF_COLLECT,
  SHIPPING_METHOD_INTERNAL,
} from "../../../utils/shippingMethod.init";
import {
  IExternalShipping,
  IPeriod,
  IShipping,
  IShippingPoint,
  PickUpInfo,
} from "../../../types/shipping.types";
import { IContact, IProduct } from "../../../types/logistic.types";
import ContactSection from "./ContactSection/ContactSection";
import AddressSection from "./AddressSection/AddressSection";
import SelectDate from "./SelectDate/SelectDate";
import SelectState from "./SelectState/SelectState";
import ValidateButton from "../../../components/validateButton/validateButton";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import GenerateLabel from "./GenerateLabel/GenerateLabel";
import {
  GP_SHIPPING_ADDRESS,
  INITIAL_SHIPPING_EXTERNAL,
  STATE_OPTIONS,
} from "../../../utils/shipping.init";
import { addShipping, updateShipping } from "../../../requests/shipping";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getAddressesList } from "../../../requests/customer";
import SectionFormTemplate from "../../../components/forms/sectionFormTemplate/SectionFormTemplate";
import ProductSection from "../../../components/forms/productForm/ProductSection";
import OverviewButton from "../../../components/OverviewButton/OverviewButton";

interface CreateProps {
  refetch?: () => void;
  shipping?: IShipping;
  dueDate?: Date;
}

export default function Create({ refetch, shipping, dueDate }: CreateProps) {
  const [state, setState] = useState<string>(shipping?.state || "PENDING");
  const [direction, setDirection] = useState<string | null>(
    shipping?.direction || null
  );
  const [externalShipping, setExternalShipping] =
    useState<IExternalShipping | null>(shipping?.externalShipping || null);
  const [pickUpInfo, setPickUpInfo] = useState<PickUpInfo | null>(null);
  const [shippingMethod, setShippingMethod] = useState<IShippingMethod | null>(
    (shipping?.shippingMethod as IShippingMethod) || null
  );
  const [productsList, setProductsList] = useState<IProduct[]>(
    shipping?.productsList || []
  );
  const [shippingPeriod, setShippingPeriod] = useState<IPeriod | null>(
    shipping?.period || null
  );
  const [address, setAddress] = useState<IShippingPoint | null>(initAddress());
  const [contact, setContact] = useState<IContact | null>(
    shipping?.contact || null
  );
  const [shippingDate, setShippingDate] = useState<Date | null>(initDate());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: addressesList } = useQuery(
    ["customer-addresses", contact?.id],
    () => getAddressesList(contact?.id),
    { enabled: Boolean(contact?.id) }
  );
  const navigate = useNavigate();
  const SHIPPING_METHOD_LIST = [
    SHIPPING_METHOD_INTERNAL,
    SHIPPING_METHOD_CHRONOPOST_2SHOP,
    SHIPPING_METHOD_CHRONOPOST_EUR,
    SHIPPING_METHOD_IN_STORE_DROP_OFF_COLLECT,
  ];

  return (
    <div className={style["shipping-form"]}>
      <div className={style["shipping-form-container"]}>
        <div className={style["title"]}>
          <span className={style["title-container"]}>
            {shipping
              ? `Modifier document ${
                  direction === "INCOMING" ? "collecte" : "livraison"
                }`
              : `Nouveau document livraison`}
            <OverviewButton trackId={shipping?.trackId} />
          </span>
          <div className={style["top-right"]}>
            <SelectState
              label="Status de la livraison"
              state={state}
              setState={setState}
              stateOption={STATE_OPTIONS}
            />
          </div>
        </div>
        <SectionFormTemplate title={"Direction"}>
          <SelectDirection
            direction={direction}
            setDirection={setDirection}
            isEdit={Boolean(shipping)}
            shippingLabel={externalShipping?.reservationNumber || null}
          />
        </SectionFormTemplate>
        {stepByStep("shipping-method") && (
          <SectionFormTemplate title={"Méthode de livraison"}>
            <SelectShippingMethod
              shippingLabel={externalShipping?.reservationNumber || null}
              resetAddress={resetAddress}
              shippingMethod={shippingMethod}
              setShippingMethod={setShippingMethod}
              shippingMethodList={SHIPPING_METHOD_LIST}
            />
          </SectionFormTemplate>
        )}
        {stepByStep("contact") && (
          <SectionFormTemplate title={"Contact"}>
            <ContactSection
              contact={contact}
              setContact={setContact}
              shippingLabel={externalShipping?.reservationNumber || null}
            />
          </SectionFormTemplate>
        )}
        {stepByStep("address") && (
          <SectionFormTemplate title={"Adresse"}>
            <AddressSection
              addressesList={addressesList}
              shippingMethod={shippingMethod || SHIPPING_METHOD_INTERNAL}
              setPickUpInfo={setPickUpInfo}
              address={address}
              setAddress={setAddress}
              shippingLabel={externalShipping?.reservationNumber || null}
            />
          </SectionFormTemplate>
        )}
        {stepByStep("shipping-date") && (
          <SectionFormTemplate title={"Date de livraison"}>
            <SelectDate
              shippingDate={shippingDate}
              setShippingDate={setShippingDate}
              shippingMethod={shippingMethod || SHIPPING_METHOD_INTERNAL}
              shippingPeriod={shippingPeriod}
              setShippingPeriod={setShippingPeriod}
              shippingLabel={externalShipping?.reservationNumber || null}
              dueDate={dueDate}
            />
          </SectionFormTemplate>
        )}
        {stepByStep("product") && (
          <>
            <hr className={style["separator"]} />
            <ProductSection
              productsList={productsList}
              setProductsList={setProductsList}
            />
          </>
        )}
        {stepByStep("generate-label") && (
          <GenerateLabel
            shipping={shipping || null}
            setDocument={setExternalShipping}
            refetch={refetch}
          />
        )}
        {stepByStep("validate") && (
          <div className={style["validate-shipping-container"]}>
            <ValidateButton
              className={style["validate-shipping-button"]}
              handleOnClick={handleValidateForm}
              isLoading={isLoading}
              title={shipping && shipping.id ? "Mettre à jour" : "Créer"}
            />
          </div>
        )}
      </div>
    </div>
  );

  async function handleValidateForm() {
    const shippingDocument = generateShippingDocument();
    if (!shippingDocument) {
      return;
    }
    if (formValidationError()) {
      return toast.error(
        `Veuillez remplir les champs requis. [${formValidationError()}]`,
        TOAST_ERROR_OPTIONS
      );
    }
    setIsLoading(true);
    try {
      const shippingId =
        shipping && shipping.id
          ? await updateShipping(shippingDocument, true)
          : await addShipping(shippingDocument);
      toast.success(
        `Document shipping ${
          shipping && shipping.id ? "mis à jour" : "crée"
        } avec succès.`,
        TOAST_SUCCESS_OPTIONS
      );
      if (shipping?.trackId) {
        navigate(`/overview/${shipping?.trackId}`);
      } else {
        navigate("/shipping-calendar");
      }
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    } finally {
      setIsLoading(false);
    }
  }

  function generateShippingDocument() {
    if (!contact) {
      toast.error(
        "Veuillez rentrer les informations du contact.",
        TOAST_ERROR_OPTIONS
      );
      return;
    }
    if (!address) {
      toast.error(
        `Veuillez rentrer l'adresse de ${
          direction === "INCOMING" ? "collecte" : "livraison"
        }.`,
        TOAST_ERROR_OPTIONS
      );
      return;
    }
    if (!direction || !shippingDate || !shippingMethod) {
      toast.error(
        `Veuillez sélectionner la date ou le créneau de la ${
          direction === "INCOMING" ? "collecte" : "livraison"
        }.`,
        TOAST_ERROR_OPTIONS
      );
      return;
    }

    const period = formatPeriod(shippingDate);
    const formattedContact = formatContact(contact);
    const formattedAddress = formatAddress(formattedContact, pickUpInfo);

    function formatExternalShipping() {
      if (!externalShipping) {
        return INITIAL_SHIPPING_EXTERNAL;
      }
      if (pickUpInfo) {
        return { ...externalShipping, pickUpId: pickUpInfo.pickUpId };
      } else {
        return externalShipping;
      }
    }

    let _shipping: IShipping = {
      ...shipping,
      state,
      direction,
      shippingMethod,
      contact: formattedContact,
      sender: direction === "INCOMING" ? formattedAddress : GP_SHIPPING_ADDRESS,
      recipient:
        direction === "INCOMING" ? GP_SHIPPING_ADDRESS : formattedAddress,
      period,
      productsList,
      externalShipping: formatExternalShipping(),
    };
    if (shippingMethod.shippingService === "INTERNAL") {
      delete _shipping["externalShipping"];
    }

    return _shipping;
  }

  function formValidationError() {
    if (
      !contact?.givenname ||
      !contact?.familyname ||
      !contact?.phone ||
      !contact?.email ||
      !contact?.displayname
    )
      return "contact";
    if (!address?.address1) {
      return "addresse";
    }
    return "";
  }

  function stepByStep(step: string) {
    switch (step) {
      case "shipping-method":
        return !!direction;
      case "contact":
        return shippingMethod && direction;
      case "address":
        return shippingMethod && direction && contact;
      case "shipping-date":
        return shippingMethod && direction && contact && address;
      case "product":
        return showProductForm();
      case "generate-label":
        return (
          shippingMethod &&
          direction &&
          contact &&
          address &&
          externalShipping &&
          shipping?.shippingMethod?.shippingService !== "INTERNAL"
        );
      case "validate":
        return (
          shippingMethod &&
          direction &&
          contact &&
          address &&
          shippingDate &&
          ((shippingMethod.shippingService === "INTERNAL" && shippingPeriod) ||
            shippingMethod.shippingService !== "INTERNAL") &&
          productsList.length > 0
        );
      default:
        return false;
    }
  }

  function initAddress() {
    if (!shipping) {
      return null;
    }
    if (shipping.direction === "INCOMING") {
      return shipping.sender || null;
    } else {
      return shipping.recipient || null;
    }
  }

  function initDate() {
    console.debug(dueDate);
    if (!shipping || !shipping.period?.start) {
      if (dueDate) {
        return new Date(dueDate);
      }
      return null;
    }
    return new Date(shipping.period?.start);
  }

  function showProductForm() {
    if (shipping && productsList.length > 0) {
      return true;
    }
    return (
      (shippingDate &&
        (shippingMethod?.shippingService === "CHRONOPOST-2SHOP" ||
          shippingMethod?.shippingService === "CHRONOPOST-EUR")) ||
      (shippingDate &&
        shippingPeriod &&
        shippingMethod?.shippingService === "INTERNAL")
    );
  }

  function resetAddress() {
    setAddress(null);
    setShippingPeriod(null);
    setShippingDate(null);
    setExternalShipping(null);
  }

  function formatContact(contact: IContact) {
    return {
      ...address,
      displayname: contact.displayname,
      givenname: contact.givenname,
      familyname: contact.familyname,
      organization: contact.organization,
      phone: contact.phone,
      email: contact.email,
    };
  }

  function formatPeriod(shippingDate: Date) {
    if (!shippingPeriod) {
      return {
        start: shippingDate,
        end: shippingDate,
      };
    }

    return {
      start: new Date(
        shippingDate.toISOString().substring(0, 10) +
          "T" +
          new Date(shippingPeriod.start)
            .toLocaleTimeString("fr-FR")
            .substring(0, 5)
      ),
      end: new Date(
        shippingDate.toISOString().substring(0, 10) +
          "T" +
          new Date(shippingPeriod.end)
            .toLocaleTimeString("fr-FR")
            .substring(0, 5)
      ),
    };
  }

  function formatAddress(contact: IContact, pickUpInfo: PickUpInfo | null) {
    return {
      ...contact,
      givenname: pickUpInfo ? pickUpInfo.name : contact.givenname,
    };
  }
}
