import { Close, WarningAmberOutlined } from "@mui/icons-material";
import translate from "../../../translation";
import { IInvoice, IQuote } from "../../../types/tracker.types";
import style from "./quoteMiniature.module.scss";
import { useNavigate } from "react-router-dom";
import getStatusColor from "../../../translation/statusColor";
import { useContext, useEffect, useState } from "react";
import { useModalComponent } from "../../../context/modalComponent.context";
import QuoteModal from "../../modals/quoteModal/quoteModal";
import { useHideSideBar } from "../../../context/hideSideBar.context";

export default function QuoteMiniature({
  trackerId,
  quote,
  invoice,
  requiredActions,
}: {
  trackerId: string;
  quote: IQuote;
  requiredActions: boolean;
  invoice: IInvoice;
}) {
  const navigate = useNavigate();

  const [requiredActionsBubble, setRequiredActionsBubble] =
    useState<boolean>(requiredActions);
  const { modalIsOpen, setModalIsOpen, setModalChildren } = useModalComponent();
  const { setHideSide } = useHideSideBar();

  useEffect(() => {
    if (!modalIsOpen) {
      setRequiredActionsBubble(requiredActions);
    }
  }, [requiredActions, modalIsOpen]);

  function openQuoteModal() {
    if (quote && quote.documentId.toString() !== "") {
      setRequiredActionsBubble(false);
      setModalIsOpen(true);
      setModalChildren(
        <QuoteModal
          id={quote.documentId}
          invoice={invoice}
          requiredActions={requiredActions}
        />
      );
      // setHideSide(true);
    }
  }

  return (
    <div className={style["quote-miniature"]}>
      {requiredActionsBubble && quote && (
        <div className={style["required-action-notification"]}>
          <div className={style["required-action-notification-container"]}>
            <div
              className={style["required-action-notification-message"]}
              onClick={openQuoteModal}
            >
              Actions requises
              <WarningAmberOutlined className={style["warning-icon"]} />
            </div>
            <div
              className={style["notification-close-button"]}
              onClick={() => setRequiredActionsBubble(false)}
            >
              <Close />
            </div>
          </div>
        </div>
      )}
      <div
        className={style["quote-miniature-container"]}
        onClick={openQuoteModal}
      >
        {quote && quote.documentId.toString() !== "" ? (
          <>
            <div className={style["top-container"]}>
              <div className={style["title"]}>Devis</div>
              <div
                className={style["state"]}
                style={{ backgroundColor: `${getStatusColor(quote.state)}` }}
              >
                {translate(quote.state)}
              </div>
            </div>
            <div className={style["body-container"]}>
              <div className={style["info-line"]}>
                <span>Date d'envoi</span>

                {quote.sentAt ? (
                  <span>
                    {new Date(quote.sentAt).toLocaleDateString("fr-FR")}
                  </span>
                ) : (
                  <span className={style["unavailable"]}>{"indisponible"}</span>
                )}
              </div>
              <div className={style["info-line"]}>
                <span>Montant</span>
                {quote.amount ? (
                  <span>{`${Math.floor(quote.amount * 100) / 100} €`}</span>
                ) : (
                  <span className={style["unavailable"]}>{"indisponible"}</span>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className={style["add-new-quote"]}>
            <div className={style["add-new-container"]}>
              <button className={style["add-new"]} onClick={generateQuote}>
                Générer un devis
              </button>
            </div>
          </div>
        )}
        {/* <hr className={style["separator"]} />
      {bill && bill.documentId.toString() !== "" ? (
        <>
          {" "}
          <div className={style["top-container"]}>
            <div className={style["title"]}>
              Facture {`n° ${bill.billNumber}`}{" "}
              <div className={style["arrow-button"]}>
                <ChevronRight className={style["arrow"]} />
              </div>
            </div>
            <div
              className={style["state"]}
              style={{ backgroundColor: `${getStatusColor(bill.state)}` }}
            >
              {translate(bill.state)}
            </div>
          </div>
          <div className={style["body-container"]}>
            <div className={style["info-line"]}>
              <span>Date de facturation</span>
              <span>
                {new Date(bill.createdAt).toLocaleDateString("fr-FR")}
              </span>
            </div>
            <div className={style["info-line"]}>
              <span>Date de paiement</span>
              <span>{new Date(bill.payedAt).toLocaleDateString("fr-FR")}</span>
            </div>
            <div className={style["info-line"]}>
              <span>Montant</span>
              <span>{`${bill.amountVAT} €`}</span>
            </div>
          </div>{" "}
        </>
      ) : (
        <div className={style["add-new-bill"]}>
          <div className={style["add-new-container"]}>
            <button className={style["add-new"]} onClick={() => {}}>
              Générer une facture
            </button>
          </div>
        </div>
      )} */}
      </div>
    </div>
  );

  function generateQuote() {
    navigate(`/quotes/generate/${trackerId}`);
  }
}
