import translate from "../../translation";
import style from "./statusItem.module.scss";
import getStatusColor from "../../translation/statusColor";
import { translateTrackingState } from "../../utils/tracking.init";

export function StatusItem({
  data,
  isGeneral,
  image,
}: {
  data: any;
  isGeneral?: boolean;
  image?: string;
}) {
  return (
    <div className={style["item-element"]}>
      {data && (data.documentId || isGeneral) ? (
        <div
          className={style["state-container"]}
          style={{
            backgroundColor: isGeneral
              ? getStatusColor(data.state)
              : "transparent",
            color: isGeneral ? "#ffffff" : getStatusColor(data.state),
            borderColor: getStatusColor(data.state),
          }}
        >
          {image && <img className={style["image"]} src={image} />}
          {isGeneral
            ? translateTrackingState(data.state)
            : translate(data.state)}
        </div>
      ) : (
        <div className={style["unavailable-item"]}>indisponible</div>
      )}
    </div>
  );
}
