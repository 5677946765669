import { useEffect, useMemo, useState } from "react";
import { SaveWidget } from "../incomingShipping/cartToLogistic/CheckIn/SaveWidget/SaveWidget";
import SliderContainer from "../incomingShipping/cartToLogistic/CheckIn/SliderContainer/SliderContainer";
import styles from "./inStoreDropOff.module.scss";
import {
  FormProduct,
  IContact,
  ILogistic,
  IProduct,
} from "../../../types/logistic.types";
import {
  DEFAULT_PRODUCT,
  INITIALE_DROP_ORDER_LOGISTIC,
} from "../../../utils/logistic.init";
import ProductTab from "../incomingShipping/cartToLogistic/CheckIn/ProductTab/ProductTab";
import { useMutation } from "react-query";
import { deleteFile } from "../../../requests/file";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../../../utils/toast.options";
import { generateRandomString } from "../../../utils/utils";
import { addDropOrder } from "../../../requests/tracker";
import { useNavigate } from "react-router-dom";
import { updateLogisticMutation } from "../../../requests/logistic";
import CheckInContactForm from "../incomingShipping/cartToLogistic/CheckIn/CheckInContactForm/CheckInContactForm";
import { logisticFormValidation } from "../../../utils/logistic.function";
import { ImageSearchOutlined, InsertPhotoOutlined } from "@mui/icons-material";
import { ModalContainer } from "../../../components/modals/ModalContainer";
import EstimateModal from "../../../components/modals/estimateModal/estimateModal";

interface InStoreDropOffProps {
  logisticData?: ILogistic;
}

export default function InStoreDropOff({ logisticData }: InStoreDropOffProps) {
  const [selectedProduct, setSelectedProduct] = useState<
    undefined | FormProduct
  >(undefined);
  const [logistic, setLogistic] = useState<ILogistic>(
    logisticData ? logisticData : INITIALE_DROP_ORDER_LOGISTIC
  );
  const [estimateModal, setEstimateModal] = useState<boolean>(false);
  const [error, setError] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    validateLogistic();
  }, [logistic]);

  const { mutate: deleteImage } = useMutation(deleteFile, {
    onSuccess() {},
    onError() {
      toast.error("Une erreur est survenue.", TOAST_ERROR_OPTIONS);
    },
  });

  const { mutate: addOrder } = useMutation(addDropOrder, {
    onSuccess(data) {
      const previousUrl = `/quick-access/in-store-drop-off/${data.trackId}`;
      navigate(`/quick-access/in-store-drop-off/quote-edit/${data.trackId}`, {
        state: { previousUrl },
      });
    },
    onError(error: any) {
      if (error.status === 409) {
        toast.error(
          `Utilisateur déjà existant, veuillez l'ajouter en utilisant l'auto-complétion.`,
          TOAST_ERROR_OPTIONS
        );
      } else {
        toast.error(
          `Une erreur est survenue. ${error?.message}`,
          TOAST_ERROR_OPTIONS
        );
      }
    },
    onSettled() {
      setIsLoading(false);
    },
  });

  const { mutate: updateOrder } = useMutation(updateLogisticMutation, {
    onSuccess() {
      if (!logisticData) {
        toast.error("Unexpected Error");
        return;
      }
      navigate(
        `/quick-access/in-store-drop-off/quote-edit/${logisticData.trackId}`
      );
    },
    onError() {
      setIsLoading(false);
      toast.error("Une erreur est survenue.", TOAST_ERROR_OPTIONS);
    },
    onSettled() {
      setIsLoading(false);
    },
  });

  return (
    <>
      <div className={styles["in-store-drop-off"]}>
        <div className={styles["in-store-drop-off-header"]}>
          <button
            className={styles["estimate-button"]}
            onClick={handleOpenEstimateModal}
          >
            <ImageSearchOutlined />
          </button>
          <CheckInContactForm
            contact={logistic.contact}
            onChange={handleContactChange}
          />
          <SaveWidget
            onClick={handleClickOnSave}
            error={error}
            disabled={error.length > 0}
            className={styles["responsive"]}
            isLoading={isLoading}
          />
        </div>
        <SliderContainer
          onAddClicked={handleAddProduct}
          logistic={logistic}
          onProductSelected={handleProductSelected}
          onProductDelete={handleDeleteProduct}
        />
        {selectedProduct && (
          <ProductTab
            trackId={"shop-deposit"} //trackId is just used for naming the uploaded pictures
            product={selectedProduct}
            onChange={updateLogisticProduct}
          />
        )}
        <SaveWidget
          onClick={handleClickOnSave}
          error={error}
          disabled={error.length > 0}
          className={styles["responsive-mobile"]}
          isResponsive
          isLoading={isLoading}
        />
      </div>
      <ModalContainer isOpen={estimateModal} onCancel={handleOpenEstimateModal}>
        <EstimateModal trackId={logistic.trackId} />
      </ModalContainer>
    </>
  );

  function handleOpenEstimateModal() {
    setEstimateModal(!estimateModal);
  }

  function handleClickOnSave() {
    setIsLoading(true);
    logisticData
      ? updateOrder({
          logistic,
          updateQuote: true,
          setShippingToDelivered: true,
        })
      : addOrder({
          ...logistic,
          productsList: logistic.productsList.map((p) => {
            delete p.id;
            return p;
          }),
        });
  }

  function handleContactChange(contact: IContact): void {
    setLogistic((prev) => ({ ...prev, contact }));
  }

  function handleAddProduct() {
    function generateUniqueProductId(logistic: ILogistic) {
      let id = generateRandomString(24);
      while (logistic.productsList.some((p) => p.id == id)) {
        id = generateRandomString(24);
      }
      return id;
    }
    const productId = generateUniqueProductId(logistic);
    changeSelectedProduct(productId, {
      ...logistic,
      productsList: [
        ...logistic.productsList,
        { ...DEFAULT_PRODUCT, id: productId },
      ],
    });
  }

  function handleProductSelected(product?: FormProduct): void {
    changeSelectedProduct(product?.id, logistic);
  }

  function changeSelectedProduct(
    productId: string | undefined,
    logistic: ILogistic
  ) {
    setLogistic((prev) => ({
      ...prev,
      productsList: logistic.productsList.map((p: FormProduct) => {
        return { ...p, selected: p.id === productId };
      }),
    }));
    setSelectedProduct(
      logistic.productsList.find((p: FormProduct) => p.id === productId)
    );
  }

  function updateLogisticProduct(product: FormProduct) {
    setLogistic((prev) => ({
      ...prev,
      productsList: prev.productsList.map((p: FormProduct) => {
        return p.id === product.id ? { ...product, selected: true } : p;
      }),
    }));
    setSelectedProduct(product);
  }

  function handleDeleteProduct(product: IProduct) {
    if (
      product.incomingPicturesList &&
      product.incomingPicturesList.length > 0
    ) {
      product.incomingPicturesList.forEach((f) => deleteImage(f));
    }
    setLogistic((prev) => ({
      ...prev,
      productsList: prev.productsList
        .filter((p) => p.id !== product.id)
        .map((p) => ({ ...p, selected: false })),
    }));
    setSelectedProduct(undefined);
  }

  async function validateLogistic() {
    try {
      setError([]);
      const errorList = await logisticFormValidation(logistic);
      setError(errorList);
    } catch (e) {
      setError([]);
    }
  }
}
