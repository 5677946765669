import clsx from "clsx";
import InputContainer from "../../../../../../../components/forms/inputs/inputContainer/inputContainer";
import { IOperation } from "../../../../../../../types/accounting.types";
import styles from "./operationEntry.module.scss";
import translate from "../../../../../../../translation";
import { Delete } from "@mui/icons-material";
import { OPERATION_TYPE_BY_GROUP } from "../../../../../../../utils/operation.init";

interface OperationEntryProps {
  operationGroup: string;
  operation: IOperation;
  datalist: IOperation[];
  onChange: (operation: IOperation) => void;
  onDelete: (operation: IOperation) => void;
}

export default function OperationEntry({
  operationGroup,
  operation,
  datalist,
  onChange,
  onDelete,
}: OperationEntryProps) {
  return (
    <div className={clsx(styles["operation-entry"])}>
      <InputContainer className={clsx(styles["operation-description"])}>
        <label>Opération</label>
        <input
          className={styles["operation-edit-input"]}
          type="text"
          name="description"
          onChange={handleChange}
          value={operation.description}
          autoComplete="off"
          list="operation-description"
        />
        <datalist id={"operation-description"}>
          {datalist &&
            datalist.map((operation: IOperation, key: number) => {
              if (!operation.canceled) {
                return (
                  <option key={key} className={styles["datalist-item"]}>
                    {operation.description || ""}
                  </option>
                );
              }
            })}
        </datalist>
      </InputContainer>
      <InputContainer className={clsx(styles["operation-price"])}>
        <label>Prix</label>
        <input
          className={styles["operation-edit-input"]}
          type="text"
          name="price"
          onChange={handleChange}
          value={operation.price}
        />
      </InputContainer>
      <InputContainer className={clsx(styles["operation-type"])}>
        <label>Type d'opération</label>
        <select
          className={styles["operation-edit-input"]}
          name="operationType"
          onChange={handleOperationTypeSelected}
          value={operation.operationType}
        >
          <option>Sélectionnez un type d'opération</option>
          {(
            OPERATION_TYPE_BY_GROUP[
              operationGroup as keyof typeof OPERATION_TYPE_BY_GROUP
            ] || []
          ).map((r) => (
            <option key={r} value={r}>
              {translate(r)}
            </option>
          ))}
        </select>
      </InputContainer>
      <div className={styles["delete-icon-container"]} onClick={handleDelete}>
        <div className={styles["delete-icon"]}>
          <Delete />
        </div>
      </div>
    </div>
  );

  function handleOperationTypeSelected(
    event: React.ChangeEvent<HTMLSelectElement>
  ): void {
    let op: IOperation = {
      ...operation,
      operationType: event.target.value,
    };
    onChange && onChange(op);
  }

  function handleDelete(): void {
    onDelete && onDelete(operation);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target;
    let baseOperation: IOperation | undefined = undefined;
    if (name === "description") {
      baseOperation = datalist.find((o) => o.description === value);
    }
    if (baseOperation) {
      onChange && onChange(baseOperation);
    } else {
      onChange && onChange({ ...operation, [name]: value });
    }
  }
}
