import { CustomError } from "../types/customError.types";
import { IShop } from "../types/shop.types";

export async function getShopsList(
  query: string,
  signal?: AbortSignal
): Promise<{
  shops: IShop[];
  totalCount: number;
}> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shop/list/${query || ""}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "GET",
        credentials: "include",
        signal: signal,
      }
    );

    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    console.error(`Front-end Error: ${error.message}`);
    if (error instanceof CustomError) {
      console.error(
        `CustomError: ${error.message}, Status: ${
          error.status
        }, Body: ${JSON.stringify(error.body)}`
      );
      throw new Error(`Error get shops list: ${error.message}`);
    }
    throw new Error("Unknown error occurred");
  }
}

export async function getShop(id?: string): Promise<IShop> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shop/${id}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "GET",
        credentials: "include",
      }
    );

    const data = await response.json();
    // const shop: IShop = shopFormatter(data);
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.shop;
  } catch (error: any) {
    console.error(`Front-end Error: ${error.message}`);
    if (error instanceof CustomError) {
      console.error(
        `CustomError: ${error.message}, Status: ${
          error.status
        }, Body: ${JSON.stringify(error.body)}`
      );
      throw new Error(`Error get shop: ${error.message}`);
    }
    throw new Error("Unknown error occurred");
  }
}


export async function getShopProductGroups(id?: string): Promise<{ productGroups: string[]}> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shop/${id}/product-groups`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "GET",
        credentials: "include",
      }
    );

    const data = await response.json();
    // const shop: IShop = shopFormatter(data);
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    console.error(`Front-end Error: ${error.message}`);
    if (error instanceof CustomError) {
      console.error(
        `CustomError: ${error.message}, Status: ${
          error.status
        }, Body: ${JSON.stringify(error.body)}`
      );
      throw new Error(`Error get shop: ${error.message}`);
    }
    throw new Error("Unknown error occurred");
  }
}

export async function getShopOperationGroups(id?: string, productGroup?: string): Promise<{operationGroups: string[]}> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shop/${id}/operation-groups${productGroup ? `?productGroup=${productGroup}` : ''}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "GET",
        credentials: "include",
      }
    );

    const data = await response.json();
    // const shop: IShop = shopFormatter(data);
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    console.error(`Front-end Error: ${error.message}`);
    if (error instanceof CustomError) {
      console.error(
        `CustomError: ${error.message}, Status: ${
          error.status
        }, Body: ${JSON.stringify(error.body)}`
      );
      throw new Error(`Error get shop: ${error.message}`);
    }
    throw new Error("Unknown error occurred");
  }
}


export async function deleteShop(
  id: string,
  signal?: AbortSignal
): Promise<void | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shop/${id}`,
      {
        credentials: "include",
        method: "DELETE",
        signal: signal,
      }
    );
    if (response.status !== 200) {
      if (response.status === 301) {
        return null;
      }
      throw new Response(JSON.stringify(response), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return;
  } catch (error: any) {
    console.error(`Front-end Error: ${error.message}`);
    if (error instanceof CustomError) {
      console.error(
        `CustomError: ${error.message}, Status: ${
          error.status
        }, Body: ${JSON.stringify(error.body)}`
      );
      throw new Error(`Error delete shop: ${error.message}`);
    }
    throw new Error("Unknown error occurred");
  }
}

export async function addShop(
  data: IShop,
  signal?: AbortSignal
): Promise<void | null | string> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shop/create`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "include",
        method: "POST",
        signal: signal,
        body: JSON.stringify(data),
      }
    );
    if (response.status === 500) {
      const errorBody = await response.json();
      throw new CustomError("ERROR cannot create the shop!", {
        status: response.status,
        body: errorBody,
      });
    }

    if (response.status === 409) {
      return "Shop already existe!";
    }
    const res = await response.json();
    return res.id;
  } catch (error: any) {
    console.error(`Front-end Error: ${error.message}`);
    if (error instanceof CustomError) {
      console.error(
        `CustomError: ${error.message}, Status: ${
          error.status
        }, Body: ${JSON.stringify(error.body)}`
      );
      throw new Error(`Error creating shop: ${error.message}`);
    }
    throw new Error("Unknown error occurred");
  }
}

export async function updateShop(
  data: IShop,
  id: string,
  signal?: AbortSignal
): Promise<void | null | string> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shop/${id}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "include",
        method: "PUT",
        signal: signal,
        body: JSON.stringify(data),
      }
    );
    if (response.status >= 400) {
      // if (response.status === 301) {
      //   return null;
      // }
      if (response.status === 409) {
        return "Nom d'utilisateur déjà existant";
      }
      if (response.status === 400) {
        return "Champs manquants";
      }
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    const res = await response.json();
    return res.shop.id;
  } catch (error: any) {
    console.error(`Front-end Error: ${error.message}`);
    if (error instanceof CustomError) {
      console.error(
        `CustomError: ${error.message}, Status: ${
          error.status
        }, Body: ${JSON.stringify(error.body)}`
      );
      throw new Error(`Error update shop: ${error.message}`);
    }
    throw new Error("Unknown error occurred");
  }
}
