import { isDesktop } from "react-device-detect";
import style from "./image.module.scss";
import { Delete, ZoomOutMap } from "@mui/icons-material";
import { useState } from "react";
import { useImageViewer } from "../../context/imageViewer.context";
import { handleImageError } from "../../utils/utils";
import PictureLoadingSpinner from "../loadingSpinner/pictureloadingSpinner";

interface ImageProps {
  handleDelete?: () => void;
  index?: number;
  isBlob?: boolean;
  pictureList?: string[];
  showTools?: boolean;
  clickable?: boolean;
}

export default function Image({
  handleDelete,
  index = 0,
  isBlob = false,
  showTools = true,
  clickable = true,
  pictureList,
  ...props
}: ImageProps & React.ImgHTMLAttributes<HTMLImageElement>) {
  const { setViewerIsOpen, setPictureList, setIndex, setIsBlob } =
    useImageViewer();
  const [onHover, setOnHover] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const STYLE = { display: !isLoading ? "block" : "none" };

  return (
    <div
      className={`${style["image-container"]} ${props.className || ""}`}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <div
        className={`${style["loader-container"]} ${props.className}`}
        style={{ display: isLoading ? "flex" : "none" }}
      >
        <PictureLoadingSpinner color="gold" />
      </div>
      <img
        {...props}
        className={`${style["image"]} ${
          isDesktop ? style["hover"] : style["active"]
        }`}
        // loading="lazy"
        style={Object.assign(STYLE, props.style)}
        src={
          isBlob
            ? props.src
            : `${process.env.REACT_APP_API_URL}/file/${props.src}`
        }
        onClick={handleOnClickOtherDevice}
        onError={handleImageError}
        onLoad={() => setIsLoading(false)}
      />
      {showTools && onHover && (
        <div className={style["tools-container"]}>
          <div className={style["delete-wrapper"]} onClick={handleOnClick}>
            <ZoomOutMap className={style["zoom-icon"]} />
          </div>
          {handleDelete && (
            <div className={style["delete-wrapper"]} onClick={handleDelete}>
              <Delete className={style["delete-icon"]} />
            </div>
          )}
        </div>
      )}
    </div>
  );

  function handleOnClick(e: React.MouseEvent<HTMLImageElement, MouseEvent>) {
    props.onClick && props.onClick(e);
    if (pictureList) {
      setViewerIsOpen(true);
      setIndex(index);
      setPictureList(pictureList);
      setIsBlob(isBlob);
    }
  }

  function handleOnClickOtherDevice(
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) {
    if (clickable) {
      if (pictureList) {
        setViewerIsOpen(true);
        setIndex(index);
        setPictureList(pictureList);
        setIsBlob(isBlob);
      }
      return;
    }
    if (!showTools) {
      props.onClick && props.onClick(e);
    } else {
      isDesktop ? props.onClick && props.onClick(e) : setOnHover(!onHover);
    }
  }
}
