import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../../pages/error-page";
import { authloader } from "..";
import Home from "../home";

export const route: RouteObject = {
  path: "/dashboard",
  element: <Outlet />,
  loader: authloader,
  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <Home />,
      loader: authloader,
    },
  ],
};
