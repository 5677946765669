import { IValidationError } from "../types";
import { IDetailedProduct, IProduct } from "../types/logistic.types";

export async function getProduct(id?: string) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/logistic/product/${id}`,
      {
        credentials: "include",
        // signal: request.signal,
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.product;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getDetailedProduct(
  id?: string
): Promise<IDetailedProduct> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/logistic/detailedProduct/${id}`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getProductList(
  query?: string
): Promise<{ productsList: IProduct[]; totalCount: number } | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/logistic/product/without-canceled-operation${query}`,
      {
        credentials: "include",
        // signal: request.signal,
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      if (response.status === 401) {
        return null;
      }
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateProduct(
  product: Partial<IProduct>,
  id?: string,
  parentId?: string,
  signal?: AbortSignal
): Promise<{ message: string } | IValidationError[] | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/logistic/updateProduct`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        // signal: signal,
        credentials: "include",
        body: JSON.stringify({
          id: id,
          parentId: parentId,
          product: product,
        }),
      }
    );
    const data = await response.json();
    if (![201, 200, 400].includes(response.status)) {
      if (response.status === 301) {
        return null;
      }
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
