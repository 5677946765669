import clsx from "clsx";
import styles from "./sectionTitle.module.scss";

interface SectionTitleProps {
  className?: string;
  title: string;
}

export default function SectionTitle({ className, title }: SectionTitleProps) {
  return (
    <div className={clsx(styles["section-title"], className)}>
      <label className={styles["section-label"]}>{title}</label>
      <div className={styles["dotted"]}></div>
    </div>
  );
}
