import { useQuery } from "react-query";
import { IShippingPro } from "../../../../types/shipping.types";
import { useState } from "react";
import style from "./proShippingList.module.scss";
import { Replay } from "@mui/icons-material";
import { getShippingProList } from "../../../../requests/shippingPro";
import LoadingSpinner from "../../../../components/loadingSpinner/loadingSpinner";
import { useModalComponent } from "../../../../context/modalComponent.context";
import AddShippingProModal from "../../../../components/modals/addShippingProModal/addShippingProModal";
import SelectAndSearchShop from "../../../../components/SelectAndSearchShop/SelectAndSearchShop";
import { IShop } from "../../../../types/shop.types";
import ShippingProCard from "../shippingProCard/ShippingProCard";

interface ProShippingListProps {
  direction: string;
}

export default function ProShippingList({ direction }: ProShippingListProps) {
  const [shop, setShop] = useState<IShop | null>(null);
  const [date, setDate] = useState<Date | null>(null);
  const { data, isFetching } = useQuery(
    ["shippings-pro", date, shop],
    () => getShippingProList(formatedQuery()),
    { refetchOnWindowFocus: true }
  );
  const { setModalIsOpen, setModalChildren } = useModalComponent();

  return (
    <div className={style["incoming-pro-shipping"]}>
      <div className={style["query-section"]}>
        <div className={style["shop-select"]}>
          <SelectAndSearchShop shop={shop} setShop={setShop} />
        </div>
        <div className={style["date-picker"]}>
          <input
            className={style["date-picker-input"]}
            onChange={handleDateChange}
            type="date"
            value={date ? date.toISOString().substring(0, 10) : ""}
          />
        </div>
        <div className={style["reset-button"]} onClick={handleResetSearch}>
          {date && <Replay />}
        </div>
      </div>
      <div className={style["shipping-list"]}>
        {isFetching ? (
          <div className={style["loading-spinner-container"]}>
            <LoadingSpinner color="gold" />
          </div>
        ) : (
          <div className={style["shipping-pro-card-list"]}>
            {data && (
              <>
                {data.shippingProList.length > 0 ? (
                  data.shippingProList.map(
                    (shippingPro: IShippingPro, index: number) => {
                      return (
                        <ShippingProCard
                          direction={direction}
                          shippingPro={shippingPro}
                          key={index}
                        />
                      );
                    }
                  )
                ) : (
                  <div className={style["loading-spinner-container"]}>
                    <span>{`aucune ${
                      direction === "OUTGOING" ? "livraison" : "collecte"
                    } disponible`}</span>
                  </div>
                )}
              </>
            )}
          </div>
        )}
        <div className={style["modal-link"]} onClick={handleOpenModal}>
          {`ajouter une nouvelle ${
            direction === "OUTGOING" ? "livraison" : "collecte"
          }`}
        </div>
      </div>
    </div>
  );

  function handleOpenModal() {
    setModalChildren(<AddShippingProModal direction={direction} />);
    setModalIsOpen(true);
  }

  function handleResetSearch() {
    setDate(null);
    setShop(null);
  }

  function handleDateChange({ target }: { target: HTMLInputElement }) {
    setDate(new Date(target.value));
  }

  function formatedQuery(): string {
    let formatedQuery = `?state=PENDING&direction=${direction}`;

    if (date) {
      formatedQuery += `&shippingDate=${date.toISOString().substring(0, 10)}`;
    }

    if (shop) {
      formatedQuery += `&${
        direction === "INCOMING" ? "sender" : "recipient"
      }.organization=${shop.name}`;
    }

    return formatedQuery;
  }
}
