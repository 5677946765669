import { useQuery } from "react-query";
import Widget from "../../newWidget/widget";
import { fetchLogisticList } from "../../../requests/logistic";
import style from "./quoteWidget.module.scss";
import { FileEarmarkText, Pen, PenFill } from "react-bootstrap-icons";
import { EditNote } from "@mui/icons-material";

export default function QuoteWidget() {
  // const { isLoading, error, data } = useQuery("quote-widget", () =>
  //   fetchLogisticList("?status=LOGISTIC_QUOTE_ESTIMATION")
  // );

  return (
    <Widget to={"quote-edit-search"}>
      <div className={style["icon-container"]}>
        <EditNote className={style["icon"]} />
      </div>
      <div>Rédiger devis</div>
    </Widget>
  );
}
