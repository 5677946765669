import { useEffect, useState } from "react";
import { IShopOperation } from "../../../../types/shop.types";
import WorkshopId from "../../../workshopId/workshopId";
import style from "./productInformation.module.scss";
import { IProduct } from "../../../../types/logistic.types";
import translate from "../../../../translation";
import StateWidget from "../../../stateWidget/StateWidget";
import PrintLabelButton from "../../../PrintLabel/PrintLabel";

interface ProductInformationProps {
  product?: IProduct;
  className?: string;
}

export default function ProductInformation({
  product,
  className,
}: ProductInformationProps) {
  const MOBILE_BREAKPOINT = 730;
  const [animateKey, setAnimateKey] = useState<number>(0);

  useEffect(() => {
    setAnimateKey((prev) => prev + 1);
  }, [product]);

  return (
    <div className={`${style["info-product"]} ${className}`}>
      <div key={animateKey} className={style["info-product-container"]}>
        <div className={style["left"]}>
          <div className={style["operation-group"]}>
            {translate(product?.productGroup || "")} {" - "}
            {translate(product?.operationGroup || "")}
          </div>
          <div className={style["brand"]}>
            <label>Marque</label>
            {product?.brand || "-"}
          </div>
          <div className={style["comment-container"]}>
            <label>Commentaire</label>
            <div className={style["comment"]}>{product?.comments || "-"}</div>
          </div>
          <div className={style["operation-list-container"]}>
            <label>Liste des opérations</label>
            <ul className={style["operation-list"]}>
              {product?.operationsList &&
                product?.operationsList.map(
                  (operation: IShopOperation, index: number) => {
                    return (
                      <li className={style["operation-item"]} key={index}>
                        {operation.description}
                        {operation.comments && (
                          <span className={style["operation-item-comment"]}>
                            {`"${operation.comments}"`}
                          </span>
                        )}
                      </li>
                    );
                  }
                )}
            </ul>
          </div>
          {/* <div className={style["picture-list"]}>
            {product?.pictureList &&
              product.pictureList.map((uri, index) => {
                return (
                  <Image
                    alt={uri}
                    className={style["picture-item"]}
                    index={index}
                    key={index}
                    pictureList={product.pictureList}
                    src={uri}
                    isBlob={false}
                  />
                );
              })}
          </div> */}
        </div>
        <div className={style["right"]}>
          <WorkshopId
            workshopId={product?.workshopId}
            size={window.innerWidth < MOBILE_BREAKPOINT ? "x-small" : "small"}
          />
          <StateWidget
            state={(product as any).state || "CART_PENDING"}
            className={style["state-widget"]}
          />
          <PrintLabelButton product={product as IProduct}/>
        </div>
      </div>
    </div>
  );
}
