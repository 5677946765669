import React from "react";

export default function BeautifulDiv() {
  return (
    <div
      style={{
        height: "5px",
        backgroundColor: "#c5a37b",
        borderRadius: "0 0 3px 3px",
        position: "relative",
        zIndex: "10",
      }}
    ></div>
  );
}
