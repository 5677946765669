import { useEffect } from "react";

export function useKeyDown(
  key: string,
  callback: (e?: KeyboardEvent) => void,
  deps?: React.DependencyList
) {
  useEffect(() => {
    function handlePressArrow(e: KeyboardEvent) {
      if (e.key === key) {
        callback(e);
      }
    }
    document.addEventListener("keydown", handlePressArrow);
    return () => {
      document.removeEventListener("keydown", handlePressArrow);
    };
  }, deps);
}
