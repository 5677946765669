import style from "./item.module.scss";
import { Launch } from "@mui/icons-material";
import { useModalComponent } from "../../../context/modalComponent.context";
import CartModal from "../../../components/modals/cartModal/cartModal";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import { Referral } from "../../../types/referral.types";
import State from "../../../components/StateItem/State";

export default function Item({ referral }: { referral: Referral }) {
  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Launch />,
      name: "Ouvrir",
      onClick: () => {},
    },
  ];

  return (
    <tr className={style["item"]}>
      <td className={style["item-cell"]}>
        {referral.createdAt &&
          new Date(referral.createdAt).toLocaleDateString()}
      </td>
      <td className={style["item-cell"]}>
        {referral.updatedAt &&
          new Date(referral.updatedAt).toLocaleDateString()}
      </td>
      <td className={style["item-cell"]}>
        <span>{referral.referredEmail}</span>
      </td>
      <td className={style["item-cell"]}>{referral.amount} €</td>
      <td className={style["item-cell"]}>
        <CenterCell>
          <State state={referral.state} />
        </CenterCell>
      </td>
      <td className={style["item-cell"]}>
        <CenterCell>
          <MoreTools options={MORE_TOOLS_OPTIONS} />
        </CenterCell>
      </td>
    </tr>
  );
}

interface CenterCellProps {
  children: React.ReactNode;
}

function CenterCell({ children }: CenterCellProps) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>{children}</div>
  );
}
