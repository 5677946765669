import React from "react";
import style from "./licenseLabel.module.scss";
import translate from "../../translation";

function Logo({ className }: { className?: string }) {
  return (
    <svg
      className={`${style["logo"]} ${className}`}
      viewBox="681.545 349.44 556.91 381.12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="matrix(1, 0, 0, 1, -0.41500699520111084, 199.44000244140625)">
        <g>
          <path
            className={style["logo-fill"]}
            d="M804.25,223.61c4.08-16.46,9-30.97,14.79-43.54,5.76-12.57,12.06-22.61,18.84-30.07-20.39,0-39.91,5.02-58.59,15.02-18.68,10.03-35.24,23.6-49.68,40.77-14.44,17.14-25.98,37.27-34.66,60.36-8.65,23.12-12.99,47.75-12.99,73.9s4.24,50.71,12.77,73.64c8.46,22.93,19.87,42.96,34.12,60.13,14.28,17.14,30.74,30.74,49.42,40.74,18.68,10.03,38.23,15.05,58.62,15.05h1c-6.79-7.46-13.09-17.49-18.84-30.07-5.79-12.57-10.71-27.08-14.79-43.57-4.05-16.46-7.3-34.57-9.68-54.28-2.38-19.68-3.57-40.23-3.57-61.64s1.19-42.45,3.57-62.16c2.38-19.71,5.63-37.78,9.68-54.28Z"
          />
          <path
            className={style["logo-fill"]}
            d="M959.53,184.38c12.73,14.12,23.7,27.78,32.86,41.03,10.52,15.63,19.87,31.77,28.04,48.4v-123.8h-104.48c16.3,8.81,30.84,20.29,43.57,34.38Z"
          />
          <path
            className={style["logo-fill"]}
            d="M1229.96,223.64c-5.95-14.79-14.02-27.62-24.21-38.49-10.19-10.87-22.25-19.42-36.18-25.73-13.92-6.27-28.72-9.42-44.34-9.42h-32.61c7.46,4.41,14.44,10.61,20.9,18.59,6.43,8.01,12.06,17.17,16.82,27.53,4.76,10.35,8.39,21.9,10.93,34.63,2.57,12.77,3.83,26.24,3.83,40.52s-1.25,27.27-3.83,40c-2.54,12.73-6.17,24.37-10.93,34.89-4.76,10.55-10.39,19.81-16.82,27.78-6.46,7.97-13.44,14.18-20.9,18.59h31.1c16.3,0,31.48-2.96,45.6-8.91,14.08-5.95,26.24-14.28,36.43-24.95,10.19-10.71,18.26-23.54,24.21-38.49,5.92-14.92,8.91-31.22,8.91-48.91s-2.99-32.86-8.91-47.62Z"
          />
          <path
            className={style["logo-fill"]}
            d="M934.2,356.86h-57.89l-2.16,18.03,2.16,2.17c7.22,0,33.89,1.44,41.83,2.16,6.95,.4,12.07,1.41,16.05,3v148.9c5.43-5.43,12.41-11.03,20.9-16.82,7.14-4.73,16.05-9.84,26.75-15.27,10.68-5.43,23.51-10.35,38.46-14.79v-127.37h-86.12Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default function LicenseLabel({
  rank,
  className,
}: {
  rank: string;
  className?: string;
}) {
  return (
    <div
      className={`${style["label"]} ${
        style[rank.toLocaleLowerCase()]
      } ${className}`}
    >
      <Logo className={style["logo-small"]} />
      <div className={style["label-rank"]}>
        {translate(rank).toLocaleLowerCase()}
      </div>
    </div>
  );
}
