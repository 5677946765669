import clsx from "clsx";
import styles from "./listHeader.module.scss";

interface ListHeaderProps {}

export default function ListHeader({}: ListHeaderProps) {
  return (
    <thead className={styles["list-header"]}>
      <tr className={styles["list-header-row"]}>
        <th className={styles["list-header-cell"]}>Date de création</th>
        <th className={styles["list-header-cell"]}>Coordonnées</th>
        <th className={styles["list-header-cell"]}>Articles</th>
        <th className={clsx(styles["list-header-cell"], styles["center-cell"])}>
          Status
        </th>
        <th className={clsx(styles["list-header-cell"], styles["center-cell"])}>
          Actions
        </th>
      </tr>
    </thead>
  );
}
