import { ReactNode } from "react";
import styles from "./filtersContainer.module.scss";
import { TuneOutlined } from "@mui/icons-material";

interface FiltersContainerProps {
  children: ReactNode;
}
export default function FiltersContainer({ children }: FiltersContainerProps) {
  return (
    <div className={styles["filters-container"]}>
      <div className={styles["filters-wrapper"]}>
        <p className={styles["filters-title"]}>
          <TuneOutlined className={styles["icon"]} /> {"Filtres"}
        </p>
        <div className={styles["filters"]}>{children}</div>
      </div>
    </div>
  );
}
