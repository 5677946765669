export async function sendEstimateSentEmail(
  email: string,
  trackId: string
): Promise<{ message: string }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/notification/estimate-sent/${trackId}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ email }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function sendEstimateCanceledEmail(
  email: string,
  trackId: string,
  reason: string
): Promise<{ message: string }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/notification/estimate-canceled/${trackId}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ email, reason }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function sendQuoteValidationPendingEmail(
  email: string
): Promise<{ message: string }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/notification/quote-validation-pending/${email}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
