import style from "./readproductMiniatureCard.module.scss";
import { isDesktop } from "react-device-detect";
import WorkshopId from "../../../workshopId/workshopId";
import { IProduct } from "../../../../types/logistic.types";
import translate from "../../../../translation";
import StateWidget from "../../../stateWidget/StateWidget";

interface ReadProductMiniatureCardProps {
  isSelected: boolean;
  product: IProduct;
  handleSelectProduct: () => void;
  side: string;
}

export function ReadProductMiniatureCard({
  isSelected,
  product,
  handleSelectProduct,
  side,
}: ReadProductMiniatureCardProps) {
  return (
    <div
      className={`${style["product-miniature-card"]} ${
        side === "left" ? style["shadow-left"] : style["shadow-right"]
      } ${isSelected ? style["is-selected"] : ""}  ${
        isDesktop ? style["hover"] : style["active"]
      }`}
      onClick={handleSelectProduct}
    >
      <div className={style["product-miniature-card-top"]}>
        <div className={style["product-group"]}>
          {translate(product.productGroup || "-")}
        </div>
        <div className={style["product-miniature-card-top-right"]}>
          <WorkshopId
            workshopId={product.workshopId}
            size="xx-small"
            className={style["workshop-id"]}
          />
          <StateWidget
            state={(product as any).state || "CART_PENDING"}
            className={style["state-widget"]}
          />
        </div>
      </div>
      <div className={style["product-miniature-card-brand"]}>
        {product.brand || "-"}
      </div>
    </div>
  );
}
