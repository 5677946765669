import Widget from "../../newWidget/widget";
import style from "./workshop.module.scss";
import { Architecture } from "@mui/icons-material";

export default function WorkshopWidget() {
  return (
    <Widget to={"workshop"}>
      <div className={style["icon-container"]}>
        <Architecture className={style["icon"]} />
      </div>
      <div>Atelier</div>
    </Widget>
  );
}
