import style from "./cobbler.module.scss";
import logo from "../../../assets/shoe.png";
import Widget from "../../newWidget/widget";

export default function CobblerWidget() {
  return (
    <Widget to={"cobbler"}>
      <div className={style["icon-container"]}>
        <img src={logo} className={style["icon"]} />
      </div>
      <div>Cordonnerie</div>
    </Widget>
  );
}
