import Select from "../../../../components/Select/Select";
import ShippingMethodForm from "../../../../components/forms/shopsForm/shippingMethodForm/shippingMethodForm";
import Input from "../../../../components/inputs/Input/Input";
import { IShop } from "../../../../types/shop.types";
import style from "./shopForm.module.scss";

export default function ShopForm({
  shop,
  setShop,
}: {
  shop: IShop;
  setShop: (shop: IShop) => void;
}) {
  return (
    <form>
      <div>
        <label htmlFor="licenseRank">License</label>
        <Select
          name="licenseRank"
          id="licenseRank"
          optionsList={["FREE", "BASIC", "ESSENTIAL", "PREMIUM"]}
          value={shop.licenseRank || ""}
          setValue={(licenseRank) => {
            setShop({ ...shop, licenseRank });
          }}
          doTranslate={true}
          placeholder="Sélectionnner une license..."
          className={style["address-select"]}
          style={{ width: "100%" }}
        />
      </div>
      <Input
        label="Nom"
        type="text"
        name="name"
        id="name"
        onChange={(e) => {
          setShop({ ...shop, name: e.target.value });
        }}
        value={shop.name}
      />
      <Input
        label="Préfixe ticket (XX-XX)"
        name={"workshopPrefixId"}
        onChange={(e) => {
          setShop({ ...shop, workshopPrefixId: e.target.value });
        }}
        placeholder="XX-XX"
        type="text"
        value={shop.workshopPrefixId}
      />
      <label>Méthode de livraison</label>
      <ShippingMethodForm shop={shop} setShop={setShop} />
    </form>
  );
}
