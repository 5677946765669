import { Filter } from "../types/filter.types";
import { IInvoicePro } from "../types/invoicePro.types";

export async function clearInvoicePro(
  id: string
): Promise<{ invoicePro: IInvoicePro }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/invoice-pro/${id}/clear-invoice`,
      {
        method: "PUT",
        credentials: "include",
      }
    );
    const data = await response.json();

    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchInvoicePro(
  id: string
): Promise<{ invoicePro: IInvoicePro }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/invoice-pro/${id}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();

    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getInvoiceProList(
  query?: string
): Promise<{ invoices: IInvoicePro[]; totalCount: number }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/invoice-pro${query || ""}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();

    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getInvoiceProListWithTotal(
  filter?: Filter[]
): Promise<{ invoices: IInvoicePro[]; totalCount: number }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/invoice-pro/total`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        body: JSON.stringify(filter || []),
      }
    );
    const data = await response.json();

    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateInvoicePro(
  invoicePro?: IInvoicePro
): Promise<IInvoicePro> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/invoice-pro/`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(invoicePro),
      }
    );
    const data = await response.json();

    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function addShippingToInvoicePro({
  invoiceId,
  shippingId,
}: {
  invoiceId: string;
  shippingId: string;
}): Promise<IInvoicePro> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/invoice-pro/${invoiceId}/add-shipping`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "PUT",
        credentials: "include",
        body: JSON.stringify({ shippingId }),
      }
    );
    const data = await response.json();

    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
