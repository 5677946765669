import { useLoaderData } from "react-router-dom";
import Create from "../create/create";
import { IProAccount } from "../../../types/proAccount.types";
import { getProAccount } from "../../../requests/proAccount";

export async function loader({ params }: { params: any }) {
  try {
    const user = await getProAccount({ params });
    return user;
  } catch (error: any) {
    throw error;
  }
}

export default function Edit() {
  const data = useLoaderData() as any;
  const proAccountToEdit: IProAccount = {
    id: data.proAccount.id,
    username: data.proAccount.username,
    password: data.proAccount.password,
    shops: data.proAccount.shops,
    email: data.proAccount.email
  }

  return <Create data={proAccountToEdit} />;
}
