import { useParams } from "react-router-dom";
import style from "./read.module.scss";
import { getTracker } from "../../../requests/tracker";
import { useQuery } from "react-query";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import { useRefetchOnModalClose } from "../../../hooks/useRefetchOnModalClose";
import OverviewHistory from "./OverviewHistory/OverviewHistory";
import OverviewWidgets from "./OverviewWidgets/OverviewWidgets";
import OverviewHeader from "./OverviewHeader/OverviewHeader";

export default function Read() {
  const { id } = useParams();
  const { data, isFetching, refetch } = useQuery(
    ["tracker", id],
    () => getTracker(id || ""),
    { enabled: Boolean(id), refetchOnWindowFocus: false }
  );
  useRefetchOnModalClose(refetch);

  if (isFetching) {
    return <LoadingSpinner color="gold" />;
  }

  if (!data) {
    return <></>;
  }

  return (
    <div className={style["overview-container"]}>
      <OverviewHeader
        tracker={data}
        refetch={refetch}
        isFetching={isFetching}
      />
      <hr className={style["separator"]} />
      <OverviewWidgets tracker={data} />
      <OverviewHistory eventsList={data.eventsList} />
    </div>
  );
}
