import {
  IShop,
  IContact,
  IShippingMethod,
  IBusinessDays,
} from "../types/shop.types";

const INITIAL_CONTACT: IContact = {
  displayname: "",
  familyname: "",
  givenname: "",
  email: "",
  phone: "",
  organization: "",
  address1: "",
  address2: "",
  city: "",
  zipCode: "",
  country: "",
  countryCode: "",
};

const INITIAL_BUSINESSDAYS: IBusinessDays = {
  sunday: false,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
};

const INITIAL_SHIPPINGMETHOD: IShippingMethod = {
  name: "",
  shippingService: "",
  price: 0,
  priceNoTax: 0,
  businessDays: INITIAL_BUSINESSDAYS,
  businessHours: [],
};

export const INITIAL_SHOP: IShop = {
  name: "",
  contact: INITIAL_CONTACT,
  workshopPrefixId: "",
  shippingMethod: INITIAL_SHIPPINGMETHOD,
  cartsId: [],
  pricing: { operationsList: [] },
};


