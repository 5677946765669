import { useContext } from "react";
import { IOperation, IProduct } from "../../../types/proCart.types";
import ImagesForm from "../../forms/imagesForm/imagesForm";
import style from "./proTakePictureModal.module.scss";
import { useModalComponent } from "../../../context/modalComponent.context";

interface ProTakePictureModalProps {
  imageList?: string[];
  fileList: File[];
  setFileList: (fileList: File[]) => void;
  product: IProduct;
}

export default function ProTakePictureModal({
  imageList,
  fileList,
  setFileList,
  product,
}: ProTakePictureModalProps) {
  const { setModalIsOpen } = useModalComponent();

  return (
    <div className={style["pro-express-checkin-modal"]}>
      <div className={style["pro-express-checkin-modal-container"]}>
        <div className={style["pro-express-checkin-modal-container-top"]}>
          <div className={style["section-title"]}>Ajouter une photo</div>
          <ImagesForm
            imageList={imageList || []}
            fileList={fileList}
            setFileList={setFileList}
          />
        </div>
        <div className={style["button-container"]}>
          <button className={style["validate-button"]} onClick={handleCheckIn}>
            Valider
          </button>
        </div>
      </div>
    </div>
  );

  function handleCheckIn() {
    setModalIsOpen(false);
  }
}
