import style from "./productMiniature.module.scss";
import { IProduct } from "../../../types/logistic.types";
import WorkshopId from "../../workshopId/workshopId";
import { StatusItem } from "../../statusItem/statusItem";
import translate from "../../../translation";
import { Delete } from "@mui/icons-material";
import getStatusColor from "../../../translation/statusColor";

interface ProductMiniatureProps {
  className?: string;
  handleOnClick: () => void;
  product: IProduct;
  handleDelete: () => void;
}

export default function ProductMiniature({
  className,
  handleOnClick,
  product,
  handleDelete,
}: ProductMiniatureProps) {
  return (
    <div
      className={`${style["product-miniature"]} ${className ? className : ""}`}
      onClick={handleOnClick}
    >
      <div className={style["product-miniature-top"]}>
        <div
          className={style["state"]}
          style={{
            backgroundColor: `${getStatusColor(product.status)}`,
          }}
        >
          {translate(product.status)}
        </div>
        <div className={style["delete"]} onClick={handleFctDelete}>
          <Delete />
        </div>
      </div>
      <div className={style["product-miniature-bottom"]}>
        <WorkshopId workshopId={product.workshopId} size={"x-small"} />
        <div className={style["brand"]}>{product.brand}</div>
      </div>
    </div>
  );

  function handleFctDelete(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    handleDelete();
  }
}
