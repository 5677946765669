import clsx from "clsx";
import styles from "./listHeader.module.scss";

interface ListHeaderProps {}

export default function ListHeader({}: ListHeaderProps) {
  return (
    <thead className={styles["list-header"]}>
      <tr className={styles["list-header-row"]}>
        <th className={styles["list-header-cell"]}>Date d'envoi</th>
        <th className={styles["list-header-cell"]}>Date de réponse</th>
        <th className={styles["list-header-cell"]}>Coordonnées</th>
        <th className={styles["list-header-cell"]}>{"Délai estimé (jours)"}</th>
        <th className={styles["list-header-cell"]}>Total</th>
        <th className={clsx(styles["list-header-cell"], styles["center-cell"])}>
          Status
        </th>
        <th className={clsx(styles["list-header-cell"], styles["center-cell"])}>
          Actions
        </th>
      </tr>
    </thead>
  );
}
