import { ReactNode } from "react";
import styles from "./dashboardWidget.module.scss";
import clsx from "clsx";

interface DashboardWidgetProps {
  children: ReactNode;
  className?: string;
}

export default function DashboardWidget({
  children,
  className,
}: DashboardWidgetProps) {
  return (
    <div className={clsx(styles["dashboard-widget"], className)}>
      {children}
    </div>
  );
}
