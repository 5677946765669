import { IValidationError } from "../types";
import { IProCart } from "../types/proCart.types";

export async function addProCart(
  cart: IProCart
): Promise<{ cart: IProCart } | IValidationError[] | null> {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/pro-cart`, {
      headers: new Headers({ "Content-Type": "application/json" }),
      method: "POST",
      credentials: "include",
      body: JSON.stringify(cart),
    });
    const data = await response.json();

    if (![201, 200, 400].includes(response.status)) {
      if (response.status === 401) {
        return null;
      }
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchCart(id: string | undefined): Promise<IProCart> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/pro-cart/${id}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.cart;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getCartByTrackId(
  id: string | undefined
): Promise<IProCart> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/pro-cart/tracker/${id}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.cart;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getCartsList(
  query?: string
): Promise<{ cartsList: IProCart[]; totalCount: number }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/pro-cart/${query || ""}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();

    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getCartsListByTrackIdList(
  trackIdList: string[]
): Promise<{ cartsList: IProCart[]; totalCount: number }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/pro-cart/trackers-list`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "include",
        method: "POST",
        body: JSON.stringify({ trackIdList }),
      }
    );
    const data = await response.json();

    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateCart(
  cart: IProCart
): Promise<{ cart: IProCart } | IValidationError[] | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/pro-cart/${cart.id}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(cart),
      }
    );

    const data = await response.json();
    if (![201, 200, 400].includes(response.status)) {
      if (response.status === 401) {
        return null;
      }
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function deleteCart(
  id?: string,
  signal?: AbortSignal
): Promise<void> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/pro-cart/${id}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "include",
        method: "DELETE",
        signal: signal,
      }
    );
    if (response.status !== 200) {
      throw new Response(JSON.stringify(response), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
