import { toast } from "react-toastify";
import { IValidationError } from "../types";
import { IQuote } from "../types/accounting.types";

export async function registerRepair(requestData: any): Promise<Blob | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/refashion/register-repair`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        body: JSON.stringify(requestData),
      }
    );
    if (response.status >= 400) {
      throw new Response(JSON.stringify(requestData), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    const data = await response.blob();
    // const data = await response.json();
    // if (![201, 200, 400].includes(response.status)) {
    //   if (response.status === 401) {
    //     return null;
    //   }
    //   throw new Response(JSON.stringify(data), {
    //     statusText: response.statusText,
    //     status: response.status,
    //   });
    // }
    return data;
  } catch (error: any) {
    toast.error(error);
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function registerImages({
  files,
  idDeclaration,
  categoryProduit,
  numeroReference,
}: {
  files: File[];
  idDeclaration: string;
  categoryProduit: string;
  numeroReference: string;
}): Promise<{ id: string } | IValidationError[] | null> {
  try {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("photos", file);
    });
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/refashion/register-images/${idDeclaration}?categoryProduit=${categoryProduit}&numeroReference=${numeroReference}`,
      {
        // headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        body: formData, //JSON.stringify(requestData),
      }
    );
    const data = await response.json();
    if (![201, 200, 400].includes(response.status)) {
      if (response.status === 401) {
        return null;
      }
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    toast.error(error);
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
