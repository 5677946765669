import { IProduct } from "../../../../types/logistic.types";
import Image from "../../../image/Image";
import WorkshopId from "../../../workshopId/workshopId";
import styles from "./modalProductMiniatureV2.module.scss";
import ProductGroupLogo from "../../../ProductGroupLogo/ProductGroupLogo";
import clsx from "clsx";

interface ModalProductProps {
  className?: string;
  handleOnClick?: () => void;
  product: IProduct;
  isSelected?: boolean;
}

export function ModalProductMiniatureV2({
  className,
  handleOnClick,
  product,
  isSelected = false,
}: ModalProductProps) {
  return (
    <div
      className={clsx(
        styles["modal-product-miniature"],
        className,
        isSelected && styles["selected"]
      )}
      onClick={handleOnClick && handleOnClick}
    >
      <div className={styles["image-miniature-container"]}>
        <Image
          className={styles["image-miniature"]}
          src={product?.incomingPicturesList?.at(0)}
          showTools={false}
        />
      </div>
      <div className={styles["modal-product-miniature-right"]}>
        <div className={styles["modal-product-miniature-right-top"]}>
          <ProductGroupLogo
            className={styles["product-group-logo"]}
            productGroup={product.productGroup}
          />
          <div className={styles["brand"]}>{product.brand}</div>
        </div>
        <WorkshopId workshopId={product.workshopId} size={"xxx-small"} />
      </div>
    </div>
  );
}
