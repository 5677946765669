import { useState } from "react";
import InputContainer from "../../../../../../components/forms/inputs/inputContainer/inputContainer";
import styles from "./additionalInfoSection.module.scss";
import { ICommentObject } from "../../../../../../types/logistic.types";
import { isValidJSONString } from "../../../../../../utils/utils";

interface AdditionalInfoSectionProps {
  defaultComment: string;
  onChange: (value: ICommentObject) => void;
}

export default function AdditionalInfoSection({
  defaultComment,
  onChange,
}: AdditionalInfoSectionProps) {
  const [commentObject, setCommentObject] = useState<ICommentObject>(
    initCommentObject()
  );

  return (
    <div className={styles["product-list-bottom"]}>
      <InputContainer>
        <div className={styles["check-line"]}>
          <input
            type="checkbox"
            name={"embauchoir"}
            className={styles["input-checkbox"]}
            onChange={handleIsChecked}
            checked={isChecked("embauchoir")}
          />
          <span>Embauchoirs</span>
          <input
            name={"embauchoir"}
            disabled={!isChecked("embauchoir")}
            type="text"
            className={styles["input-text"]}
            onChange={handleCommentChange}
            value={commentObject.embauchoir ?? ""}
          />
        </div>
      </InputContainer>
      <InputContainer>
        <div className={styles["check-line"]}>
          <span>Pochons</span>
          <input
            type="checkbox"
            name={"pochon"}
            className={styles["input-checkbox"]}
            onChange={handleIsChecked}
            checked={isChecked("pochon")}
          />
          <input
            type="text"
            name={"pochon"}
            className={styles["input-text"]}
            disabled={!isChecked("pochon")}
            onChange={handleCommentChange}
            value={commentObject.pochon ?? ""}
          />
        </div>
      </InputContainer>
      <InputContainer>
        <div className={styles["check-line"]}>
          <input
            type="checkbox"
            name={"other"}
            className={styles["input-checkbox"]}
            onChange={handleIsChecked}
            checked={isChecked("other")}
          />
          <span>Autre</span>
          <input
            type="text"
            name={"other"}
            className={styles["input-text"]}
            disabled={!isChecked("other")}
            value={commentObject.other ?? ""}
            onChange={handleCommentChange}
          />
        </div>
      </InputContainer>
    </div>
  );

  function handleCommentChange({ target }: { target: HTMLInputElement }) {
    const newCommentObject = { ...commentObject };

    newCommentObject[target.name as keyof ICommentObject] = target.value;
    setCommentObject(newCommentObject);
    onChange(newCommentObject);
  }

  function handleIsChecked({ target }: { target: HTMLInputElement }) {
    const newCommentObject = { ...commentObject };

    if (target.checked) {
      Object.assign(newCommentObject, {
        [target.name]:
          newCommentObject[target.name as keyof ICommentObject] ?? "",
      });
    } else {
      delete newCommentObject[target.name as keyof ICommentObject];
    }
    setCommentObject(newCommentObject);
    onChange(newCommentObject);
  }
  function isChecked(name: string): boolean {
    return Object.entries(commentObject).some(
      (value: [string, any]) => value[0] === name
    );
  }

  function initCommentObject() {
    if (!isValidJSONString(defaultComment)) {
      return {};
    }
    let initialCommentObject: ICommentObject = {};
    initialCommentObject = JSON.parse(defaultComment);
    return initialCommentObject;
  }
}
