import React, { ChangeEvent, useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import {
  fetchOperationGroups,
  fetchOperationTypes,
  fetchProductGroups,
} from "../../../requests/operation";
import style from "./create.module.scss";
import translate from "../../../translation";
import { IValidationError } from "../../../types";
import { IOperation } from "../../../types/accounting.types";
import {
  OPERATION_DEFAULT,
  REFASHION_OPERATIONS_PRICE,
} from "../../../utils/operation.init";
import { authloader } from "../..";
import {
  addOperationPro,
  updateOperationPro,
} from "../../../requests/operationPro";

export async function loader({
  request,
  params,
}: {
  request: Request;
  params: any;
}) {
  try {
    const productGroups = await fetchProductGroups({ request });
    const operationGroups = await fetchOperationGroups({ request });
    const operationTypes = await fetchOperationTypes({ request });
    authloader();
    return { productGroups, operationGroups, operationTypes };
  } catch (error: any) {
    throw new Response(error);
  }
}

interface OperationProps {
  operation?: IOperation;
  editProductGroups?: string[];
  editOperationGroups?: string[];
  editOperationTypes?: string[];
}

interface OPERATION_OPTIONS {
  productGroups: string[];
  operationGroups: string[];
  operationTypes: string[];
}

export default function Create({
  operation,
  editProductGroups,
  editOperationGroups,
  editOperationTypes,
}: OperationProps) {
  const { productGroups, operationGroups, operationTypes } =
    useLoaderData() as any;

  const [formValues, setFormValues] = useState<IOperation>(
    operation || OPERATION_DEFAULT
  );
  const [operationOptions, setOperationOptions] = useState<OPERATION_OPTIONS>({
    productGroups: editProductGroups || productGroups,
    operationGroups: editOperationGroups || operationGroups,
    operationTypes: editOperationTypes || operationTypes,
  });
  const [validationError, setValidationError] = useState<
    IValidationError[] | string[]
  >([]);
  const navigate = useNavigate();

  useEffect(() => {
    setFormValues({ ...OPERATION_DEFAULT });
  }, [productGroups]);

  useEffect(() => {
    if (operation) {
      setFormValues(operation);
    }
  }, [operation]);

  function handleRefashionChange({ target }: ChangeEvent<HTMLSelectElement>) {
    let refashionDiscount =
      REFASHION_OPERATIONS_PRICE.find((o) => o.name === target.value)?.amount ||
      0;
    let refashionOperation =
      REFASHION_OPERATIONS_PRICE.find((o) => o.name === target.value)?.name ||
      "N/A";

    setFormValues({ ...formValues, refashionDiscount, refashionOperation });
  }

  return (
    <div className={style["container"]}>
      <div className={style["title"]}>Ajouter une opération B2B</div>
      <form>
        <div className={style["inputcontainer"]}>
          <label className={isRequired("description")}>
            Description{" "}
            <span className={style["error-message"]}>
              {IErrorMessage("description")}
            </span>
          </label>
          <input
            onChange={handleChange}
            name="description"
            placeholder="Nouveau service"
            aria-label="Service description"
            type="text"
            value={formValues.description}
            className={isRequired("description")}
            onFocus={handleStartCorrection}
          />
        </div>
        <div className={style["inputcontainer"]}>
          <label>Groupe d'articles</label>
          <select
            value={formValues.productGroup}
            onChange={handleChange}
            name="productGroup"
          >
            {operationOptions.productGroups.map((p: string) => (
              <option key={p} value={p}>
                {translate(p)}
              </option>
            ))}
          </select>
        </div>
        <div className={style["inputcontainer"]}>
          <label>Groupe de services</label>
          <select
            value={formValues.operationGroup}
            onChange={handleChange}
            name="operationGroup"
          >
            {operationOptions.operationGroups.map((p: string) => (
              <option key={p} value={p}>
                {translate(p)}
              </option>
            ))}
          </select>
        </div>
        <div className={style["inputcontainer"]}>
          <label>Type de services</label>
          <select
            value={formValues.operationType}
            onChange={handleChange}
            name="operationType"
          >
            {operationOptions.operationTypes.map((p: string) => (
              <option key={p} value={p}>
                {translate(p)}
              </option>
            ))}
          </select>
        </div>
        <div className={style["inputcontainer"]}>
          <label className={isRequired("price")}>
            Prix{" "}
            <span className={style["error-message"]}>
              {IErrorMessage("price")}
            </span>
          </label>
          <input
            onChange={({ target }) =>
              setFormValues((prev) => ({
                ...prev,
                price: parseFloat(target.value) * 100,
                priceNoTax: (parseFloat(target.value) / 1.2) * 100,
              }))
            }
            name="price"
            placeholder="0"
            aria-label="Service price"
            type="number"
            min={0}
            value={formValues.price && formValues.price / 100}
            className={isRequired("price")}
            onFocus={handleStartCorrection}
          />
        </div>
        <div className={style["inputcontainer"]}>
          <label className={isRequired("slug")}>
            Identifiant{" "}
            <span className={style["error-message"]}>
              {IErrorMessage("slug")}
            </span>
          </label>
          <input
            onChange={handleChange}
            name="slug"
            placeholder="nouveau_service_unique"
            aria-label="Service slug"
            type="text"
            value={formValues.slug}
            className={isRequired("slug")}
            onFocus={handleStartCorrection}
          />
        </div>
        <div className={style["inputcontainer"]}>
          <label className={isRequired("refashionOperation")}>
            refashionOperation{" "}
            <span className={style["error-message"]}>
              {IErrorMessage("refashionOperation")}
            </span>
          </label>
          <select
            value={formValues.refashionOperation}
            onChange={handleRefashionChange}
            name="refashionOperation"
          >
            <option key={0} value={""}>
              N/A
            </option>
            {REFASHION_OPERATIONS_PRICE.map((p) => (
              <option key={p.name} value={p.name}>
                {p.description} - {p.amount} €
              </option>
            ))}
          </select>
        </div>
        <button
          className={`button-s ${style["validationbutton"]}`}
          type="submit"
          onClick={handleSubmit}
        >
          {operation ? "Sauvegarder" : "Créer Nouveau"}
        </button>
      </form>
    </div>
  );

  function handleChange({
    target,
  }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const { name, value } = target;
    setFormValues({ ...formValues, [name]: value });
  }

  async function handleSubmit(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault();
    try {
      const response = operation
        ? await updateOperationPro(formValues)
        : await addOperationPro(formValues);
      if ((response as IValidationError[]).length > 0) {
        setValidationError(response as IValidationError[]);
        return;
      }
      navigate("../");
    } catch (error) {
      console.error(error);
    }
  }

  function isRequired(name: string): string {
    if (
      (validationError as IValidationError[]).find(
        (value: IValidationError) => name === value.field
      )
    ) {
      return style["validation-error"];
    }
    return "";
  }

  function IErrorMessage(name: string): string {
    const found = (validationError as IValidationError[]).find(
      (value: IValidationError) => name === value.field
    );
    if (found) {
      return "*" + translate(found.errorType);
    }
    return "";
  }

  function handleStartCorrection({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    const newValidation = (validationError as IValidationError[]).filter(
      (value: IValidationError) => value.field !== target.name
    );
    setValidationError([...newValidation]);
  }
}
