import { Sort, Straight } from "@mui/icons-material";
import style from "./listHeaderComponent.module.scss";
import { useEffect, useState } from "react";
import { queryObject } from "../../types/query.types";

export default function ListHeaderComponent({
  name,
  title,
  headerClassName,
  query,
  setQuery,
}: {
  name: string;
  title: string;
  headerClassName: string;
  query: queryObject;
  setQuery: (query: queryObject) => void;
}) {
  const [sortOrder, setSortOrder] = useState<boolean>(false);
  const [sort, setSort] = useState<string>("");

  useEffect(() => {
    setQuery({ ...query, sort: sort });
  }, [sort]);

  return (
    <div className={headerClassName} onClick={() => handleFieldSort(name)}>
      <div
        className={`${style["header-element"]} ${
          showSortOrderIcon(name) ? style["translate"] : ""
        }`}
      >
        {showSortOrderIcon(name) && (
          <div
            className={`${style["sort-icon"]} ${style["sort-icon-first"]} ${
              sortOrder ? style["rotate"] : ""
            }`}
          >
            <Straight className={style["sort-icon-arrow"]} />
            <Sort className={style["sort-icon-list"]} />
          </div>
        )}
        {title}
      </div>
    </div>
  );

  function handleFieldSort(name: string) {
    setSortOrder(!sortOrder);
    setSort &&
      setSort(
        `&sort_field=${name}&sort_direction=${sortOrder ? "asc" : "desc"}`
      );
  }

  function showSortOrderIcon(name: string) {
    if (!query.sort) {
      return;
    }
    return (
      query.sort.includes("sort") &&
      /(?:=)(.*?)(?:&)/.exec(query.sort)?.[1] === name
    );
  }
}
