import { useModalComponent } from "../../../context/modalComponent.context";
import style from "./addCrossSellLogistic.module.scss";
import { ICrossSellQuote } from "../../../types/accounting.types";
import CrossSellItem from "./CrossSellItem/CrossSellItem";
import AddCrossSellModal from "../../modals/addCrossSellModal/addCrossSellModal";
import {
  ICrossSellItem,
  ICrossSellLogistic,
} from "../../../types/logistic.types";
import WorkshopId from "../../workshopId/workshopId";
import Input from "../../inputs/Input/Input";

interface AddCrossSellLogisticProps {
  handleCrossSellChange: (cs: ICrossSellLogistic) => void;
  crossSell: ICrossSellLogistic | undefined;
  handleDeleteCrossSellItem: (index: number) => void;
  handleSetCrossSellItemState?: (index: number) => void;
}

export default function AddCrossSellLogistic({
  handleCrossSellChange,
  crossSell,
  handleDeleteCrossSellItem,
  handleSetCrossSellItemState,
}: AddCrossSellLogisticProps) {
  const { setModalIsOpen, setModalChildren } = useModalComponent();

  return (
    <div className={style["cross-sell-form-container"]}>
      {crossSell &&
        crossSell.crossSellItems &&
        crossSell.crossSellItems.length > 0 && (
          <div className={style["cross-selling-products-container"]}>
            <Input
              label="Numéro de ticket"
              name="workshopId"
              inputMode="numeric"
              value={crossSell.workshopId}
              onChange={({ target }) =>
                handleCrossSellChange({
                  ...crossSell,
                  workshopId: target.value,
                })
              }
            />
            <ul className={style["cross-selling-products-list"]}>
              {crossSell.crossSellItems.map(
                (cs: ICrossSellQuote, index: number) => {
                  return (
                    <CrossSellItem
                      crossSell={cs}
                      handleDelete={() => handleDeleteCrossSellItem(index)}
                      handleSetCrossSellItemState={
                        handleSetCrossSellItemState
                          ? () => handleSetCrossSellItemState(index)
                          : undefined
                      }
                      key={index}
                    />
                  );
                }
              )}
            </ul>
          </div>
        )}
      <div className={style["add-crosssell"]} onClick={handleOpenModal}>
        ajouter des articles Cross-selling
      </div>
    </div>
  );

  function handleOpenModal() {
    // if (!crossSell) {
    //   return;
    // }
    setModalIsOpen(true);
    setModalChildren(
      <AddCrossSellModal
        handleCrossSellChange={handleCrossSellItemChange}
        setModalIsOpen={setModalIsOpen}
        crossSellItems={crossSell?.crossSellItems || []}
      />
    );
  }

  function handleCrossSellItemChange(crossSellItems: ICrossSellQuote[]) {
    handleCrossSellChange({
      ...crossSell,
      crossSellItems: crossSellItems as ICrossSellItem[],
    });
  }
}
