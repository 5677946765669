import { useQuery } from "react-query";
import style from "./leather.module.scss";
import { getProductList } from "../../../../requests/product";
import { useState } from "react";
import { queryObject } from "../../../../types/query.types";
import ProductCard from "../productCard/ProductCard";
import SearchByWorkshopId from "../../../../components/searchByWorkshopId/searchByWorkshopId";
import PagingComponent from "../../../../components/pagingComponent/pagingComponent";
import OperationTypeCard from "./OperationTypeCard/OperationTypeCard";
import Button from "../../../../components/Button/Button";
import LoadingSpinner from "../../../../components/loadingSpinner/loadingSpinner";
import { useRefetchOnModalClose } from "../../../../hooks/useRefetchOnModalClose";

const INITIAL_QUERY: queryObject = {
  limit: 10,
  offset: 0,
  sort: "",
  operationGroup: "",
  operationType: "LEATHER_AUTRE",
  field: "",
  value: "",
};

export default function Leather() {
  const [query, setQuery] = useState<queryObject>(INITIAL_QUERY);
  const [showAll, setShowAll] = useState<boolean>(false);
  const { data, isLoading, refetch } = useQuery(
    ["leather-product", query],
    () => getProductList(formatedQuery()),
    { keepPreviousData: true }
  );
  useRefetchOnModalClose(refetch);

  return (
    <div className={style["cobbler"]}>
      <h1>Maroquinerie</h1>
      <div className={style["filters-section"]}>
        <div className={style["set-mode"]}>
          <Button
            className={`${style["select-mode-button"]} ${
              showAll ? style["selected"] : ""
            }`}
            onClick={() => setShowAll(true)}
          >
            Tous les articles
          </Button>
          <Button
            className={`${style["select-mode-button"]} ${
              !showAll ? style["selected"] : ""
            }`}
            onClick={() => setShowAll(false)}
          >
            Par Operation
          </Button>
        </div>
        <SearchByWorkshopId
          workshopId={query.workshopId || ""}
          setWorkshopId={(workshopId) =>
            setQuery((prev) => ({ ...prev, workshopId }))
          }
        />
      </div>
      {showAll ? (
        <>
          <PagingComponent
            setQuery={setQuery}
            query={query}
            totalCount={data?.totalCount}
          />
          {isLoading ? (
            <LoadingSpinner color="gold" />
          ) : (
            <div className={style["product-list"]}>
              {data &&
                data?.productsList.map((p, index: number) => {
                  return (
                    <ProductCard
                      key={index}
                      product={p}
                      showOperationAcronym={true}
                    />
                  );
                })}
            </div>
          )}
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </>
      ) : (
        <>
        <OperationTypeCard
            operationType="LEATHER_FAG"
            title="FAG"
            workshopId={query.workshopId}
          />
          <OperationTypeCard
            operationType="LEATHER_TRANCHE"
            title="Teinture de tranches"
            workshopId={query.workshopId}
          />
          <OperationTypeCard
            operationType="LEATHER_DOUBLURE"
            title="Doublure"
            workshopId={query.workshopId}
          />
          <OperationTypeCard
            operationType="LEATHER_JONC"
            title="Jonc"
            workshopId={query.workshopId}
          />
          <OperationTypeCard
            operationType="LEATHER_COUTURE"
            title="Couture"
            workshopId={query.workshopId}
          />
          <OperationTypeCard
            operationType="LEATHER_ENCHAPE"
            title="Enchape"
            workshopId={query.workshopId}
          />
          <OperationTypeCard
            operationType="LEATHER_BANDOULIERE"
            title="Bandoulière"
            workshopId={query.workshopId}
          />
          <OperationTypeCard
            operationType="LEATHER_POIGNEE"
            title="Poignée"
            workshopId={query.workshopId}
          />
          <OperationTypeCard
            operationType="LEATHER_AUTRE"
            title="Autres"
            workshopId={query.workshopId}
          />
          <OperationTypeCard
            operationType="LEATHER_TEINTURE"
            title="Teinture"
            workshopId={query.workshopId}
          />
        </>
      )}
    </div>
  );

  function formatedQuery() {
    let formatedQuery: string = `?limit=${query.limit}&offset=${
      query.offset * query.limit
    }&productGroup=LEATHER&status=WORKSHOP_REPAIR;WORKSHOP_CARE;EXTERNAL_JOB`;

    if (query.workshopId) {
      formatedQuery += `&workshopId=${query.workshopId}`;
    }

    return formatedQuery;
  }
}
