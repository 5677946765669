import { useMutation, useQuery } from "react-query";
import {
  addShippingToInvoicePro,
  clearInvoicePro,
  fetchInvoicePro,
} from "../../../requests/invoicePro";
import style from "./invoiceProModal.module.scss";
import LoadingSpinner from "../../loadingSpinner/loadingSpinner";
import Button from "../../Button/Button";
import { getShippingProList } from "../../../requests/shippingPro";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";

interface InvoiceProModalProps {
  invoiceId: string;
  shopId: string;
}

export default function InvoiceProModal({
  invoiceId,
  shopId,
}: InvoiceProModalProps) {
  const { data, isFetching, refetch } = useQuery(
    "invoicepro-modal",
    () => fetchInvoicePro(invoiceId),
    { refetchOnWindowFocus: false }
  );

  const { mutate: clearInvoice } = useMutation(clearInvoicePro, {
    onSuccess(data, variables, context) {
      refetch();
      toast.success("Facture mise à zéro.", TOAST_SUCCESS_OPTIONS);
    },
    onError(error, variables, context) {
      refetch();
      toast.error("Erreur lors de la mise à zéro.", TOAST_ERROR_OPTIONS);
    },
  });

  if (isFetching) {
    return (
      <div className={style["invoice-pro-modal"]}>
        <LoadingSpinner color="gold" />
      </div>
    );
  }

  function handleDeleteClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    event.preventDefault();
    clearInvoice(invoiceId);
  }

  return (
    <div className={style["invoice-pro-modal"]}>
      <div className={style["invoice-pro-modal-container"]}>
        {/* <div>{data && data.invoicePro && JSON.stringify(data)}</div> */}
        {data?.invoicePro &&
          data.invoicePro.shippingQuotes &&
          data.invoicePro.shippingQuotes.map((shippingQuote) => (
            <div>
              <span>{shippingQuote.shippingDate}</span>
              {/* <Button
                id={shippingQuote.shippingDate}
                onClick={handleDeleteClick}
              >
                Supprimer
              </Button> */}
            </div>
          ))}
        <Button onClick={handleDeleteClick}>Clear</Button>
        <ShippingList
          shopId={shopId}
          invoiceId={invoiceId}
          onUpdate={() => refetch()}
        />
      </div>
    </div>
  );
}

function ShippingList({
  shopId,
  invoiceId,
  onUpdate,
}: {
  shopId: string;
  invoiceId: string;
  onUpdate?: () => void;
}) {
  const { data, isLoading } = useQuery(
    ["invoice-pro-shipping-list", shopId],
    () =>
      getShippingProList(
        `?direction=OUTGOING&state=DELIVERED&shopId=${shopId}`
      ),
    {
      refetchOnWindowFocus: false,
      select(data) {
        return {
          shippingProList: (data?.shippingProList || []).sort(
            (a, b) =>
              new Date(b.shippingDate || 0).getTime() -
              new Date(a.shippingDate || 0).getTime()
          ),
        };
      },
    }
  );

  const { mutate: addShipping } = useMutation(addShippingToInvoicePro, {
    onSuccess(data, variables, context) {
      //   refetch();
      onUpdate && onUpdate();
      toast.success("Livraison ajoutée.", TOAST_SUCCESS_OPTIONS);
    },
    onError(error, variables, context) {
      //   refetch();
      onUpdate && onUpdate();
      toast.error("Erreur lors de l'ajout de livraison.", TOAST_ERROR_OPTIONS);
    },
  });

  function handleAddShippingClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    let id = event.currentTarget.id;
    addShipping({ shippingId: id, invoiceId });
  }

  return (
    <div>
      {data &&
        data.shippingProList &&
        data.shippingProList.map((shipping) => (
          <div key={shipping.id}>
            <div>
              {shipping.shippingDate} - {(shipping.trackId || []).length}
              <Button id={shipping.id} onClick={handleAddShippingClick}>
                Ajouter
              </Button>
            </div>
          </div>
        ))}
    </div>
  );
}
