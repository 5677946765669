import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import style from "./index.module.scss";
import { useQuery } from "react-query";
import { deleteCart } from "../../requests/professionalCarts";
import Item from "./item/item";
import { queryObject } from "../../types/query.types";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import { IShop } from "../../types/shop.types";
import { getCartsByShippingProId } from "../../requests/shippingPro";
import translate from "../../translation";

export const INITIAL_QUERY: queryObject = {
  field: "productsList.workshopId",
  value: "",
  service: "",
  direction: "",
  status: "Tous",
  limit: 10,
  offset: 0,
  sort: "",
  dateFrom: "",
  dateTo: "",
  state: "",
  shopId: "",
};

let controller = new AbortController();

export default function ShippingPro() {
  const [searchParams] = useSearchParams();
  const shopName = searchParams.get("shop");
  const shippingDate = searchParams.get("shipping-date");
  const direction = searchParams.get("direction");
  const { id } = useParams();
  const { data, isFetching, refetch } = useQuery(
    ["professionalCart", id],
    () => getCartsByShippingProId(id),
    { keepPreviousData: true, enabled: Boolean(id) }
  );

  return (
    <div className={style["item-list"]}>
      <div className={style["top"]}>
        <div className={style["title"]}>{`${shopName} - ${translate(
          direction || ""
        )} du ${
          shippingDate
            ? new Date(shippingDate).toLocaleDateString()
            : "non-disponible"
        }`}</div>
      </div>
      <div className={style["bottom"]}>
        <div className={style["item"]}>
          <div className={style["item-left"]}>
            <div className={style["item-element"]}>Coordonnées</div>
            <div className={style["item-element"]}>Date de création</div>
            <div className={style["item-element"]}>Articles</div>
            <div className={style["item-element"]}>Status</div>
          </div>
          <div className={style["item-element"]}>Actions</div>
        </div>
        {isFetching ? (
          <div className={style["loading-spinner-container"]}>
            <LoadingSpinner color="gold" />
          </div>
        ) : (
          <>
            {data && data.cartsList && data.cartsList.length > 0 ? (
              data.cartsList.map((value: any, index: number) => {
                return (
                  <Item
                    key={index}
                    data={value}
                    onDelete={handleDelete}
                    zIndex={data.totalCount - index}
                    onUpdate={refetch}
                  />
                );
              })
            ) : (
              <div className={style["unavailable"]}>
                aucune commande à cette date
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );

  async function handleDelete(id?: string) {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette commande ?")) {
      controller.abort();
      try {
        controller = new AbortController();
        await deleteCart(id, controller.signal);
        refetch();
      } catch (error) {
        console.error(error);
      }
    }
  }
}
