import { createPortal } from "react-dom";

interface ModalProps {
  isOpen?: boolean;
  children: React.ReactNode;
}

export default function ModalPortal({ isOpen, children }: ModalProps) {
  if (!isOpen) {
    return null;
  }
  return createPortal(children, document.body);
}
