import { useEffect, useRef } from "react";

export default function useTextToLabel(text: string) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }

    const lineHeight = 50;
    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    // Set text properties
    ctx.font = "35px Arial";
    ctx.textAlign = "left";

    // Draw text on canvas
    const lines = text.split("\n"); // Handle multi-line text
    ctx.fillStyle = "black";
    lines.forEach((line, index) => {
      ctx.fillText(line, 10, 40 + index * lineHeight); // Adjust line height as needed
    });
  }, [text]);

  return canvasRef;
}
