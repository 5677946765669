import { IForcedPayment, IPayment } from "../types/payment.type";

export async function addPaymentOrder(
  paymentData: IForcedPayment
): Promise<{ paymentURL: string }> {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/payment/sog/payment-order`,
    {
      headers: new Headers({ "Content-Type": "application/json" }),
      method: "POST",
      body: JSON.stringify(paymentData),
      credentials: "include",
    }
  );
  if (response.status !== 200) {
    throw new Error();
  }
  let result = await response.json();
  return result;
}

export async function addPayment({
  paymentData,
  sendEmail = true,
}: {
  paymentData: IForcedPayment;
  sendEmail?: boolean;
}): Promise<boolean> {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/payment/sog/callback`,
    {
      headers: new Headers({ "Content-Type": "application/json" }),
      method: "POST",
      body: JSON.stringify({ paymentData, sendEmail }),
      credentials: "include",
    }
  );
  if (response.status !== 200) {
    throw new Error();
  }
  return response.status === 200;
}

export async function fetchPayment(paymentId: string): Promise<IPayment> {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/payment/${paymentId}`,
    {
      headers: new Headers({ "Content-Type": "application/json" }),
      method: "GET",
      credentials: "include",
    }
  );
  if (response.status !== 200) {
    throw new Error();
  }
  return await response.json();
}

export async function fetchPaymentByTrackId(
  paymentId: string
): Promise<{ payment: IPayment }> {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/payment/trackId/${paymentId}`,
    {
      headers: new Headers({ "Content-Type": "application/json" }),
      method: "GET",
      credentials: "include",
    }
  );
  if (response.status !== 200) {
    throw new Error();
  }
  return await response.json();
}

export async function fetchPaymentsList(
  // request: Request,
  query?: string
): Promise<{ payments: IPayment[]; totalCount: number }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/payment${query || ""}`,
      {
        credentials: "include",
        // signal: request.signal,
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
