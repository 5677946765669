import { useState } from "react";
import InputCode from "../../inputCode";
import { IShipping } from "../../../../../types/shipping.types";
import ShippingSelection from "../../shippingSelection/shippingSelection";

interface IncomingChronopostProps {
  shippingService?: string;
}

export default function IncomingChronopost({
  shippingService,
}: IncomingChronopostProps) {
  const [shippings, setShippings] = useState<null | IShipping[]>(null);
  return (
    <>
      <InputCode
        onFetched={handleShippingFetched}
        shippingService={shippingService}
      />
      {shippings && <ShippingSelection shippings={shippings} />}
    </>
  );

  function handleShippingFetched(data: IShipping[]) {
    const found = data.filter(
      (shipping: IShipping) =>
        shipping.state === "PENDING" || shipping.state === "TRANSITING"
    );
    if (found) setShippings(data);
  }
}
