import { FormEvent, useEffect, useState } from "react";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import style from "./create.module.scss";
import { IProAccount } from "../../../types/proAccount.types";
import { addProAccount, updateProAccount } from "../../../requests/proAccount";
import { ShopPopup } from "../../../components/popUp/addShopPopUp/shopPopUp";
import { IShop } from "../../../types/shop.types";
import { getShop } from "../../../requests/shops";
import { ShopAutoComplete } from "../../../components/autoComplet/shopsComplet";
import { toast } from "react-toastify";
import { TOAST_OPTIONS } from "../../../utils/toast.options";

const INITALE_STATE: IProAccount = {
  id: "",
  username: "",
  password: "",
  shops: [],
  email: "",
};

export default function Create({ data }: { data?: IProAccount }) {
  const [newUser, setNewUser] = useState<IProAccount>(data || INITALE_STATE);
  const [visible, setVisible] = useState<Boolean>(false);
  const [IErrorMessage, setIErrorMessage] = useState<string>("");

  const [isShopPopupOpen, setShopPopupOpen] = useState(false);
  const [shops, setShops] = useState<string[]>(data ? data.shops : []);
  const [shopsTab, setShopsTab] = useState<IShop[]>([]); //presente dans proaccount
  const navigate = useNavigate();

  useEffect(() => {
    if (data && data.shops.length) {
      let tab: IShop[] = [];
      for (let id of data.shops) {
        getShop(id).then((temps) => {
          let newShop: IShop = {
            id: temps.id,
            name: temps.name,
            contact: temps.contact,
            workshopPrefixId: temps.workshopPrefixId,
            shippingMethod: temps.shippingMethod,
            cartsId: temps.cartsId,
            nextIncomingShipping: temps.nextIncomingShipping,
            nextOutgoingShipping: temps.nextOutgoingShipping,
            pricing: temps.pricing,
          };

          tab.push(newShop);
          setShopsTab([...tab]);
        });
      }
    }
  }, [data]);

  function handleOnChange(
    target: (EventTarget & HTMLInputElement) | (EventTarget & HTMLSelectElement)
  ) {
    setNewUser({ ...newUser, [target.name]: target.value });
  }

  const openShopPopup = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (isShopPopupOpen) {
      setShopPopupOpen(false);
      return;
    }
    setShopPopupOpen(true);
  };

  const closeShopPopup = () => {
    setShopPopupOpen(false);
  };

  const addShop = (name: string, shopToAdd: IShop) => {
    let tempUser: IProAccount = { ...newUser };
    if (shopToAdd) {
      let newShopsTab: IShop[] = [...shopsTab];
      newShopsTab.push(shopToAdd);
      setShopsTab([...newShopsTab]);
      let newShops: string[] = [...shops];
      shopToAdd.id && newShops.push(shopToAdd.id);
      setShops([...newShops]);
      tempUser.shops = [...newShops];
      setNewUser({ ...tempUser });
    }
  };

  const removeShop = (name: string) => {
    let tempUser: IProAccount = { ...newUser };
    const removedShop = shopsTab.find((shop) => shop.name === name);
    if (removedShop) {
      const newShopsTab: IShop[] = shopsTab.filter(
        (shop) => shop.name !== name
      );
      setShopsTab([...newShopsTab]);
      const newShops: string[] = shops.filter((id) => id !== removedShop.id);
      setShops([...newShops]);
      tempUser.shops = [...newShops];
      setNewUser({ ...tempUser });
    }
  };

  useEffect(() => {}, [shops]);
  async function handleUpdateUser(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const response = await updateProAccount(newUser);
    setIErrorMessage(response || "");
    if (response === undefined) {
      navigate("../");
    }
  }

  async function handleAddUser(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      const response = await addProAccount(newUser);
      if (response === "Account already existe!") {
        toast.warning("Nom d'utilisateur déjà utilisé", TOAST_OPTIONS);
        //setIErrorMessage(response || "");
      } else {
        navigate(`/shops?proAccountId=${response}&name=${newUser.username}`);
      }
    } catch (error) {
      console.error(
        "Une erreur inattendue s'est produite lors de la création du compte:",
        error
      );
      toast.warning(
        "Une erreur inattendue s'est produite lors de la création du compte:",
        TOAST_OPTIONS
      );
    }
  }

  return (
    <div className={style["create"]}>
      <div className={style["title"]}>
        {data
          ? `Modifier (utilisateur: ${data.username})`
          : "Ajouter un nouvel utilisateur"}
      </div>
      <form
        className={style["form"]}
        onSubmit={(event) =>
          data ? handleUpdateUser(event) : handleAddUser(event)
        }
      >
        <div className={style["username"]}>
          <input
            className={style["input-container"]}
            type="text"
            name={"username"}
            value={newUser.username}
            placeholder={"nom d'utilisateur"}
            onChange={({ target }) => handleOnChange(target)}
          />
        </div>
        {!data && (
          <div className={style["password"]}>
            <input
              className={style["input-container"]}
              type={visible ? "text" : "password"}
              name={"password"}
              placeholder={"mot de passe"}
              value={newUser.password}
              onChange={({ target }) => handleOnChange(target)}
            />
            <div
              className={style["eye-icon"]}
              onClick={() => setVisible(!visible)}
            >
              {visible ? <EyeSlash /> : <Eye />}
            </div>
          </div>
        )}
        <div className={style["password"]}>
          <input
            className={style["input-container"]}
            type={visible ? "text" : "email"}
            name={"email"}
            placeholder={"email"}
            value={newUser.email}
            onChange={({ target }) => handleOnChange(target)}
          />
        </div>
        {/* <div className={style["shops"]}>
          <div className={style["shops-list"]}>
            {isShopPopupOpen && (
              <ShopPopup
                shops={shopsTab}
                onAddShop={addShop}
                onRemoveShop={removeShop}
              />
            )}
          </div>
          <button
            onClick={(event) => openShopPopup(event)}
            className={style["button"]}
          >
            {isShopPopupOpen ? "Fermer" : "Gérer les magasins"}
          </button>
          <div className={style["shops-list"]}>
            {!isShopPopupOpen &&
              shopsTab &&
              shopsTab.map((shop, index) => (
                <div className={style["shop-name"]} key={index}>
                  {shop.name}
                </div>
              ))}
          </div>
        </div> */}
        <div className={style["add-user"]}>
          {data ? (
            <button className={style["button"]} type={"submit"}>
              Mettre à jour
            </button>
          ) : (
            <button className={style["button"]} type={"submit"}>
              Enregistrer
            </button>
          )}
        </div>
      </form>
      <div className={style["error"]}>
        {IErrorMessage ? `*${IErrorMessage}` : ""}
      </div>
    </div>
  );
}
