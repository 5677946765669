import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../../pages/error-page";
// import Create from "./create/create";
// import Read from "./read/read";
import { authloader } from "..";
// import Edit from "./edit/edit";
import ShippingPro from ".";

export const route: RouteObject = {
  path: "/shipping-pro",
  element: <Outlet />,
  loader: authloader,
  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <ShippingPro />,
    },
    {
      path: "/shipping-pro/:id",
      element: <ShippingPro />,
    },
  ],
};
