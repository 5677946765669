import { useQuery } from "react-query";
import { IOperation } from "../../../../../../types/accounting.types";
import Input from "../../../../../inputs/Input/Input";
import style from "./autoCompleteOperationInput.module.scss";
import {
  fetchOperation,
  fetchOperationsList,
} from "../../../../../../requests/operation";
import { useEffect, useState } from "react";
import useClickOutside from "../../../../../../hooks/useClickOutSide";
import BeautifulDiv from "../../../../../beautifulDiv/BeautifulDiv";
import { useKeyDown } from "../../../../../../hooks/useKeyDown";

interface AutoCompleteOperationInputProps {
  autoCompleteOperationsList?: IOperation[];
  operationForm: IOperation;
  setOperationForm: (operationForm: IOperation | null) => void;
  setPriceIsDisabled: (value: boolean) => void;
}

export default function AutoCompleteOperationInput({
  autoCompleteOperationsList,
  operationForm,
  setOperationForm,
  setPriceIsDisabled,
}: AutoCompleteOperationInputProps) {
  const [openDropDown, setOpenDropDown] = useState<boolean>(false);
  const ref = useClickOutside<HTMLDivElement>(() => setOpenDropDown(false));
  useKeyDown("Escape", () => setOpenDropDown(false));

  return (
    <div className={style["auto-complete-operation-input"]} ref={ref}>
      <Input
        className={style["input-container"]}
        label="Opération"
        name="description"
        autoComplete="off"
        value={operationForm.description}
        onChange={({ target }) =>
          setOperationForm({
            ...operationForm,
            description: target.value,
          })
        }
        onClick={() => setOpenDropDown(!openDropDown)}
      />
      {openDropDown && (
        <div className={style["drop-down-container"]}>
          {/* <div> */}
          <ul className={style["drop-down"]}>
            {autoCompleteOperationsList &&
              autoCompleteOperationsList.map((op, index) => {
                return (
                  <li
                    className={style["drop-down-item"]}
                    key={index}
                    onClick={() => handleSelectOperation(op)}
                  >
                    {op.description}
                  </li>
                );
              })}
          </ul>
          <BeautifulDiv />
        </div>
      )}
    </div>
  );

  function handleSelectOperation(operation: IOperation) {
    setOperationForm(operation);
    setPriceIsDisabled(true);
    setOpenDropDown(false);
  }
}
