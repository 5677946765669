import { Delete, Edit } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import translate from "../../../translation";
import { IShippingMethod } from "../../../types/accounting.types";
import style from "./item.module.scss";

export default function Item({
  data,
  onDelete,
}: {
  data: IShippingMethod;
  onDelete: (id: string) => void;
}) {
  return (
    <div className={style["item"]}>
      <div className={style["item-element"]}>{data.name}</div>
      <div className={style["item-element"]}>{data.shippingService}</div>
      {/* <div className={style["item-element"]}>
        {data.operationGroup && translate(data.operationGroup)}
      </div>
      <div className={style["item-element"]}>
        {data.operationType && translate(data.operationType)}
      </div>
      <div className={style["item-element"]}>
        {data.productGroup && translate(data.productGroup)}
      </div> */}
      <div className={style["item-element"]}>{data.price} €</div>
      <div className={style["item-element"]}>
        {data.freeShippingMinimumAmount} €
      </div>
      <div className={style["item-element"]}>
        <ul>
          {Object.entries(data.businessDays)
            .filter((entry) => entry[1])
            .map((entry, index: number) => (
              <li key={index}>{translate(entry[0])}</li>
            ))}
        </ul>
      </div>
      <div className={style["item-element"]}>
        <ul>
          {data.businessHours.map(({ start, end }, index: number) => (
            <li key={index}>{`${start} - ${end}`}</li>
          ))}
        </ul>
      </div>
      <ProtectedComponent roles={["ROLE_ADMIN"]}>
        <div className={`${style["item-element"]} ${style["tools"]}`}>
          <Link
            // onClick={() => window.scrollTo(0, 0)}
            className={style["mylink"]}
            to={`/shipping-method/edit/${data.id}`}
          >
            <Edit className={style["icon"]} />
          </Link>
          <Delete className={style["icon"]} onClick={handleDelete} />
        </div>
      </ProtectedComponent>
    </div>
  );

  function handleDelete() {
    if (
      data.id &&
      window.confirm(
        "Êtes-vous sûr de vouloir supprimer cette méthode de livraison ?"
      )
    ) {
      onDelete(data.id);
    }
  }
}
