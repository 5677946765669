import styles from "./index.module.scss";
import { deleteLogistic, fetchLogisticList } from "../../requests/logistic";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { ProtectedComponent } from "../../components/protectedComponent/protectedComponent";
import { INITIAL_QUERY } from "../../utils/query.init";
import SearchByKeyComponent from "../../components/searchByKeyComponent/searchByKeyComponent";
import FilterByStatus from "../../components/filterByStatus/filterByStatus";
import { LOGISTIC_STATUS } from "../../utils/logistic.init";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import { useRefetchOnModalClose } from "../../hooks/useRefetchOnModalClose";
import useSaveQueryStorage from "../../hooks/useSaveQueryStorage";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import Button from "../../components/Button/Button";
import ListHeader from "./ListHeader/ListHeader";
import clsx from "clsx";
import Item from "./listItem/item";
import FiltersContainer from "../../components/Filters/FiltersContainer";
import ListPageHeader from "../referral/ListPageHeader/ListPageHeader";

const OPTIONS_LIST = [
  "productsList.workshopId",
  "contact.displayname",
  "contact.organization",
  "contact.phone",
  "contact.email",
  "productsList.brand",
];

let controller = new AbortController();

export default function Logistic() {
  const [query, setQuery] = useSaveQueryStorage(INITIAL_QUERY, "logistic");
  const { data, isLoading, refetch } = useQuery(
    ["logistic", query],
    () => fetchLogisticList(queryFormatter()),
    { keepPreviousData: true, refetchOnWindowFocus: true }
  );

  useRefetchOnModalClose(refetch);

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  return (
    <div className={styles["list-page"]}>
      <ListPageHeader
        title={"Documents Logistiques"}
        actionSection={
          <Link onClick={() => window.scrollTo(0, 0)} to={"./create"}>
            <ProtectedComponent roles={["ROLE_ADMIN", "ROLE_LOGISTICS"]}>
              <Button>+ Ajouter nouveau</Button>
            </ProtectedComponent>
          </Link>
        }
      />
      <FiltersContainer>
        <SearchByKeyComponent
          query={query}
          setQuery={setQuery}
          optionsList={OPTIONS_LIST}
        />
        <FilterByStatus
          query={query}
          setQuery={setQuery}
          optionsList={LOGISTIC_STATUS}
          field={"status"}
        />
      </FiltersContainer>
      <div className={styles["list-container"]}>
        <div className={styles["paging-component-top"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
        <div className={clsx(styles["list"])}>
          <table className={styles["table"]}>
            <ListHeader />
            <tbody>
              {data &&
                data.logisticsList &&
                data.logisticsList.map((value: any, index: number) => {
                  return (
                    <Item
                      key={index}
                      data={value}
                      onDelete={() => handleDelete(value.id)}
                      refetch={refetch}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className={styles["paging-component"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
      </div>
    </div>
  );

  async function handleDelete(id: string) {
    controller.abort();
    try {
      controller = new AbortController();
      await deleteLogistic(id, controller.signal);
      refetch();
    } catch (error) {
      console.error(error);
    }
  }

  function queryFormatter() {
    let formatedQuery: string = "";

    if (query.limit) {
      formatedQuery += `?limit=${query.limit}`;
    }

    formatedQuery += `&offset=${query.offset * query.limit}`;

    if (query.state && query.state != "Tous") {
      formatedQuery += `&status=${query.state}`;
    }
    if (query.field && query.value) {
      formatedQuery += `&${query.field}=${query.value}`;
    }
    if (query.sort) {
      formatedQuery += `${query.sort}`;
    } else {
      formatedQuery += `&sort_field=createdAt&sort_direction=desc`;
    }
    return formatedQuery;
  }
}
