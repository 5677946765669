import translate from "../../translation";
import getStatusColor from "../../translation/statusColor";
import style from "./stateWidget.module.scss";

interface StateWidgetProps {
  className?: string;
  size?: string;
  state: string;
}

export default function StateWidget({
  className,
  size,
  state,
}: StateWidgetProps) {
  return (
    <div
      className={`${style["state-widget"]} ${sizeStyle()} ${className}`}
      style={{ backgroundColor: getStatusColor(state) }}
    >
      {translate(state)}
    </div>
  );

  function sizeStyle() {
    switch (size) {
      case "xx-small":
        return style["xx-small"];
      case "x-small":
        return style["x-small"];
      case "small":
        return style["small"];
      case "medium":
        return style["medium"];
      case "large":
        return style["large"];
      default:
        return "";
    }
  }
}
