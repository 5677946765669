import { IValidationError } from "../types";
import { IShippingMethod } from "../types/accounting.types";

export async function fetchShippingMethod({
  request,
  params,
}: {
  request: Request;
  params: any;
}): Promise<IShippingMethod> {
  try {
    if (!params.shippingMethodId) {
      throw new Response("Missing shippingMethod id");
    }
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping-method/${params.shippingMethodId}`,
      {
        credentials: "include",
        signal: request.signal,
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.shippingMethod;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchShippingMethodsList2(
  params: { key: string; value: string }[]
): Promise<IShippingMethod[]> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping-method?${params
        .map((param) => param.key + "=" + param.value)
        .join("&")}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.shippingMethods;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchShippingMethodsList(
  query?: string
): Promise<{ shippingMethodsList: IShippingMethod[]; totalCount: number }> {
  try {
    let queryUrl = query ? query : "";
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping-method?` + queryUrl,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function postShippingMethod(
  request: Request,
  shippingMethod: IShippingMethod
): Promise<string | IValidationError[] | null> {
  // const formData = await request.formData();
  // const shippingMethod = Object.fromEntries(formData);
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping-method`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "include",
        method: "POST",
        signal: request.signal,
        body: JSON.stringify(shippingMethod),
      }
    );
    const data = await response.json();

    if (![201, 200, 400].includes(response.status)) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateShippingMethod(
  request: Request,
  shippingMethod: IShippingMethod,
  slug: string
): Promise<IShippingMethod> {
  try {
    if (!slug) {
      throw new Response("Missing shippingMethod slug");
    }
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping-method/${slug}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "include",
        method: "PUT",
        signal: request.signal,
        body: JSON.stringify(shippingMethod),
      }
    );
    const data = await response.json();
    if (![201, 200, 400].includes(response.status)) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function deleteShippingMethod({
  slug,
  signal,
}: {
  slug: string;
  signal?: AbortSignal;
}) {
  try {
    if (!slug) {
      throw new Response("Missing shippingMethod slug");
    }
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping-method/${slug}`,
      {
        credentials: "include",
        signal: signal,
        method: "DELETE",
      }
    );
    if (response.status !== 200) {
      throw new Response("Deletion failes", {
        statusText: response.statusText,
        status: response.status,
      });
    }
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
