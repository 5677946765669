import { useState } from "react";
import translate from "../../translation";
import getStatusColor from "../../translation/statusColor";
import style from "./state.module.scss";
import useClickOutside from "../../hooks/useClickOutSide";
import BeautifulDiv from "../beautifulDiv/BeautifulDiv";
import StateItem from "./StateItem";
import { translateTrackingState } from "../../utils/tracking.init";
import PictureLoadingSpinner from "../loadingSpinner/pictureloadingSpinner";

interface StateProps {
  className?: string;
  isTracker?: boolean;
  isLoading?: boolean;
  state: string;
  size?: string;
  stateList?: string[];
  handleSetState?: (state: string) => void;
}

export default function State({
  className,
  isTracker = false,
  isLoading = false,
  state,
  size,
  stateList,
  handleSetState,
}: StateProps) {
  const [dropdown, setDropdown] = useState<boolean>(false);
  const ref = useClickOutside<HTMLDivElement>(() => setDropdown(false));

  return (
    <div
      className={`${style["state-container"]} ${
        handleSetState ? style["hover"] : ""
      } ${className ? className : ""}`}
      ref={ref}
    >
      <div
        className={`${style["state"]} ${sizeStyle()}`}
        style={{
          backgroundColor: `${getStatusColor(state)}`,
        }}
        onClick={() => setDropdown(!dropdown)}
      >
        {isLoading ? (
          <PictureLoadingSpinner
            className={style["loading-spinner"]}
            color="white"
          />
        ) : (
          <>{isTracker ? translateTrackingState(state) : translate(state)}</>
        )}
      </div>
      {stateList && handleSetState && dropdown && (
        <div className={style["state-dropdown-container"]}>
          <ul className={style["state-dropdown"]}>
            {stateList.map((state: string, index: number) => (
              <StateItem
                handleChangeState={() => handleChangeState(state)}
                key={index}
                state={
                  isTracker ? translateTrackingState(state) : translate(state)
                }
              />
            ))}
          </ul>
          <BeautifulDiv />
        </div>
      )}
    </div>
  );

  function handleChangeState(state: string) {
    handleSetState && handleSetState(state);
    setDropdown(false);
  }

  function sizeStyle() {
    switch (size) {
      case "xxx-small":
        return style["xxx-small"];
      case "xx-small":
        return style["xx-small"];
      case "x-small":
        return style["x-small"];
      case "small":
        return style["small"];
      case "medium":
        return style["medium"];
      case "large":
        return style["large"];
      default:
        return "";
    }
  }
}
