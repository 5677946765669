import { Outlet, RouteObject } from "react-router-dom";
import ShippingMethods from ".";
import { authloader } from "..";
import ErrorPage from "../../pages/error-page";
import Create from "./create/create";
import Edit, { loader as editLoader } from "./edit/edit";

export const route: RouteObject = {
  path: "/shipping-method",
  element: <Outlet />,
  errorElement: <ErrorPage />,
  loader: authloader,
  children: [
    {
      index: true,
      element: <ShippingMethods />,
      loader: authloader,
    },
    {
      path: "create",
      element: <Create />,
      loader: authloader,
    },
    {
      path: "edit/:shippingMethodId",
      loader: editLoader,
      element: <Edit />,
    },
  ],
};
