import { useEffect, useRef, useState } from "react";
import style from "./imageCrop.module.scss";
import {
  CameraAltRounded,
  Close,
  Crop as CropIcon,
  Delete,
} from "@mui/icons-material";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/src/ReactCrop.scss";
import "react-toastify/dist/ReactToastify.css";

interface ImageCropProps {
  startCrop: boolean;
  setRawImage: (rawImage: HTMLImageElement | null) => void;
  className?: string;
  image: File | null | undefined;
  crop: Crop | undefined;
  setCrop: (crop: Crop) => void;
  aspect?: number | undefined;
}

export function ImageCrop({
  startCrop,
  setRawImage,
  className,
  image,
  crop,
  setCrop,
  aspect,
}: ImageCropProps) {
  return (
    <div className={style["image-container"]}>
      {image && (
        <>
          {startCrop ? (
            <div className={style["add-image-wrapper"]}>
              <ReactCrop
                crop={crop}
                onChange={(c) => setCrop(c)}
                // minHeight={height}
                // minWidth={width}
                // maxHeight={height}
                // maxWidth={width}
                aspect={aspect}
              >
                <img
                  className={className ?? style["image-preview-miniature"]}
                  src={URL.createObjectURL(image)}
                  alt={image.name}
                  onLoad={(e) => {
                    setRawImage(e.currentTarget);
                  }}
                />
              </ReactCrop>
            </div>
          ) : (
            <>
              <img
                className={className ?? style["image-preview-miniature"]}
                src={URL.createObjectURL(image)}
                alt={image.name}
                onLoad={(e) => {
                  setRawImage(e.currentTarget);
                }}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}
