import DropAndCollect from "./DropAndCollect/DropAndCollect";
import IncomingChronopost from "./IncomingChronopost/IncomingChronopost";
import IncomingInternal from "./IncomingInternal/IncomingInternal";

interface ShippingServiceProps {
  shippingService: string;
}

export default function ShippingService({
  shippingService,
}: ShippingServiceProps) {
  switch (shippingService) {
    case "CHRONOPOST-2SHOP":
      return <IncomingChronopost />;
    case "CHRONOPOST-EUR":
      return <IncomingChronopost shippingService={"CHRONOPOST-EUR"} />;
    case "DROPANDCOLLECT":
      return <DropAndCollect />;
    case "GP":
      return <IncomingInternal />;
    default:
      return <></>;
  }
}
