import styles from "./payedListWidget.module.scss";
import useSaveQueryStorage from "../../../../hooks/useSaveQueryStorage";
import { queryObject } from "../../../../types/query.types";
import PagingComponent from "../../../../components/pagingComponent/pagingComponent";
import { useQuery } from "react-query";
import { fetchPayedTrackersList } from "../../../../requests/tracker";
import PayedList from "./PayedList/PayedList";
import DashboardWidget from "../DashboardWidget";
import DashboardWidgetHeader from "../DashboardWidgetHeader/DashboardWidgetHeader";
import LoadingWidget from "../LoadingWidget/LoadingWidget";
import ErrorWidget from "../ErrorWidget/ErrorWidget";

export const INITIAL_QUERY: queryObject = {
  limit: 5,
  offset: 0,
};

export default function PayedListWidget() {
  const [query, setQuery] = useSaveQueryStorage(INITIAL_QUERY, "payed-list");
  const { data, isLoading } = useQuery(
    ["payedTrackersList-widget", query],
    () => fetchPayedTrackersList("?" + formatedQuery()),
    {
      keepPreviousData: true,
    }
  );

  if (isLoading) {
    return <LoadingWidget />;
  }

  if (!data) {
    return <ErrorWidget />;
  }

  return (
    <DashboardWidget>
      <div className={styles["payed-list-widget"]}>
        <DashboardWidgetHeader
          title={"A réparer"}
          totalCount={data.totalCount}
        />
        <div className={styles["body"]}>
          <PayedList list={data.trackersList} />
        </div>
        <div className={styles["footer"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data.totalCount}
          />
        </div>
      </div>
    </DashboardWidget>
  );

  function formatedQuery() {
    return (
      new URLSearchParams({
        limit: query.limit.toString(),
        offset: (query.limit * query.offset).toString(),
      }).toString() + "&sort_field=updatedAt&sort_direction=desc"
    );
  }
}
