import { useEffect, useState } from "react";
import style from "./modalContainer.module.scss";
import { useModalComponent } from "../../context/modalComponent.context";
import { Close } from "@mui/icons-material";
import { useConfirmModal } from "../../context/confirmationModalContext";
import ConfirmModalComp from "../confirmationModal/ConfirmModalComp";

export default function ModalContainer() {
  const {
    modalChildren: children,
    setModalIsOpen,
    confirmClose,
    setConfirmClose,
    setModalChildren,
  } = useModalComponent();
  const { setConfirmModalIsOpen, setConfirmModalChildren } = useConfirmModal();
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () =>
      setWindowHeight(window.innerHeight)
    );
    return () => {
      window.removeEventListener("resize", () =>
        setWindowHeight(window.innerHeight)
      );
    };
  }, []);

  return (
    <div
      className={style["modal-container"]}
      style={{ height: `${windowHeight}px` }}
    >
      <div
        className={style["exit-icon"]}
        onClick={confirmClose ? handleConfirmCloseModal : handleCloseModal}
      >
        <Close className={style["icon"]} />
      </div>
      <div className={style["modal"]}>{children}</div>
      <div
        className={style["background"]}
        onClick={confirmClose ? handleConfirmCloseModal : handleCloseModal}
      ></div>
    </div>
  );

  function handleCloseModal() {
    setModalIsOpen(false);
    setModalChildren(<></>);
    setConfirmClose(false);
  }

  function handleConfirmCloseModal() {
    setConfirmModalChildren(
      <ConfirmModalComp
        confirmButtonClassName={style["validate-button"]}
        title={"Etes-vous sur de vouloir fermer cette fenêtre ?"}
        confirmText="Fermer"
        onConfirm={handleCloseModal}
      />
    );
    setConfirmModalIsOpen(true);
  }
}
