export async function generateInvoice(trackId: string): Promise<any> {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/pennylane/generate-invoice`,
    {
      headers: new Headers({ "Content-Type": "application/json" }),
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ trackId: trackId }),
    }
  );
  if (response.status !== 200) {
    throw new Error();
  }
  return await response.json();
}

export async function finalizeInvoice(invoiceId: string): Promise<any> {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/pennylane/finalize-invoice`,
    {
      headers: new Headers({ "Content-Type": "application/json" }),
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ invoiceId: invoiceId }),
    }
  );
  if (response.status !== 200) {
    throw new Error();
  }
  return await response.json();
}
