import { IValidationError } from "../types";
import { IOperation } from "../types/accounting.types";

export async function getOperationPro({
  request,
  params,
}: {
  request: Request;
  params: any;
}): Promise<IOperation> {
  try {
    if (!params.operationId) {
      throw new Response("Missing operation id");
    }
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation-pro/${params.operationId}`,
      {
        credentials: "include",
        signal: request.signal,
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.operation;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getOperationsListPro(
  query?: string
): Promise<{ operations: IOperation[]; totalCount: number }> {
  try {
    let queryUrl = query ? query : "";
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation-pro?` + queryUrl,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function addOperationPro(
  operation: IOperation
): Promise<string | IValidationError[] | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation-pro`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "include",
        method: "POST",
        body: JSON.stringify(operation),
      }
    );
    const data = await response.json();

    if (![201, 200, 400].includes(response.status)) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateOperationPro(
  operation: IOperation
): Promise<IOperation> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation-pro/${operation.slug}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "include",
        method: "PUT",
        body: JSON.stringify(operation),
      }
    );
    const data = await response.json();
    if (![201, 200, 400].includes(response.status)) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function deleteOperationPro(slug: string) {
  try {
    if (!slug) {
      throw new Response("Missing operation slug");
    }
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation-pro/${slug}`,
      {
        credentials: "include",
        method: "DELETE",
      }
    );
    if (response.status !== 200) {
      throw new Response("Deletion failes", {
        statusText: response.statusText,
        status: response.status,
      });
    }
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getProductGroups({
  request,
}: {
  request?: Request;
}): Promise<string[]> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation-pro/product-groups`,
      {
        credentials: "include",
        signal: request?.signal,
      }
    );

    const data = await response.json();

    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.productGroups;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getOperationGroups({
  request,
}: {
  request?: Request;
}): Promise<string[]> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation-pro/operation-groups`,
      {
        credentials: "include",
        signal: request?.signal,
      }
    );

    const data = await response.json();

    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.operationGroups;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getOperationTypes({
  request,
}: {
  request?: Request;
}): Promise<string[]> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation-pro/operation-types`,
      {
        credentials: "include",
        signal: request?.signal,
      }
    );

    const data = await response.json();

    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.operationTypes;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
