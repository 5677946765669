import style from "./imageViewer.module.scss";
import { useImageViewer } from "../../context/imageViewer.context";
import { ChevronLeft, ChevronRight, Close } from "@mui/icons-material";
import Image from "../image/Image";
import { useEffect, useMemo, useState } from "react";
import { useBackButton } from "../../hooks/useBackButton";
import { useMouseCoord } from "../../hooks/useMouseCoord";

export default function ImageViewer() {
  const { isBlob, index, pictureList, setViewerIsOpen } = useImageViewer();
  const [pictureIndex, setPictureIndex] = useState(index);
  const [zoomIn, setZoomIn] = useState<boolean>(false);
  const isBack = useBackButton();
  const coords = useMouseCoord();

  useEffect(() => {
    isBack && setViewerIsOpen(false);
  }, [isBack]);

  const TRANSLATE_STYLE = useMemo(() => {
    return zoomIn
      ? {
          transform: `translate(${window.innerWidth / 2 - coords.x}px ,${
            window.innerHeight / 2 - coords.y
          }px)`,
        }
      : {};
  }, [zoomIn, coords]);

  return (
    <div className={style["image-viewer"]}>
      <div
        className={style["close-button-container"]}
        onClick={() => setViewerIsOpen(false)}
      >
        <Close className={style["close-icon"]} />
      </div>
      <div className={style["slider"]}>
        <div
          className={`${style["chevron-container"]} ${
            pictureIndex === 0 ? style["disabled"] : ""
          }`}
          onClick={previousPicture}
        >
          <ChevronLeft className={style["chevron"]} />
        </div>
        <div
          className={style["main-picture-container"]}
          onClick={handleClickOutsidePicture}
        >
          <Image
            onClick={handleZoomIn}
            className={`${style["main-picture"]} ${
              zoomIn ? style["zoom-in"] : ""
            }`}
            style={TRANSLATE_STYLE}
            isBlob={isBlob}
            src={pictureList[pictureIndex] ? pictureList[pictureIndex] : ""}
            showTools={false}
          />
        </div>
        <div
          className={`${style["chevron-container"]} ${
            pictureIndex === pictureList.length - 1 ? style["disabled"] : ""
          }`}
          onClick={nextPicture}
        >
          <ChevronRight className={style["chevron"]} />
        </div>
      </div>
      <div
        className={`${style["image-carrousel-container"]} ${
          zoomIn ? style["hide"] : ""
        }`}
      >
        <div className={style["image-carrousel"]}>
          {pictureList &&
            pictureList.map((uri, index) => {
              return (
                <Image
                  className={`${style["image-item"]} ${
                    pictureIndex === index ? style["is-selected"] : ""
                  }`}
                  isBlob={isBlob}
                  onClick={() => handleSelectPicture(index)}
                  src={uri}
                  showTools={false}
                />
              );
            })}
        </div>
      </div>
    </div>
  );

  function handleZoomIn(e: React.MouseEvent<HTMLImageElement, MouseEvent>) {
    e.stopPropagation();
    setZoomIn(!zoomIn);
  }

  function handleClickOutsidePicture() {
    if (zoomIn) {
      return setZoomIn(false);
    }
    setViewerIsOpen(false);
  }

  function handleSelectPicture(index: number) {
    setPictureIndex(index);
  }

  function nextPicture() {
    if (pictureIndex + 1 < pictureList.length) {
      setPictureIndex(pictureIndex + 1);
    }
  }

  function previousPicture() {
    if (pictureIndex - 1 >= 0) {
      setPictureIndex(pictureIndex - 1);
    }
  }
}
