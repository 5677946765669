import { useState } from "react";
import style from "./cancelEstimateReasonModal.module.scss";
import Button from "../../Button/Button";

interface CancelEstimateReasonModalProps {
  onSubmit: (reason: string) => void;
}

export default function CancelEstimateReasonModal({
  onSubmit,
}: CancelEstimateReasonModalProps) {
  const [reason, setReason] = useState("");

  function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    onSubmit && onSubmit(reason)
  }

  return (
    <div className={style["cancel-estimate-reason-modal"]}>
      <form className={style["cancel-estimate-reason-form"]} onSubmit={handleSubmit}>
        <p><b>Veuillez entrer une explication pour le client</b></p>
        <p>Après étude de votre demande,</p>
        <textarea className={style["reason-textarea"]} value={reason} name={"reason"} onChange={({target}) => setReason(target.value)} />
        <Button>Envoyer</Button>
      </form>
    </div>
  );
}
