import { cloneElement, useEffect, useRef, useState } from "react";
import style from "./moreTools.module.scss";
import { MoreVert } from "@mui/icons-material";
import useClickOutside from "../../hooks/useClickOutSide";
import { Link } from "react-router-dom";
import { createPortal } from "react-dom";
import clsx from "clsx";

export interface IMoreToolsOptions {
  icon?: JSX.Element;
  name: string;
  onClick?: () => void;
  to?: string;
}

interface MoreToolsProps {
  className?: string;
  zIndex?: number;
  options: IMoreToolsOptions[];
}

export default function MoreTools({
  zIndex,
  options,
  className,
}: MoreToolsProps) {
  const [expand, setExpand] = useState<boolean>(false);
  const dropdownRef = useClickOutside<HTMLDivElement>(handleCloseDropDown);
  const moreButtonRef = useRef<HTMLDivElement | null>(null);
  const [dropdownPosition, setDropdownPosition] = useState({
    top: -1000,
    left: -1000,
  });

  useEffect(() => {
    if (moreButtonRef.current && dropdownRef.current) {
      const buttonRect = moreButtonRef.current.getBoundingClientRect();
      const dropdownWidth = dropdownRef.current.offsetWidth;

      setDropdownPosition({
        top: buttonRect.bottom + window.scrollY,
        left:
          buttonRect.left + window.scrollX - dropdownWidth + buttonRect.width,
      });
    }
  }, [expand]);

  return (
    <div className={clsx(style["more-tools"], className)}>
      <div className={style["more-tools-container"]}>
        <div
          className={style["more-button"]}
          onClick={handleExpand}
          ref={moreButtonRef}
        >
          <MoreVert />
        </div>
        <div className={style["more-tools-dropdown-wrapper"]}>
          {expand &&
            createPortal(
              <div
                className={style["more-tools-dropdown"]}
                ref={dropdownRef}
                style={{
                  position: "absolute",
                  top: dropdownPosition.top,
                  left: dropdownPosition.left,
                  zIndex: 2000,
                }}
              >
                <div className={style["option-list"]}>
                  {options.map((option, index: number) => {
                    const styledIcon: JSX.Element = cloneElement(
                      option.icon || <span></span>,
                      {
                        className: style["option-list-item-icon"],
                      }
                    );
                    return option.to ? (
                      <Link
                        className={style["option-list-item"]}
                        key={index}
                        onClick={() => handleClickOnOption(option.onClick)}
                        to={option.to}
                      >
                        {styledIcon}
                        {option.name}
                      </Link>
                    ) : (
                      <div
                        className={style["option-list-item"]}
                        key={index}
                        onClick={() => handleClickOnOption(option.onClick)}
                      >
                        {styledIcon}
                        {option.name}
                      </div>
                    );
                  })}
                </div>
                <div className={style["beautiful-div"]}></div>
              </div>,
              document.body
            )}
        </div>
      </div>
    </div>
  );

  function handleClickOnOption(fct?: () => void) {
    fct && fct();
    setExpand(false);
  }

  function handleExpand() {
    setExpand(!expand);
  }

  function handleCloseDropDown() {
    setExpand(false);
  }
}
