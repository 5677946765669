import { IAddress, ICustomer } from "../types/customer.type";

export async function addCustomer(
  // request: Request,
  customer: ICustomer
): Promise<{ id: string }> {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/customer`, {
      headers: new Headers({ "Content-Type": "application/json" }),
      method: "POST",
      credentials: "include",
      // signal: request?.signal,
      body: JSON.stringify(customer),
    });
    const data = await response.json();
    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function addAddress(
  // request: Request,
  customer: ICustomer,
  address: IAddress
): Promise<string> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/customer/${customer.id}/address`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        // signal: request?.signal,
        body: JSON.stringify(address),
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateCustomer(
  // request: Request,
  customer: ICustomer,
  id?: string
): Promise<string | string[]> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/customer/${id}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "PUT",
        credentials: "include",
        // signal: request?.signal,
        body: JSON.stringify(customer),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchCustomersList(
  // request: Request,
  query: string
): Promise<{ customers: ICustomer[]; totalCount: number }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/customer${query}`, //&period.start=02-05-2023
      //   request,
      {
        credentials: "include",
        // signal: request?.signal,
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchCustomer(
  // request: Request,
  id?: string
): Promise<ICustomer> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/customer/${id}`,
      {
        credentials: "include",
        // signal: request?.signal,
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.customer;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function linkTracker(
  customerId: string,
  trackerId: string
): Promise<string> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/customer/${customerId}/link-tracker`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        // signal: request?.signal,
        body: JSON.stringify({ trackerId }),
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getAddressesList(
  customerId?: string
): Promise<IAddress[] | undefined> {
  if (!customerId) {
    return;
  }
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/customer/${customerId}/address`, //&period.start=02-05-2023
      {
        credentials: "include",
      }
    );
    const { addresses } = await response.json();
    if (response.status !== 200) {
      throw new Response(JSON.stringify(addresses), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return addresses;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
