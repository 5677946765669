import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getDetailedProduct } from "../../../requests/product";
import style from "./readProduct.module.scss";
import ContactInfoCard from "../../../components/contactInfoCard/contactInfoCard";
import { IDetailedProduct } from "../../../types/logistic.types";
import { isValidJSONString } from "../../../utils/utils";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import ModalDetailedProduct from "../../../components/modals/logisticModal/logisticReadSection/ModalDetailedProduct/ModalDetailedProduct";

export default function ReadProduct({ id }: { id?: string }) {
  const { productId } = useParams();
  const { data: detailedProduct, isFetching } = useQuery<IDetailedProduct>(
    ["readProduct", productId],
    () => getDetailedProduct(id || productId),
    { enabled: Boolean(productId) }
  );

  if (isFetching) {
    return <LoadingSpinner color="gold" />;
  }

  if (!detailedProduct) {
    return <>{`Erreur article avec l'ID: ${productId} introuvable`}</>;
  }

  return (
    <div className={style["read-product"]}>
      <ContactInfoCard
        contact={detailedProduct && detailedProduct.contact}
        trackId={detailedProduct?.trackId}
      />
      <ModalDetailedProduct product={detailedProduct?.product} />
    </div>
  );

  function parseComment() {
    if (detailedProduct && detailedProduct.product.comments) {
      if (!isValidJSONString(detailedProduct.product.comments)) {
        return {};
      } else {
        return JSON.parse(detailedProduct.product.comments);
      }
    }
  }
}
