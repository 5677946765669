import { FormEvent, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import style from "./create.module.scss";
import {
  IProduct as ILogisticProduct,
  IContact as ILogisticContact,
} from "../../../types/logistic.types";
import { IValidationError } from "../../../types";
import { CART_STATE, DEFAULT_PRO_CART } from "../../../utils/booking.init";
import { addProCart, updateCart } from "../../../requests/professionalCarts";
import { TOAST_OPTIONS } from "../../../utils/toast.options";
import { toast } from "react-toastify";
import { IContact, IProCart, IProduct } from "../../../types/proCart.types";
import ContactForm from "../../../components/forms/ContactForm/ContactForm";
import SelectState from "../../shipping/create/SelectState/SelectState";
import ProductSection from "../../../components/forms/productForm/ProductSection";
import SectionFormTemplate from "../../../components/forms/sectionFormTemplate/SectionFormTemplate";
import Button from "../../../components/Button/Button";

export default function Create({
  className,
  data,
  shopId,
  postSubmit,
}: {
  className?: string;
  data?: IProCart;
  shopId?: string;
  postSubmit?: () => void;
}) {
  const params = useParams();
  const [formValues, setFormValues] = useState<IProCart>({
    ...(data || { ...DEFAULT_PRO_CART, shopId: params.id || shopId || "" }),
  });
  const [validationError, setValidationError] = useState<IValidationError[]>(
    []
  );
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  return (
    <div className={`${style["container"]} ${className}`}>
      <div ref={ref} className={style["title"]}>
        {data ? "Modifier commande" : "Nouvelle commande"}
        <SelectState
          label="Status de la demande"
          state={formValues.state}
          stateOption={CART_STATE}
          setState={(state) => {
            setFormValues({ ...formValues, state });
          }}
        />
      </div>
      <form className={style["form"]} onSubmit={handleSubmit}>
        <SectionFormTemplate title="Contact">
          <ContactForm
            setContact={handleContactChange}
            contact={formValues.contact}
          />
        </SectionFormTemplate>
        <SectionFormTemplate title="Commentaire">
          {data?.comments ? (
            <p>"{data?.comments}"</p>
          ) : (
            <textarea
              className={style["comment"]}
              value={formValues.comments}
              onChange={({ target }) =>
                setFormValues((prev) => ({ ...prev, comments: target.value }))
              }
            />
          )}
        </SectionFormTemplate>
        <hr className={style["separator"]} />
        <ProductSection
          productsList={formValues.productsList as ILogisticProduct[]}
          setProductsList={handleProductChange}
        />
        <div>
          {validationError.find(
            (value: IValidationError) => value.field === "productsList"
          ) && (
            <div className={style["error-message"]}>
              * veuillez ajouter au moins un article.
            </div>
          )}
        </div>
        <div className={style["submit-button-container"]}>
          <Button type={"submit"}>{data ? "Mettre à jour" : "Créer"}</Button>
        </div>
      </form>
    </div>
  );

  function handleContactChange(contact: ILogisticContact) {
    setFormValues((prev) => ({ ...prev, contact: contact as IContact }));
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      const response = data
        ? await updateCart(formValues as IProCart)
        : await addProCart(formValues);
      if ((response as IValidationError[]).length > 0) {
        toast.warning("erreur", TOAST_OPTIONS);
        setValidationError(response as IValidationError[]);
      }
      postSubmit
        ? postSubmit()
        : navigate(`/professionalCarts/?shopId=${data?.shopId || params.id}`);
    } catch (error) {
      console.error(error);
    }
  }

  function handleChange({
    target,
  }: {
    target: HTMLSelectElement | HTMLInputElement;
  }) {
    setFormValues((prev) => ({ ...prev, [target.name]: target.value }));
  }

  function handleProductChange(productsList: ILogisticProduct[]) {
    const newFormValues = { ...formValues };
    newFormValues["productsList"] = productsList as IProduct[];
    setFormValues(newFormValues);
  }
}
