import React, { useState } from "react";
import { useQuery } from "react-query";
import { fetchShippingList } from "../../../requests/shipping";
import ShippingSelection from "../incomingShipping/shippingSelection/shippingSelection";
import { Replay } from "@mui/icons-material";
import InputContainer from "../../../components/forms/inputs/inputContainer/inputContainer";
import styles from "./inStoreCollect.module.scss";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import { IShipping } from "../../../types/shipping.types";

export default function InStoreCollect() {
  const [date, setDate] = useState<Date>(new Date());
  const { data, isFetching } = useQuery(
    ["shippings-list", date],
    () => fetchShippingList(formatedQuery()),
    { refetchOnWindowFocus: false, keepPreviousData: true }
  );

  return (
    <div className={styles["in-store-collect"]}>
      <h1 className={styles["title"]}>Retrait boutique</h1>
      <InputContainer className={styles["input-container"]}>
        <label>Choisir une date</label>
        <div className={styles["set-date-container"]}>
          <input
            type="date"
            onChange={handleDateChange}
            value={date.toISOString().substring(0, 10)}
          />
          <div
            className={styles["reset-button"]}
            onClick={() => setDate(new Date())}
          >
            <Replay />
          </div>
        </div>
      </InputContainer>
      <InStoreCollectList date={date} shippingList={data?.shippingsList} />
    </div>
  );

  function handleDateChange({ target }: { target: HTMLInputElement }) {
    setDate(new Date(target.value));
  }

  function formatedQuery() {
    return `?period.start=${date.toISOString()}&period.start=${date.toISOString()}&shippingMethod.shippingService=DROP-OR-COLLECT&direction=OUTGOING&state=PENDING`;
  }
}

interface InStoreCollectListProps {
  date: Date;
  shippingList?: IShipping[];
}

function InStoreCollectList({ date, shippingList }: InStoreCollectListProps) {
  const { data, isFetching } = useQuery(
    ["shippings-list", date],
    () => fetchShippingList(formatedQuery()),
    { refetchOnWindowFocus: false, keepPreviousData: true }
  );

  if (isFetching) {
    return (
      <div className={styles["loading-spinner-container"]}>
        <LoadingSpinner color="gold" />
      </div>
    );
  }

  if (!shippingList) {
    return <></>;
  }

  if (shippingList.length === 0) {
    return (
      <i>aucun dépôt disponible pour le {date.toLocaleDateString("fr-FR")}</i>
    );
  }

  return (
    <ShippingSelection shippings={data?.shippingsList} link={`/overview`} />
  );

  function formatedQuery() {
    return `?period.start=${date.toISOString()}&period.start=${date.toISOString()}&shippingMethod.shippingService=DROP-OR-COLLECT&direction=OUTGOING&state=PENDING`;
  }
}
