import { Launch, OutboxOutlined, Share } from "@mui/icons-material";
import style from "./overviewButton.module.scss";
import { Link } from "react-router-dom";

interface OverviewButtonProps {
  trackId?: string;
}

export default function OverviewButton({ trackId }: OverviewButtonProps) {
  if (!trackId) {
    return <></>;
  }
  return (
    <Link
      className={style["overview-button"]}
      to={`/overview/${trackId}`}
      title={"Vue d'ensemble"}
    >
      <Launch className={style["icon"]} />
    </Link>
  );
}
