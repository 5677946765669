import { useNavigate } from "react-router-dom";
import { ITracker } from "../../../../../types/tracker.types";
import styles from "./unfinishedListItem.module.scss";
import State from "../../../../../components/StateItem/State";
import CenterCell from "../../../../../components/CenterCell/CenterCell";
import ShippingServiceLogo from "../../../../../components/ShippingServiceLogo/ShippingServiceLogo";
import clsx from "clsx";
import { isDesktop } from "react-device-detect";

interface UnfinishedListItemProps {
  tracker: ITracker;
}

export default function UnfinishedListItem({
  tracker,
}: UnfinishedListItemProps) {
  const navigate = useNavigate();

  return (
    <tr
      onClick={() => navigate(`/overview/${tracker.id}`)}
      className={clsx(
        styles["item"],
        isDesktop ? styles["hover"] : styles["active"]
      )}
    >
      <td className={styles["item-cell"]}>{tracker.contact?.displayname}</td>
      <td className={`${styles["item-cell"]} ${styles["status-box"]}`}>
        <CenterCell>
          <State state={tracker.state} />
        </CenterCell>
      </td>
      <td className={`${styles["item-cell"]} ${styles["status-box"]}`}>
        <CenterCell>
          <ShippingServiceLogo
            shippingService={tracker.outgoingShipping.shippingService}
          />
        </CenterCell>
      </td>
    </tr>
  );
}
