import { useState } from "react";
import ContactForm from "../../../../../../components/forms/ContactForm/ContactForm";
import { IContact } from "../../../../../../types/logistic.types";
import styles from "./checkInContactForm.module.scss";

interface CheckInContactFormProps {
  contact: IContact;
  onChange: (value: IContact) => void;
}

export default function CheckInContactForm({
  contact,
  onChange,
}: CheckInContactFormProps) {
  const [autoComplete, setAutoComplete] = useState<boolean>(true);

  return (
    <div className={styles["contact-widget"]}>
      <div className={styles["autocomplete-container"]}>
        <div
          className={styles["autocomplete-wrapper"]}
          onClick={handleSetAutoComplete}
        >
          <label className={styles["autocomplete-label"]}>
            {"Auto-complétion"}
          </label>
          <input
            type={"checkbox"}
            checked={autoComplete}
            onChange={handleSetAutoComplete}
            disabled={!!contact.id}
          />
        </div>
      </div>
      <ContactForm
        contact={contact}
        setContact={handleContactChange}
        detailedForm={false}
        autoComplete={autoComplete}
      />
    </div>
  );

  function handleContactChange(contact: IContact) {
    onChange(contact);
  }

  function handleSetAutoComplete() {
    if (!!contact.id) {
      return;
    }
    setAutoComplete(!autoComplete);
  }
}
