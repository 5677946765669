import { useState } from "react";
import translate from "../../translation";
import style from "./stateItem.module.scss";
import getStatusColor from "../../translation/statusColor";

interface StateItemProps {
  state: string;
  handleChangeState: () => void;
}

export default function StateItem({
  state,
  handleChangeState,
}: StateItemProps) {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  return (
    <li
      className={style["state-dropdown-item"]}
      onClick={handleChangeState}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        backgroundColor: isHovered ? `${getStatusColor(state)}` : "",
        color: isHovered ? `white` : "",
      }}
    >
      {translate(state)}
    </li>
  );
}
