import style from "./item.module.scss";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import { Delete, Edit, Launch } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import translate from "../../../translation";
import getStatusColor from "../../../translation/statusColor";
import { ICart } from "../../../types/booking.type";
import WorkshopId from "../../../components/workshopId/workshopId";
import { useContext } from "react";
import { useModalComponent } from "../../../context/modalComponent.context";
import ReadCartModal from "../../../components/modals/readCartModal/ReadCartModal";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import ProShippingModal from "../../../components/modals/proShippingModal/proShippingModal";
import { IProCart } from "../../../types/proCart.types";
import { useMutation } from "react-query";
import { updateCart } from "../../../requests/professionalCarts";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";

export default function Item({
  data,
  zIndex,
  onDelete,
  onUpdate,
}: {
  data: ICart;
  zIndex: number;
  onDelete: (id?: string) => void;
  onUpdate?: () => void;
}) {
  const { setModalIsOpen, setModalChildren } = useModalComponent();
  const { mutate } = useMutation(updateCart, {
    onSuccess(data, variables, context) {
      onUpdate && onUpdate();
      toast.success("Panier mis à jour.", TOAST_SUCCESS_OPTIONS);
    },
    onError(error, variables, context) {
      toast.error(
        "Erreur lors de la mise à jour du panie.",
        TOAST_ERROR_OPTIONS
      );
    },
  });
  const navigate = useNavigate();
  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Launch />,
      name: "Ouvrir",
      onClick: handleOpenReadCartModal,
    },
    {
      icon: <Edit />,
      name: "Modifier",
      // to: `/professionalCarts/edit/${data.id}`,
      onClick: handleCartEditModal,
    },
    {
      icon: <Delete />,
      name: "Supprimer",
      onClick: handleDelete,
    },
  ];

  return (
    <div className={style["item"]}>
      <div className={style["item-left"]} onClick={handleOpenReadCartModal}>
        <div className={`${style["item-element"]} ${style["coordinates"]}`}>
          <span>{data.contact?.displayname}</span>
          <span>{data.contact?.phone}</span>
        </div>
        <div className={style["item-element"]}>
          {data.createdAt && new Date(data.createdAt).toLocaleDateString()}
          {data.updatedAt && new Date(data.updatedAt).toLocaleDateString()}
        </div>
        <div className={style["item-element"]}>
          {data.productsList[0] && (
            <WorkshopId
              size={"xx-small"}
              workshopId={data.productsList[0].workshopId}
            />
          )}
          {data.productsList.length > 1 && (
            <div className={style["more-circle"]}>
              + {data.productsList.length - 1}
            </div>
          )}
        </div>
        <div className={style["item-element"]}>
          <div
            className={style["state-container"]}
            style={{ backgroundColor: getStatusColor(data.state) }}
          >
            {data.state && translate(data.state)}
          </div>
        </div>
      </div>
      <div className={`${style["item-element"]} ${style["tools"]}`}>
        <MoreTools options={MORE_TOOLS_OPTIONS} zIndex={zIndex} />
      </div>
    </div>
  );

  function handleDelete() {
    if (window.confirm("Voulez-vous supprimer cette demande ?")) {
      onDelete(data.id);
    }
  }

  function handleOpenReadCartModal() {
    setModalChildren(<ReadCartModal cart={data} />);
    setModalIsOpen(true);
  }

  function handleCartEditModal() {
    // setModalChildren(<ReadCartModal cart={data} />);
    setModalChildren(
      <ProShippingModal
        cart={data as any}
        setCart={(cart) => {
          mutate(cart);
        }}
      />
    );
    setModalIsOpen(true);
  }
}
