import { useEffect, useRef } from "react";
import {
  LABEL_HEIGHT,
  LABEL_PIXEL_PER_MM,
  LABEL_WIDTH,
} from "../../../utils/constants";

const canvasWidth = LABEL_PIXEL_PER_MM * LABEL_WIDTH; // Canvas width in px
const canvasHeight = LABEL_PIXEL_PER_MM * LABEL_HEIGHT; // Canvas height in px

export function useFrontLabel(
  lines: string[][],
  leftFooter: string,
  rightFooter: string
) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const padding = 15; // Padding around the canvas
    const operationPadding = 10; // Padding inside the operation boxes
    const gap = 6; // Gap between operations
    const verticalGap = 6;

    // Set canvas dimensions
    canvas.width = canvasWidth; // 400px
    canvas.height = canvasHeight; // 240px

    // Clear the canvas and set its size
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);
    ctx.fillStyle = "white"; // Background color
    ctx.fillRect(0, 0, canvasWidth, canvasHeight); // Fill the background

    // Set up font
    const maxFontSize = lines.length === 4 ? 35 : 45; // Maximum font size
    ctx.font = `${maxFontSize}px Arial`; // Font size

    // Set up maximum font size
    const minFontSize = 10; // Minimum font size to avoid making the font too small
    const lineHeightFactor = 1.2; // Line height as a multiple of the font size

    // Function to fit the entire line within available width by reducing the font size
    const fitLine = (
      operations: string[],
      availableWidth: number,
      initialFontSize: number
    ): number => {
      let fontSize = initialFontSize;

      // Calculate the total width of the line with the current font size
      const calculateTotalWidth = (fontSize: number) => {
        ctx.font = `${fontSize}px Arial`;
        let totalWidth = 0;
        operations.forEach((operation) => {
          totalWidth +=
            ctx.measureText(operation).width + 2 * operationPadding + gap;
        });
        totalWidth -= gap; // Remove the last gap
        return totalWidth;
      };

      // Reduce font size until the entire line fits within the available width
      let totalWidth = calculateTotalWidth(fontSize);
      while (totalWidth > availableWidth && fontSize > minFontSize) {
        fontSize -= 1;
        totalWidth = calculateTotalWidth(fontSize);
      }

      return fontSize;
    };

    // Draw each line of operations
    let y = padding; // Start y position with padding

    for (let operations of lines) {
      const availableWidth = canvasWidth - 2 * padding; // Available width for the entire line
      const fontSize = fitLine(operations, availableWidth, maxFontSize); // Adjust font size to fit
      const lineHeight = fontSize * lineHeightFactor; // Calculate line height based on font size

      // If the y-position exceeds the canvas height, stop drawing
      if (y + lineHeight > canvasHeight - padding) {
        return;
      }

      // Draw each operation with the adjusted font size
      let x = padding; // Start x position with padding
      operations.forEach((operation) => {
        const textWidth =
          ctx.measureText(operation).width + 2 * operationPadding;

        // Draw the operation box
        ctx.font = `${fontSize}px Arial`;
        ctx.strokeStyle = "black";
        ctx.strokeRect(x, y, textWidth, lineHeight); // Draw the border
        ctx.fillStyle = "black";
        ctx.fillText(operation, x + operationPadding, y + fontSize); // Write the text

        // Move x position for the next operation
        x += textWidth + gap;
      });

      // Move y position for the next line
      y += lineHeight + verticalGap; // Space for next line
    }

    ctx.font = "20px Arial";
    // Calculate the y position for the bottom strings
    const bottomY = canvasHeight - padding; // Start at the bottom padding
    // Draw bottom strings, one left-aligned and one right-aligned
    const leftString = leftFooter || "";
    const rightString = rightFooter || "";
    const rightStringWidth =
      ctx.measureText(rightString).width + 2 * operationPadding; // Width with padding
    // Draw left string
    ctx.fillText(leftString, padding + operationPadding, bottomY + 5); // Write text inside the box
    // Draw right string
    ctx.fillText(
      rightString,
      canvasWidth - rightStringWidth - padding + operationPadding,
      bottomY + 5
    ); // Write text inside the box
  }, [lines, leftFooter, rightFooter]);

  return canvasRef;
}
