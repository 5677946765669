import style from "./productItem.module.scss";
import translate from "../../../../translation";
import WorkshopId from "../../../workshopId/workshopId";
import { Delete } from "@mui/icons-material";
import { IProduct } from "../../../../types/logistic.types";

interface ProductItemProps {
  product: IProduct;
  handleEditProduct: () => void;
  handleDeleteProduct: () => void;
  canEdit?: boolean;
}

export default function ProductItem({
  product,
  handleEditProduct,
  handleDeleteProduct,
  canEdit = true,
}: ProductItemProps) {
  return (
    <li className={style["product-item"]}>
      <div
        className={style["product-item-left"]}
        onClick={canEdit ? () => handleEditProduct() : () => {}}
      >
        <WorkshopId workshopId={product.workshopId || ""} size="xxx-small" />
        <div className={style["product-brand-group"]}>
          {`${translate(product.productGroup)} - ${product.brand}`}
        </div>
      </div>
      {canEdit && (
        <>
          <div className={style["dots"]}></div>
          <div className={style["delete"]} onClick={handleDeleteProduct}>
            <Delete />
          </div>
        </>
      )}
    </li>
  );
}
