import style from "./index.module.scss";
import Item from "./listItem/item";
import { deleteUser, getUserList } from "../../requests/user";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { IUserData } from "../../types/user.types";
import Button from "../../components/Button/Button";

let controller = new AbortController();

export default function Users() {
  const { refetch, data }: any = useQuery("userList", () =>
    getUserList(controller.signal)
  );

  async function handleDelete(id: string) {
    await deleteUser(id);
    refetch();
  }

  return (
    <div className={style["item-list"]}>
      <div className={style["top"]}>
        <span className={style["title"]}>Utilisateurs</span>
        <Link onClick={() => window.scrollTo(0, 0)} to={"./create"}>
          <Button>+ Ajouter nouveau</Button>
        </Link>
      </div>
      <div className={style["bottom"]}>
        <div className={style["item"]}>
          <div className={style["item-element"]}>Nom d'utilisateur</div>
          <div className={style["item-element"]}>Rôles</div>
          <div className={style["item-element"]}>Actions</div>
        </div>
        <div>
          {data &&
            data.map((value: IUserData, key: number) => {
              return <Item key={key} data={value} onDelete={handleDelete} />;
            })}
        </div>
      </div>
    </div>
  );
}
