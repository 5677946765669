import styles from "./readModal.module.scss";
import { Close } from "@mui/icons-material";
import RemoveBodyScroll from "../RemoveBodyScroll";

interface ReadModalProps {
  children: React.ReactNode;
  handleCloseModal: () => void;
}

export default function ReadModal({
  children,
  handleCloseModal,
}: ReadModalProps) {
  return (
    <RemoveBodyScroll>
      <div
        className={styles["read-modal-container"]}
        onClick={handleCloseModal}
      >
        <div
          className={styles["read-modal-wrapper"]}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles["read-modal"]}>{children}</div>
          <div className={styles["close-button"]} onClick={handleCloseModal}>
            <Close />
          </div>
        </div>
      </div>
    </RemoveBodyScroll>
  );
}
