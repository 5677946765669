import { isDesktop } from "react-device-detect";
import style from "./button.module.scss";
import PictureLoadingSpinner from "../loadingSpinner/pictureloadingSpinner";

interface ButtonProps {
  error?: string | null;
  isLoading?: boolean;
  loadingSpinnerStyle?: string;
  children: React.ReactNode;
}

export default function Button({
  error,
  isLoading,
  loadingSpinnerStyle,
  children,
  ...props
}: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <>
      <button
        {...props}
        className={`${style["button"]} ${
          isDesktop ? style["hover"] : style["active"]
        } ${props.className}`}
        onClick={isLoading ? () => {} : props.onClick}
      >
        {isLoading ? (
          <PictureLoadingSpinner
            color="white"
            className={style["loading-spinner"]}
          />
        ) : (
          children
        )}
      </button>
      {error && <span className={style["error"]}>* {error}</span>}
    </>
  );
}
