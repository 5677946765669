import styles from "./progressionBar.module.scss"; // Import the CSS file

interface ProgressionBarProps {
  percentage: number;
}

export default function ProgressionBar({ percentage }: ProgressionBarProps) {
  return (
    <div className={styles["progression-bar-container"]}>
      <div
        className={styles["progression-bar-fill"]}
        style={{ width: `${percentage}%` }}
      ></div>
      <span className={styles["progression-bar-text"]}>{percentage}%</span>
    </div>
  );
}
