import styles from "./navigationBar.module.scss";
import { useHideSideBar } from "../../context/hideSideBar.context";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import HubIcon from "@mui/icons-material/Hub";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import {
  Architecture,
  ArchitectureOutlined,
  Assessment,
  Dashboard,
  DashboardOutlined,
  Description,
  DescriptionOutlined,
  RocketLaunch,
  RocketLaunchOutlined,
  Settings,
  SettingsOutlined,
  ShoppingCart,
  ShoppingCartOutlined,
  Today,
  TodayOutlined,
  Work,
} from "@mui/icons-material";
import MenuItem, { ShowMenu } from "./MenuItem/MenuItem";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import clsx from "clsx";
import SubMenuItem from "./SubMenuItem/SubMenuItem";

export default function NavigationBar() {
  const { hideSide } = useHideSideBar();
  return (
    <nav
      className={clsx(styles["navigation-bar"], !hideSide && styles["expand"])}
    >
      <ul className={styles["menu"]}>
        <MenuItem
          link={"/dashboard"}
          title={"Dashboard"}
          Logo={DashboardOutlined}
          SelectedLogo={Dashboard}
        />
        <MenuItem
          link={"/quick-access"}
          title={"Accès rapide"}
          Logo={RocketLaunchOutlined}
          SelectedLogo={RocketLaunch}
        />
        <MenuItem
          link={"/overview"}
          title={"Vue d'ensemble"}
          Logo={HubOutlinedIcon}
          SelectedLogo={HubIcon}
        />
        <SubMenuItem
          title={"Calendriers"}
          Logo={TodayOutlined}
          SelectedLogo={Today}
        />
        <MenuItem
          link={"/logistic"}
          title={"Atelier"}
          Logo={ArchitectureOutlined}
          SelectedLogo={Architecture}
        />
        <SubMenuItem
          title={"Demandes"}
          Logo={ShoppingCartOutlined}
          SelectedLogo={ShoppingCart}
        />
        <SubMenuItem
          title={"Documents"}
          Logo={DescriptionOutlined}
          SelectedLogo={Description}
        />
        <SubMenuItem
          title={"Professionels"}
          Logo={WorkOutlineIcon}
          SelectedLogo={Work}
        />
        <MenuItem
          link={"/reports"}
          title={"Rapports"}
          Logo={AssessmentOutlinedIcon}
          SelectedLogo={Assessment}
        />
        <SubMenuItem
          title={"Gestion"}
          Logo={SettingsOutlined}
          SelectedLogo={Settings}
        />
      </ul>
      <div className={styles["menu-bottom"]}>
        <ShowMenu />
      </div>
    </nav>
  );
}
