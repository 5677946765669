import {
  ICrossSell,
  ICrossSellProduct,
} from "../../../../../types/accounting.types";
import style from "./crossSellCardOverlay.module.scss";
import { Add, Delete, Remove } from "@mui/icons-material";

interface CrossSellCardOverlayProps {
  item: ICrossSell;
  selectedCrossSells: ICrossSellProduct[];
  setSelectedCrossSells: (value: ICrossSellProduct[]) => void;
}

export default function CrossSellCardOverlay({
  item,
  selectedCrossSells,
  setSelectedCrossSells,
}: CrossSellCardOverlayProps) {
  return (
    <div className={style["change-quantity-card"]}>
      <div className={style["change-quantity-section"]}>
        <div
          className={style["quantity-button"]}
          onClick={() => changeCartQuantity(item.slug, -1)}
        >
          <Remove className={style["icon"]} />
        </div>
        <span className={style["quantity-value"]}>
          <span>{getItemQuantity(item.slug)}</span>
        </span>
        <div
          className={style["quantity-button"]}
          onClick={() => changeCartQuantity(item.slug, 1)}
        >
          <Add className={style["icon"]} />
        </div>
      </div>
      <div className={style["delete-icon-container"]}>
        <div
          className={style["delete-icon"]}
          onClick={() => removeSelectedItem(item.slug)}
        >
          <Delete className={style["icon"]} />
        </div>
      </div>
      <div className={style["background"]}></div>
    </div>
  );

  function getItemQuantity(slug: string | undefined): number {
    return selectedCrossSells.find((cs) => cs.slug === slug)?.cartQuantity || 0;
  }

  function changeCartQuantity(slug: string | undefined, cartQuantity: number) {
    const newSelectedCrossSells = [...selectedCrossSells];
    const findIndex = newSelectedCrossSells.findIndex((cs) => cs.slug === slug);
    if (findIndex === -1) {
      return;
    }
    let newQuantity = newSelectedCrossSells[findIndex].cartQuantity ?? 0;
    if (newQuantity + cartQuantity <= 0) {
      if (newQuantity + cartQuantity === 0) {
        removeSelectedItem(slug);
      }
      return;
    }
    newQuantity += cartQuantity;
    newSelectedCrossSells[findIndex].cartQuantity = newQuantity;
    setSelectedCrossSells(newSelectedCrossSells);
  }

  function removeSelectedItem(slug: string | undefined) {
    const newSelectedCrossSells = [...selectedCrossSells];

    const findIndex = newSelectedCrossSells.findIndex((cs) => cs.slug === slug);
    if (findIndex === -1) {
      return;
    }
    newSelectedCrossSells.splice(findIndex, 1);
    setSelectedCrossSells(newSelectedCrossSells);
  }
}
