import { ReactElement } from "react";
import { useConfirmModal } from "../../context/confirmationModalContext";
import ValidateButton from "../validateButton/validateButton";
import style from "./confirmModalComp.module.scss";
interface ConfirmationModalProps {
  cancelText?: string;
  confirmText?: string;
  confirmButtonClassName?: string;
  title: string;
  message?: string;
  element?: ReactElement;
  onConfirm: () => void;
  onCancel?: () => void;
}

export default function ConfirmModalComp({
  cancelText,
  confirmButtonClassName,
  confirmText,
  title,
  message,
  element,
  onConfirm,
  onCancel,
}: ConfirmationModalProps) {
  const { setConfirmModalIsOpen, setConfirmModalChildren } = useConfirmModal();

  return (
    <div className={style["confirm-modal-comp"]}>
      <div className={style["title"]}>{title}</div>
      <div className={style["message"]}>{message}</div>
      <div className={style["element"]}>{element}</div>
      <div className={style["button-section"]}>
        <ValidateButton
          className={confirmButtonClassName}
          handleOnClick={handleConfirm}
          title={confirmText || "Confirmer"}
        />
        <ValidateButton
          handleOnClick={handleCancel}
          title={cancelText || "Annuler"}
        />
      </div>
    </div>
  );

  function handleCancel() {
    onCancel && onCancel();
    setConfirmModalIsOpen(false);
    setConfirmModalChildren(<></>);
  }

  function handleConfirm() {
    onConfirm();
    setConfirmModalIsOpen(false);
    setConfirmModalChildren(<></>);
  }
}
