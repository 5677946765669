import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
import { ICrossSellItem } from "../../../../../../types/logistic.types";
import ColorComponent from "../../../../../colorComponent/colorComponent";
import Image from "../../../../../image/Image";
import style from "./prepareCrossSellCard.module.scss";

interface PrepareCrossSellCardProps {
  isItemReady: boolean;
  item: ICrossSellItem;
  handleOnClick: () => void;
}

export default function PrepareCrossSellCard({
  isItemReady,
  item,
  handleOnClick,
}: PrepareCrossSellCardProps) {
  return (
    <div
      className={`${style["cross-sell-card"]} ${
        isItemReady && style["item-selected"]
      }`}
      onClick={handleOnClick}
    >
      <div className={style["cross-sell-card-wrapper"]}>
        <div className={style["cross-sell-card-left"]}>
          <div>{item.description}</div>
          {item.slug}
          <div>{item.category}</div>
          {item.cartQuantity > 1 && <div>quantité: {item.cartQuantity}</div>}
          {item.color && (
            <ColorComponent
              color={item.color}
              className={style["color-container"]}
            />
          )}
        </div>
        <Image
          className={style["image-miniature"]}
          src={item.image}
          showTools={false}
        />
      </div>
      <div className={style["action"]}>
        {isItemReady ? "retirer" : "remettre"}
      </div>
      <div className={style["status-icon"]}>
        {isItemReady ? (
          <CheckCircleOutline className={style["ready-icon"]} />
        ) : (
          <CancelOutlined className={style["not-ready-icon"]} />
        )}
      </div>
    </div>
  );
}
