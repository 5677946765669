import styles from "./responsiveMenu.module.scss";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import HubIcon from "@mui/icons-material/Hub";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import {
  Architecture,
  ArchitectureOutlined,
  Assessment,
  Dashboard,
  DashboardOutlined,
  Description,
  DescriptionOutlined,
  Logout,
  RocketLaunch,
  RocketLaunchOutlined,
  Settings,
  SettingsOutlined,
  ShoppingCart,
  ShoppingCartOutlined,
  Today,
  TodayOutlined,
  Work,
} from "@mui/icons-material";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import ResponsiveMenuItem from "./ResponsiveMenuItem/ResponsiveMenuItem";
import ResponsiveMenuExpandableItem from "./ResponsiveMenuExpandableItem/ResponsiveMenuExpandableItem";
import {
  CalendarMenuList,
  DocumentsMenuList,
  ManagementMenuList,
  OrdersMenuList,
  ProfesionnalMenuList,
} from "../NavigationBar/SubMenu/SubMenus/SubMenus";
import RemoveBodyScroll from "../modals/RemoveBodyScroll";
import BackButtonInterceptor from "../modals/BackButtonInterceptor";

interface ResponsiveMenuProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function ResponsiveMenu({
  isOpen,
  onClose,
}: ResponsiveMenuProps) {
  if (!isOpen) {
    return null;
  }

  return (
    <BackButtonInterceptor onBack={onClose}>
      <RemoveBodyScroll>
        <div className={styles["responsive-menu-container"]}>
          <div className={styles["background"]} onClick={onClose}></div>
          <div className={styles["responsive-menu"]}>
            <ResponsiveMenuItem
              link={"/dashboard"}
              title={"Dashboard"}
              Logo={DashboardOutlined}
              SelectedLogo={Dashboard}
              onClick={onClose}
            />
            <ResponsiveMenuItem
              link={"/quick-access"}
              title={"Accès rapide"}
              Logo={RocketLaunchOutlined}
              SelectedLogo={RocketLaunch}
              onClick={onClose}
            />
            <ResponsiveMenuItem
              link={"/overview"}
              title={"Vue d'ensemble"}
              Logo={HubOutlinedIcon}
              SelectedLogo={HubIcon}
              onClick={onClose}
            />
            <ResponsiveMenuExpandableItem
              title={"Calendriers"}
              Logo={TodayOutlined}
              SelectedLogo={Today}
            >
              <CalendarMenuList onMenuClose={onClose} />
            </ResponsiveMenuExpandableItem>
            <ResponsiveMenuItem
              link={"/logistic"}
              title={"Atelier"}
              Logo={ArchitectureOutlined}
              SelectedLogo={Architecture}
              onClick={onClose}
            />
            <ResponsiveMenuExpandableItem
              title={"Demandes"}
              Logo={ShoppingCartOutlined}
              SelectedLogo={ShoppingCart}
            >
              <OrdersMenuList onMenuClose={onClose} />
            </ResponsiveMenuExpandableItem>
            <ResponsiveMenuExpandableItem
              title={"Documents"}
              Logo={DescriptionOutlined}
              SelectedLogo={Description}
            >
              <DocumentsMenuList onMenuClose={onClose} />
            </ResponsiveMenuExpandableItem>
            <ResponsiveMenuExpandableItem
              title={"Professionels"}
              Logo={WorkOutlineIcon}
              SelectedLogo={Work}
            >
              <ProfesionnalMenuList onMenuClose={onClose} />
            </ResponsiveMenuExpandableItem>
            <ResponsiveMenuItem
              link={"/reports"}
              title={"Rapports"}
              Logo={AssessmentOutlinedIcon}
              SelectedLogo={Assessment}
              onClick={onClose}
            />
            <ResponsiveMenuExpandableItem
              title={"Gestion"}
              Logo={SettingsOutlined}
              SelectedLogo={Settings}
            >
              <ManagementMenuList onMenuClose={onClose} />
            </ResponsiveMenuExpandableItem>
            <ResponsiveMenuItem
              link={"/reports"}
              title={"Se déconnecter"}
              Logo={Logout}
              SelectedLogo={Logout}
              onClick={onClose}
            />
          </div>
        </div>
      </RemoveBodyScroll>
    </BackButtonInterceptor>
  );
}
