import { useLoaderData, useParams } from "react-router-dom";
import Create from "../create/create";
import { IEstimate } from "../../../types/booking.type";
import useLogout from "../../../hooks/useLogout";
import { fetchEstimate } from "../../../requests/estimate";
import { useQuery } from "react-query";
import style from "./edit.module.scss";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";

export default function Edit() {
  const { id } = useParams();
  const { data, isFetching, error, refetch } = useQuery(
    "cart",
    () => fetchEstimate({ params: { id: id } }),
    { refetchOnWindowFocus: false }
  );

  useLogout(data, isFetching);

  return (
    <>
      {!isFetching ? (
        <>{data && <Create data={data} />}</>
      ) : (
        <div className={style["loading-spinner-container"]}>
          <LoadingSpinner color="gold" />
        </div>
      )}
    </>
  );
}
