export async function setTocall({ trackId }: { trackId: string }) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/hubspot/toCall/${trackId}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ toCall: true }),
      }
    );

    if (![201, 200, 400].includes(response.status)) {
      if (response.status === 401) {
        return null;
      }
      throw new Response(JSON.stringify({ toCall: true }), {
        statusText: response.statusText,
        status: response.status,
      });
    }
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
