import { useLoaderData } from "react-router-dom";
import { authloader } from "../..";
import { getLicense } from "../../../requests/licenses";
import Create from "../create/create";
import { ILicense } from "../../../types/license.types";
import { getShop } from "../../../requests/shops";

export async function loader({
  request,
  params,
}: {
  request: Request;
  params: any;
}) {
  try {
    authloader();
    const { license } = await getLicense({ id: params.licenseId });
    if (!license) {
      return { license: null };
    }
    for (const shop of license.shopsList || []) {
      const data = await getShop(shop.id);
      if (data) {
        shop.shopData = { ...data, licenseRank: shop.licenseRank };
      }
    }
    return { license };
  } catch (error: any) {
    throw new Response(error);
  }
}

export default function Edit() {
  const { license } = useLoaderData() as {
    license: ILicense;
  };

  return <Create data={license} />;
}
