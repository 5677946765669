import clsx from "clsx";
import styles from "./operationsList.module.scss";
import { IOperation } from "../../../../../../types/accounting.types";
import SectionTitle from "../../../../../SectionTitle/SectionTitle";

interface ProductOperationsListProps {
  operationsList: IOperation[];
}

export default function ProductOperationsList({
  operationsList,
}: ProductOperationsListProps) {
  return (
    <section className={styles["product-operations-list"]}>
      <SectionTitle title={"Opérations"} />
      <ul className={styles["operations-list"]}>
        {operationsList.map((operation, index) => (
          <li
            key={index}
            className={clsx(
              styles["operation"],
              operation.canceled && styles["canceled"]
            )}
          >
            {operation.description}
          </li>
        ))}
      </ul>
    </section>
  );
}
