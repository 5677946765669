import { IValidationError } from "../types";
import { ICart } from "../types/booking.type";

export async function addCart(
  cart: ICart,
  addOrUpdateQuote: boolean = false
): Promise<{ cart: ICart } | IValidationError[] | null> {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/cart`, {
      headers: new Headers({ "Content-Type": "application/json" }),
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ cart, addOrUpdateQuote }),
    });
    const data = await response.json();

    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateCart(
  cart: ICart,
  addOrUpdateQuote: boolean = false
): Promise<{ cart: ICart } | IValidationError[] | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/cart/${cart.id}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "PUT",
        credentials: "include",
        body: JSON.stringify({ cart, addOrUpdateQuote }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchCart(id: string | undefined): Promise<ICart> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/cart/${id}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.cart;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchCartsList(
  query?: string
): Promise<{ cartsList: ICart[]; totalCount: number }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/cart${query || ""}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function deleteCart(
  id?: string,
  signal?: AbortSignal
): Promise<void> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/cart/${id}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "include",
        method: "DELETE",
        signal: signal,
      }
    );
    if (response.status >= 400) {
      throw new Response(JSON.stringify(response), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
