import { useDrag } from "react-dnd";
import { ModalProductMiniature } from "../../../ModalProductMiniature/ModalProductMiniature";
import { IProduct } from "../../../../../../types/logistic.types";
import style from "./draggableProduct.module.scss";

interface DraggableProductProps {
  product: IProduct;
}

export default function DraggableProduct({ product }: DraggableProductProps) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "Product",
    item: { product },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
      }}
      draggable
      className={style["draggable-product"]}
    >
      <ModalProductMiniature
        className={style["product-miniature-container"]}
        product={product}
        showOperationList={true}
      />
    </div>
  );
}
