import { Delete, Edit } from "@mui/icons-material";
import { Link } from "react-router-dom";
import style from "./item.module.scss";
import { IProAccount } from "../../../types/proAccount.types";

interface ItemProps {
  data: IProAccount;
  onDelete: (id: string) => void;
}

export default function Item({ data, onDelete }: ItemProps) {
  return (
    <div className={style["item"]}>
      <Link
        className={style["username"]}
        to={`/shops?proAccountId=${data.id}&name=${data.username}`}
      >
        {data.username}
      </Link>
      <div className={style["tools"]}>
        <div className={style["icon"]} title={"modifier"}>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            className={style["icon"]}
            to={`./edit/${data.id}`}
          >
            <Edit />
          </Link>
        </div>
        <div
          className={style["icon"]}
          onClick={handleDelete}
          title={"supprimer"}
        >
          <Delete />
        </div>
      </div>
    </div>
  );

  function handleDelete() {
    if (
      window.confirm(
        "Êtes-vous sûre de vouloir supprimer définitivement cet utilisateur ?"
      )
    ) {
      onDelete(data.id);
    }
  }
}
