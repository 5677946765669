import { IQuote } from "../../../../types/accounting.types";
import styles from "./timeLimit.module.scss";

interface TimeLimitProps {
  quote: IQuote | undefined;
  onChange: ({ target }: { target: HTMLInputElement }) => void;
}

export default function TimeLimit({ quote, onChange }: TimeLimitProps) {
  return (
    <div className={styles["timelimit"]}>
      <div className={styles["title"]}>Délais</div>
      <div className={styles["timelimit-container"]}>
        <div className={styles["timelimit-line"]}>
          <label>Délai minimum</label>
          <input
            name={"minimumDurationDays"}
            value={quote?.minimumDurationDays ?? 0}
            type="number"
            min={0}
            onChange={onChange}
            className={styles["input"]}
          />
        </div>
        <div className={styles["timelimit-line"]}>
          <label>Délai maximum</label>
          <input
            name={"maximumDurationDays"}
            value={quote?.maximumDurationDays ?? 0}
            type="number"
            min={0}
            className={styles["input"]}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
}
