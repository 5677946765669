import { createContext, useContext, useState } from "react";

interface ImageViewerContextProps {
  index: number;
  setIndex: (index: number) => void;
  isBlob: boolean;
  setIsBlob: (value: boolean) => void;
  pictureList: string[];
  setPictureList: (pictureList: string[]) => void;
  viewerIsOpen: boolean;
  setViewerIsOpen: (value: boolean) => void;
}

const defaultContextProps: ImageViewerContextProps = {
  index: 0,
  setIndex: (index: number) => {},
  isBlob: false,
  setIsBlob: (value: boolean) => {},
  viewerIsOpen: false,
  setViewerIsOpen: (value: boolean) => {},
  pictureList: [],
  setPictureList: (value: string[]) => {},
};

const ImageViewerContext =
  createContext<ImageViewerContextProps>(defaultContextProps);

export default function ImageViewerProvider(props: any) {
  const [index, setIndex] = useState<number>(0);
  const [viewerIsOpen, setViewerIsOpen] = useState<boolean>(false);
  const [isBlob, setIsBlob] = useState<boolean>(false);
  const [pictureList, setPictureList] = useState<string[]>();

  return (
    <ImageViewerContext.Provider
      value={
        {
          index,
          setIndex,
          isBlob,
          setIsBlob,
          pictureList,
          setPictureList,
          viewerIsOpen,
          setViewerIsOpen,
        } as ImageViewerContextProps
      }
    >
      {props.children}
    </ImageViewerContext.Provider>
  );
}

export function useImageViewer() {
  const context = useContext(ImageViewerContext);
  if (context === undefined) {
    throw new Error("useImageViewer must be used within a ImageViewerProvider");
  }
  return context;
}
