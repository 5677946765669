import styles from "./operationGroupSelect.module.scss";
import translate from "../../../../../../translation";
import clsx from "clsx";
import SectionTitle from "../../../../../../components/SectionTitle/SectionTitle";
import HANDBAG_DEFAULT from "../../../../../../assets/maro.webp";
import SNEAKERS_DEFAULT from "../../../../../../assets/sneakers.webp";
import WOMAN_SHOES_DEFAULT from "../../../../../../assets/article-femme.webp";
import MAN_SHOES_DEFAULT from "../../../../../../assets/man_shoes.webp";
import CLIMBING_SHOES_DEFAULT from "../../../../../../assets/climbing_shoes.webp";

interface OperationGroupSelectProps {
  onChange: (value: string) => void;
  operationGroup: string;
  operationGroupList: string[];
}

export default function OperationGroupSelect({
  onChange,
  operationGroup,
  operationGroupList,
}: OperationGroupSelectProps) {
  return (
    <>
      <SectionTitle title={"Groupe d'opérations"} />
      <div className={styles["operation-group-list"]}>
        {operationGroupList.map((op, index) => (
          <div
            className={clsx(styles["operation-group"], {
              [styles["is-selected"]]: operationGroup === op,
            })}
            key={index}
            onClick={() => handleOnOperationGroupSelect(op)}
          >
            <img
              draggable={false}
              className={styles["logo"]}
              src={getOperationGroupLogo(op)}
            />
            <p>{translate(op)}</p>
          </div>
        ))}
      </div>
    </>
  );

  function handleOnOperationGroupSelect(operationGroup: string) {
    onChange(operationGroup);
  }

  function getOperationGroupLogo(operationGroup: string) {
    switch (operationGroup) {
      case "SHOES_MAN":
        return MAN_SHOES_DEFAULT;
      case "SHOES_WOMAN":
        return WOMAN_SHOES_DEFAULT;
      case "SHOES_SNEAKERS":
        return SNEAKERS_DEFAULT;
      case "SHOES_CLIMBING":
        return CLIMBING_SHOES_DEFAULT;
      case "LEATHER_LARGE":
        return HANDBAG_DEFAULT;
      default:
        return "";
    }
  }
}
