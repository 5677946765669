import Input from "../../../inputs/Input/Input";
import style from "./workshopInfoForm.module.scss";
import {
  OPERATION_GROUP,
  PRODUCT_GROUP,
} from "../../../../utils/logistic.init";
import Select from "../../../Select/Select";
import { IProduct } from "../../../../types/logistic.types";
import { useEffect, useMemo, useState } from "react";
import OperationSection from "../operationSection/OperationSection";
import Button from "../../../Button/Button";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../../../../utils/toast.options";
import { IOperation } from "../../../../types/accounting.types";

interface WorkshopInfoFormProps {
  handleValidateProduct: () => void;
  productForm: IProduct;
  setProductForm: (product: IProduct | null) => void;
  hasWorkshopId?: boolean;
}

export default function WorkshopInfoForm({
  handleValidateProduct,
  productForm,
  setProductForm,
  hasWorkshopId = true,
}: WorkshopInfoFormProps) {
  const [operationForm, setOperationForm] = useState<IOperation | null>(null);
  useEffect(() => {
    setProductForm({
      ...productForm,
      operationGroup: FILTERED_OPERATION_GROUP[0],
    });
  }, [productForm.productGroup]);

  const FILTERED_OPERATION_GROUP = useMemo(
    () => OPERATION_GROUP.filter((op) => op.includes(productForm.productGroup)),
    [productForm.productGroup]
  );

  return (
    <div className={style["workshop-pro-info-form"]}>
      <div className={style["right"]}>
        <div className={style["input-line"]}>
          <div className={style["select-container"]}>
            <label>Groupe d'articles *</label>
            <Select
              optionsList={PRODUCT_GROUP}
              value={productForm.productGroup}
              setValue={(value) =>
                setProductForm({ ...productForm, productGroup: value })
              }
              doTranslate
              placeholder={"Sélectionnez un groupe d'article..."}
            />
          </div>
          <div className={style["select-container"]}>
            <label>Groupe d'opérations *</label>
            <Select
              optionsList={FILTERED_OPERATION_GROUP}
              value={productForm.operationGroup}
              setValue={(value) =>
                setProductForm({ ...productForm, operationGroup: value })
              }
              doTranslate
              placeholder={"Sélectionnez un groupe d'opérations..."}
            />
          </div>
        </div>
        <div className={style["input-line"]}>
          <Input
            label="Marque *"
            name="brand"
            value={productForm.brand}
            onChange={({ target }) =>
              setProductForm({ ...productForm, brand: target.value })
            }
          />
          <Input
            className={style["input-container"]}
            label={hasWorkshopId ? "Numéro de ticket" : "Commentaire"}
            name={hasWorkshopId ? "workshopId" : "comments"}
            value={
              hasWorkshopId ? productForm.workshopId : productForm.comments
            }
            onChange={({ target }) =>
              setProductForm({
                ...productForm,
                [hasWorkshopId ? "workshopId" : "comments"]: target.value,
              })
            }
          />
        </div>
        <OperationSection
          className={style["operation-section-container"]}
          productForm={productForm}
          setProductForm={setProductForm}
          operationForm={operationForm}
          setOperationForm={setOperationForm}
        />
        <div className={style["workshop-pro-form-footer"]}>
          <Button
            className={style["product-form-button"]}
            onClick={handleCancel}
          >
            Annuler
          </Button>
          <Button
            className={style["product-form-validate-button"]}
            onClick={handleConfirmForm}
          >
            Valider
          </Button>
        </div>
      </div>
    </div>
  );

  function handleCancel() {
    setProductForm(null);
  }

  function handleConfirmForm() {
    if (
      !productForm.brand ||
      !productForm.operationGroup ||
      !productForm.productGroup ||
      productForm.operationsList.length === 0
    ) {
      return toast.warning(
        "Veuillez remplir les champs obligatoires.",
        TOAST_ERROR_OPTIONS
      );
    }
    if (operationForm !== null) {
      return toast.warning(
        "Veuillez valider ou annuler l'opération avant de pouvoir valider l'article",
        TOAST_ERROR_OPTIONS
      );
    }
    handleValidateProduct();
  }
}
