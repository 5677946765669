import style from "./selectDirection.module.scss";
import Select from "../../../../components/Select/Select";

interface SelectDirectionProps {
  document: string | null;
  direction: string | null;
  setDirection: (value: string) => void;
}

export default function SelectDirection({
  document,
  direction,
  setDirection,
}: SelectDirectionProps) {
  return (
    <>
      <label>1 - Choisir la direction</label>
      <Select
        className={style["select-shipping-method"]}
        optionsList={["INCOMING", "OUTGOING"]}
        placeholder="Direction..."
        setValue={handleChangeDirection}
        value={direction || ""}
      />
    </>
  );

  function handleChangeDirection(value: string) {
    if (document) {
      return window.alert(
        'Vous ne pouvez pas faire de modifications. Appuyer sur "Génerer Nouveau Bon" ou annuler le bon actuel.'
      );
    }
    setDirection(value);
  }
}
