import ColorComponent from "../../../../components/colorComponent/colorComponent";
import Image from "../../../../components/image/Image";
import WorkshopId from "../../../../components/workshopId/workshopId";
import { IProduct } from "../../../../types/logistic.types";
import style from "./qualityControlHeader.module.scss";

interface QualityControlHeaderProps {
  product: IProduct;
}

export default function QualityControlHeader({
  product,
}: QualityControlHeaderProps) {
  return (
    <div className={style["operation-control-top"]}>
      <div className={style["operation-control-top-left"]}>
        <Image
          className={style["incoming-picture"]}
          src={
            product?.incomingPicturesList
              ? product?.incomingPicturesList[0]
              : ""
          }
          pictureList={product?.incomingPicturesList}
        />
        <div>{product.brand || "NR"}</div>
        <ColorComponent color={product.color || "NR"} />
      </div>
      <WorkshopId
        workshopId={product.workshopId}
        className={style["workshop-id"]}
      />
    </div>
  );
}
