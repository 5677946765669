import ValidateButton from "../../validateButton/validateButton";
import { SmallModalContainer } from "../SmallModalContainer";
import styles from "./confirmModal.module.scss";

interface ConfirmModalProps {
  title?: string;
  message?: string;
  onCancel: () => void;
  onConfirm: () => void;
  isOpen: boolean;
}

export default function ConfirmModal({
  title,
  message,
  onCancel,
  onConfirm,
  isOpen,
}: ConfirmModalProps) {
  return (
    <SmallModalContainer isOpen={isOpen} handleCancel={handleCancel}>
      <div className={styles["confirm-modal-container"]}>
        <div className={styles["confirm-modal-comp"]}>
          <div className={styles["confirm-modal-text"]}>
            <div className={styles["title"]}>{title}</div>
            <div className={styles["message"]}>{message}</div>
          </div>
          <div className={styles["button-section"]}>
            <ValidateButton handleOnClick={onCancel} title={"Annuler"} />
            <ValidateButton
              className={styles["validate-button"]}
              handleOnClick={onConfirm}
              title={"Confirmer"}
            />
          </div>
        </div>
      </div>
    </SmallModalContainer>
  );

  function handleCancel() {
    onCancel();
  }
}
