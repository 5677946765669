import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../../pages/error-page";
// import { loader as readLoader } from "./read/read";
import { authloader } from "..";
import Overview from "./index";
import Read from "./read/read";

export const route: RouteObject = {
  path: "/overview",
  element: <Outlet />,
  errorElement: <ErrorPage />,
  loader: authloader,
  children: [
    {
      index: true,
      element: <Overview />,
      loader: authloader,
    },
    {
      path: ":id",
      element: <Read />,
      loader: authloader,
    },
  ],
};
