export interface CustomErrorProps {
    status?: number;
    body?: any;
  }
  
  export class CustomError extends Error {
    status?: number;
    body?: any;
  
    constructor(message: string, { status, body }: CustomErrorProps = {}) {
      super(message);
      this.name = this.constructor.name;
      this.status = status;
      this.body = body;
    }
  }