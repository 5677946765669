import { Crop } from "react-image-crop";

export function handleCropImage(
  crop: Crop | undefined,
  rawImage: HTMLImageElement | null,
  resetImageUri: () => void,
  setImage: (file: File) => void,
  startCrop: boolean,
  setStartCrop: (sc: boolean) => void
) {
  if (!crop || !rawImage) {
    return;
  }

  const canvas = document.createElement("canvas");
  const scaleX = rawImage.naturalWidth / rawImage.width;
  const scaleY = rawImage.naturalHeight / rawImage.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    return;
  }

  const pixelRatio = window.devicePixelRatio;
  canvas.width = crop.width * pixelRatio;
  canvas.height = crop.height * pixelRatio;
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = "high";

  ctx.drawImage(
    rawImage,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  // Converting to base64
  // const base64Image = canvas.toDataURL("image/jpeg");
  const file = canvas.toBlob((blob: Blob | null) => {
    if (!blob) {
      return;
    }
    const newFile = new File([blob], "output");
    resetImageUri();
    setImage(newFile);
    setStartCrop(!startCrop);
  });
  // setOutput(base64Image);
}
