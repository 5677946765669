import styles from "./smallModalContainer.module.scss";
import ModalPortal from "./ModalPortal";
import RemoveBodyScroll from "./RemoveBodyScroll";

interface SmallModalContainerProps {
  children: React.ReactNode;
  handleCancel?: () => void;
  isOpen: boolean;
}

export function SmallModalContainer({
  children,
  handleCancel,
  isOpen,
}: SmallModalContainerProps) {
  return (
    <ModalPortal isOpen={isOpen}>
      <RemoveBodyScroll>
        <div className={styles["modal-container"]}>
          <div className={styles["small-modal-container-children"]}>
            {children}
          </div>
          <div className={styles["background"]} onClick={handleCancel}></div>
        </div>
      </RemoveBodyScroll>
    </ModalPortal>
  );
}
