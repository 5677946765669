import {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./menuItemContainer.module.scss";
import { useLocation } from "react-router-dom";
import { useGetPath } from "../../../utils/utils";
import clsx from "clsx";
import { isDesktop, isMobile } from "react-device-detect";
import { createPortal } from "react-dom";
import { useHideSideBar } from "../../../context/hideSideBar.context";

interface MenuItemContainerProps {
  title: string;
  children: ReactNode;
  isSubMenu?: boolean;
}

export default function MenuItemContainer({
  title,
  children,
  isSubMenu = false,
  ...props
}: MenuItemContainerProps & React.SelectHTMLAttributes<HTMLLIElement>) {
  const { hideSide } = useHideSideBar();
  const isSelected = useGetPath(title);
  const ref = useRef<HTMLLIElement>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [position, setPosition] = useState<number>(-1000);

  useEffect(() => {
    if (ref.current) {
      setPosition(ref.current.offsetTop);
    }
  }, [isHovered, ref]);

  return (
    <li
      className={clsx(
        styles["menu-item-container"],
        isDesktop && styles["hover"],
        isMobile && styles["active"],
        isSelected && styles["selected"]
      )}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      ref={ref}
      {...props}
    >
      {children}
      {isHovered &&
        isDesktop &&
        hideSide &&
        !isSubMenu &&
        createPortal(
          <div
            className={clsx(
              styles["title"],
              isHovered ? styles["opacity"] : ""
            )}
            style={{ top: position }}
          >
            {title}
          </div>,
          document.body
        )}
    </li>
  );
}
