import { Link } from "react-router-dom";
import styles from "./dropAndCollect.module.scss";

export default function DropAndCollect() {
  return (
    <div className={styles["drop-and-collect"]}>
      <Link
        className={styles["shipping-service-button"]}
        to={"/quick-access/in-store-drop-off"}
      >
        Dépôt boutique
      </Link>
      <Link
        className={styles["shipping-service-button"]}
        to={"/quick-access/collect-product"}
      >
        Retrait boutique
      </Link>
    </div>
  );
}
