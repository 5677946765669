import { useEffect, useState } from "react";
import { IShippingPoint, PickUpInfo } from "../../../../types/shipping.types";
import ContactSection from "./ContactSection/ContactSection";
import AddressSection from "./AddressSection/AddressSection";
import AddressInfo from "./AddressInfo/AddressInfo";
import ContactInfo from "./ContactInfo/ContactInfo";

interface SelectAddressProps {
  document: string | null;
  shippingInfo: IShippingPoint | null;
  setShippingInfo: (shippingInfo: IShippingPoint | null) => void;
  setPickUpInfo: (pickUpInfo: PickUpInfo | null) => void;
}

export default function SelectAddress({
  document,
  shippingInfo,
  setShippingInfo,
  setPickUpInfo,
}: SelectAddressProps) {
  const FILL_CONTACT_FORM = 0;
  const FILL_ADDRESS_FORM = 1;
  const FORM_FILLED = 2;
  const [step, setStep] = useState<number>(FILL_CONTACT_FORM);
  const [shippingForm, setShippingForm] = useState<IShippingPoint | null>(
    shippingInfo
  );

  useEffect(() => {
    if (step === FORM_FILLED) {
      setShippingInfo(shippingForm);
    } else {
      setShippingInfo(null);
    }
  }, [step]);

  return (
    <>
      <label>3 - Remplir les informations du contact</label>
      {shippingForm && (
        <ContactInfo
          shippingForm={shippingForm}
          edit={() => handleEdit(FILL_CONTACT_FORM)}
        />
      )}
      {step === FILL_CONTACT_FORM && (
        <ContactSection
          shippingForm={shippingForm}
          setShippingForm={setShippingForm}
          setStep={setStep}
        />
      )}
      {shippingForm && <label>4 - Remplir une addresse</label>}
      {shippingForm?.address1 && (
        <AddressInfo
          shippingForm={shippingForm}
          step={step}
          edit={handleAddressEdit}
        />
      )}
      {step === FILL_ADDRESS_FORM && (
        <AddressSection
          setPickUpInfo={setPickUpInfo}
          shippingForm={shippingForm}
          setShippingForm={setShippingForm}
          setStep={setStep}
        />
      )}
    </>
  );

  function handleEdit(nextStep: number) {
    if (document) {
      return window.alert(
        'Vous ne pouvez pas faire de modifications. Appuyer sur "Génerer Nouveau Bon" ou "Annuler" le bon actuel.'
      );
    }
    setStep(nextStep);
  }

  function handleAddressEdit() {
    if (step === FILL_ADDRESS_FORM) {
      return handleEdit(FORM_FILLED);
    }
    handleEdit(FILL_ADDRESS_FORM);
  }
}
