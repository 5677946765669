import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../../pages/error-page";
import Shippings from ".";
import Create from "./create/Create";
import Read from "./read/read";
import { authloader } from "..";
import Edit from "./edit/edit";
import Generate, { loader as generateLoader } from "./generate/generate";
import ReadOne, { loader as readLoader } from "./readOne/readOne";

export const route: RouteObject = {
  path: "/shipping-calendar",
  element: <Outlet />,
  errorElement: <ErrorPage />,
  loader: authloader,
  children: [
    {
      index: true,
      element: <Shippings />,
      loader: authloader,
    },
    {
      path: "create",
      element: <Create />,
      loader: authloader,
    },
    {
      path: "read/",
      element: <Read />,
      loader: authloader,
    },
    {
      path: "read/:id",
      element: <ReadOne />,
      loader: readLoader,
    },
    {
      path: "generate/:trackerId/:direction",
      loader: generateLoader,
      element: <Generate />,
    },
    {
      path: "edit/:id",
      element: <Edit />,
      loader: authloader,
    },
  ],
};
