import { useEffect, useState } from "react";
import style from "./expandableComponent.module.scss";
import { CheckCircleOutline } from "@mui/icons-material";

interface ExpandableComponentProps {
  children: JSX.Element;
  state: boolean;
  title: string;
}
export default function ExpandableComponent({
  children,
  state,
  title,
}: ExpandableComponentProps) {
  const [expand, setExpand] = useState<boolean>(false);

  useEffect(() => {
    if (state) {
      setExpand(false);
    }
  }, [state]);

  return (
    <div className={style["expandable-component"]}>
      <div
        onClick={() => setExpand(!expand)}
        className={[
          style["expandable-component-title"],
          state ? style["isFinished"] : "",
        ].join(" ")}
      >
        <span className={style["expand-icon"]}>{`${expand ? "-" : "+"}`}</span>
        <span>{title}</span>
        {state && <CheckCircleOutline />}
      </div>
      {expand && (
        <div className={style["expandable-container"]}>{children}</div>
      )}
    </div>
  );
}
