import { useNavigate, useParams } from "react-router-dom";
import { loadQuote } from "../../../requests/tracker";
import QuoteEdit from "./quoteEdit";
import { useQuery } from "react-query";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import { IQuote } from "../../../types/accounting.types";

interface QuoteEditProviderProps {}

export default function QuoteEditProvider({}: QuoteEditProviderProps) {
  const { trackId } = useParams();
  const { data, isFetching, refetch } = useQuery(
    ["quote-edit", trackId],
    () => loadQuote({ params: { trackerId: trackId } }),
    { refetchOnWindowFocus: false, enabled: !!trackId }
  );
  const navigate = useNavigate();

  if (isFetching) {
    return <LoadingSpinner />;
  }

  if (!data) {
    return <></>;
  }

  return (
    <QuoteEdit
      defaultQuote={data}
      onUpdate={handleQuoteUpdate}
      onSuccess={handleOnSuccess}
    />
  );

  function handleQuoteUpdate(quote: IQuote) {
    refetch();
  }

  function handleOnSuccess() {
    navigate("../");
  }
}
