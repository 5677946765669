import { ILicense, ILicenseShop } from "../types/license.types";
import { IShop } from "../types/shop.types";

export async function getLicensesList(query?: string): Promise<ILicense[]> {
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "GET",
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/license${query || ""}`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function getLicense({
  id,
}: {
  id: string;
}): Promise<{ license: ILicense }> {
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "GET",
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/license/${id}`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function createLicense({
  license,
}: {
  license: Partial<ILicense>;
}): Promise<{ license: ILicense }> {
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "POST",
    body: JSON.stringify(license),
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/license/`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function updateLicense({
  id,
  license,
}: {
  id: string;
  license: Partial<ILicense>;
}): Promise<{ license: ILicense }> {
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "PUT",
    body: JSON.stringify({ license }),
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/license/${id}`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function deleteLicense({ id }: { id: string }): Promise<ILicense> {
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "DELETE",
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/license/${id}`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function setAdmin({
  licenseId,
  adminId,
}: {
  licenseId: string;
  adminId: string;
}): Promise<ILicense> {
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "POST",
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/license/${licenseId}/admin/${adminId}`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function addShop({
  licenseId,
  shop,
}: {
  licenseId: string;
  shop: IShop;
}): Promise<{ license: ILicense }> {
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "POST",
    body: JSON.stringify({ shop }),
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/license/${licenseId}/shop/`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function updateShop({
  licenseId,
  shopId,
  shop,
}: {
  licenseId: string;
  shopId: string;
  shop: IShop;
}): Promise<{ license: ILicense }> {
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "put",
    body: JSON.stringify({ shop }),
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/license/${licenseId}/shop/${shopId}`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function removeShop({
  licenseId,
  shopId,
}: {
  licenseId: string;
  shopId: string;
}): Promise<ILicense> {
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "DELETE",
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/license/${licenseId}/shop/${shopId}`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function addShopUser({
  licenseId,
  shopId,
  userId,
}: {
  licenseId: string;
  shopId: string;
  userId: string;
}): Promise<ILicense> {
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "POST",
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/license/${licenseId}/shop/${shopId}/user/${userId}`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function removeShopUser({
  licenseId,
  shopId,
  userId,
}: {
  licenseId: string;
  shopId: string;
  userId: string;
}): Promise<ILicense> {
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "DELETE",
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/license/${licenseId}/shop/${shopId}/user/${userId}`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}

export async function generateLicenseKey({ id }: { id: string }): Promise<{
  redirections: { email: string; redirectionEndpoint: string }[];
}> {
  const options: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    method: "GET",
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/license/${id}/generate-key`,
      options
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error) {
    throw error;
  }
}
