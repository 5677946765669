import { useRef } from "react";
import styles from "./addImage.module.scss";
import { AddAPhoto } from "@mui/icons-material";
import { useMutation } from "react-query";
import { deleteFile, streamFileMutation } from "../../../requests/file";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../../../utils/toast.options";
import ImagePreview from "./ImagePreview/ImagePreview";
import Button from "../../Button/Button";
import clsx from "clsx";

interface AddImageProps {
  filename: string;
  multiple?: boolean;
  onAddImage?: (data: any) => void;
  onDeleteImage?: (data: any) => void;
  imagesList?: string[];
  className?: string;
}

export default function AddImage({
  className,
  imagesList = [],
  filename,
  multiple = true,
  onAddImage,
  onDeleteImage,
}: AddImageProps) {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { isLoading, mutateAsync: addFileAsync } = useMutation(
    streamFileMutation,
    {
      onSuccess(data) {
        onAddImage && onAddImage(data.message);
      },
      onError() {
        toast.error("Erreur lors de l'ajout de l'image.", TOAST_ERROR_OPTIONS);
      },
    }
  );

  const { mutate: deleteImage, isLoading: deleteImageIsLoading } = useMutation(
    deleteFile,
    {
      onSuccess(data) {
        onDeleteImage && onDeleteImage(data);
      },
      onError() {
        toast.error(
          "Erreur lors de la suppression de l'image.",
          TOAST_ERROR_OPTIONS
        );
      },
    }
  );

  return (
    <div className={clsx(styles["add-image"], className)}>
      <ImagePreview
        isLoading={isLoading || deleteImageIsLoading}
        imagesList={imagesList}
        onDeleteImage={handleDeleteImage}
      />
      <div className={styles["add-container"]} onClick={handleAddClick}>
        <Button
          type="button"
          className={styles["add-new-picture-button"]}
          disabled={isLoading || deleteImageIsLoading}
        >
          <AddAPhoto className={styles["camera-icon"]} />
        </Button>
        <input
          ref={hiddenFileInput}
          className={styles["file-input"]}
          type="file"
          onChange={handleFileChange}
          multiple
        />
      </div>
    </div>
  );

  function handleAddClick() {
    hiddenFileInput?.current?.click();
  }

  function handleDeleteImage(filename: string) {
    deleteImage && deleteImage(filename);
  }

  async function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) {
      return;
    }
    if (!multiple) {
      if (e.target.files && e.target.files[0]) {
        await addFileAsync({ filename, fileContent: e.target.files[0] });
      }
      return;
    }
    for (let file of e.target.files) {
      await addFileAsync({ filename, fileContent: file });
    }
  }
}
