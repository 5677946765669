import { ChangeEvent, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fetchCustomersList } from "../../requests/customer";
import style from "./contactSearch.module.scss";
import { ICustomer } from "../../types/customer.type";

interface ContactSearchProps {
  onSelect: (contact: ICustomer) => void;
}

export default function ContactSearch({ onSelect }: ContactSearchProps) {
  const [search, setSearch] = useState("");
  const { data, isFetching, error, refetch } = useQuery(
    "customersList",
    () => fetchCustomersList(`?search=${search}&limit=5`),
    { enabled: false }
  );

  useEffect(() => {
    if (search.length > 2) {
      refetch();
    }
  }, [search]);

  return (
    <div className={style["contact-search-anchor"]}>
      <div className={style["contact-search-container"]}>
        <div>
          <input
            className={style["contact-search-input"]}
            type="search"
            onChange={handleChange}
          />
        </div>
        <div className={style["contact-search-results-container"]}>
          {data &&
            search.length > 2 &&
            data.customers &&
            data.customers.map((customer) => (
              <div
                key={customer.id}
                className={style["contact-search-results-item"]}
                onClick={() => onSelect(customer)}
              >
                <div className={style["contact-item-name"]}>
                  {customer.givenname} {customer.familyname}
                </div>
                <div className={style["contact-item-info"]}>
                  {customer.email} - {customer.phone}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { target } = event;
    setSearch(target.value);
  }
}
