import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../../pages/error-page";
import { authloader } from "..";
import DevTools from ".";
import CreateShippingPro from "./createShippingPro/createShippingPro";
import CreateChronopostPro from "../quickAccess/createChronopost/createChronopost";
import ModalTest from "./modalTest/modalTest";
import ImageConversion from "./image_conversion/ImageConversion";
import BlePrinter from "./ble_printer/BlePrinter";

export const route: RouteObject = {
  path: "/dev-tools",
  element: <Outlet />,
  loader: authloader,
  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <DevTools />,
      loader: authloader,
    },
    {
      path: "create-shipping-pro",
      element: <CreateShippingPro />,
      loader: authloader,
    },
    {
      path: "create-chronopost-pro",
      element: <CreateChronopostPro />,
      loader: authloader,
    },
    {
      path: "modal-test",
      element: <ModalTest />,
      loader: authloader,
    },
    {
      path: "image-conversion",
      element: <ImageConversion />,
      loader: authloader,
    },
    {
      path: "ble-printer",
      element: <BlePrinter />,
      loader: authloader,
    },
  ],
};
