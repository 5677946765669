import { ReactElement, useState } from "react";
import { useConfirmModal } from "../../context/confirmationModalContext";
import ValidateButton from "../validateButton/validateButton";
import style from "./sendEstimationModalComp.module.scss";

interface SendEstimationModalProps {
  confirmButtonClassName?: string;
  message?: string;
  element?: ReactElement;
  onConfirm: (mailBody?: string) => void;
  title?: string;
}

export default function SendEstimationModalComp({
  confirmButtonClassName,
  message,
  element,
  title,
  onConfirm,
}: SendEstimationModalProps) {
  const { setConfirmModalIsOpen, setConfirmModalChildren } = useConfirmModal();
  const [mailBody, setMailBody] = useState<string>("");

  return (
    <div className={style["confirm-modal-comp"]}>
      <div className={style["title"]}>{title}</div>
      <div className={style["message"]}>{message}</div>
      <div className={style["element"]}>{element}</div>

      <textarea
        className={style["mail-body"]}
        value={mailBody}
        onChange={handleAddEmailBodyMessage}
        rows={10}
        placeholder="Notes pour le client... (optionnel)"
      />
      <div className={style["button-section"]}>
        <ValidateButton
          className={confirmButtonClassName}
          handleOnClick={handleConfirm}
          title={"Oui"}
        />
        <ValidateButton handleOnClick={handleCancel} title={"Non"} />
      </div>
    </div>
  );

  function handleCancel() {
    setConfirmModalIsOpen(false);
    setConfirmModalChildren(<></>);
  }

  function handleConfirm() {
    onConfirm(mailBody);
    setConfirmModalIsOpen(false);
    setConfirmModalChildren(<></>);
  }

  function handleAddEmailBodyMessage({
    target,
  }: {
    target: EventTarget & HTMLTextAreaElement;
  }) {
    setMailBody(target.value);
  }
}
