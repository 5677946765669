import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logoutRequest } from "../requests/user";
import { useAuth } from "../context/auth.context";

export default function useLogout(data: any, isFetching?: any) {
  const { unregisterUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!data && !isFetching) {
      logoutRequest();
      unregisterUser();
      navigate("/login");
    }
  }, [data, isFetching]);
}
