import { ReactElement, createContext, useContext, useState } from "react";

interface ModalComponentContextProps {
  modalChildren: ReactElement | undefined;
  setModalChildren: (modalChildren: ReactElement | undefined) => void;
  modalIsOpen: boolean;
  setModalIsOpen: (value: boolean) => void;
  confirmClose: boolean;
  setConfirmClose: (value: boolean) => void;
}

const defaultContextProps: ModalComponentContextProps = {
  modalIsOpen: false,
  setModalIsOpen: (value: boolean) => {},
  confirmClose: false,
  setConfirmClose: (value: boolean) => {},
  modalChildren: undefined,
  setModalChildren: (value: ReactElement | undefined) => {},
};

export const ModalComponentContext =
  createContext<ModalComponentContextProps>(defaultContextProps);

export default function ModalComponentProvider(props: any) {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [confirmClose, setConfirmClose] = useState<boolean>(false);
  const [modalChildren, setModalChildren] = useState<
    ReactElement | undefined
  >();

  return (
    <ModalComponentContext.Provider
      value={{
        modalIsOpen,
        setModalIsOpen,
        confirmClose,
        setConfirmClose,
        modalChildren,
        setModalChildren,
      }}
    >
      {props.children}
    </ModalComponentContext.Provider>
  );
}

export function useModalComponent() {
  const context = useContext(ModalComponentContext);

  if (context === undefined) {
    throw new Error(
      "useModalComponent must be used within a ModalComponentProvider"
    );
  }
  return context;
}
