import { useState } from "react";
import { IExternalShipping, IShipping } from "../../../../types/shipping.types";
import style from "./generateLabel.module.scss";
import ValidateButton from "../../../../components/validateButton/validateButton";
import { toast } from "react-toastify";
import {
  cancelChronopostLabel,
  cancelShippingLabel,
  reserveShippingLabel,
} from "../../../../requests/shipping";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../../utils/toast.options";
import DownloadLabelButton from "../DownloadLabelButton/DownloadLabelButton";
import SectionFormTemplate from "../../../../components/forms/sectionFormTemplate/SectionFormTemplate";

interface GenerateLabelProps {
  shipping: IShipping | null;
  setDocument: (value: IExternalShipping | null) => void;
  refetch?: () => void;
}

export default function GenerateLabel({
  shipping,
  setDocument,
  refetch,
}: GenerateLabelProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState<boolean>(false);

  return (
    <SectionFormTemplate title={"Bon Chronopost"}>
      <div className={style["generate-label"]}>
        {shipping?.externalShipping?.reservationNumber ? (
          <div className={style["generate-label-section"]}>
            <DownloadLabelButton
              className={style["generate-label-button"]}
              shipping={shipping}
            />
            <ValidateButton
              className={style["generate-label-button"]}
              title="Annuler"
              handleOnClick={handleCancelShippingLabel}
              isLoading={isLoadingCancel}
            />
          </div>
        ) : (
          <ValidateButton
            className={style["generate-label-button"]}
            title="Génerer le bon CHRONPOST"
            handleOnClick={handleReserveShippingLabel}
            isLoading={isLoading}
          />
        )}
      </div>
    </SectionFormTemplate>
  );

  async function handleReserveShippingLabel() {
    if (!shipping || !shipping.id) {
      return;
    }
    setIsLoading(true);
    try {
      const externalShipping = await reserveShippingLabel(shipping.id);
      setDocument(externalShipping);
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    } finally {
      setIsLoading(false);
      refetch && refetch();
    }
  }

  async function handleCancelShippingLabel() {
    if (!shipping || !shipping.id) {
      return toast.error("Shipping ID is missing", TOAST_ERROR_OPTIONS);
    }
    setIsLoadingCancel(true);
    try {
      await cancelShippingLabel(shipping.id);
      toast.success(
        "Le bon Chronppost a été annulé avec succès",
        TOAST_SUCCESS_OPTIONS
      );
    } catch (error: any) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    } finally {
      setIsLoadingCancel(false);
      setDocument(null);
    }
  }
}
