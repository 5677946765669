import {
  EncodedImage,
  HeartbeatFailedEvent,
  ImageEncoder,
  NiimbotAbstractClient,
  NiimbotBluetoothClient,
  NiimbotSerialClient,
  PrintDirection,
  PrintProgressEvent,
} from "@mmote/niimbluelib";
import { createContext, useCallback, useContext, useState } from "react";
import { toast } from "react-toastify";

export type ConnectionType = "bluetooth" | "usb";
export type PrintState = "idle" | "sending" | "printing";
export type ConnectionState = "connecting" | "connected" | "disconnected";

interface PrinterContextProps {
  client: NiimbotAbstractClient | null;
  connectionType: ConnectionType | null;
  endPrint: () => void;
  initPrinter: (value: ConnectionType) => void;
  onConnectClicked: () => void;
  onDisconnectClicked: () => void;
  onPrint: ({
    canvas,
    quantity,
    printDirection,
  }: {
    canvas: HTMLCanvasElement | null;
    quantity?: number;
    printDirection?: PrintDirection;
  }) => void;
  printProgress: number;
  printState: PrintState;
  connectionState: ConnectionState;
}

const defaultContextProps: PrinterContextProps = {
  client: null,
  endPrint: () => {},
  connectionType: null,
  initPrinter: (value: ConnectionType) => {},
  onConnectClicked: () => {},
  onDisconnectClicked: () => {},
  onPrint: () => {},
  printProgress: 0,
  printState: "idle",
  connectionState: "disconnected",
};

const PrinterContext = createContext<PrinterContextProps>(defaultContextProps);

export default function PrinterProvider(props: any) {
  const [client, setClient] = useState<NiimbotAbstractClient | null>(null);
  const [connectionType, setConnectionType] = useState<ConnectionType | null>(
    null
  );
  const [printProgress, setPrintProgress] = useState<number>(0);
  const [printState, setPrintState] = useState<PrintState>("idle");
  const [connectionState, setConnectionState] =
    useState<ConnectionState>("disconnected");

  const setupEventListeners = useCallback(() => {
    if (client) {
      client.addEventListener("connect", () => {
        const printerModel = client.getModelMetadata()?.model;
        toast.info(`Imprimante ${printerModel} connectée avec succès.`);
        setConnectionState("connected");
      });

      client.addEventListener("disconnect", () => {
        toast.info("Imprimante déconnectée avec succès.");
        setConnectionState("disconnected");
        setConnectionType(null);
      });

      client.addEventListener("heartbeatfailed", (e: HeartbeatFailedEvent) => {
        const maxFails = 5;

        if (e.failedAttempts >= maxFails) {
          toast.error("connector.disconnect.heartbeat");
          client.disconnect();
        }
      });
    }
  }, [client]);

  const initPrinter = async (connectionType: ConnectionType) => {
    if (client) {
      client.disconnect();
      setConnectionState("disconnected");
    }
    setClient(
      connectionType === "bluetooth"
        ? new NiimbotBluetoothClient()
        : new NiimbotSerialClient()
    );
    setConnectionType(connectionType);
    setupEventListeners();
  };

  const onConnectClicked = async () => {
    if (!client) {
      return toast.error("Choisissez une méthode de connexion");
    }
    setConnectionState("connecting");
    try {
      await client.connect();
      setConnectionState("connected");
    } catch (e: any) {
      toast.error(e.message);
      setConnectionState("disconnected");
      setConnectionType(null);
    }
  };

  const onDisconnectClicked = async () => {
    if (!client) {
      return toast.error("Error printer client is not setup.");
    }
    client.disconnect();
    setConnectionState("disconnected");
    setConnectionType(null);
  };

  const endPrint = async () => {
    if (!client) {
      return;
    }
    await client.abstraction.printEnd();
    client.startHeartbeat();

    setPrintState("idle");
    setPrintProgress(0);
  };

  const onPrint = async ({
    canvas,
    quantity = 1,
    printDirection = "top",
  }: {
    canvas: HTMLCanvasElement | null;
    quantity?: number;
    printDirection?: PrintDirection;
  }) => {
    if (!client || !canvas || printState !== "idle") {
      return;
    }

    setPrintState("sending");
    client.stopHeartbeat();
    const printTaskName = client.getPrintTaskType() ?? "D110";
    const printTask = client.abstraction.newPrintTask(printTaskName, {
      totalPages: quantity,
      statusPollIntervalMs: 100,
      statusTimeoutMs: 8_000,
    });
    const encoded: EncodedImage = ImageEncoder.encodeCanvas(
      canvas,
      printDirection
    );

    try {
      await printTask.printInit();
      await printTask.printPage(encoded, quantity);
    } catch (e: any) {
      toast.error(e.message || "Erreur lors de l'impression.");
      client.startHeartbeat();
      setPrintState("idle");
      setPrintProgress(0);
    }

    setPrintState("printing");

    const listener = (e: PrintProgressEvent) => {
      setPrintProgress(e.pagePrintProgress);
    };

    client.addEventListener("printprogress", listener);

    try {
      await printTask.waitForFinished();
    } catch (e) {
      console.error(e);
    }

    client.removeEventListener("printprogress", listener);

    await endPrint();
  };

  return (
    <PrinterContext.Provider
      value={{
        client,
        connectionType,
        connectionState,
        endPrint,
        initPrinter,
        onConnectClicked,
        onDisconnectClicked,
        onPrint,
        printProgress,
        printState,
      }}
    >
      {props.children}
    </PrinterContext.Provider>
  );
}

export function usePrinter() {
  const context = useContext(PrinterContext);

  if (context === undefined) {
    throw new Error("usePrinter must be used within a PrinterProvider");
  }
  return context;
}
