import style from "../index.module.scss";

import { PieChart } from "@mui/x-charts/PieChart";
import { useQuery } from "react-query";
import {
  getOperationsMonthlyChart,
  getProductsChart,
  getProductsMonthlyChart,
} from "../../../requests/reports";
import { useState } from "react";
import moment from "moment";
import {
  MakeOptional,
  SeriesValueFormatterContext,
} from "@mui/x-charts/internals";
import { BarChart, PieValueType } from "@mui/x-charts";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import translate from "../../../translation";

function ProductPieChart({
  dateFrom,
  dateTo,
  compareDateFrom,
  compareDateTo,
}: {
  dateFrom: Date;
  dateTo: Date;
  compareDateFrom?: Date;
  compareDateTo?: Date;
}) {
  const { data: primaryData, isLoading: primaryDataIsLoading } = useQuery({
    queryKey: [
      "product-pie-chart",
      {
        dateFrom,
        dateTo,
        eventDescription: "BOOKING_PAYMENT_RECEIVED",
        eventService: "ms-accounting",
        field: "count",
      },
    ],
    queryFn: getProductsChart,
    refetchOnWindowFocus: false,
  });

  return (
    <PieChart
      series={[
        {
          data: primaryData?.data || [],
          highlightScope: { fade: "global", highlight: "item" },
          faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
          innerRadius: 30,
          outerRadius: 100,
          paddingAngle: 5,
          cornerRadius: 5,
        },
      ]}
      width={800}
      height={200}
    />
  );
}

function MonthlyOperationsBarChart({
  year,
  valueKey,
}: {
  year: string;
  valueKey: string;
}) {
  const { data: primaryData, isLoading: primaryDataIsLoading } = useQuery({
    queryKey: [
      "monthly-operation-chart",
      {
        year,
        valueKey,
      },
    ],
    queryFn: getOperationsMonthlyChart,
    refetchOnWindowFocus: false,
  });

  function valueFormatter(value: number | null) {
    if (valueKey === "price") {
      return `${(Math.round((value || 0) * 100) / 100).toFixed(2)} €`;
    }
    return `${value}`;
  }

  const chartSetting = {
    yAxis: [
      {
        label: valueKey === "price" ? "Montant payé (TTC)" : "Total",
      },
    ],
    width: 1400,
    height: 600,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-40px, 0)",
      },
    },
  };

  return (
    <>
      <BarChart
        dataset={primaryData?.data || ([] as any)}
        xAxis={[{ scaleType: "band", dataKey: "month" }]}
        series={(primaryData?.data || [])
          .map((data) =>
            Object.entries(data)
              .filter(([key, value]) => value > 0)
              .map(([key, value]) => {
                return {
                  dataKey: key,
                  label: translate(key),
                  valueFormatter,
                };
              })
              .filter((d) => d.dataKey !== "month")
          )
          .flat()
          .filter(
            (d, i, self) => i === self.findIndex((t) => t.dataKey === d.dataKey)
          )}
        {...chartSetting}
      />
    </>
  );
}

function MonthlyProductsBarChart({
  year,
  valueKey,
}: {
  year: string;
  valueKey: string;
}) {
  const { data: primaryData, isLoading: primaryDataIsLoading } = useQuery({
    queryKey: [
      "monthly-products-chart",
      {
        year,
        valueKey,
      },
    ],
    queryFn: getProductsMonthlyChart,
    refetchOnWindowFocus: false,
  });

  function valueFormatter(value: number | null) {
    if (valueKey === "price") {
      return `${(Math.round((value || 0) * 100) / 100).toFixed(2)} €`;
    }
    return `${value}`;
  }

  const chartSetting = {
    yAxis: [
      {
        label: valueKey === "price" ? "Montant payé (TTC)" : "Total",
      },
    ],
    width: 1400,
    height: 600,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-40px, 0)",
      },
    },
  };

  return (
    <>
      <BarChart
        dataset={primaryData?.data || ([] as any)}
        xAxis={[{ scaleType: "band", dataKey: "month" }]}
        series={[
          { dataKey: "shoes_man", label: "Homme", valueFormatter },
          { dataKey: "shoes_woman", label: "Femme", valueFormatter },
          { dataKey: "shoes_sneakers", label: "Sneakers", valueFormatter },
          { dataKey: "shoes_climbing", label: "Escalade", valueFormatter },
          { dataKey: "leather_large", label: "Maroquinerie", valueFormatter },
        ]}
        {...chartSetting}
      />
    </>
  );
}

function formatDate(date: string | undefined) {
  return date !== ""
    ? date && new Date(date).toISOString().substring(0, 10)
    : "";
}

function DateRange({
  dateFrom,
  dateTo,
  onChange,
  title,
  disabled,
}: {
  dateFrom: Date;
  dateTo: Date;
  onChange: ({ dateFrom, dateTo }: { dateFrom: Date; dateTo: Date }) => void;
  title?: string;
  disabled?: boolean;
}) {
  return (
    <div className={style["date-range-picker-container"]}>
      <div className={style["date-range-picker-title"]}>{title || ""}</div>
      <div className={style["shipping-date"]}>
        <label>Du</label>
        <input
          disabled={disabled || false}
          name={"dateFrom"}
          type="date"
          lang="fr-FR"
          value={formatDate(dateFrom.toISOString())}
          onChange={handleChange}
          className={style["date-input"]}
        />
      </div>
      <div className={style["shipping-date"]}>
        <label>Au</label>
        <input
          disabled={true}
          name={"dateTo"}
          type="date"
          lang="fr-FR"
          value={formatDate(dateTo.toISOString())}
          onChange={handleChange}
          className={style["date-input"]}
        />
      </div>
    </div>
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    let dateRange = { dateFrom, dateTo };
    dateRange[event.target.name as keyof typeof dateRange] = new Date(
      event.target.value
    );
    onChange && onChange(dateRange);
  }
}

function getFullMonth(referenceDate: Date) {
  let d = moment(referenceDate);
  //   if (d.endOf("week").isAfter(moment())) {
  //     d = d.subtract(1, "week");
  //   }

  return {
    dateFrom: d.startOf("month").toDate(),
    dateTo: d.endOf("month").toDate(),
  };
}

function getFullWeekBefore(referenceDate: Date) {
  let d = moment(referenceDate);
  if (d.endOf("week").isAfter(moment())) {
    d = d.subtract(1, "week");
  }

  d = d.subtract(1, "week");

  return {
    dateFrom: d.startOf("week").toDate(),
    dateTo: d.endOf("week").toDate(),
  };
}

export default function Charts() {
  return (
    <div className={style["container"]}>
      <div className={style["tables-container"]}>
        <MonthlyOperationsBarChart year="2024" valueKey={"count"} />
      </div>
      <div className={style["tables-container"]}>
        <MonthlyOperationsBarChart year="2024" valueKey={"price"} />
      </div>
      <div className={style["tables-container"]}>
        <MonthlyProductsBarChart year="2024" valueKey={"count"} />
      </div>
      <div className={style["tables-container"]}>
        <MonthlyProductsBarChart year="2024" valueKey={"price"} />
      </div>
    </div>
  );
}
