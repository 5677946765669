import { IOpenModal } from "../..";
import AllDayShippingModal from "./allDayShippingModal";
import ExternalShippingModal from "./externalShippingModal";
import ShippingModal from "./shippingModal";

export default function ShippingModalSelect({
  openModal,
}: // setOpenModal,
{
  openModal: IOpenModal;
  // setOpenModal: (modal: IOpenModal) => void;
}) {
  if (openModal.isExternal) {
    return <ExternalShippingModal openModal={openModal} />;
  } else if (openModal.isAllDay) {
    return (
      <AllDayShippingModal openModal={openModal} />
    );
  }
  return <ShippingModal openModal={openModal} />;
}
