import { IProduct } from "../../../../../types/logistic.types";
import { ModalProductMiniatureV2 } from "../../ModalProductMiniature/ModalProductMiniatureV2";
import CrossSellMiniature from "../ModalCrossSellProductList/ModalCrossSellMiniature/ModalCrossSellMiniature";
import styles from "./modalProductList.module.scss";

interface ModalProductListProps {
  handleSelectProduct: (product: IProduct) => void;
  selectedId?: string;
  productsList: IProduct[];
}

export default function ModalProductList({
  handleSelectProduct,
  selectedId,
  productsList,
}: ModalProductListProps) {
  return (
    <>
      {productsList.map((product: IProduct, index: number) => {
        return (
          <ModalProductMiniatureV2
            key={index}
            className={styles["product-miniature-container"]}
            isSelected={selectedId === product.id}
            product={product}
            handleOnClick={() => handleSelectProduct(product)}
          />
        );
      })}
    </>
  );
}
