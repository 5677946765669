import { LoginForm } from "../routes/login";
import { IUserData } from "../types/user.types";

export interface LoginResponse {
  roles: string[];
  uid: string;
  username: string;
}

export async function loginRequest(
  loginForm: LoginForm
): Promise<LoginResponse> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/login`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        body: JSON.stringify(loginForm),
      }
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function logoutRequest(
  signal?: AbortSignal
): Promise<{ id: string }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/logout`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        signal: signal,
      }
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getUserList(signal?: AbortSignal): Promise<IUserData[]> {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/user/read`, {
      headers: new Headers({ "Content-Type": "application/json" }),
      credentials: "include",
      signal: signal,
    });
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getUser({
  // request,
  params,
}: {
  // request: Request;
  params: any;
}): Promise<IUserData> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/user/read/${params.id}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "include",
        // signal: request?.signal,
      }
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function deleteUser(
  id: string,
  signal?: AbortSignal
): Promise<void | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/user/${id}`,
      {
        credentials: "include",
        method: "DELETE",
        signal: signal,
      }
    );
    if (response.status !== 200) {
      if (response.status === 301) {
        return null;
      }
      throw new Response(JSON.stringify(response), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function addUser(
  data: IUserData,
  signal?: AbortSignal
): Promise<void | null | string> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/user/create`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "include",
        method: "POST",
        signal: signal,
        body: JSON.stringify(data),
      }
    );
    if (response.status !== 200) {
      if (response.status === 301) {
        return null;
      }
      if (response.status === 409) {
        return "Nom d'utilisateur déjà existant";
      }
      if (response.status === 400) {
        return "Champs manquants";
      }
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateUser(
  data: IUserData,
  id: string,
  signal?: AbortSignal
): Promise<void | null | string> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/user/${id}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "include",
        method: "PUT",
        signal: signal,
        body: JSON.stringify(data),
      }
    );
    if (response.status !== 200) {
      if (response.status === 301) {
        return null;
      }
      if (response.status === 409) {
        return "Nom d'utilisateur déjà existant";
      }
      if (response.status === 400) {
        return "Champs manquants";
      }
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function isLogged() {
  try {
    const connected = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/logged`,
      { credentials: "include" }
    );
    return connected;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
