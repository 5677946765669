import {
  CancelOutlined,
  CheckCircleOutline,
  Delete,
} from "@mui/icons-material";
import {
  ICrossSell,
  ICrossSellQuote,
} from "../../../../types/accounting.types";
import style from "./crossSellItem.module.scss";
import ColorComponent from "../../../colorComponent/colorComponent";
import { ICrossSellItem } from "../../../../types/logistic.types";

interface CrossSellItemProps {
  crossSell: ICrossSellQuote;
  handleDelete: () => void;
  handleSetCrossSellItemState?: () => void;
}

export default function CrossSellItem({
  crossSell,
  handleDelete,
  handleSetCrossSellItemState,
}: CrossSellItemProps) {
  return (
    <li className={style["cross-selling-products-item"]}>
      <div className={style["description"]}>
        {crossSell.description}
        {crossSell.color && (
          <ColorComponent
            color={crossSell.color}
            className={style["color-component"]}
          />
        )}
      </div>
      {!!handleSetCrossSellItemState ? (
        <div className={style["state"]} onClick={handleSetCrossSellItemState}>
          {(crossSell as ICrossSellItem).isItemReady ? (
            <CheckCircleOutline className={style["ready-icon"]} />
          ) : (
            <CancelOutlined className={style["not-ready-icon"]} />
          )}
        </div>
      ) : (
        <div className={style["price"]}>
          {((crossSell.price || 0) * crossSell.cartQuantity) / 100} €
        </div>
      )}
      {crossSell.cartQuantity > 1 && (
        <div className={style["cart-quantity"]}>
          (x{crossSell.cartQuantity})
        </div>
      )}
      <div className={style["dots"]}></div>
      <div className={style["delete"]}>
        <div className={style["delete-button"]} onClick={handleDelete}>
          <Delete />
        </div>
      </div>
    </li>
  );
}
