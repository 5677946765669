import { MoveDown } from "@mui/icons-material";
import Widget from "../../newWidget/widget";
import style from "../incomingShippingWidget/incomingShippingWidget.module.scss";

export default function LogisticWidget() {
  return (
    <Widget to={"move-product"}>
      <div className={style["icon-container"]}>
        <MoveDown className={style["icon"]} />
      </div>
      <div>Déplacer articles</div>
    </Widget>
  );
}
