import { IShippingPro } from "../types/shipping.types";
import { INITIAL_SHIPPING_ADDRESS } from "./shipping.init";

export const INITIAL_SHIPPING_PRO: IShippingPro = {
  sender: INITIAL_SHIPPING_ADDRESS,
  recipient: INITIAL_SHIPPING_ADDRESS,
  direction: "INCOMING",
  period: {
    start: null,
    end: null,
  },
  shippingMethod: {
    shippingService: "",
    price: 0,
    name: "",
    priceNoTax: 0,
    businessHours: [],
    freeShippingMinimumAmount: 0,
  },
  externalShipping: null,
  state: "PENDING",
  shippingDate: "",
};
