import { useQuery } from "react-query";
import CartMiniature from "../../../../components/miniature/cartMiniature/cartMiniature";
import EstimationMiniature from "../../../../components/miniature/estimationMiniature/estimationMiniature";
import LogisticMiniature from "../../../../components/miniature/logisticMiniature/logisticMiniature";
import QuoteMiniature from "../../../../components/miniature/quoteMiniature/quoteMiniature";
import ShippingMiniature from "../../../../components/miniature/shippingMiniature/shippingMiniature";
import { ITracker } from "../../../../types/tracker.types";
import styles from "./overviewWidgets.module.scss";
import { fetchLogistic } from "../../../../requests/logistic";
import { useEffect, useState } from "react";

interface OverviewWidgetsProps {
  tracker: ITracker;
}

export default function OverviewWidgets({ tracker }: OverviewWidgetsProps) {
  const { data: logistic, refetch } = useQuery(
    ["overview-logistic-document", tracker],
    () => fetchLogistic({ params: { id: tracker?.logistic.documentId || "" } }),
    { refetchOnWindowFocus: false }
  );
  const [logisticRequiredAction, setLogisticRequiredAction] =
    useState<boolean>(false);
  const [quoteRequiredAction, setQuoteRequiredAction] =
    useState<boolean>(false);

  useEffect(() => {
    setLogisticRequiredAction(logisticDocumentNeedRequiredAction());
  }, [logistic]);

  useEffect(() => {
    setQuoteRequiredAction(quoteDocumentNeedRequiredAction());
  }, [tracker]);

  if (!tracker) {
    return <></>;
  }

  return (
    <div className={styles["overview-widgets-container"]}>
      <div className={styles["overview-widgets"]}>
        <EstimationMiniature data={tracker.estimate} />
        <CartMiniature data={tracker.cart} trackId={tracker.id} />
        <ShippingMiniature
          trackerId={tracker.id}
          isCollect={true}
          data={tracker.incomingShipping}
          contact={tracker.contact}
        />
        <LogisticMiniature
          refetch={refetch}
          trackerId={tracker.id}
          data={tracker.logistic}
          requiredActions={logisticRequiredAction}
          dueDate={tracker?.outgoingShipping?.period?.start}
        />
        <QuoteMiniature
          trackerId={tracker.id}
          quote={tracker.quote}
          invoice={tracker.invoice}
          requiredActions={quoteRequiredAction}
        />
        <ShippingMiniature
          trackerId={tracker.id}
          isCollect={false}
          data={tracker.outgoingShipping}
          contact={tracker.contact}
        />
      </div>
    </div>
  );

  function quoteDocumentNeedRequiredAction() {
    const PAYED_BUT_NO_INVOICE = tracker?.payment && !tracker.invoice;
    return PAYED_BUT_NO_INVOICE;
  }

  function logisticDocumentNeedRequiredAction() {
    if (
      tracker?.quote?.state === "QUOTE_VALIDATED" &&
      (tracker?.logistic?.state === "LOGISTIC_QUOTE_SENT" ||
        tracker?.logistic?.state === "LOGISTIC_INCOMING")
    ) {
      return true;
    }
    if (
      logistic?.crossSell &&
      logistic?.crossSell.crossSellItems &&
      logistic?.crossSell.crossSellItems.length > 0
    ) {
      if (!logistic.crossSell.workshopId) {
        return true;
      }
    }
    return false;
  }
}
