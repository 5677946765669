import { useParams } from "react-router-dom";
import Create from "../create/create";
import useLogout from "../../../hooks/useLogout";
import { useQuery } from "react-query";
import { fetchCart } from "../../../requests/professionalCarts";
import { fetchCustomer } from "../../../requests/customer";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import style from "./edit.module.scss";

export default function Edit() {
  const { id } = useParams();
  const { data, isFetching, error, refetch } = useQuery(
    "customer",
    () => fetchCustomer(id),
    { refetchOnWindowFocus: false }
  );

  useLogout(data, isFetching);

  return (
    <>
      {!isFetching ? (
        <>{data && <Create data={data} />}</>
      ) : (
        <div className={style["loading-spinner-container"]}>
          <LoadingSpinner color="gold" />
        </div>
      )}
    </>
  );
}
