import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import styles from "./index.module.scss";
import { useQuery } from "react-query";
import { deleteCart, getCartsList } from "../../requests/professionalCarts";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import Item from "./item/item";
import { CART_STATE } from "../../utils/booking.init";
import { queryObject } from "../../types/query.types";
import SearchByKeyComponent from "../../components/searchByKeyComponent/searchByKeyComponent";
import FilterByStatus from "../../components/filterByStatus/filterByStatus";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import { IShop } from "../../types/shop.types";
import SelectAndSearchShop from "../../components/SelectAndSearchShop/SelectAndSearchShop";
import useSaveQueryStorage from "../../hooks/useSaveQueryStorage";
import Button from "../../components/Button/Button";
import ListPageHeader from "../referral/ListPageHeader/ListPageHeader";
import FiltersContainer from "../../components/Filters/FiltersContainer";
import ListHeader from "./ListHeader/ListHeader";

export const INITIAL_QUERY: queryObject = {
  field: "productsList.workshopId",
  value: "",
  service: "",
  direction: "",
  status: "Tous",
  limit: 10,
  offset: 0,
  sort: "",
  dateFrom: "",
  dateTo: "",
  state: "",
  shopId: "",
};

const CART_SEARCH_OPTIONS = [
  "productsList.workshopId",
  "contact.displayname",
  "contact.phone",
  "contact.organization",
  "contact.email",
];
let controller = new AbortController();

export default function ProfessionalCarts() {
  const [searchParams] = useSearchParams();
  const SHOP_ID = searchParams.get("shopId");
  const shopName = searchParams.get("shopName");
  const [query, setQuery] = useSaveQueryStorage(INITIAL_QUERY, "pro-cart");
  const [shop, setShop] = useState<IShop | null>(null);
  const { data, isLoading, error, refetch } = useQuery(
    ["professionalCart", query, SHOP_ID, shop],
    () => getCartsList(formatedQuery()),
    { keepPreviousData: true }
  );

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  // if (!data || !data.cartsList) {
  //   return <>Error data is missing !</>;
  // }

  return (
    <div className={styles["list-page"]}>
      <ListPageHeader
        title={`Demandes Pro ${shopName ? shopName : ""}`}
        actionSection={
          !!SHOP_ID ? (
            <Link
              onClick={() => window.scrollTo(0, 0)}
              to={`./create/${SHOP_ID}`}
            >
              <Button>+ Ajouter nouveau</Button>
            </Link>
          ) : undefined
        }
      />
      <FiltersContainer>
        <SelectAndSearchShop shop={shop} setShop={setShop} />
        <SearchByKeyComponent
          query={query}
          setQuery={setQuery}
          optionsList={CART_SEARCH_OPTIONS}
        />
        <FilterByStatus
          query={query}
          setQuery={setQuery}
          optionsList={CART_STATE}
          field={"state"}
        />
      </FiltersContainer>
      <div className={styles["list-container"]}>
        <div className={styles["paging-component-top"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
        <div className={styles["list"]}>
          <table className={styles["table"]}>
            <ListHeader />
            <tbody>
              {data?.cartsList.map((value: any, index: number) => {
                return (
                  <Item key={index} data={value} onDelete={handleDelete} />
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={styles["paging-component"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
      </div>
    </div>
  );

  async function handleDelete(id?: string) {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette commande ?")) {
      controller.abort();
      try {
        controller = new AbortController();
        await deleteCart(id, controller.signal);
        refetch();
      } catch (error) {
        console.error(error);
      }
    }
  }

  function formatedQuery() {
    let formatedQuery: string = "";

    if (query.limit) {
      formatedQuery += `?limit=${query.limit}`;
    }

    formatedQuery += `&offset=${query.offset * query.limit}`;

    if (query.field && query.value) {
      formatedQuery += `&${query.field}=${query.value}`;
    }

    if (query.state && query.state !== "Tous") {
      formatedQuery += `&state=${query.state}`;
    }
    if (SHOP_ID) {
      formatedQuery += `&shopId=${SHOP_ID}`;
    } else if (shop) {
      formatedQuery += `&shopId=${shop.id}`;
    }

    if (!(query.dateFrom === "" && query.dateTo === "")) {
      formatedQuery =
        formatedQuery +
        `&createdAt=` +
        query.dateFrom +
        `&createdAt=` +
        query.dateTo;
    }

    formatedQuery += `&sort_field=createdAt&sort_direction=desc`;

    return formatedQuery;
  }
}
