import { useLoaderData } from "react-router-dom";
import { ILogistic } from "../../../types/logistic.types";
import style from "./generate.module.scss";
import Create from "../create/create";
import { fetchLogistic } from "../../../requests/logistic";
import useLogout from "../../../hooks/useLogout";
import { loadLogistic } from "../../../requests/tracker";
import { authloader } from "../..";

export async function loader({ params }: { params: any }) {
  try {
    const { logistic } = await loadLogistic({ params });
    authloader();
    return logistic;
  } catch (error: any) {
    throw error;
  }
}

export default function Generate() {
  const data: ILogistic | null = useLoaderData() as ILogistic;

  useLogout(data);
  return (
    <div className={style.editPage}>{data && <Create logistic={data} />}</div>
  );
}
