import styles from "./DashboardWidgetHeader.module.scss";

interface DashboardWidgetHeaderProps {
  title: string;
  totalCount: number;
}

export default function DashboardWidgetHeader({
  title,
  totalCount,
}: DashboardWidgetHeaderProps) {
  return (
    <div className={styles["dashboard-widget-header"]}>
      <p>{title}</p>
      <p>{totalCount}</p>
    </div>
  );
}
