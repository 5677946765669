import { useQuery } from "react-query";
import { IShop, IShopOperation } from "../../../types/shop.types";
import style from "./pricingModal.module.scss";
import LoadingSpinner from "../../loadingSpinner/loadingSpinner";
import { IOperation } from "../../../types/accounting.types";
import { queryObject } from "../../../types/query.types";
import React, { useMemo, useState } from "react";
import { OPERATION_GROUP } from "../../../utils/logistic.init";
import { FilterAltOff } from "@mui/icons-material";
import { useModalComponent } from "../../../context/modalComponent.context";
import { getOperationsListPro } from "../../../requests/operationPro";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../../../utils/toast.options";
import Select from "../../Select/Select";
import translate from "../../../translation";
import OperationItem from "./operationItem/OperationItem";
import Input from "../../inputs/Input/Input";

const INITIAL_QUERY: queryObject = {
  limit: 10,
  offset: 0,
  sort: "",
  operationGroup: "",
  field: "",
  value: "",
};

interface PricingModalProps {
  className?: string;
  shopForm: IShop;
  setShopForm: (value: IShop) => void;
  displayValidateButton?: boolean;
}

export default function PricingModal({
  className,
  shopForm,
  setShopForm,
  displayValidateButton = true,
}: PricingModalProps) {
  const [defaultProDiscount, setDefaultProDiscount] = useState<number>(10);
  const [query, setQuery] = useState<queryObject>(INITIAL_QUERY);
  const { data, isFetching } = useQuery(
    ["operation-list", query],
    () => getOperationsListPro(formatedQuery()),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      select(data) {
        return {
          operations: data.operations.map((op) => {
            let shopOperation = shopForm.pricing.operationsList.find(
              (o) => o.slug === op.slug
            );
            if (!shopOperation) {
              return {
                ...op,
                isSelected: false,
                professionalDiscount: 0,
                finalPrice: op.price,
              };
            }
            return {
              ...op,
              isSelected: true,
              ...shopOperation,
            };
          }),
        };
      },
    }
  );
  const sortedOperation = useMemo(() => {
    if (data) {
      return data.operations.sort(sortByOperationInShopFirst);
    }
  }, [data]);

  return (
    <div className={`${style["pricing-modal"]} ${className}`}>
      <div className={style["pricing-modal-container"]}>
        <div className={style["title"]}>
          Choisissez les opérations
          <span>{`(${shopForm.pricing.operationsList.length} sélectionnés)`}</span>
        </div>

        <div className={style["search-section"]}>
          {/* <div className={style["search-section-top"]}>
            <div>
              <h3 style={{ marginTop: "0" }}>
                Sélectionner toute la catégorie
              </h3>
              <div className={style["operation-group-select"]}>
                {OPERATION_GROUP.map((op) => {
                  return (
                    <div key={op} className={style["operation-checkbox"]}>
                      <input
                        id={op}
                        type="checkbox"
                        name={op}
                        onChange={handleSelectOperationGroup}
                      />
                      <label
                        className={style["operation-checkbox-label"]}
                        htmlFor={op}
                      >
                        {translate(op)}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={style["filter-section"]}>
              {query.operationGroup && (
                <div className={style["reset-filters"]} onClick={resetQuery}>
                  <FilterAltOff />
                </div>
              )}
              <Select
                optionsList={OPERATION_GROUP}
                placeholder="filtrer par..."
                value={query.operationGroup || ""}
                setValue={handleSelectFilter}
              />
            </div>
          </div> */}
          <div className={style["default-pro-discount-input"]}>
            <Input
              label="Réduction globale en (%)"
              type="text"
              value={defaultProDiscount}
              onChange={handleChangeDefaultProDiscount}
            />
          </div>
        </div>
        {/* <div className={style["operations-list"]}>
          {isFetching ? (
            <div className={style["loading-spinner-container"]}>
              <LoadingSpinner color="gold" />
            </div>
          ) : (
            <div className={style["operations-list-container"]}>
              {sortedOperation &&
                sortedOperation.map((operation: IOperation, index: number) => {
                  return (
                    <OperationItem
                      defaultProDiscount={defaultProDiscount}
                      handleChangeProfessionalDiscount={
                        handleChangeProfessionalDiscount
                      }
                      handleClickOperation={handleClickOperation}
                      key={index}
                      operation={operation}
                      operationList={shopForm.pricing.operationsList}
                    />
                  );
                })}
            </div>
          )}
        </div> */}

        <div className={style["price-grid"]}>
          {/* <div className={style["grid-row"]}> */}
          {/* <> */}

          {/* </>
          </div> */}
          {OPERATION_GROUP.map((opGroup) => (
            <React.Fragment key={opGroup}>
              <h3 className={style["grid-line"]}>{translate(opGroup)}</h3>
              <div className={style["grid-item"]}>
                {/* <input type="checkbox" /> */}
                {/* <span></span> */}
                <input
                  id={opGroup}
                  type="checkbox"
                  name={opGroup}
                  onChange={handleSelectOperationGroup}
                />
              </div>
              <div className={style["grid-item"]}>
                <span>Description</span>
              </div>
              <div className={style["grid-item"]}>
                <span>Prix B2C</span>
              </div>
              <div className={style["grid-item"]}>
                <span>Remise B2B (%)</span>
              </div>
              <div className={style["grid-item"]}>
                <span>Prix B2B</span>
              </div>
              <div className={style["grid-item"]}>
                <span>Prix B2B (HT)</span>
              </div>
              {sortedOperation &&
                sortedOperation
                  .filter((operation) => operation.operationGroup === opGroup)
                  .map(
                    (
                      operation: IOperation & {
                        finalPrice?: number;
                        isSelected: boolean;
                      },
                      index: number
                    ) => (
                      // <div className={style["grid-row"]}>
                      <React.Fragment key={operation.slug}>
                        <div className={style["grid-item"]}>
                          <input
                            type="checkbox"
                            checked={isSelected(operation.slug) !== undefined}
                            onChange={() => handleClickOperation(operation)}
                          />
                        </div>
                        <div className={style["grid-item"]}>
                          {operation.description}
                        </div>
                        <div className={style["grid-item"]}>
                          {(operation.price || 0) / 100} €
                        </div>
                        <div className={style["grid-item"]}>
                          <input
                            className={style["number-input"]}
                            id={`${opGroup}-discount-input-${index}`}
                            type="number"
                            value={(operation.professionalDiscount || 0) / 1000}
                            onWheel={(e) => (e.target as HTMLElement).blur()}
                            onChange={(e) => {
                              e.stopPropagation();
                              handleChangeProfessionalDiscount(
                                e.target.value,
                                operation
                              );
                            }}
                            onPaste={(e) => {
                              let data = e.clipboardData
                                .getData("text")
                                .split("\n");

                              let nextData = [...data].splice(1).join("\n");

                              navigator.clipboard.writeText(nextData);

                              e.stopPropagation();
                              e.preventDefault();
                              handleChangeProfessionalDiscount(
                                data[0],
                                operation
                              );
                              document
                                .getElementById(
                                  `${opGroup}-discount-input-${index + 1}`
                                )
                                ?.focus();
                            }}
                            onKeyDown={(e) => {
                              console.debug(e.key);
                              if (e.key === "ArrowDown" || e.key === "Tab") {
                                e.preventDefault();
                                document
                                  .getElementById(
                                    `${opGroup}-discount-input-${index + 1}`
                                  )
                                  ?.focus();
                              }
                              if (e.key === "ArrowUp") {
                                e.preventDefault();
                                document
                                  .getElementById(
                                    `${opGroup}-discount-input-${index - 1}`
                                  )
                                  ?.focus();
                              }
                            }}
                          />
                        </div>
                        <div className={style["grid-item"]}>
                          {/* <span>{operation.finalPrice || 0} €</span> */}
                          <input
                            className={style["number-input"]}
                            type="number"
                            value={(operation.finalPrice || 0) / 100}
                            onChange={(e) => handlePriceChange(e, operation)}
                          />
                        </div>

                        <div className={style["grid-item"]}>
                          <span>
                            {Math.round((operation.finalPrice || 0) / 1.2) /
                              100}{" "}
                            €
                          </span>
                        </div>
                      </React.Fragment>
                      // </div>
                    )
                  )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );

  function handleChangeDefaultProDiscount({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    setDefaultProDiscount(parseFloat(target.value));
    setShopForm({
      ...shopForm,
      pricing: {
        operationsList: shopForm.pricing.operationsList.map((op) => {
          return formatOperationFromProfessionalDiscount(
            parseFloat(target.value),
            op
          );
        }),
      },
    });
  }

  function handleSelectOperationGroup({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    if (!data || !data.operations) {
      return;
    }
    let filteredOperationList = data.operations.filter(
      (op) => op.operationGroup === target.name
    );
    filteredOperationList = filteredOperationList.filter(
      (op) =>
        !shopForm.pricing.operationsList.find(
          (shopOp) => shopOp.slug === op.slug
        )
    );
    const formatedFilteredOperationList = filteredOperationList.map((op) => {
      return formatOperationFromProfessionalDiscount(defaultProDiscount, op);
    });
    if (target.checked) {
      setShopForm({
        ...shopForm,
        pricing: {
          operationsList: [
            ...shopForm.pricing.operationsList,
            ...formatedFilteredOperationList,
          ],
        },
      });
    } else {
      setShopForm({
        ...shopForm,
        pricing: {
          operationsList: shopForm.pricing.operationsList.filter(
            (op) => op.operationGroup !== target.name
          ),
        },
      });
    }
  }

  function handlePriceChange(
    e: React.ChangeEvent<HTMLInputElement>,
    operation: IOperation | IShopOperation
  ) {
    e.stopPropagation();
    const value = parseFloat(e.target.value);
    const finalPrice = !isNaN(value) ? value : 0;
    setShopForm({
      ...shopForm,
      pricing: {
        operationsList: shopForm.pricing.operationsList.map((op) => {
          return op.slug === operation.slug
            ? formatOperationFromFinalPrice(finalPrice, op)
            : op;
        }),
      },
    });
  }

  function handleChangeProfessionalDiscount(
    // e: React.ChangeEvent<HTMLInputElement>,
    newValue: string,
    operation: IOperation | IShopOperation
  ) {
    // e.stopPropagation();
    // const value = parseFloat(e.target.value);
    const value = parseFloat(newValue);
    const professionalDiscount = !isNaN(value) ? value : 0;
    setShopForm({
      ...shopForm,
      pricing: {
        operationsList: shopForm.pricing.operationsList.map((op) => {
          return op.slug === operation.slug
            ? formatOperationFromProfessionalDiscount(professionalDiscount, op)
            : op;
        }),
      },
    });
  }

  function handleClickOperation(operation: IOperation | IShopOperation) {
    const newOperationList = [...shopForm.pricing.operationsList];
    const foundIndex = shopForm.pricing.operationsList.findIndex(
      (op) => op.slug === operation.slug
    );
    if (foundIndex > -1) {
      newOperationList.splice(foundIndex, 1);
    } else {
      newOperationList.push(
        formatOperationFromProfessionalDiscount(
          operation.professionalDiscount || defaultProDiscount,
          operation
        )
      );
    }
    setShopForm({
      ...shopForm,
      pricing: {
        operationsList: newOperationList,
      },
    });
  }

  function formatOperationFromProfessionalDiscount(
    professionalDiscount: number,
    operation: IOperation | IShopOperation
  ): IShopOperation {
    if (operation.price === undefined) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
      return {};
    }
    const finalPrice = Math.round(
      operation.price - (operation.price * professionalDiscount) / 100
    );
    return {
      ...operation,
      professionalDiscount: professionalDiscount * 1000,
      shopPrice: finalPrice,
      shopPriceNoTax: finalPrice / 1.2,
      finalPrice,
      finalPriceNoTax: finalPrice / 1.2,
    };
  }

  function formatOperationFromFinalPrice(
    price: number,
    operation: IOperation | IShopOperation
  ): IShopOperation {
    if (operation.price === undefined) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
      return {};
    }
    let finalPrice = price * 100;
    const professionalDiscount =
      ((operation.price - finalPrice) / operation.price) * 100;

    return {
      ...operation,
      professionalDiscount: professionalDiscount * 1000,
      shopPrice: finalPrice,
      shopPriceNoTax: finalPrice / 1.2,
      finalPrice,
      finalPriceNoTax: finalPrice / 1.2,
    };
  }

  function isSelected(slug?: string) {
    return shopForm.pricing.operationsList.find((op) => op.slug === slug);
  }

  function handleSelectFilter(value: string) {
    setQuery((prev) => ({ ...prev, operationGroup: value }));
  }

  function resetQuery() {
    setQuery(INITIAL_QUERY);
  }

  function formatedQuery() {
    let formatedQuery: string = "";

    if (query.operationGroup) {
      formatedQuery = formatedQuery + `operationGroup=${query.operationGroup}`;
    }
    return formatedQuery;
  }

  function sortByOperationInShopFirst(a: IOperation, b: IOperation) {
    let weight = 0;

    // if (isSelected(a.slug) && !isSelected(b.slug)) {
    //   weight = -1;
    // }
    // if (!isSelected(a.slug) && isSelected(b.slug)) {
    //   weight = 1;
    // }

    // if (isSelected(a.operationGroup) && !isSelected(b.operationGroup)) {
    //   weight = -1;
    // }
    // if (!isSelected(a.operationGroup) && isSelected(b.operationGroup)) {
    //   weight = 1;
    // }

    weight = a.description.localeCompare(b.description, "fr");

    weight =
      weight +
      (a.operationGroup || "").localeCompare(b.operationGroup || "", "fr") * 2;

    return weight;
  }
}
