import { Edit } from "@mui/icons-material";
import ContactForm from "../../../../../components/forms/ContactForm/ContactForm";
import ValidateButton from "../../../../../components/validateButton/validateButton";
import { IShippingPoint } from "../../../../../types/shipping.types";
import style from "./contactSection.module.scss";
import { IContact } from "../../../../../types/logistic.types";
import { useState } from "react";
import { INITIAL_CONTACT } from "../../../../../utils/shipping.init";

interface ContactSectionProps {
  autoComplete?: boolean;
  shippingForm: IShippingPoint | null;
  setShippingForm: (shippingForm: IShippingPoint | null) => void;
  setStep: (value: number) => void;
}

export default function ContactSection({
  autoComplete = true,
  shippingForm,
  setShippingForm,
  setStep,
}: ContactSectionProps) {
  const [contactForm, setContactForm] = useState<IContact>(
    shippingForm || INITIAL_CONTACT
  );

  return (
    <>
      <ContactForm
        autoComplete={autoComplete}
        contact={contactForm}
        setContact={setContactForm}
      />
      <ValidateButton
        className={style["generate-label-button"]}
        title="Valider"
        handleOnClick={handleValidateContact}
      />
    </>
  );

  function handleValidateContact() {
    if (contactForm) {
      setShippingForm({
        ...shippingForm,
        displayname: contactForm.displayname,
        familyname: contactForm.familyname,
        givenname: contactForm.givenname,
        phone: contactForm.phone,
        email: contactForm.email,
        organization: contactForm.organization,
      });
      shippingForm?.address1 ? setStep(2) : setStep(1);
    }
  }
}
