import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function useChangePopState() {
  const location = useLocation();
  const navigate = useNavigate();

  const previousUrl = location.state?.previousUrl || "";

  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      if (previousUrl) {
        navigate(previousUrl);
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate, previousUrl]);
}
