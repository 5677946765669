import { ReportProblemOutlined } from "@mui/icons-material";
import clsx from "clsx";
import styles from "./errorIcon.module.scss";
import { useRef, useState } from "react";
import DropDownPortal from "../../../../../../../components/DropDownPortal/DropDownPortal";

interface ErrorIconProps {
  isResponsive?: boolean;
  error?: string[];
}

export default function ErrorIcon({
  isResponsive = false,
  error,
}: ErrorIconProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [showError, setShowError] = useState<boolean>(false);

  if (!error || error.length === 0) {
    return null;
  }

  return (
    <div
      className={clsx(
        styles["error-icon-container"],
        isResponsive ? styles["responsive-mobile"] : styles["responsive"],
        isResponsive && styles["error-icon-container-responsive"]
      )}
    >
      <div
        className={clsx(
          styles["error-icon"],
          isResponsive && styles["error-icon-responsive"]
        )}
        onClick={handleShowError}
        ref={ref}
      >
        <ReportProblemOutlined className={styles["icon"]} />
      </div>
      <DropDownPortal<HTMLDivElement>
        buttonRef={ref}
        className={styles["error-list-dropdown"]}
        expand={showError}
        onClickOutside={handleCloseDropDown}
      >
        <ul className={styles["error-list"]}>
          {error?.map((err, index) => {
            return <li key={index}>{err}</li>;
          })}
        </ul>
      </DropDownPortal>
    </div>
  );

  function handleShowError() {
    setShowError(!showError);
  }

  function handleCloseDropDown() {
    setShowError(false);
  }
}
