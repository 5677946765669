import { useState } from "react";
import { IShipping } from "../types/shipping.types";

export interface ISchedulerData {
  startDate: Date;
  endDate?: Date;
  title: string;
  type: string;
  service: string;
  index: number;
  id: string;
  allDay?: boolean;
  isAllDay?: boolean;
  isDraft?: boolean;
}

//
export default function schedulerDataFormatter(
  data: IShipping[]
): ISchedulerData[] {
  const schedulerData: ISchedulerData[] = [];

  data.map((value: IShipping, index: number) => {
    if (
      value.id &&
      value.period &&
      value.direction &&
      value.contact &&
      value.shippingMethod &&
      value.state !== "CANCELED"
    ) {
      if (value.shippingMethod?.shippingService !== "CHRONOPOST-2SHOP" && value.shippingMethod?.shippingService !== "CHRONOPOST-EUR") {
        if (isAllDay(value.period.start, value.period.end)) {
          if (isSame(schedulerData, value.period.start, "ALL DAY")) {
            schedulerData.push({
              startDate: formatToAllDay(value.period.start, "T05:00:00Z"),
              endDate: formatToAllDay(value.period.end, "T06:00:00Z"),
              title: `Journée | ${getNumberOfShippingAndCollect(data)}`,
              type: "ALL DAY",
              service: value.direction,
              index: index,
              id: value.id,
              isAllDay: true,
            });
          }
        } else {
          schedulerData.push({
            startDate: value.period.start,
            endDate: value.period.end,
            title: value.contact.displayname || "",
            type: `${value.direction}-${
              value.state === "DRAFT" ? "DRAFT" : "VALID"
            }`,
            service: value.shippingMethod?.shippingService,
            index: index,
            id: value.id,
          });
        }
      } else {
        if (isSame(schedulerData, value.period.start, "CHRONOPOST-2SHOP")) {
          schedulerData.push({
            startDate: value.period.start,
            endDate: value.period.end,
            title: `CHRONOPOST-2SHOP | livraisons restantes: ${getNumberOfRemainingShipping(
              data
            )}`,
            type: "CHRONOPOST-2SHOP",
            service: "CHRONOPOST-2SHOP",
            index: index,
            id: value.id,
            allDay: true,
          });
        }
      }
    }
  });
  return schedulerData;
}

function isSameDay(date1: Date, date2: Date): boolean {
  return (
    new Date(date1).getFullYear() === new Date(date2).getFullYear() &&
    new Date(date1).getMonth() === new Date(date2).getMonth() &&
    new Date(date1).getDate() === new Date(date2).getDate()
  );
}

function isAllDay(date1: Date, date2: Date): boolean {
  return (
    new Date(date1).toLocaleTimeString("fr-FR").substring(0, 5) +
      " - " +
      new Date(date2).toLocaleTimeString("fr-FR").substring(0, 5) ===
    "08:00 - 20:00"
  );
}

function isSame(data: ISchedulerData[], date: Date, type: string): boolean {
  for (var index = 0; index < data.length; index++) {
    if (
      isSameDay(data[index].startDate, date) &&
      (type !== "month" ? data[index].type === type : 1)
    )
      return false;
  }
  return true;
}

function formatToAllDay(date: Date, time: string): Date {
  const newDate = new Date(date).toISOString();
  const res = newDate.substring(0, newDate.indexOf("T")) + time;
  return new Date(res);
}

function getNumberOfRemainingShipping(data: IShipping[]): string {
  const pending = data.filter(
    (value: IShipping) =>
      value.shippingMethod &&
      value.shippingMethod.shippingService === "CHRONOPOST-2SHOP" &&
      value.state === "PENDING" &&
      value.direction === "OUTGOING"
  ).length;
  return pending.toString();
}

function getNumberOfShippingAndCollect(data: IShipping[]): string {
  const incoming = data.filter(
    (value: IShipping) =>
      value.period &&
      isAllDay(value.period.start, value.period.end) &&
      value.direction === "INCOMING"
  ).length;
  const outgoing = data.filter(
    (value: IShipping) =>
      value.period &&
      isAllDay(value.period.start, value.period.end) &&
      value.direction === "OUTGOING"
  ).length;

  return `Livraison (${outgoing}) Collecte (${incoming})`;
}

export function schedulerMonthDataFormatter(
  data: IShipping[]
): ISchedulerData[] {
  const schedulerData: ISchedulerData[] = [];
  data.map((value: IShipping, index: number) => {
    if (
      value.id &&
      value.period &&
      value.shippingMethod &&
      isSame(schedulerData, value.period.start, "month")
    ) {
      schedulerData.push({
        startDate: formatToAllDay(value.period.start, "T00:00:00Z"),
        endDate: formatToAllDay(value.period.start, "T12:00:00Z"),
        title: `Livraison (10) Collecte (10)`,
        type: "month-shipping",
        service: value.shippingMethod?.shippingService,
        index: index,
        id: value.id,
      });
      schedulerData.push({
        startDate: formatToAllDay(value.period.start, "T00:00:00Z"),
        endDate: formatToAllDay(value.period.start, "T12:00:00Z"),
        title: "CHRONOPOST-2SHOP (4)",
        type: "month-chronopost",
        service: value.shippingMethod?.shippingService,
        index: index,
        id: value.id,
      });
      schedulerData.push({
        startDate: value.period.start,
        endDate: value.period.end,
        title: "Annulé (1)",
        type: "month-canceled",
        service: value.shippingMethod?.shippingService,
        index: index,
        id: value.id,
      });
    }
  });
  return schedulerData;
}
