import { useState } from "react";
import style from "./productSection.module.scss";
import { DEFAULT_PRO_PRODUCT } from "../../../utils/booking.init";
import ProductItem from "./productItem/ProductItem";
import { IProduct } from "../../../types/logistic.types";
import { DEFAULT_PRODUCT } from "../../../utils/logistic.init";
import WorkshopInfoForm from "./workshopInfoForm/WorkshopInfoForm";
import useClickOutside from "../../../hooks/useClickOutSide";

interface ProductSectionProps {
  productsList: IProduct[];
  setProductsList: (productsList: IProduct[]) => void;
  hasWorkshopId?: boolean;
  canEdit?: boolean;
}

export default function ProductSection({
  productsList,
  setProductsList,
  hasWorkshopId = true,
  canEdit = true,
}: ProductSectionProps) {
  const [productForm, setProductForm] = useState<IProduct | null>(null);
  const NO_PRODUCT_IS_SELECTED = -1;
  const [selectedProductIndex, setSelectedProductIndex] = useState<number>(
    NO_PRODUCT_IS_SELECTED
  );

  return (
    <div className={style["product-section"]}>
      <div className={style["product-section-top"]}>
        <div className={style["left"]}>
          Liste des articles
          <ul className={style["product-list"]}>
            {productsList.map((p, index) => {
              return (
                <ProductItem
                  key={index}
                  product={p}
                  handleEditProduct={() => handleEditProduct(p, index)}
                  handleDeleteProduct={() => handleDeleteProduct(index)}
                  canEdit={canEdit}
                />
              );
            })}
          </ul>
        </div>
        {productForm && (
          <WorkshopInfoForm
            productForm={productForm}
            setProductForm={setProductForm}
            handleValidateProduct={handleValidateProduct}
            hasWorkshopId={hasWorkshopId}
          />
        )}
      </div>
      {!productForm && canEdit && (
        <div className={style["add-new"]} onClick={handleAddNewProduct}>
          Ajouter un nouvel article
        </div>
      )}
    </div>
  );

  function handleAddNewProduct() {
    setProductForm(DEFAULT_PRODUCT);
    setSelectedProductIndex(NO_PRODUCT_IS_SELECTED);
  }

  function handleValidateProduct() {
    selectedProductIndex !== NO_PRODUCT_IS_SELECTED
      ? editProduct()
      : appendProduct();
    setProductForm(null);
  }

  function handleEditProduct(product: IProduct, index: number) {
    setProductForm(product);
    setSelectedProductIndex(index);
  }

  function handleDeleteProduct(index: number) {
    setProductsList(productsList.filter((_, i) => i !== index));
  }

  function appendProduct() {
    if (!productForm) {
      return;
    }
    setProductsList([...productsList, productForm]);
  }

  function editProduct() {
    if (!productForm) {
      return;
    }
    setProductsList(
      productsList.map((op, i) => {
        return selectedProductIndex === i ? productForm : op;
      })
    );
  }
}
