import { useEffect } from "react";
import style from "./confirmationModal.module.scss";
import { useConfirmModal } from "../../context/confirmationModalContext";
import { useBackButton } from "../../hooks/useBackButton";
import { Close } from "@mui/icons-material";

export default function ConfirmationModal() {
  const isBack = useBackButton();
  const {
    setConfirmModalIsOpen,
    confirmModalChildren,
    setConfirmModalChildren,
  } = useConfirmModal();

  useEffect(() => {
    isBack && closeModal();
  }, [isBack]);

  return (
    <div className={style["confirmation-modal"]}>
      <div className={style["modal"]}>
        {confirmModalChildren}
        <div className={style["close-button"]} onClick={closeModal}>
          <Close />
        </div>
      </div>
      <div className={style["background"]} onClick={closeModal}></div>
    </div>
  );

  function closeModal() {
    setConfirmModalIsOpen(false);
    setConfirmModalChildren(<></>);
  }
}
