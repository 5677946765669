import { useState } from "react";
import style from "./proShipping.module.scss";
import { useSearchParams } from "react-router-dom";
import ProShippingList from "./proShippingList/proShippingList";

export default function ProIncomingShipping() {
  const [searchParams] = useSearchParams();
  const [shippingService, setShippingService] = useState<string>(
    searchParams.get("direction") || "INCOMING"
  );

  return (
    <div className={style["incoming-shipping-container"]}>
      <h1 className={style["title"]}>
        {shippingService === "INCOMING"
          ? "Réceptionner une collecte"
          : "Préparer un envoi"}
      </h1>
      <div className={style["shipping-service-container"]}>
        <div
          className={`${style["shipping-service-button"]} ${
            shippingService === "INCOMING" ? style["toggled-button"] : ""
          }`}
          onClick={() => handleSelectShippingService("INCOMING")}
        >
          Collecte PRO
        </div>
        <div
          className={`${style["shipping-service-button"]} ${
            shippingService === "OUTGOING" ? style["toggled-button"] : ""
          }`}
          onClick={() => handleSelectShippingService("OUTGOING")}
        >
          Livraison PRO
        </div>
      </div>
      <div className={style["incoming-shipping-bottom"]}>
        {shippingService === "INCOMING" && (
          <ProShippingList direction={"INCOMING"} />
        )}
        {shippingService === "OUTGOING" && (
          <ProShippingList direction={"OUTGOING"} />
        )}
      </div>
    </div>
  );

  function handleSelectShippingService(name: string) {
    setShippingService(name);
  }
}
