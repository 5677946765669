import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { useState } from "react";

interface TimeSlotInputsProps {
  onChange: ({ start, end }: { start: string; end: string }) => void;
}

export default function TimeslotInputs({ onChange }: TimeSlotInputsProps) {
  const [start, setStart] = useState("00:00");
  const [end, setEnd] = useState("00:00");

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileTimePicker
          label="Début"
          value={start}
          format="HH:mm"
          onAccept={(value: any) => handleChange(value, "start")}
        />
      </LocalizationProvider>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileTimePicker
          label="Fin"
          value={end}
          format="HH:mm"
          onAccept={(value: any) => handleChange(value, "end")}
        />
      </LocalizationProvider>
      <button
        type="button"
        className="button-s outlined"
        onClick={handleValidate}
      >
        Ajouter
      </button>
    </div>
  );

  function handleChange(value: any, period: string) {
    if (period === "start") {
      setStart(value.format("HH:mm"));
    }
    if (period === "end") {
      setEnd(value.format("HH:mm"));
    }
  }

  function handleValidate(event: any) {
    onChange({ start, end });
  }
}
