import React, { useRef, useState, useEffect } from "react";
import {
  LABEL_HEIGHT,
  LABEL_PIXEL_PER_MM,
  LABEL_WIDTH,
} from "../../utils/constants";
import Button from "../Button/Button";
import { usePrinter } from "../../context/printer.context";
import ProgressionBar from "../ProgressionBar/ProgressionBar";
import styles from "./textToCanvas.module.scss";
import useTextToLabel from "../../routes/dev_tools/ble_printer/useTextToLabel";

export default function TextToCanvas() {
  const [text, setText] = useState("");
  const { onPrint, printProgress } = usePrinter();
  const [showProgressionBar, setShowProgressionBar] = useState<boolean>(false);
  const canvasRef = useTextToLabel(text);

  useEffect(() => {
    if (!printProgress) {
      setShowProgressionBar(false);
    }
  }, [printProgress]);

  return (
    <div className={styles["text-to-canvas"]}>
      <div className={styles["label-preview"]}>
        <textarea
          rows={5}
          cols={30}
          placeholder="texte à imprimer..."
          value={text}
          className={styles["text-area"]}
          onChange={handleOnChange}
        ></textarea>
        <canvas
          ref={canvasRef}
          width={LABEL_WIDTH * LABEL_PIXEL_PER_MM}
          height={LABEL_HEIGHT * LABEL_PIXEL_PER_MM}
          className={styles["canvas"]}
        ></canvas>
      </div>
      <Button onClick={handleOnPrint}>Imprimer</Button>
      {showProgressionBar && <ProgressionBar percentage={printProgress} />}
    </div>
  );

  async function handleOnPrint() {
    setShowProgressionBar(true);
    onPrint({ canvas: canvasRef.current });
  }

  function handleOnChange({
    target,
  }: {
    target: EventTarget & HTMLTextAreaElement;
  }) {
    setText(target.value);
  }
}
