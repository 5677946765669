import { Delete, LocalOfferOutlined } from "@mui/icons-material";
import styles from "./productWidget.module.scss";
import { IProduct } from "../../../../../../types/logistic.types";
import HANDBAG_DEFAULT from "../../../../../../assets/maro.webp";
import SNEAKERS_DEFAULT from "../../../../../../assets/sneakers.webp";
import WOMAN_SHOES_DEFAULT from "../../../../../../assets/article-femme.webp";
import MAN_SHOES_DEFAULT from "../../../../../../assets/man_shoes.webp";
import CLIMBING_SHOES_DEFAULT from "../../../../../../assets/climbing_shoes.webp";
import clsx from "clsx";

interface FormProduct extends IProduct {
  selected?: boolean;
  hasError?: boolean;
}

interface ProductWidgetProps {
  product: FormProduct;
  onClick: (product: IProduct) => void;
  onDeleteClick: (product: IProduct) => void;
}

export function ProductWidget({
  product,
  onClick,
  onDeleteClick,
}: ProductWidgetProps) {
  return (
    <div
      className={clsx(styles["product-widget"], {
        [styles["selected"]]: product.selected,
        [styles["error"]]: product.hasError,
        [styles["valid"]]: product.hasError === false,
      })}
      onClick={handleClick}
    >
      <div className={clsx(styles["product-widget-content"])}>
        <div className={styles["product-info"]}>
          <span className={styles["product-info-brand"]}>{product.brand}</span>

          <div className={styles["workshop-id-container"]}>
            <div>{product.workshopId || ""}</div>
            <LocalOfferOutlined />
          </div>
        </div>
        <img
          draggable={false}
          className={styles["widget-preview"]}
          src={getImagePreviewUri(product)}
        />
      </div>
      <div className={styles["delete-icon-container"]} onClick={handleDelete}>
        <Delete />
      </div>
    </div>
  );

  function handleDelete(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    onDeleteClick(product);
  }

  function handleClick(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void {
    event.preventDefault();
    onClick && onClick(product);
  }
}

export function getImagePreviewUri(product: IProduct) {
  let imageUrl = HANDBAG_DEFAULT;
  if (product.productGroup === "SHOES") {
    switch (product.operationGroup) {
      case "SHOES_MAN":
        imageUrl = MAN_SHOES_DEFAULT;
        break;
      case "SHOES_WOMAN":
        imageUrl = WOMAN_SHOES_DEFAULT;
        break;
      case "SHOES_SNEAKERS":
        imageUrl = SNEAKERS_DEFAULT;
        break;
      case "SHOES_CLIMBING":
        imageUrl = CLIMBING_SHOES_DEFAULT;
        break;
      default:
        break;
    }
  }
  if (product?.incomingPicturesList && product.incomingPicturesList.at(0)) {
    imageUrl = `${
      process.env.REACT_APP_API_URL
    }/file/${product.incomingPicturesList.at(0)}`;
  }
  return imageUrl;
}
