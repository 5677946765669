import { toast } from "react-toastify";
import AddressForm from "../../../../../components/forms/addressForm/addressForm";
import ValidateButton from "../../../../../components/validateButton/validateButton";
import { IContact } from "../../../../../types/logistic.types";
import {
  IPickUpLocationInfo,
  IShippingPoint,
  PickUpInfo,
} from "../../../../../types/shipping.types";
import style from "./addressNoPickUpSection.module.scss";
import { useRef, useState } from "react";
import { TOAST_ERROR_OPTIONS } from "../../../../../utils/toast.options";
import { getPickUpLocation } from "../../../../../requests/shipping";
import { PickUpLocationAddress } from "../../../../../components/forms/shippingForm/addressMiniature/pickUpLocationMiniature";
import { scrollToElement } from "../../../../../utils/utils";

interface AddressSectionProps {
  shippingForm: IShippingPoint | null;
  setShippingForm: (shippingForm: IShippingPoint | null) => void;
  setStep: (value: number) => void;
}

export default function AddressNoPickUpSection({
  shippingForm,
  setShippingForm,
  setStep,
}: AddressSectionProps) {
  const FORM_FILLED = 2;
  const [addressForm, setAddressForm] = useState<IShippingPoint | null>(
    shippingForm
  );
  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      <AddressForm
        className={style["address-form"]}
        contact={shippingForm as IContact}
        defaultAddress={shippingForm as IContact}
        isShippingAddress
        onChange={setAddressForm}
      />
      <ValidateButton
        className={style["generate-label-button"]}
        title="Valider"
        handleOnClick={handleValidateAddress}
      />
    </>
  );

  function handleValidateAddress() {
    if (addressForm) {
      setShippingForm({
        ...shippingForm,
        ...addressForm,
      });
      setStep(FORM_FILLED);
    }
  }
}
