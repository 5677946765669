import style from "./estimationMiniature.module.scss";
import { IEstimate } from "../../../types/tracker.types";
import translate from "../../../translation";
import { useNavigate } from "react-router-dom";
import getStatusColor from "../../../translation/statusColor";
import { useModalComponent } from "../../../context/modalComponent.context";
import EstimateModal from "../../modals/estimateModal/estimateModal";
import { useHideSideBar } from "../../../context/hideSideBar.context";
import { ModalContainer } from "../../modals/ModalContainer";
import { useState } from "react";

export default function EstimationMiniature({ data }: { data: IEstimate }) {
  const navigate = useNavigate();

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  if (!data || data.documentId.toString() === "") {
    return (
      <div className={style["estimation-miniature-container"]}>
        <div className={style["add-new-container"]}>
          <button className={style["add-new"]} onClick={generateEstimate}>
            Générer une estimation
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={style["estimation-miniature-container"]}
        onClick={toogleEstimateModal}
      >
        <div className={style["top-container"]}>
          <div className={style["header-container"]}>
            <div className={style["title"]}>Estimation</div>
            <div
              className={style["state"]}
              style={{ backgroundColor: `${getStatusColor(data.state)}` }}
            >
              {translate(data.state)}
            </div>
          </div>
          <div className={style["body-container"]}>
            <div className={style["info-line"]}>
              <span>Date de création</span>
              {data.createdAt ? (
                <span>
                  {new Date(data.createdAt).toLocaleDateString("fr-FR")}
                </span>
              ) : (
                <span className={style["unavailable"]}>{"indisponible"}</span>
              )}
            </div>
            <div className={style["info-line"]}>
              <span>Date de réponse</span>
              {data.repliedAt ? (
                <span>
                  {new Date(data.repliedAt).toLocaleDateString("fr-FR")}
                </span>
              ) : (
                <span className={style["unavailable"]}>{"indisponible"}</span>
              )}
            </div>
            <div className={style["info-line"]}>
              <span>Montant</span>
              {data.amountVAT ? (
                <span>{`${data.amountVAT} €`}</span>
              ) : (
                <span className={style["unavailable"]}>{"indisponible"}</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalContainer isOpen={modalIsOpen} onCancel={toogleEstimateModal}>
        <EstimateModal
          id={data.documentId}
          onToolSelect={toogleEstimateModal}
        />
      </ModalContainer>
    </>
  );

  function generateEstimate() {
    navigate("/estimate/create");
  }

  function toogleEstimateModal() {
    setModalIsOpen(!modalIsOpen);
  }
}
