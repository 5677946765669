import { CustomError } from "../types/customError.types";
import { IProAccount } from "../types/proAccount.types";

export async function getProAccountList(
  query: string,
  signal?: AbortSignal
): Promise<{
  proAccount: IProAccount[];
  totalCount: number;
}> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/proAccount/list/${query}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "GET",
        credentials: "include",
        signal: signal,
      }
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getProAccount({ params }: { params: any }): Promise<any> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/proAccount/${params.id}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "GET",
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status !== 201 && response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function deleteProAccount(
  id: string,
  signal?: AbortSignal
): Promise<void | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/proAccount/${id}`,
      {
        credentials: "include",
        method: "DELETE",
        signal: signal,
      }
    );
    if (response.status !== 200) {
      if (response.status === 301) {
        return null;
      }
      throw new Response(JSON.stringify(response), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function addProAccount(
  data: IProAccount,
  signal?: AbortSignal
): Promise<void | null | string> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/proAccount/create`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "include",
        method: "POST",
        signal: signal,
        body: JSON.stringify(data),
      }
    );
    if (response.status === 500) {
      const errorBody = await response.json();
      throw new CustomError("ERROR cannot create the Account!", {
        status: response.status,
        body: errorBody,
      });
    }

    if (response.status === 409) {
      return "Account already existe!";
    }
    const res = await response.json();
    return res.id;
  } catch (error: any) {
    console.error(`Front-end Error: ${error.message}`);
    if (error instanceof CustomError) {
      console.error(
        `CustomError: ${error.message}, Status: ${
          error.status
        }, Body: ${JSON.stringify(error.body)}`
      );
      throw new Error(`Error creating shop: ${error.message}`);
    }
    throw new Error("Unknown error occurred");
  }
}

export async function updateProAccount(
  data: IProAccount,
  signal?: AbortSignal
): Promise<void | null | string> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/proAccount/${data.id}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "include",
        method: "PUT",
        signal: signal,
        body: JSON.stringify(data),
      }
    );
    if (response.status !== 200) {
      if (response.status === 301) {
        return null;
      }
      if (response.status === 409) {
        return "Nom d'utilisateur déjà existant";
      }
      if (response.status === 400) {
        return "Champs manquants";
      }
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
