import { IEvent } from "../../../../types/tracker.types";
import styles from "./overviewHistory.module.scss";

interface OverviewHistoryProps {
  eventsList?: IEvent[];
}

export default function OverviewHistory({ eventsList }: OverviewHistoryProps) {
  if (!eventsList) {
    return <></>;
  }

  return (
    <div className={styles["list"]}>
      <table className={styles["table"]}>
        <thead className={styles["list-header"]}>
          <tr className={styles["list-header-row"]}>
            <th className={styles["list-header-cell"]}>Date</th>
            <th className={styles["list-header-cell"]}>Document</th>
            <th className={styles["list-header-cell"]}>Catégorie</th>
            <th className={styles["list-header-cell"]}>Message</th>
            <th className={styles["list-header-cell"]}>Source</th>
          </tr>
        </thead>
        <tbody>
          {eventsList.map((event: IEvent, index: number) => {
            return (
              <tr className={styles["item"]} key={index}>
                <td className={styles["item-cell"]}>
                  {new Date(event.date).toLocaleString("fr-FR")}
                </td>
                <td className={styles["item-cell"]}>{event.documentName}</td>
                <td className={styles["item-cell"]}>{event.category}</td>
                <td className={styles["item-cell"]}>{event.description}</td>
                <td className={styles["item-cell"]}>{event.service}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
