import { Delete } from "@mui/icons-material";
import { IOperation } from "../../../../../types/accounting.types";
import style from "./operationItem.module.scss";

interface OperationItemProps {
  operation: IOperation;
  index: number;
  isFormOpen: boolean;
  handleEditOperation: (operation: IOperation, index: number) => void;
  handleDelete: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => void;
}
//

export default function OperationItem({
  operation,
  index,
  isFormOpen,
  handleEditOperation,
  handleDelete,
}: OperationItemProps) {
  return (
    <li
      className={`${style["operation-item"]} ${
        isFormOpen ? style["larger"] : ""
      }`}
      onClick={() => handleEditOperation(operation, index)}
    >
      <div className={style["description-container"]}>
        <span className={style["description"]}>- {operation.description}</span>
        <span className={style["dots"]}></span>
        <span className={style["price"]}>{operation.price || 0} €</span>
      </div>
      {!isFormOpen && (
        <div
          className={style["delete"]}
          onClick={(e) => handleDelete(e, index)}
        >
          <Delete />
        </div>
      )}
    </li>
  );
}
