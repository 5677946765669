import { ReactNode, useEffect, useState } from "react";

interface BackButtonInterceptorProps {
  onBack?: () => void;
  children: ReactNode;
}

export default function BackButtonInterceptor({
  children,
  onBack,
}: BackButtonInterceptorProps) {
  const [initialHistoryLength, setInitialHistoryLength] = useState<
    number | null
  >(window.history.length);

  useEffect(() => {
    if (window.history.length === initialHistoryLength) {
      window.history.pushState(null, "", window.location.pathname);
      setInitialHistoryLength(null);
    }
    const handlePopState = (event: any) => {
      event.preventDefault();
      onBack && onBack();
    };

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return <>{children}</>;
}
