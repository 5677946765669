import { useNavigate } from "react-router-dom";
import style from "./toastedLink.module.scss";
import { isDesktop } from "react-device-detect";

interface ToastedLinkProps {
  message: string;
  linkMessage?: string;
  handleOnLinkClick: () => void;
}

export default function ToastedLink({
  message,
  linkMessage,
  handleOnLinkClick,
}: ToastedLinkProps) {
  return (
    <div className={style["toasted-link"]}>
      {message}
      {linkMessage && (
        <div
          className={`${style["toasted-link-message"]} ${
            isDesktop ? style["hover"] : style["active"]
          }`}
          onClick={handleOnLinkClick}
        >
          {linkMessage}
        </div>
      )}
    </div>
  );
}
