import style from "./selectAndSearchShop.module.scss";
import { useQuery } from "react-query";
import { getShopsList } from "../../requests/shops";
import useClickOutside from "../../hooks/useClickOutSide";
import { useEffect, useState } from "react";
import { Close, KeyboardArrowDown, Search } from "@mui/icons-material";
import { IShop } from "../../types/shop.types";
import { useKeyDown } from "../../hooks/useKeyDown";

interface SelectAndSearchProps {
  className?: string;
  shop: IShop | null;
  setShop: (shop: IShop | null) => void;
}

export default function SelectAndSearchShop({
  className,
  shop,
  setShop,
}: SelectAndSearchProps) {
  const [expand, setExpand] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(5);
  const [shopName, setShopName] = useState<string>("");
  const ref = useClickOutside<HTMLDivElement>(handleClickOutside);
  const { data } = useQuery(
    ["shop-list", limit, shopName],
    () => getShopsList(formatedQuery()),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
  const [hoveredItem, setHoveredItem] = useState<number>(0);
  useKeyDown("ArrowDown", handlePressArrowDown, [limit]);
  useKeyDown("ArrowUp", handlePressArrowUp, [limit]);
  useKeyDown("Enter", handlePressEnter, [hoveredItem]);

  return (
    <div
      ref={ref}
      className={`${style["select-by-list-and-Search"]} ${className}`}
    >
      <div className={style["shop-select-input-container"]}>
        <div className={style["shop-select-input"]} onClick={handleExpand}>
          <div className={style["selected-item"]}>
            {shop && shop.name ? (
              <span className={style["selected-item-value"]}>{shop.name}</span>
            ) : (
              <span className={style["selected-item-placeholder"]}>
                choisir une boutique
              </span>
            )}
          </div>
          <div className={style["shop-select-input-right"]}>
            <KeyboardArrowDown />
          </div>
        </div>
        {shop && shop.name && (
          <div className={style["reset-icon"]}>
            <div
              className={style["close-icon-container"]}
              onClick={handleResetSearch}
            >
              <Close className={style["close-icon"]} />
            </div>
          </div>
        )}
      </div>
      <div className={style["select-drop-container"]}>
        {expand && (
          <>
            <div className={style["select-drop"]}>
              <div className={style["select-drop-input-container"]}>
                <div className={style["search-icon-container"]}>
                  <Search className={style["search-icon"]} />
                </div>
                <input
                  className={style["select-drop-input"]}
                  placeholder={"rechercher..."}
                  type="text"
                  value={shopName}
                  onChange={handleOnShopNameChange}
                />
              </div>
              <div className={style["select-drop-list"]}>
                {data && data.shops.length > 0 ? (
                  data.shops.map((shop: IShop, index: number) => {
                    return (
                      <div
                        className={`${style["select-drop-item"]} ${
                          hoveredItem === index ? style["selected"] : ""
                        }`}
                        key={index}
                        onClick={() => handleSelectShop(shop)}
                        onMouseEnter={() => setHoveredItem(index)}
                        title={shop.name}
                      >
                        <span className={style["shop-workshopPrefixId"]}>
                          {shop.workshopPrefixId}
                        </span>
                        <span className={style["shop-name"]}>{shop.name}</span>
                      </div>
                    );
                  })
                ) : (
                  <div className={style["no-value-found"]}>
                    aucune valeur trouvée
                  </div>
                )}
              </div>
            </div>
            {data && limit < data.totalCount && (
              <div className={style["show-more"]} onClick={showMore}>
                afficher plus
              </div>
            )}
            <div className={style["beautiful-div"]}></div>
          </>
        )}
      </div>
    </div>
  );

  function handleOnShopNameChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    setShopName(target.value);
    hoveredItem > -1 && setHoveredItem(-1);
  }

  function formatedQuery() {
    let formatedQuery: string = `?sort_field=workshopPrefixId&sort_direction=asc&limit=${limit}`;

    if (shopName) {
      formatedQuery += `&$or=${shopName}`;
    }

    return formatedQuery;
  }

  function handleClickOutside() {
    setExpand(false);
    setHoveredItem(-1);
  }

  function handleExpand() {
    setExpand(!expand);
    resetList();
  }

  function handleResetSearch() {
    setShop(null);
    resetList();
  }

  function handleSelectShop(shop: IShop) {
    setShop(shop);
    setExpand(false);
    resetList();
  }

  function resetList() {
    setLimit(5);
    setShopName("");
    setHoveredItem(-1);
  }

  function showMore() {
    if (!data || !data.totalCount) {
      return;
    }
    if (limit < data.totalCount) {
      setLimit(limit + 5);
    }
  }

  function handlePressArrowDown(e?: KeyboardEvent) {
    e?.preventDefault();
    setHoveredItem((prev) => (prev + 1 < limit ? prev + 1 : 0));
  }

  function handlePressArrowUp(e?: KeyboardEvent) {
    e?.preventDefault();
    setHoveredItem((prev) => (prev - 1 >= 0 ? prev - 1 : limit - 1));
  }

  function handlePressEnter(e?: KeyboardEvent) {
    if (hoveredItem > -1 && data && data.shops[hoveredItem]) {
      handleSelectShop(data.shops[hoveredItem]);
    }
  }
}
