import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { loadQuote } from "../../../requests/tracker";
import Create from "../create/create";

export default function Generate() {
  const { trackerId } = useParams();
  const { data, isFetching, error, refetch } = useQuery(
    "loadQuote",
    () => loadQuote({ params: { trackerId } }),
    { refetchOnWindowFocus: false }
  );

  return <>{data && !isFetching ? <Create data={data} /> : <></>}</>;
}
