import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../../pages/error-page";
import { authloader } from "..";
import Reports from ".";
import Charts from "./charts/charts";

export const route: RouteObject = {
  path: "/reports",
  element: <Outlet />,
  errorElement: <ErrorPage />,
  loader: authloader,
  children: [
    {
      index: true,
      element: <Reports />,
    },
    {
      path: "charts",
      element: <Charts />
    }
  ],
};
