export interface ColorType {
  code: number;
  name: string;
  color: string;
}

export const COLOR_LIST = [
  { code: 1, name: "Noir", color: "#010101" },
  { code: 2, name: "Incolore", color: "#f1ebd1" },
  { code: 3, name: "Marron clair", color: "#8e3c0c" },
  { code: 4, name: "Marron", color: "#31190f" },
  { code: 5, name: "Marron foncé", color: "#0f0e0a" },
  { code: 6, name: "Bleu marine", color: "#0b0c10" },
  { code: 7, name: "Bleu saphir", color: "#0d286d" },
  { code: 8, name: "Bordeaux", color: "#0e0506" },
  { code: 9, name: "Acajou", color: "#34130c" },
  { code: 10, name: "Cognac", color: "#4a180d" },
  { code: 11, name: "Rouge", color: "#cf130a" },
  { code: 12, name: "Rouge hermes", color: "#421115" },
  { code: 13, name: "Gris clair", color: "#919891" },
  { code: 14, name: "Gris", color: "#4e5452" },
  { code: 15, name: "Gris foncé", color: "#202528" },
  { code: 16, name: "Beige", color: "#c9b289" },
  { code: 17, name: "Ficelle", color: "#cfb76d" },
  { code: 18, name: "Biscuit", color: "#c18b4b" },
  { code: 19, name: "Fauve", color: "#b05215" },
  { code: 20, name: "Vert foncé", color: "#021710" },
  { code: 21, name: "Blanc", color: "#f5f0dd" },
  { code: 25, name: "Bleu iris", color: "#004c9a" },
  { code: 27, name: "Rose pale", color: "#f7beb7" },
  { code: 28, name: "Kaki", color: "#3b3620" },
  { code: 29, name: "Vison", color: "#2e2925" },
  { code: 30, name: "Brun cuivré", color: "#702714" },
  { code: 31, name: "Gris anthracite", color: "#0f1014" },
  { code: 32, name: "Brun sanglier", color: "#0e0c0d" },
  { code: 33, name: "Vert chasse", color: "#1b2817" },
  { code: 34, name: "Havane", color: "#301a0f" },
  { code: 35, name: "Havane moyen", color: "#33160e" },
  { code: 36, name: "Havane clair", color: "#4f1d12" },
  { code: 37, name: "Marron moyen", color: "#361911" },
  { code: 38, name: "Noisette", color: "#5f2b16" },
  { code: 39, name: "Cuir naturel", color: "#d39035" },
  { code: 41, name: "Chamois", color: "#a57642" },
  { code: 42, name: "Beige rosé", color: "#dcc099" },
  { code: 44, name: "Crème", color: "#cfcaac" },
  { code: 45, name: "Ivoire", color: "#c5b88c" },
  { code: 46, name: "Bleu pétrole", color: "#111820" },
  { code: 47, name: "Gris éléphant", color: "#515c60" },
  { code: 48, name: "Lavande", color: "#41182a" },
  { code: 49, name: "Vert jardin", color: "#007426" },
  { code: 51, name: "Mouette", color: "#aea38d" },
  { code: 52, name: "Orange", color: "#f13800" },
  { code: 53, name: "Jaune", color: "#dbb401" },
  { code: 54, name: "Rose", color: "#eb8e7d" },
  { code: 55, name: "Bleu pale", color: "#9acdd0" },
  { code: 56, name: "Gabardine", color: "#70563b" },
  { code: 58, name: "Vert pomme", color: "#5fa600" },
  { code: 60, name: "Marbre", color: "#b3b3a7" },
  { code: 61, name: "Canelle", color: "#a56124" },
  { code: 63, name: "Blanc casse", color: "#e0d8c1" },
  { code: 66, name: "Violet", color: "#222248" },
  { code: 68, name: "Vertanis", color: "#bde471" },
  { code: 70, name: "Chameau", color: "#8e5f31" },
  { code: 72, name: "Rose pompadour", color: "#de5d86" },
  { code: 78, name: "Cognac clair", color: "#6e2310" },
  { code: 80, name: "Fumée", color: "#b4ae8c" },
  { code: 81, name: "Bouleau", color: "#cfd1c4" },
  { code: 82, name: "Coquille d'oeuf", color: "#decfa4" },
  { code: 83, name: "Bleu azur", color: "#1f4b94" },
  { code: 84, name: "Violine", color: "#4d3a68" },
  { code: 86, name: "Campari", color: "#380f0d" },
  { code: 87, name: "Prune", color: "#130b09" },
  { code: 88, name: "Gris taupe", color: "#393931" },
  { code: 89, name: "Rouge cerise", color: "#6b0f12" },
  { code: 90, name: "Bleu jean", color: "#5a85af" },
  { code: 91, name: "Vieux rose", color: "#d1a394" },
  { code: 92, name: "Caramel", color: "#b96c00" },
  { code: 93, name: "Fuschia", color: "#8d1231" },
  { code: 94, name: "Pêche", color: "#e86426" },
  { code: 96, name: "Gris pierre", color: "#6d6f61" },
  { code: 98, name: "Chocolat", color: "#34190e" },
  { code: 99, name: "Brume", color: "#c4c1ae" },
  { code: 901, name: "Rubis", color: "#b40b0e" },
  { code: 100, name: "Or", color: "#fcdc12" },
  { code: 100, name: "Argent", color: "#868992" },
  { code: 904, name: "Bleu ocean", color: "#050714" },
  { code: 913, name: "Brun rosé", color: "#855e57" },
  { code: 914, name: "Corail", color: "#f16c7f" },
  { code: 916, name: "Ocre rouge", color: "#a94939" },
];
