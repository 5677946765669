import { ReactNode } from "react";
import styles from "./widget.module.scss";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { isDesktop } from "react-device-detect";

interface WidgetProps {
  children?: ReactNode;
  to?: string;
  selected?: boolean;
  className?: string;
}

export default function Widget({
  children,
  to,
  selected,
  className,
}: WidgetProps) {
  return (
    <Link
      to={to || ""}
      className={clsx(
        styles["widget-container"],
        className,
        isDesktop && styles["hover"],
        !isDesktop && styles["active"]
      )}
    >
      {children}
    </Link>
  );
}
