import { Link } from "react-router-dom";
import styles from "./navigationBarHeader.module.scss";
import BrandLogo from "../../vectors/brand.component";
import brandLogo from "../../../assets/logo-header.jpg";

export default function NavigationBarHeader() {
  return (
    <Link
      className={styles["brand-logo-link"]}
      onClick={() => window.scrollTo(0, 0)}
      to="/"
      title="accueil"
    >
      {/* <BrandLogo className={styles["brand-logo"]} /> */}
      <img src={brandLogo} className={styles["brand-logo"]} />
    </Link>
  );
}
