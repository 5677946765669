import style from "./item.module.scss";
import { ICrossSell } from "../../../types/accounting.types";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import { Link } from "react-router-dom";
import { Delete, Edit, Launch } from "@mui/icons-material";
import translate from "../../../translation";
import { useContext } from "react";
import ColorComponent from "../../../components/colorComponent/colorComponent";

import { useModalComponent } from "../../../context/modalComponent.context";
import ReadModal from "../readModal/readModal";
import { handleImageError } from "../../../utils/utils";
import { useHideSideBar } from "../../../context/hideSideBar.context";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import { useConfirmModal } from "../../../context/confirmationModalContext";
import ConfirmModalComp from "../../../components/confirmationModal/ConfirmModalComp";

interface ItemProps {
  data: ICrossSell;
  handleDelete: (slug: string | undefined) => void;
  handleCloseModal: () => void;
  zIndex?: number;
}

export default function Item({
  data,
  handleDelete,
  handleCloseModal,
  zIndex,
}: ItemProps) {
  const { setModalIsOpen, setModalChildren } = useModalComponent();
  const { setConfirmModalChildren, setConfirmModalIsOpen } = useConfirmModal();

  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Launch />,
      name: "Ouvrir",
      onClick: handleOpenModal,
    },
    {
      icon: <Edit />,
      name: "Modifier",
      to: `/cross-selling/edit/${data.slug}`,
    },
    {
      icon: <Delete />,
      name: "Supprimer",
      onClick: () => handleConfirmDeleteModal(),
    },
  ];

  return (
    <div className={style["item"]}>
      <div className={style["item-element"]}>
        <img
          className={style["item-element-miniature"]}
          src={`${process.env.REACT_APP_API_URL}/file/${data.image}`}
          alt="preview"
          onError={handleImageError}
        />
        <span className={style["description"]}>{data.description}</span>
        {data.color && <ColorComponent color={data.color} />}
      </div>
      <div className={style["item-element"]}>
        {data.productGroup &&
          data.productGroup.sort().map((pg: string, index: number) => (
            <div key={index} className={style["product-group-item"]}>
              {translate(pg)}
            </div>
          ))}
      </div>
      <div className={style["item-element"]}>
        {translate(data.category ?? "-")}
      </div>
      <div className={style["item-element"]}>
        {(data.price || 0) / 100}
        {" €"}
      </div>
      <div className={style["item-element"]}>{data.quantity}</div>
      <div className={`${style["item-element"]} ${style["tools"]}`}>
        <MoreTools options={MORE_TOOLS_OPTIONS} zIndex={zIndex} />
      </div>
    </div>
  );

  function handleOpenModal() {
    setModalIsOpen(true);
    setModalChildren(
      <ReadModal data={data} handleCloseModal={handleCloseModal} />
    );
  }

  function handleConfirmDeleteModal() {
    setConfirmModalChildren(
      <ConfirmModalComp
        confirmButtonClassName={style["delete-button"]}
        title={"Êtes-vous sûr de vouloir supprimer cet article ?"}
        confirmText="Supprimer"
        onConfirm={() => handleDelete(data.slug)}
      />
    );
    setConfirmModalIsOpen(true);
  }
}
