import { Moped, Store } from "@mui/icons-material";
import styles from "./shippingServiceLogo.module.scss";
import translate from "../../translation";
import chronopostLogo from "../../assets/logo-chronopost.png";

interface ShippingServiceLogoProps {
  shippingService?: string;
}
export default function ShippingServiceLogo({
  shippingService,
}: ShippingServiceLogoProps) {
  if (!shippingService) {
    return <></>;
  }

  switch (true) {
    case shippingService.includes("INTERNAL"):
      return (
        <div className={styles["shipping-service"]}>
          <Moped className={styles["icon"]} />
          {translate(shippingService)}
        </div>
      );
    case shippingService.includes("DROP-OR-COLLECT"):
      return (
        <div className={styles["shipping-service"]}>
          <Store className={styles["icon"]} />
          {translate(shippingService)}
        </div>
      );
    case shippingService.includes("CHRONO"):
      return <img src={chronopostLogo} className={styles["logo"]} />;
    default:
      return (
        <div className={styles["shipping-service"]}>
          {translate(shippingService)}
        </div>
      );
  }
}
