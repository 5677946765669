import { useParams } from "react-router-dom";
import { loadLogistic } from "../../../../requests/tracker";
import style from "./cartToLogistic.module.scss";
import CheckIn from "./CheckIn/CheckIn";
import { FormProduct, ILogistic } from "../../../../types/logistic.types";
import { isProductFormValid } from "../../../../utils/logistic.function";
import { UseMutateFunction } from "react-query";
import { IValidationError } from "../../../../types";

export default function CartToLogistic() {
  const { trackId } = useParams();
  return (
    <div className={style["cart-to-logistic-container"]}>
      <CheckIn
        fetchingMethod={loadLogistic}
        handleSaveClicked={handleSaveClicked}
        trackId={trackId as string}
      />
    </div>
  );

  async function handleSaveClicked(
    logistic: ILogistic,
    setLogistic: (value: ILogistic) => void,
    updateLogistic: UseMutateFunction<
      string | IValidationError[] | null,
      unknown,
      {
        logistic: ILogistic;
        updateQuote: boolean;
        setShippingToDelivered: boolean;
      },
      unknown
    >
  ) {
    let updatedLogistic: ILogistic = { ...logistic };
    if (!updatedLogistic.productsList) {
      updatedLogistic.productsList = [];
    }
    for (let product of updatedLogistic.productsList) {
      (product as FormProduct).hasError = !(await isProductFormValid(product));
    }
    updatedLogistic.status = "LOGISTIC_QUOTE_ESTIMATION";
    setLogistic(updatedLogistic);
    updateLogistic({
      logistic: updatedLogistic,
      updateQuote: true,
      setShippingToDelivered: true,
    });
    return updatedLogistic;
  }
}
