import clsx from "clsx";
import styles from "./listHeader.module.scss";

interface ListHeaderProps {}

export default function ListHeader({}: ListHeaderProps) {
  return (
    <thead className={styles["list-header"]}>
      <tr className={styles["list-header-row"]}>
        <th className={styles["list-header-cell"]}>Facture</th>
        <th className={styles["list-header-cell"]}>Nom / Organisation</th>
        <th className={styles["list-header-cell"]}>Date</th>
        <th className={styles["list-header-cell"]}>Article</th>
        <th className={styles["list-header-cell"]}>Total HT</th>
        <th className={styles["list-header-cell"]}>Total TTC</th>
        <th className={clsx(styles["list-header-cell"], styles["center-cell"])}>
          Status
        </th>
        <th className={clsx(styles["list-header-cell"], styles["center-cell"])}>
          Actions
        </th>
      </tr>
    </thead>
  );
}
