import style from "./MoveProducts.module.scss";
import { IProduct } from "../../../../../types/logistic.types";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../../../../../utils/toast.options";
import { DroppableList } from "./DroppableList/DroppableList";
import ModalPortal from "../../../ModalPortal";
import ReadModal from "../../../ReadModal/ReadModal";
import PrintProductLabelModal from "../../../printProductLabelModal/PrintProductLabelModal";
import { useState } from "react";

interface MoveProductsProps {
  dueDate?: Date;
  productsList: IProduct[];
  setProductsList: (value: IProduct[]) => void;
}

export default function MoveProducts({
  productsList,
  setProductsList,
  dueDate,
}: MoveProductsProps) {
  const [product, setProduct] = useState<IProduct | null>(null);

  return (
    <>
      <div className={style["logistic-action-container"]}>
        <span className={style["message"]}>
          Le devis a été payé veuillez déplacer tous les articles sur l'étagère
          de réparations.
        </span>
        <div className={style["move-products"]}>
          <DroppableList
            className={style["pending-list"]}
            list={getList("PENDING_SHELVED")}
            productsList={productsList}
            title={"PENDING_SHELVED"}
            moveProduct={moveProduct}
          />
          <div className={style["state-grid"]}>
            <DroppableList
              list={getList("WORKSHOP_REPAIR")}
              productsList={productsList}
              title={"WORKSHOP_REPAIR"}
              moveProduct={moveProduct}
            />
            <DroppableList
              list={getList("WORKSHOP_CARE")}
              productsList={productsList}
              title={"WORKSHOP_CARE"}
              moveProduct={moveProduct}
            />
            <DroppableList
              list={getList("EXTERNAL_JOB")}
              productsList={productsList}
              title={"EXTERNAL_JOB"}
              moveProduct={moveProduct}
            />
            <DroppableList
              list={getList("PRODUCT_CONTROLLED")}
              productsList={productsList}
              title={"PRODUCT_CONTROLLED"}
              moveProduct={moveProduct}
            />
          </div>
        </div>
      </div>
      <ModalPortal isOpen={!!product}>
        <ReadModal handleCloseModal={handleClosePrintLabelModal}>
          <PrintProductLabelModal
            product={product!}
            comments={
              product?.dueDate
                ? "à faire avant le: " +
                  new Date(product.dueDate).toLocaleDateString("fr-FR")
                : ""
            }
          />
        </ReadModal>
      </ModalPortal>
    </>
  );

  function moveProduct(item: { product: IProduct }, listName: string) {
    if (
      item.product.operationsList.every((op) => op.canceled) &&
      listName !== "PRODUCT_CONTROLLED"
    ) {
      toast.warning(
        `L'article que vous essayez de déplacer n'a aucune opération. Vous pouvez le mettre en "Non-faisable".`,
        TOAST_ERROR_OPTIONS
      );
      return;
    }
    setProductsList(
      productsList.map((p) => {
        if (p.id === item.product.id) {
          return { ...p, status: listName, dueDate };
        } else {
          return p;
        }
      })
    );
    if (!["PENDING_SHELVED", "PRODUCT_CONTROLLED"].includes(listName)) {
      setProduct(item.product);
    }
  }

  function getList(state: string) {
    return productsList.filter((p) => p.status === state);
  }

  function handleClosePrintLabelModal() {
    setProduct(null);
  }
}
