import { useEffect, useState } from "react";
import { IProduct } from "../../../../types/logistic.types";
import { INITIALE_OPERATION } from "../../../../utils/logistic.init";
import style from "./operationSection.module.scss";
import { IOperation } from "../../../../types/accounting.types";
import OperationForm from "./operationForm/OperationForm";
import OperationItem from "./operationItem/OperationItem";

interface OperationFormProps {
  className?: string;
  productForm: IProduct;
  setProductForm: (product: IProduct) => void;
  operationForm: IOperation | null;
  setOperationForm: (value: IOperation | null) => void;
}

export default function OperationSection({
  className,
  productForm,
  setProductForm,
  operationForm,
  setOperationForm,
}: OperationFormProps) {
  const NO_OPERATION_IS_SELECTED = -1;
  const [selectedOperationIndex, setSelectedOperationIndex] = useState<number>(
    NO_OPERATION_IS_SELECTED
  );

  useEffect(() => {
    if (!operationForm) {
      return;
    }
    setOperationForm({
      ...operationForm,
      productGroup: productForm.productGroup,
      operationGroup: productForm.operationGroup,
    });
  }, [productForm]);

  return (
    <div
      className={`${style["operation-section"]} ${className ? className : ""}`}
    >
      <div className={style["operation-section-top"]}>
        <div className={style["left"]}>
          Liste des opérations *
          <ul className={style["operation-list"]}>
            {productForm.operationsList.map((op, index) => {
              return (
                <OperationItem
                  operation={op}
                  index={index}
                  isFormOpen={!!operationForm}
                  key={`${op.slug}-${index}`}
                  handleEditOperation={handleEditOperation}
                  handleDelete={handleDelete}
                />
              );
            })}
          </ul>
        </div>
        {operationForm && (
          <OperationForm
            operationForm={operationForm}
            setOperationForm={setOperationForm}
            handleValidateOperation={handleValidateOperation}
          />
        )}
      </div>
      <div className={style["add-new"]}>
        <span onClick={handleAddNewOperation}>
          Ajouter une nouvelle opération
        </span>
      </div>
    </div>
  );

  function handleValidateOperation() {
    selectedOperationIndex !== NO_OPERATION_IS_SELECTED
      ? editOperation()
      : appendOperation();
    setOperationForm(null);
  }

  function appendOperation() {
    if (!operationForm) {
      return;
    }
    const newProduct = {
      ...productForm,
      operationsList: [...productForm.operationsList, operationForm],
    };
    const totalPrice = newProduct.operationsList.reduce(
      (acc, op) => acc + (op?.price || 0),
      0
    );
    setProductForm({ ...newProduct, totalPrice });
  }

  function editOperation() {
    if (!operationForm) {
      return;
    }
    const newProduct = {
      ...productForm,
      operationsList: productForm.operationsList.map((op, i) => {
        return selectedOperationIndex === i ? operationForm : op;
      }),
    };
    const totalPrice = newProduct.operationsList.reduce(
      (acc, op) => acc + (op?.price || 0),
      0
    );
    setProductForm({
      ...newProduct,
      totalPrice,
    });
  }

  function handleAddNewOperation() {
    setOperationForm({
      ...INITIALE_OPERATION,
      operationGroup: productForm.operationGroup,
      productGroup: productForm.productGroup,
    });
    setSelectedOperationIndex(NO_OPERATION_IS_SELECTED);
  }

  function handleEditOperation(operation: IOperation, index: number) {
    setOperationForm({
      ...operation,
      operationGroup: operation.operationGroup || productForm.operationGroup,
      productGroup: operation.productGroup || productForm.productGroup,
    });
    setSelectedOperationIndex(index);
  }

  function handleDelete(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) {
    e.stopPropagation();
    const newProduct = {
      ...productForm,
      operationsList: productForm.operationsList.filter((_, i) => index !== i),
    };
    const totalPrice = newProduct.operationsList.reduce(
      (acc, op) => acc + (op?.price || 0),
      0
    );
    setProductForm({
      ...newProduct,
      totalPrice,
    });
    setOperationForm(null);
  }
}
