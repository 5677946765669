import style from "./selectShop.module.scss";
import SelectAndSearchShop from "../../../../components/SelectAndSearchShop/SelectAndSearchShop";
import { IShop } from "../../../../types/shop.types";

interface SelectShopProps {
  document: string | null;
  shop: IShop | null;
  setShop: (shop: IShop | null) => void;
}

export default function SelectShop({
  document,
  shop,
  setShop,
}: SelectShopProps) {
  return (
    <>
      <label>3 - Sélectionnez une boutique</label>
      <SelectAndSearchShop
        className={style["select-shipping-method"]}
        shop={shop}
        setShop={handleChangeShop}
      />
    </>
  );

  function handleChangeShop(shop: IShop | null) {
    if (document) {
      return window.alert(
        'Vous ne pouvez pas faire de modifications. Appuyer sur "Génerer Nouveau Bon" ou annuler le bon actuel.'
      );
    }
    setShop(shop);
  }
}
