import InStoreDropOff from "../InStoreDropOff";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { fetchLogisticFromTrackerId } from "../../../../requests/tracker";
import LoadingSpinner from "../../../../components/loadingSpinner/loadingSpinner";
import { useEffect } from "react";

export default function InStoreDropOffEdit() {
  const { trackId } = useParams();
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(
    ["logistic", trackId],
    () => fetchLogisticFromTrackerId({ params: { id: trackId } }),
    {
      enabled: !!trackId,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(["logistic", trackId]);
    };
  }, [queryClient, trackId]);

  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (!trackId || !data) {
    return <></>;
  }

  return <>{data && <InStoreDropOff logisticData={data} />}</>;
}
