import { Referral } from "../types/referral.types";

export async function getReferralFilter(
  query?: string
): Promise<{ totalCount: number; referrals: Referral[] }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/referral?${query}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
