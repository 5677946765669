import LogisticModal from "../../../../components/modals/logisticModal/logisticModal";
import WorkshopId from "../../../../components/workshopId/workshopId";
import { useModalComponent } from "../../../../context/modalComponent.context";
import { IProduct } from "../../../../types/logistic.types";
import { dateDiffInDays } from "../../../../utils/utils";
import style from "./productCard.module.scss";
import { fetchLogisticList } from "../../../../requests/logistic";
import { useState } from "react";
import LoadingSpinner from "../../../../components/loadingSpinner/loadingSpinner";
import { getOperationsListAcronym } from "../../../../utils/parseLabelOperation.function";
import { ModalContainer } from "../../../../components/modals/ModalContainer";

interface ProductCardProps {
  product: IProduct;
  showOperationAcronym?: boolean;
}

export default function ProductCard({
  product,
  showOperationAcronym = false,
}: ProductCardProps) {
  const operationAcronym = getOperationsListAcronym(product.operationsList);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [productIndex, setProductIndex] = useState<number>(0);
  const [logisticId, setLogisticId] = useState<string>("");
  const [dueDate, setDueDate] = useState<Date | undefined>();

  return (
    <>
      <div className={style["product-card"]} onClick={handleOpenModal}>
        <div className={style["product-card-left"]}>
          <WorkshopId
            className={style["workshop-id"]}
            workshopId={product.workshopId}
            size="small"
          />
          <div className={style["brand"]}>{product.brand}</div>
          {showOperationAcronym && (
            <div className={style["operations-acronym-list"]}>
              {operationAcronym.map((opAcro) => {
                return (
                  <div className={style["operations-acronym"]}>{opAcro}</div>
                );
              })}
            </div>
          )}
        </div>
        {product.dueDate && (
          <div className={style["due-date"]}>
            {`${new Date(product.dueDate).toLocaleDateString("fr-FR")} `}
            <span>
              {`(${dateDiffInDays(
                new Date(),
                new Date(product.dueDate)
              )} jours)`}
            </span>
          </div>
        )}
      </div>
      <ModalContainer isOpen={modalIsOpen} onCancel={handleOpenModal}>
        <LogisticModal
          id={logisticId}
          productIndex={productIndex}
          dueDate={dueDate}
          requiredActions={false}
        />
      </ModalContainer>
    </>
  );

  async function toogleLogisticModal() {
    setModalIsOpen(!modalIsOpen);
  }

  async function handleOpenModal() {
    if (modalIsOpen) {
      setModalIsOpen(false);
      return;
    }
    if (!product.trackId) {
      return;
    }
    setModalIsOpen(true);
    fetchLogisticList(`?trackId=${product.trackId}`).then((data) => {
      let logistic = data?.logisticsList.at(0);
      if (!logistic) {
        setModalIsOpen(false);
        return;
      }
      let productIndex = logistic.productsList.findIndex(
        (p) => p.workshopId === product.workshopId
      );
      //productIndex
      setLogisticId(logistic.id);
      setProductIndex(productIndex);
      setDueDate(logistic.productsList[productIndex]?.dueDate);
      setModalIsOpen(!modalIsOpen);
    });
  }
}
