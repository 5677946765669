import styles from "./listPageHeader.module.scss";

interface ListPageHeaderProps {
  title: string;
  actionSection?: JSX.Element;
}

export default function ListPageHeader({
  title,
  actionSection,
}: ListPageHeaderProps) {
  return (
    <div className={styles["list-page-header"]}>
      <div className={styles["title"]}>{title}</div>
      {actionSection}
    </div>
  );
}
