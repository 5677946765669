import {
  ICrossSell,
  ICrossSellProduct,
} from "../../../../types/accounting.types";
import ColorComponent from "../../../colorComponent/colorComponent";
import Image from "../../../image/Image";
import CrossSellCardOverlay from "./CrossSellCardOverlay/CrossSellCardOverlay";
import style from "./crossSellCard.module.scss";

interface CrossSellCardProps {
  item: ICrossSell;
  isSelected: boolean;
  handleOnClick: () => void;
  selectedCrossSells: ICrossSellProduct[];
  setSelectedCrossSells: (value: ICrossSellProduct[]) => void;
}

export default function CrossSellCard({
  item,
  isSelected,
  handleOnClick,
  selectedCrossSells,
  setSelectedCrossSells,
}: CrossSellCardProps) {
  return (
    <div onClick={handleOnClick} className={style["cross-sell-card-container"]}>
      <div
        className={`${style["cross-sell-card"]} ${
          isSelected && style["item-selected"]
        }`}
      >
        <div className={style["cross-sell-card-wrapper"]}>
          <div className={style["cross-sell-card-left"]}>
            <div>{item.description}</div>
            <div>{item.category}</div>
            <div>
              {(item.price || 0) / 100}
              {" €"}
            </div>
            {item.color && (
              <ColorComponent
                color={item.color}
                className={style["color-container"]}
              />
            )}
          </div>
          <Image
            className={style["image-miniature"]}
            src={item.image}
            showTools={false}
          />
        </div>
      </div>
      {isSelected && (
        <CrossSellCardOverlay
          item={item}
          selectedCrossSells={selectedCrossSells}
          setSelectedCrossSells={setSelectedCrossSells}
        />
      )}
    </div>
  );
}
