import { useEffect } from "react";
import { IPickUpLocationInfo } from "../../../../types/shipping.types";
import style from "./addressMiniature.module.scss";

interface PickUpLocationAddressProps {
  address: IPickUpLocationInfo;
  isSelected: boolean;
  setSelectedAddress: () => void;
  // setShippingMethod?: () => void;
}

export function PickUpLocationAddress({
  address,
  isSelected,
  setSelectedAddress,
}: // setShippingMethod,
PickUpLocationAddressProps) {
  // useEffect(() => {
  //   if (isSelected) {
  //     setShippingMethod && setShippingMethod();
  //   }
  // }, [isSelected]);

  return (
    <div className={style["address-miniature"]} onClick={setSelectedAddress}>
      <div className={style["radio-button"]}>
        <input
          type="radio"
          checked={isSelected}
          className={style["radio-button"]}
          onChange={() => setSelectedAddress()}
        />
      </div>
      <div className={style["address-info"]}>
        <div className={style["pick-up-location-name"]}>{address.name}</div>
        <div>{[address.address1, address.address2].join(" ")}</div>
        <div>
          {[address.zipCode, address.city, address.country_code].join(" ")}
        </div>
        {address.distance && (
          <div
            className={style["pick-up-location-distance"]}
          >{`distance: ${address.distance} m`}</div>
        )}
      </div>
    </div>
  );
}
