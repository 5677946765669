import { dateDiffInDays } from "../../../../../../utils/utils";
import SectionTitle from "../../../../../SectionTitle/SectionTitle";
import styles from "./productDueDate.module.scss";

interface ProductDueDateProps {
  dueDate?: Date;
}

export default function ProductDueDate({ dueDate }: ProductDueDateProps) {
  if (!dueDate) {
    return (
      <div className={styles["product-due-date"]}>
        <SectionTitle title={"Date de livraison"} />
        <p>{"-"}</p>
      </div>
    );
  }

  return (
    <div className={styles["product-due-date"]}>
      <SectionTitle title={"Date de livraison"} />
      <p className={styles["due-date"]}>
        {new Date(dueDate).toLocaleDateString("fr-FR")}{" "}
        {`(${dateDiffInDays(new Date(), new Date(dueDate))} jours)`}
      </p>
    </div>
  );
}
