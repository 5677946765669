import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import AddressForm from "../../../components/forms/addressForm/addressForm";
import ContactForm from "../../../components/forms/ContactForm/ContactForm";
import { useModalComponent } from "../../../context/modalComponent.context";
import {
  addShop,
  createLicense,
  removeShop,
  updateLicense,
  updateShop,
} from "../../../requests/licenses";
import { ILicense } from "../../../types/license.types";
import { IContact } from "../../../types/logistic.types";
import style from "./create.module.scss";
import { IShop } from "../../../types/shop.types";
import { useNavigate, useRevalidator } from "react-router-dom";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import { INITIAL_CONTACT } from "../../../utils/shipping.init";
import StepByStepModal from "./StepByStepModal/StepByStepModal";
import ShopMiniature from "./ShopMiniature/ShopMiniature";
import Button from "../../../components/Button/Button";
import { useConfirmModal } from "../../../context/confirmationModalContext";
import ConfirmModalComp from "../../../components/confirmationModal/ConfirmModalComp";
import SectionFormTemplate from "../../../components/forms/sectionFormTemplate/SectionFormTemplate";

export default function Create({ data }: { data?: ILicense }) {
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const [contact, setContact] = useState<IContact | undefined>(
    data?.contact || undefined
  );
  const [shopsList, setShopsList] = useState<IShop[]>(
    (data?.shopsList || []).map((s) => s.shopData as IShop)
  );
  const { setConfirmModalIsOpen, setConfirmModalChildren } = useConfirmModal();

  useEffect(() => {
    if (data) {
      setContact(data.contact);
      setShopsList((data.shopsList || []).map((s) => s.shopData as IShop));
    }
  }, [data]);

  const saveLicenseMutation = useMutation(
    data ? updateLicense : createLicense,
    {
      onSuccess: (mutationData) => {
        if (mutationData.license && !data) {
          navigate(`/licenses/edit/${mutationData.license.id}`);
        } else if (mutationData.license) {
          navigate(`/licenses`);
        }
      },
      onError: (error) => {
        console.debug(error);
      },
    }
  );

  const addShopMutation = useMutation(addShop, {
    onSuccess: (mutationData) => {
      toast.success("Boutique enregistrée", TOAST_SUCCESS_OPTIONS);
      revalidator.revalidate();
    },
    onError: (error) => {
      toast.success(
        "Erreur lors de l'enregistrement de la boutique",
        TOAST_ERROR_OPTIONS
      );
      revalidator.revalidate();
    },
  });

  const updateShopMutation = useMutation(updateShop, {
    onSuccess: (mutationData) => {
      toast.success("Boutique modifiée", TOAST_SUCCESS_OPTIONS);
      revalidator.revalidate();
    },
    onError: (error) => {
      toast.success(
        "Erreur lors de la modification de la boutique",
        TOAST_ERROR_OPTIONS
      );
      revalidator.revalidate();
    },
  });

  const removeShopMutation = useMutation(removeShop, {
    onSuccess: (mutationData) => {
      toast.success("Boutique supprimée", TOAST_SUCCESS_OPTIONS);
      revalidator.revalidate();
    },
    onError: (error) => {
      toast.success(
        "Erreur lors de la suppression de la boutique",
        TOAST_ERROR_OPTIONS
      );
      revalidator.revalidate();
    },
  });

  const { setModalIsOpen, setModalChildren } = useModalComponent();

  return (
    <div className={style["create"]}>
      <div className={style["title"]}>License professionels</div>
      <form className={style["form"]} onSubmit={handleSubmit}>
        <SectionFormTemplate title="Contact">
          <ContactForm
            autoComplete={false}
            contact={contact || INITIAL_CONTACT}
            setContact={handleContactChange}
          />
        </SectionFormTemplate>
        <SectionFormTemplate title="Adresse">
          <AddressForm
            className={style["contact-form"]}
            contact={contact}
            defaultAddress={contact}
            isShippingAddress={false}
            onChange={handleContactChange}
          />
        </SectionFormTemplate>
        {data && (
          <>
            <hr className={style["separator"]} />
            <div>Boutiques</div>
            <div className={style["shop-list"]}>
              {(shopsList || []).map((shop, index) => (
                <ShopMiniature
                  handleAddShop={handleAddShop}
                  handleDeleteShop={(e) => handleConfirmDeleteShop(e, shop)}
                  key={`${shop.id}-${index}`}
                  shop={shop}
                />
              ))}
            </div>
            <Button
              className={style["add-shop-button"]}
              type={"button"}
              onClick={(e) => {
                e.preventDefault();
                handleAddShop();
              }}
            >
              Ajouter boutique
            </Button>
          </>
        )}
        <div className={style["footer"]}>
          <Button type={"submit"}>Enregistrer</Button>
        </div>
      </form>
    </div>
  );

  function handleAddShop(initialShop?: IShop) {
    setModalChildren(
      <StepByStepModal
        shopInit={initialShop}
        onValidate={(shop: IShop) => {
          setShopsList(shopsList ? [...shopsList, shop] : [shop]);
          setModalIsOpen(false);
          initialShop
            ? updateShopMutation.mutate({
                licenseId: data?.id as string,
                shopId: shop.id as string,
                shop,
              })
            : addShopMutation.mutate({ licenseId: data?.id as string, shop });
        }}
      />
    );
    setModalIsOpen(true);
  }

  function handleContactChange(contact: IContact) {
    setContact((prev) => {
      return { ...prev, ...contact };
    });
  }

  function handleConfirmDeleteShop(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    shop: IShop
  ) {
    e.stopPropagation();
    setConfirmModalIsOpen(true);
    setConfirmModalChildren(
      <ConfirmModalComp
        confirmButtonClassName={style["delete-button"]}
        title={"Etes-vous sur de vouloir supprimer cette boutique ?"}
        confirmText="Supprimer"
        onConfirm={() => handleDeleteShop(shop)}
      />
    );
  }

  function handleDeleteShop(shop: IShop) {
    if (!data) {
      return;
    }
    removeShopMutation.mutate({
      licenseId: data.id as string,
      shopId: shop.id as string,
    });
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (contact) {
      saveLicenseMutation.mutate({
        id: data?.id || "",
        license: { contact },
      });
    }
  }
}
