import { ChangeEvent, useEffect, useState } from "react";
import ContactForm from "../../../components/forms/ContactForm/ContactForm";
import style from "./createShippingPro.module.scss";
import {
  IShippingMethod,
  IShippingPoint,
  IShippingPro,
} from "../../../types/shipping.types";
import { IContact } from "../../../types/logistic.types";
import AddressForm from "../../../components/forms/addressForm/addressForm";
import { INITIAL_CONTACT } from "../../../utils/shipping.init";
import { INITIAL_SHIPPING_PRO } from "../../../utils/shippingPro.init";
import SelectStatus from "../../../components/selectStatusComponent/selectStatusComponent";
import { fetchShippingMethodsList } from "../../../requests/shippingMethod";
import { useQuery } from "react-query";
import { getShopsList, updateShop } from "../../../requests/shops";
import { IShop } from "../../../types/shop.types";
import ValidateButton from "../../../components/validateButton/validateButton";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import { addShippingPro } from "../../../requests/shippingPro";
import MOCK_SHIPPING_PRO from "../mock_data/shippingPro.json";
import { AutoFixHigh } from "@mui/icons-material";

const STATE_OPTIONS = [
  "DRAFT",
  "PENDING",
  "TRANSITING",
  "DELIVERED",
  "CANCELED",
];

export default function CreateShippingPro() {
  const [formValues, setFormValues] =
    useState<IShippingPro>(INITIAL_SHIPPING_PRO);
  const [shop, setShop] = useState<string>("");

  useEffect(() => {
    setFormValues((prev) => ({
      ...prev,
      sender: {
        ...prev.sender,
        organization: formValues.direction === "INCOMING" ? shop : "",
      },
      recipient: {
        ...prev.recipient,
        organization: formValues.direction === "INCOMING" ? "" : shop,
      },
    }));
  }, [shop, formValues.direction]);

  return (
    <div className={style["create-shipping-pro"]}>
      <h1>CREATE_SHIPPING_PRO</h1>
      <button
        onClick={fillForm}
        title="auto-fill"
        className={style["magic-tool"]}
      >
        <AutoFixHigh />
      </button>
      <form className={style["create-shipping-pro-form"]}>
        <h2>SHOP</h2>
        <div>
          <ShopSelect
            shippingPro={formValues}
            onChange={handleShopIdChange}
            setShop={setShop}
          />
        </div>
        <h2>DIRECTION</h2>
        <div className={style["direction-section"]}>
          <button
            className={`${style["normal-button"]} ${
              formValues.direction === "INCOMING" && style["selected"]
            }`}
            onClick={handleSelectDirection}
          >
            INCOMING
          </button>
          <button
            className={`${style["normal-button"]} ${
              formValues.direction === "OUTGOING" && style["selected"]
            }`}
            onClick={handleSelectDirection}
          >
            OUTGOING
          </button>
        </div>
        <h2>STATUS</h2>
        <SelectStatus
          state={formValues.state}
          options={STATE_OPTIONS}
          onChange={handleChangeState}
        />
        <h2>SENDER</h2>
        <h3>CONTACT</h3>
        <ContactForm
          contact={formValues.sender as IContact}
          setContact={handleSenderContactChange}
        />
        <h3>ADDRESS</h3>
        <AddressForm
          className={style["form-component"]}
          defaultAddress={formValues.sender}
          contact={formValues.sender as IContact}
          isShippingAddress
          onChange={(address, isShippingAddress) =>
            handleAddressChange(address, "sender", isShippingAddress)
          }
        />
        <h2>RECIPIENT</h2>
        <h3>CONTACT</h3>
        <ContactForm
          contact={formValues.recipient as IContact}
          setContact={handleRecipientContactChange}
        />
        <h3>ADDRESS</h3>
        <AddressForm
          className={style["form-component"]}
          defaultAddress={formValues.recipient}
          contact={formValues.recipient as IContact}
          isShippingAddress
          onChange={(address, isShippingAddress) =>
            handleAddressChange(address, "recipient", isShippingAddress)
          }
        />
        <h2>SHIPPING_DATE</h2>
        <div>
          <input
            type="date"
            value={formValues.shippingDate}
            onChange={handleDateChange}
          />
        </div>
        <h2>PERIOD</h2>
        <p>timezone bug (TO FIX LATER) e.g: if you want 11h put 10h</p>
        <div>
          <label>Start</label>
          <input
            type="datetime-local"
            name="start"
            value={
              formValues.period.start?.toISOString().substring(0, 16) || ""
            }
            onChange={handlePeriodChange}
          />
          <label>End</label>
          <input
            type="datetime-local"
            name="end"
            value={formValues.period.end?.toISOString().substring(0, 16) || ""}
            onChange={handlePeriodChange}
          />
        </div>
        <h2>SHIPPING_METHOD</h2>
        <ShippingMethodSelect
          shippingPro={formValues}
          onChange={handleShippingMethodForm}
        />
        <div className={style["validate-button-container"]}>
          <ValidateButton
            title={"CREATE SHIPPING PRO"}
            handleOnClick={handleSubmit}
          />
        </div>
      </form>
    </div>
  );

  async function handleSubmit(
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e?.preventDefault();
    try {
      const shippingId = await addShippingPro(formValues);
      toast.success("Shipping pro created", TOAST_SUCCESS_OPTIONS);
      if (!formValues.shopId) {
        throw new Error("No shop id");
      }
      const request =
        formValues.direction === "INCOMING"
          ? {
              id: formValues.shopId,
              nextIncomingShipping: {
                date: formValues.shippingDate || "",
                documentId: (shippingId as string) || "",
              },
            }
          : {
              id: formValues.shopId,
              nextOutgoingShipping: {
                date: formValues.shippingDate || "",
                documentId: (shippingId as string) || "",
              },
            };
      const res = await updateShop(request as IShop, formValues.shopId);

      toast.success(
        `Shop nextShipping ID Updated ${formValues.shopId}`,
        TOAST_SUCCESS_OPTIONS
      );
    } catch (error) {
      toast.error(
        "Un problème est survenu lors de la création du shipping pro",
        TOAST_ERROR_OPTIONS
      );
      console.error(error);
    }
  }

  function handleShopIdChange(shopId: string) {
    setFormValues({ ...formValues, shopId });
  }

  function handleShippingMethodForm(shippingMethod: IShippingMethod) {
    setFormValues((prev) => ({ ...prev, shippingMethod: shippingMethod }));
  }

  function handleSenderContactChange(contact: IContact) {
    setFormValues((prev) => ({
      ...prev,
      sender: {
        ...prev.sender,
        displayname: contact.displayname,
        familyname: contact.familyname,
        givenname: contact.givenname,
        email: contact.email,
        phone: contact.phone,
        organization: formValues.direction === "INCOMING" ? shop : "",
      },
    }));
  }

  function handleRecipientContactChange(contact: IContact) {
    setFormValues((prev) => ({
      ...prev,
      recipient: {
        ...prev.recipient,
        displayname: contact.displayname,
        familyname: contact.familyname,
        givenname: contact.givenname,
        email: contact.email,
        phone: contact.phone,
        organization: formValues.direction === "INCOMING" ? "" : shop,
      },
    }));
  }

  function handleAddressChange(
    address: IShippingPoint,
    name: "sender" | "recipient",
    isShippingAddress: boolean
  ) {
    setFormValues((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        zipCode: address.zipCode,
        country: address.country,
        countryCode: address.countryCode,
      },
    }));
  }

  function handleChangeState(state: ChangeEvent<HTMLSelectElement>) {
    setFormValues((prev) => ({
      ...prev,
      state: state.target.value,
    }));
  }

  function handlePeriodChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    if (!target.value) {
      return;
    }
    setFormValues((prev) => ({
      ...prev,
      period: {
        ...prev.period,
        [target.name]: new Date(target.value + ":00.000Z"),
      },
    }));
  }

  function handleDateChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    setFormValues((prev) => ({
      ...prev,
      shippingDate: target.value,
    }));
  }

  function handleSelectDirection(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.preventDefault();
    setFormValues((prev) => ({
      ...prev,
      direction: prev.direction === "INCOMING" ? "OUTGOING" : "INCOMING",
    }));
  }

  function fillForm() {
    const formatedShippingPro: IShippingPro = {
      ...MOCK_SHIPPING_PRO,
      period: {
        start: MOCK_SHIPPING_PRO.period.start
          ? new Date(MOCK_SHIPPING_PRO.period.start)
          : null,
        end: MOCK_SHIPPING_PRO.period.end
          ? new Date(MOCK_SHIPPING_PRO.period.end)
          : null,
      },
    };
    setFormValues(formatedShippingPro);
  }
}

interface ShippingMethodSelectProps {
  shippingPro: IShippingPro;
  onChange: (shippingMethod: IShippingMethod) => void;
}

function ShippingMethodSelect({
  shippingPro,
  onChange,
}: ShippingMethodSelectProps) {
  const { data, isFetching, refetch } = useQuery("shipping-method-list", () =>
    fetchShippingMethodsList()
  );

  return (
    <div>
      <select
        onChange={handleMethodSelect}
        value={shippingPro.shippingMethod.name}
      >
        <option value="placeholder">
          Sélectionner une méthode de livraison
        </option>
        {data &&
          data.shippingMethodsList &&
          data.shippingMethodsList.map(
            (value: IShippingMethod, index: number) => {
              return (
                <option key={index} value={value.name}>
                  {value.name}
                </option>
              );
            }
          )}
      </select>
    </div>
  );

  function handleMethodSelect(e: ChangeEvent<HTMLSelectElement>) {
    const SHIPPING_METHOD = data?.shippingMethodsList.find(
      (shpmthd) => shpmthd.name === e.target.value
    );
    if (!SHIPPING_METHOD) {
      return;
    }
    onChange(SHIPPING_METHOD);
  }
}

interface ShopSelectProps {
  shippingPro: IShippingPro;
  onChange: (shopId: string) => void;
  setShop: (shop: string) => void;
}

function ShopSelect({ shippingPro, onChange, setShop }: ShopSelectProps) {
  const { data, isFetching, refetch } = useQuery("shop-list", () =>
    getShopsList("")
  );

  return (
    <div>
      <select onChange={handleShopSelect} value={getShopName()}>
        <option value="placeholder">Sélectionner une boutique</option>
        {data &&
          data.shops &&
          data.shops.map((value: IShop, index: number) => {
            return (
              <option key={index} value={value.name}>
                {value.name}
              </option>
            );
          })}
      </select>
    </div>
  );

  function handleShopSelect(e: ChangeEvent<HTMLSelectElement>) {
    const SHOP = data?.shops.find((shop) => shop.name === e.target.value);
    if (!SHOP || !SHOP.id) {
      return;
    }
    onChange(SHOP.id);
    setShop(SHOP.name);
  }

  function getShopName() {
    const SHOP = data?.shops.find((shop) => shop.id === shippingPro.shopId);
    if (!SHOP) {
      return "";
    }
    return SHOP.name;
  }
}
