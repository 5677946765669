import { IDiscount } from "../../../types/accounting.types";
import style from "./item.module.scss";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import { Delete, RemoveCircle, RemoveCircleOutline } from "@mui/icons-material";

export default function Item({
  data,
  onDelete,
  onSubstract,
  refetch,
}: {
  data: IDiscount;
  onDelete: (id: string) => void;
  onSubstract: (id: string) => void;
  refetch: () => void;
}) {
  return (
    <div className={style["item"]}>
      <div className={style["item-element"]}>{data.code}</div>
      <div className={style["item-element"]}>{`${data.value} ${
        data.isRate ? "%" : "€"
      }`}</div>
      <div className={style["item-element"]}>
        {new Date(data?.expireAt as Date)?.toLocaleDateString("fr-FR") || ""}
      </div>
      <div className={style["item-element"]}>{data.baseQuantity}</div>
      <div className={style["item-element"]}>{data.quantity}</div>
      <div className={`${style["item-element"]} ${style["tools"]}`}>
        {/* <div
          className={style["substract-quantity"]}
          onClick={handleSubstractQuantity}
        >
          <RemoveCircleOutline />
        </div> */}
        <ProtectedComponent roles={["ROLE_ADMIN"]}>
          <Delete
            className={`${style["icon"]} ${style["delete-icon"]}`}
            onClick={handleDelete}
          />
        </ProtectedComponent>
      </div>
    </div>
  );

  function handleSubstractQuantity() {
    // if (window.confirm("Êtes-vous sûr de vouloir supprimer ce CODE PROMO ?")) {
    onSubstract(data.code);
    refetch();
    // }
  }

  function handleDelete() {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce CODE PROMO ?")) {
      onDelete(data.code);
    }
  }
}
