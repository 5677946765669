import {
  ICommentObject,
  IProduct,
} from "../../../../../../types/logistic.types";
import { isValidJSONString } from "../../../../../../utils/utils";
import SectionTitle from "../../../../../SectionTitle/SectionTitle";
import styles from "./productComment.module.scss";

interface ProductCommentProps {
  product: IProduct;
}

export default function ProductComment({ product }: ProductCommentProps) {
  const OTHER_ARTICLE: ICommentObject = parseComment();
  if (!OTHER_ARTICLE || !Object.entries(OTHER_ARTICLE).length) {
    return null;
  }
  return (
    <div className={styles["other-article"]}>
      <SectionTitle title={"Autres informations"} />
      {OTHER_ARTICLE.embauchoir && `Embauchoir: ${OTHER_ARTICLE.embauchoir}`}
      <span>{OTHER_ARTICLE.pochon && `Pochon: ${OTHER_ARTICLE.pochon}`}</span>
      {OTHER_ARTICLE.other && `Autres: ${OTHER_ARTICLE.other}`}
    </div>
  );

  function parseComment() {
    if (product.comments) {
      if (!isValidJSONString(product.comments)) {
        return null;
      } else {
        return JSON.parse(product.comments);
      }
    }
  }
}
