import { useState } from "react";
import translate from "../../../../translation";
import { IProduct } from "../../../../types/logistic.types";
import Image from "../../../image/Image";
import WorkshopId from "../../../workshopId/workshopId";
import style from "./modalProductMiniature.module.scss";
import { IOperation } from "../../../../types/accounting.types";
import useClickOutside from "../../../../hooks/useClickOutSide";

interface ModalProductProps {
  className?: string;
  handleOnClick?: () => void;
  product: IProduct;
  showOperationList?: boolean;
  isSelected?: boolean;
}
//
export function ModalProductMiniature({
  className,
  handleOnClick,
  product,
  isSelected = false,
  showOperationList = false,
}: ModalProductProps) {
  const [showOperation, setShowOperation] = useState<boolean>(false);
  const ref = useClickOutside<HTMLDivElement>(() => setShowOperation(false));

  return (
    <div
      className={style["modal-product-miniature-container"]}
      onClick={handleOnClick && handleOnClick}
      draggable
    >
      <div
        className={`${style["modal-product-miniature"]} ${className}`} //styles["selected-product"]
        draggable
        onClick={() => setShowOperation(!showOperation)}
        ref={ref}
      >
        <div className={style["modal-product-miniature-left"]}>
          <div className={style["product-operation-group-miniature"]}>
            {translate(product.productGroup)}
          </div>
          <div className={style["brand"]}>{product.brand}</div>
          <div>
            <WorkshopId workshopId={product.workshopId} size={"x-small"} />
          </div>
        </div>
        <Image
          className={style["image-miniature"]}
          src={product?.incomingPicturesList?.at(0)}
          showTools={false}
          draggable={false}
        />
      </div>
      {showOperationList && showOperation && (
        <div className={style["operation-list-container"]}>
          <ul className={style["operation-list-wrapper"]}>
            {product.operationsList.map((op: IOperation, index: number) => {
              return (
                <li
                  key={index}
                  className={`${op.canceled && style["strikethrough"]}`}
                >
                  {op.description}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
