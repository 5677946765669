import { Backspace, Close, Dialpad, Keyboard } from "@mui/icons-material";
import style from "./searchByWorkshopId.module.scss";
import { useState } from "react";
import useClickOutside from "../../hooks/useClickOutSide";
import { isDesktop, isTablet } from "react-device-detect";

interface SearchByWorkshopIdProps {
  workshopId: string;
  setWorkshopId: (workshopId: string) => void;
}

export default function SearchByWorkshopId({
  workshopId,
  setWorkshopId,
}: SearchByWorkshopIdProps) {
  const [showPad, setShowPad] = useState<boolean>(false);
  const ref = useClickOutside<HTMLDivElement>(handleClickOutside);

  return (
    <div className={style["search-by-workshop-id"]}>
      {workshopId && (
        <div
          className={style["reset-workshopId-button"]}
          onClick={handleButtonReset}
        >
          <Close />
        </div>
      )}
      <div className={style["search-by-workshop-id-container"]} ref={ref}>
        <div className={style["input-section"]} onClick={handleShowNumericPad}>
          <input
            className={style["workshop-id-input"]}
            inputMode={"none"}
            onChange={handleWorkshopIdChange}
            placeholder="rechercher par numéro de ticket..."
            type="text"
            value={workshopId}
          />
          <div className={style["dial-pad-button"]}>
            <Dialpad />
          </div>
        </div>
        {showPad && (
          <div className={style["numeric-pad-dropdown"]}>
            <div className={style["numeric-pad-dropdown-container"]}>
              <div className={style["numeric-pad-grid"]}>
                <PadButton value={"1"} onClick={handleButtonPress} />
                <PadButton value={"2"} onClick={handleButtonPress} />
                <PadButton value={"3"} onClick={handleButtonPress} />
                <PadButton value={"4"} onClick={handleButtonPress} />
                <PadButton value={"5"} onClick={handleButtonPress} />
                <PadButton value={"6"} onClick={handleButtonPress} />
                <PadButton value={"7"} onClick={handleButtonPress} />
                <PadButton value={"8"} onClick={handleButtonPress} />
                <PadButton value={"9"} onClick={handleButtonPress} />
                <PadButton
                  icon={<Backspace />}
                  onClick={handleButtonErase}
                  onDoubleClick={handleButtonReset}
                />
                <PadButton value={"0"} onClick={handleButtonPress} />
                <PadButton value={"-"} onClick={handleButtonPress} />
              </div>
            </div>
            <div className={style["beautiful-div"]}></div>
          </div>
        )}
      </div>
    </div>
  );

  function handleWorkshopIdChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    setWorkshopId(target.value);
  }

  function handleButtonErase() {
    if (workshopId.length > 0) {
      setWorkshopId(workshopId.substring(0, workshopId.length - 1));
    }
  }

  function handleButtonReset() {
    setWorkshopId("");
  }

  function handleButtonPress(value?: string) {
    if (value) {
      setWorkshopId(workshopId + value);
    }
  }

  function handleClickOutside() {
    setShowPad(false);
  }

  function handleShowNumericPad() {
    setShowPad(!showPad);
  }
}

interface PadButtonProps {
  icon?: JSX.Element;
  onClick: (value?: string) => void;
  onDoubleClick?: () => void;
  value?: string;
}

function PadButton({ icon, onClick, onDoubleClick, value }: PadButtonProps) {
  return (
    <div
      className={style["numeric-pad-item"]}
      onClick={() => onClick(value)}
      onDoubleClick={onDoubleClick}
    >
      {value ? value : icon}
    </div>
  );
}
