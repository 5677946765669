import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";
import { useQuery } from "react-query";
import { deleteCart, fetchCartsList } from "../../requests/carts";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import ListHeaderComponent from "../../components/listHeaderComponent/listHeaderComponent";
import Item from "./item/item";
import { CART_STATE } from "../../utils/booking.init";
import { ICart } from "../../types/booking.type";
import SearchByKeyComponent from "../../components/searchByKeyComponent/searchByKeyComponent";
import FilterByStatus from "../../components/filterByStatus/filterByStatus";
import { queryObject } from "../../types/query.types";
import { useAuth } from "../../context/auth.context";
import useSaveQueryStorage, {
  loadQueryStorage,
} from "../../hooks/useSaveQueryStorage";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import Button from "../../components/Button/Button";
import ListPageHeader from "../referral/ListPageHeader/ListPageHeader";
import FiltersContainer from "../../components/Filters/FiltersContainer";
import ListHeader from "./ListHeader/ListHeader";

export const INITIAL_QUERY: queryObject = {
  field: "",
  value: "",
  status: "",
  limit: 10,
  offset: 0,
  state: "CART_VALIDATED",
};

const CART_SEARCH_OPTIONS = [
  "contact.phone",
  "contact.displayname",
  "contact.organization",
  "contact.email",
];

let controller = new AbortController();

export default function Carts() {
  const [query, setQuery] = useSaveQueryStorage(INITIAL_QUERY, "cart");
  const { data, isLoading, refetch } = useQuery(
    ["carts", query],
    () => fetchCartsList(formatedQuery()),
    { keepPreviousData: true }
  );
  const { roles } = useAuth();

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  if (!data || !data.cartsList) {
    return <>Error data is missing !</>;
  }

  return (
    <div className={styles["list-page"]}>
      <ListPageHeader
        title={"Paiements"}
        actionSection={
          <Link onClick={() => window.scrollTo(0, 0)} to={"./create"}>
            <Button>+ Ajouter nouveau</Button>
          </Link>
        }
      />
      <FiltersContainer>
        <SearchByKeyComponent
          query={query}
          setQuery={setQuery}
          optionsList={CART_SEARCH_OPTIONS}
        />
        <FilterByStatus
          query={query}
          setQuery={setQuery}
          optionsList={CART_STATE}
          field={"status"}
        />
      </FiltersContainer>
      <div className={styles["list-container"]}>
        <div className={styles["paging-component-top"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
        <div className={styles["list"]}>
          <table className={styles["table"]}>
            <ListHeader />
            <tbody>
              {data.cartsList.map((cart: ICart, index: number) => {
                return (
                  <Item
                    key={index}
                    data={cart}
                    onDelete={handleDelete}
                    zIndex={query.limit - index}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={styles["paging-component"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
      </div>
    </div>
  );

  async function handleDelete(id?: string) {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette commande ?")) {
      controller.abort();
      try {
        controller = new AbortController();
        await deleteCart(id, controller.signal);
        refetch();
      } catch (error) {
        console.error(error);
      }
    }
  }

  function formatedQuery() {
    let formatedQuery: string = "";

    if (query.limit) {
      formatedQuery = formatedQuery + `?limit=${query.limit}`;
    }

    formatedQuery = formatedQuery + `&offset=${query.offset * query.limit}`;

    if (query.field && query.value) {
      formatedQuery = formatedQuery + `&${query.field}=${query.value}`;
    }
    if (query.sort) {
      formatedQuery = formatedQuery + `${query.sort}`;
    } else {
      formatedQuery += `&sort_field=createdAt&sort_direction=desc`;
    }

    if (query.state && query.state !== "Tous") {
      formatedQuery = formatedQuery + `&state=${query.state}`;
    }

    return formatedQuery;
  }
}
