import {
  ICrossSell,
  ICrossSellProduct,
} from "../../../../types/accounting.types";
import LoadingSpinner from "../../../loadingSpinner/loadingSpinner";
import CrossSellCard from "../CrossSellCard/CrossSellCard";
import style from "./crossSellList.module.scss";

interface CrossSellListProps {
  list?: ICrossSell[];
  selectedCrossSells: ICrossSellProduct[];
  setSelectedCrossSells: (value: ICrossSellProduct[]) => void;
}

export default function CrossSellList({
  list,
  selectedCrossSells,
  setSelectedCrossSells,
}: CrossSellListProps) {
  if (!list) {
    return <LoadingSpinner color="gold" />;
  }
  return (
    <div className={style["add-cross-sell-modal-list-container"]}>
      <div className={style["add-cross-sell-modal-list"]}>
        {list.map((item: ICrossSell, index: number) => {
          return (
            <CrossSellCard
              key={index}
              item={item}
              isSelected={isSelected(item.slug)}
              handleOnClick={() => handleSelectItem(item)}
              selectedCrossSells={selectedCrossSells}
              setSelectedCrossSells={setSelectedCrossSells}
            />
          );
        })}
      </div>
    </div>
  );

  function handleSelectItem(item: ICrossSell) {
    if (selectedCrossSells.find((cs) => cs.slug === item.slug)) {
      return;
    }
    setSelectedCrossSells([
      ...selectedCrossSells,
      { ...item, cartQuantity: 1 },
    ]);
  }

  function isSelected(slug: string | undefined): boolean {
    return !!selectedCrossSells.find((cs) => cs.slug === slug);
  }
}
