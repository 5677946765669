import React, { useState } from "react";
import GenerateProLabel from "../GenerateProLabel/GenerateProLabel";
import GenerateLabel from "../GenerateLabel/GenerateLabel";
import { IShop } from "../../../../types/shop.types";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../../../../utils/toast.options";
import { GP_SHIPPING_ADDRESS } from "../../../../utils/shipping.init";
import {
  IShipping,
  IShippingPoint,
  IShippingPro,
  PickUpInfo,
} from "../../../../types/shipping.types";
import { IShippingMethod } from "../../../../types/accounting.types";
import { generateShippingProAndChronopostNoLinkToShop } from "../../../../requests/shippingPro";
import { createShippingAndChronopostLabel } from "../../../../requests/shipping";

interface GenerateLabelSectionProps {
  direction: string | null;
  shop: IShop | null;
  pickUpInfo: PickUpInfo | null;
  resetForm: () => void;
  shippingMethod: IShippingMethod | null;
  shippingInfo: IShippingPoint | null;
  setDocument: (value: string | null) => void;
  isOtherShop: boolean;
}

export default function GenerateLabelSection({
  direction,
  shippingMethod,
  shop,
  shippingInfo,
  pickUpInfo,
  resetForm,
  setDocument,
  isOtherShop,
}: GenerateLabelSectionProps) {
  const [shippingPro, setShippingPro] = useState<IShippingPro | null>(null);
  const [shipping, setShipping] = useState<IShipping | null>(null);

  if (shop) {
    return (
      <GenerateProLabel
        generateProLabel={generateProLabel}
        resetForm={resetForm}
        setDocument={setDocument}
        shippingPro={shippingPro}
        setShippingPro={setShippingPro}
      />
    );
  }
  if (isOtherShop && shippingInfo) {
    return (
      <GenerateProLabel
        generateProLabel={generateProLabel}
        resetForm={resetForm}
        setDocument={setDocument}
        shippingPro={shippingPro}
        setShippingPro={setShippingPro}
      />
    );
  }
  if (shippingInfo) {
    return (
      <GenerateLabel
        generateLabel={generateLabel}
        resetForm={resetForm}
        setDocument={setDocument}
        shipping={shipping}
        setShipping={setShipping}
      />
    );
  }
  return <></>;

  async function generateProLabel(setIsLoading: (value: boolean) => void) {
    setIsLoading(true);
    try {
      const shippingProDocument = shop
        ? generateShippingProFromShop()
        : generateShippingProFromForm();
      if (!shippingProDocument) {
        return toast.error("Un problème est survenu", TOAST_ERROR_OPTIONS);
      }
      const { shippingPro } =
        await generateShippingProAndChronopostNoLinkToShop(shippingProDocument);
      if (!shippingPro) {
        return toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
      }
      setShippingPro(shippingPro);
      setDocument(shippingPro.externalShipping?.reservationNumber || null);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  function formValidationError() {
    if (
      !shippingInfo?.givenname ||
      !shippingInfo?.familyname ||
      !shippingInfo?.phone ||
      !shippingInfo?.email ||
      !shippingInfo?.displayname
    )
      return "contact";
    if (!shippingInfo.address1) {
      return "addresse";
    }
    return "";
  }

  async function generateLabel(setIsLoading: (value: boolean) => void) {
    setIsLoading(true);
    try {
      if ((shippingMethod?.shippingService === "CHRONOPOST-2SHOP" || shippingMethod?.shippingService === "CHRONOPOST-EUR")) {
        if (formValidationError()) {
          return toast.error(
            `Veuillez remplir les champs requis. [${formValidationError()}]`,
            TOAST_ERROR_OPTIONS
          );
        }
        const shippingDocument = generateShippingToShop();
        if (!shippingDocument) {
          return toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
        }
        const { shipping } = await createShippingAndChronopostLabel(
          shippingDocument
        );
        setShipping(shipping);
        setDocument(shipping.externalShipping?.reservationNumber || null);
        toast.success("Bon chronopost crée avec succès.", TOAST_ERROR_OPTIONS);
      }
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    } finally {
      setIsLoading(false);
    }
  }

  function generateShippingProFromShop() {
    if (!shop || !shippingMethod) {
      return;
    }
    function getShippingPeriod(shop: IShop) {
      if (!shop.nextIncomingShipping || !shop.nextIncomingShipping.date) {
        return { start: null, end: null };
      }
      return {
        start: new Date(
          shop.nextIncomingShipping.date +
            "T" +
            shop.shippingMethod.businessHours[0].start
        ),
        end: new Date(
          shop.nextIncomingShipping.date +
            "T" +
            shop.shippingMethod.businessHours[0].end
        ),
      };
    }

    const shippingPro: IShippingPro = {
      state: "PENDING",
      trackId: [],
      shopId: shop.id,
      sender: direction === "OUTGOING" ? GP_SHIPPING_ADDRESS : shop.contact,
      recipient: direction === "OUTGOING" ? shop.contact : GP_SHIPPING_ADDRESS,
      direction: direction || "INCOMING",
      shippingDate: shop.nextOutgoingShipping?.date || "",
      period: getShippingPeriod(shop),
      shippingMethod: shop.shippingMethod,
      externalShipping: {
        reservationNumber: "",
        externalId: "",
        pickUpId: "",
      },
    };

    return shippingPro;
  }

  function generateShippingProFromForm() {
    if (!shippingMethod || !shippingInfo) {
      return;
    }

    const shippingPro: IShippingPro = {
      state: "PENDING",
      trackId: [],
      // shopId: "",
      sender:
        direction === "OUTGOING"
          ? GP_SHIPPING_ADDRESS
          : { ...shippingInfo, givenname: pickUpInfo?.name || "" },
      recipient:
        direction === "OUTGOING"
          ? { ...shippingInfo, givenname: pickUpInfo?.name || "" }
          : GP_SHIPPING_ADDRESS,
      direction: direction || "INCOMING",
      shippingDate: new Date().toISOString().substring(0, 10) || "",
      period: { start: new Date(), end: new Date() },
      shippingMethod: shippingMethod,
      externalShipping: {
        reservationNumber: "",
        externalId: "",
        pickUpId: "",
      },
    };

    return shippingPro;
  }

  function generateShippingToShop() {
    if (!shippingMethod || !shippingInfo) {
      return;
    }

    const shipping: IShipping = {
      direction: direction || "INCOMING",
      contact: shippingInfo,
      period: { start: new Date(), end: new Date() },
      sender:
        direction === "OUTGOING"
          ? GP_SHIPPING_ADDRESS
          : { ...shippingInfo, givenname: pickUpInfo?.name || "" },
      recipient:
        direction === "OUTGOING"
          ? { ...shippingInfo, givenname: pickUpInfo?.name || "" }
          : GP_SHIPPING_ADDRESS,
      shippingMethod: shippingMethod,
      productsList: [],
      state: "PENDING",
      externalShipping: {
        reservationNumber: "",
        externalId: "",
        pickUpId: pickUpInfo?.pickUpId || "",
      },
    };

    return shipping;
  }
}
