import { Close } from "@mui/icons-material";
import translate from "../../translation";
import style from "./searchInput.module.scss";

interface SearchInputProps {
  query: string;
  setQuery: (query: string) => void;
}

export default function SearchInput({ query, setQuery }: SearchInputProps) {
  return (
    <div className={style["search-by-key-component"]}>
      <div className={style["input-value-container"]}>
        <input
          type="text"
          value={query}
          placeholder={`rechercher...`}
          onChange={({ target }) => setQuery(target.value)}
          className={style["input-value"]}
        />
        {query && (
          <div
            className={style["close-icon-container"]}
            onClick={() => setQuery("")}
          >
            <Close />
          </div>
        )}
      </div>
    </div>
  );
}
