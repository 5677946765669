import { useState } from "react";
import { IShipping, IShippingPro } from "../../../../types/shipping.types";
import style from "./generateProLabel.module.scss";
import ValidateButton from "../../../../components/validateButton/validateButton";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../../utils/toast.options";
import { toast } from "react-toastify";
import { cancelChronopostLabel } from "../../../../requests/shipping";
import DownloadLabelButton from "../DownloadLabelButton/DownloadLabelButton";

interface GenerateProLabelProps {
  generateProLabel: (setIsLoading: (value: boolean) => void) => void;
  resetForm: () => void;
  setDocument: (value: string | null) => void;
  shippingPro: IShippingPro | null;
  setShippingPro: (shippingPro: IShippingPro | null) => void;
}

export default function GenerateProLabel({
  resetForm,
  generateProLabel,
  setDocument,
  shippingPro,
  setShippingPro,
}: GenerateProLabelProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState<boolean>(false);

  function resetProShopForm() {
    resetForm();
    setShippingPro(null);
  }

  return (
    <>
      {shippingPro ? (
        <div className={style["generate-label-section"]}>
          <DownloadLabelButton
          shipping={shippingPro as IShipping}
            // reservationNumber={shippingPro.externalShipping?.reservationNumber}
          />
          <ValidateButton
            className={style["generate-label-button"]}
            title="Annuler"
            handleOnClick={handleCancelShippingLabel}
            isLoading={isLoadingCancel}
          />
          <ValidateButton
            className={style["generate-label-button"]}
            title="Génerer un nouveau bon"
            handleOnClick={resetProShopForm}
            // isLoading={isLoadingCancel}
          />
        </div>
      ) : (
        <ValidateButton
          className={style["generate-label-button"]}
          title="Génerer le bon CHRONPOST"
          handleOnClick={() => generateProLabel(setIsLoading)}
          isLoading={isLoading}
        />
      )}
    </>
  );

  async function handleCancelShippingLabel() {
    if (!shippingPro || !shippingPro.id) {
      return toast.error("ShippingPro ID is missing", TOAST_ERROR_OPTIONS);
    }
    setIsLoadingCancel(true);
    try {
      await cancelChronopostLabel(shippingPro.id);
      toast.success(
        "Le bon Chronppost a été annulé avec succès",
        TOAST_SUCCESS_OPTIONS
      );
    } catch (error: any) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    } finally {
      setIsLoadingCancel(false);
      setShippingPro(null);
      setDocument(null);
    }
  }
}
