import { useEffect, useState } from "react";
import { fetchOperationsList } from "../../../../../../requests/operation";
import { IProduct } from "../../../../../../types/logistic.types";
import styles from "./operationForm.module.scss";
import { IOperation } from "../../../../../../types/accounting.types";
import { useQuery } from "react-query";
import clsx from "clsx";
import { OPERATION_DEFAULT } from "../../../../../../utils/operation.init";
import AddOperationButton from "./AddOperationButton/AddOperationButton";
import OperationEntry from "./OperationEntry/OperationEntry";

interface OperationsFormProps {
  product: IProduct;
  onChange: (product: IProduct) => void;
}

export default function OperationsForm({
  product,
  onChange,
}: OperationsFormProps) {
  const { data, isFetching, error, refetch } = useQuery(
    "quick-access-operationListSelect",
    () =>
      fetchOperationsList(
        `productGroup=${product.productGroup}&operationGroup=${product.operationGroup}`
      )
  );
  const [operations, setOperations] = useState<IOperation[] | undefined>(
    undefined
  );

  useEffect(() => {
    refetch();
  }, [product.productGroup, product.operationGroup]);

  useEffect(() => {
    if (product) {
      setOperations(product.operationsList);
    }
  }, [product]);

  return (
    <div className={clsx(styles["operation-form"])}>
      {(operations || []).map((operation: IOperation, index: number) => (
        <OperationEntry
          key={`product-${product.id || ""}-${index}-operation`}
          operationGroup={product.operationGroup}
          datalist={data?.operations || []}
          operation={operation}
          onChange={(updatedOperation) => {
            updateOperationAtIndex(updatedOperation, index);
          }}
          onDelete={(operation: IOperation) =>
            handleDeleteOperation(operation, index)
          }
        />
      ))}
      <AddOperationButton onClick={handleAddOperation} />
    </div>
  );

  function handleDeleteOperation(operation: IOperation, index: number) {
    let tmpOperations = operations ? [...operations] : [];
    if (tmpOperations.length > index) {
      tmpOperations.splice(index, 1);
    }
    setOperations(tmpOperations);
    onChange({ ...product, operationsList: tmpOperations });
  }

  function handleAddOperation(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    event.preventDefault();
    setOperations([...(operations || []), OPERATION_DEFAULT]);
  }

  function updateOperationAtIndex(operation: IOperation, index: number) {
    let tmpOperations = operations ? [...operations] : [];
    if (tmpOperations.length > index) {
      tmpOperations[index] = operation;
    } else {
      tmpOperations.push(operation);
    }
    setOperations(tmpOperations);
    onChange({ ...product, operationsList: tmpOperations });
  }
}
