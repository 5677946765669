import { Close, Edit } from "@mui/icons-material";
import { IShippingPoint } from "../../../../types/shipping.types";
import style from "./addressInfo.module.scss";

interface AddressInfoProps {
  handleEdit: () => void;
  edit: boolean;
  shippingForm: IShippingPoint;
}

export default function AddressInfo({
  edit,
  handleEdit,
  shippingForm,
}: AddressInfoProps) {
  const FILL_ADDRESS_FORM = 1;
  // const FORM_FILLED = 2;

  return (
    <div className={style["address-information"]}>
      {shippingForm?.address1 && (
        <div>
          <div>
            {shippingForm?.address1}
            {shippingForm?.address2 ? " " + shippingForm?.address2 : ""}
          </div>
          <div>
            {shippingForm?.zipCode} {shippingForm?.city}
          </div>
        </div>
      )}
      <div className={style["edit-icon-container"]} onClick={handleEdit}>
        {edit ? (
          <Close className={style["edit-icon"]} />
        ) : (
          <Edit className={style["edit-icon"]} />
        )}
      </div>
    </div>
  );
}
