import { useDragScroll } from "../../../../../../hooks/useDragScroll";
import { ILogistic, IProduct } from "../../../../../../types/logistic.types";
import AddWidget from "../AddWidget/AddWidget";
import { ProductWidget } from "../ProductWidget/ProductWidget";
import styles from "./sliderContainer.module.scss";

interface FormProduct extends IProduct {
  selected?: boolean;
  hasError?: boolean;
}

export default function SliderContainer({
  disabled = false,
  logistic,
  onProductSelected,
  onAddClicked,
  onProductDelete,
}: {
  disabled?: boolean;
  logistic: ILogistic;
  onProductSelected: (product: FormProduct) => void;
  onAddClicked: () => void;
  onProductDelete: (product: IProduct) => void;
}) {
  const [ref] = useDragScroll();

  return (
    <div ref={ref} className={styles["slider-container"]}>
      <div className={styles["slider-content"]}>
        {logistic.productsList.map((product: IProduct) => (
          <ProductWidget
            key={product.id as string}
            product={product}
            onClick={handleProductSelected}
            onDeleteClick={handleDeleteClicked}
          />
        ))}
        <AddWidget onClick={onAddClicked} disabled={disabled} />
      </div>
    </div>
  );

  function handleDeleteClicked(product: IProduct): void {
    onProductDelete && onProductDelete(product);
  }

  function handleProductSelected(product: IProduct): void {
    onProductSelected && onProductSelected(product);
  }
}
