import { PencilSquare, Trash } from "react-bootstrap-icons";
import style from "./item.module.scss";
import { Link } from "react-router-dom";
import { useContext } from "react";

import { IOperation } from "../../../types/accounting.types";
import translate from "../../../translation";
import { Delete, Edit } from "@mui/icons-material";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import { useConfirmModal } from "../../../context/confirmationModalContext";
import ConfirmModalComp from "../../../components/confirmationModal/ConfirmModalComp";

export default function Item({
  data,
  onDelete,
}: {
  data: IOperation;
  onDelete: (id: string) => void;
}) {
  const { setConfirmModalIsOpen, setConfirmModalChildren } = useConfirmModal();

  return (
    <div className={style["item"]}>
      <div className={style["item-element"]}>{data.description}</div>
      <div className={style["item-element"]}>
        {data.operationGroup && translate(data.operationGroup)}
      </div>
      <div className={style["item-element"]}>
        {data.operationType && translate(data.operationType)}
      </div>
      <div className={style["item-element"]}>
        {data.productGroup && translate(data.productGroup)}
      </div>
      <div className={style["item-element"]}>{(data.price || 0) / 100} €</div>
      <div className={style["item-element"]}>{data.comments}</div>
      <ProtectedComponent roles={["ROLE_ADMIN"]}>
        <div className={`${style["item-element"]} ${style["tools"]}`}>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            className={style.mylink}
            to={`/operation-pro/edit/${data.slug}`}
          >
            <Edit className={style["icon"]} />
          </Link>
          <Delete className={style["icon"]} onClick={handleConfirmDelete} />
        </div>
      </ProtectedComponent>
    </div>
  );

  function handleConfirmDelete() {
    setConfirmModalChildren(
      <ConfirmModalComp
        confirmButtonClassName={style["delete-button"]}
        title={"Voulez-vous supprimer cette opération ?"}
        confirmText="Supprimer"
        onConfirm={() => onDelete(data.slug)}
      />
    );
    setConfirmModalIsOpen(true);
  }
}
