import { createContext, useContext, useState } from "react";

interface WebCamCaptureContextProps {
  webcamFileList: File[];
  webCamIsOpen: string;
  pictureSlug: string;
  setWebcamFileList: (value: File[]) => void;
  setWebCamIsOpen: (value: string) => void;
  setPictureSlug: (value: string) => void;
}

const defaultContextProps: WebCamCaptureContextProps = {
  webcamFileList: [],
  setWebcamFileList: (value: File[]) => {},
  webCamIsOpen: "",
  setWebCamIsOpen: (value: string) => {},
  pictureSlug: "",
  setPictureSlug: (value: string) => {},
};

export const WebCamCaptureContext =
  createContext<WebCamCaptureContextProps>(defaultContextProps);

export default function WebCamCaptureProvider(props: any) {
  const [webcamFileList, setWebcamFileList] = useState<File[]>([]);
  const [webCamIsOpen, setWebCamIsOpen] = useState<string>("");
  const [pictureSlug, setPictureSlug] = useState<string>("");

  return (
    <WebCamCaptureContext.Provider
      value={{
        webcamFileList,
        setWebcamFileList,
        webCamIsOpen,
        setWebCamIsOpen,
        pictureSlug,
        setPictureSlug,
      }}
    >
      {props.children}
    </WebCamCaptureContext.Provider>
  );
}

export function useWebCamCapture() {
  const context = useContext(WebCamCaptureContext);

  if (context === undefined) {
    throw new Error(
      "useWebCamCapture must be used within a WebCamCaptureProvider"
    );
  }
  return context;
}
