import { Edit } from "@mui/icons-material";
import { IShippingPoint } from "../../../../../types/shipping.types";
import style from "./contactInfo.module.scss";

interface ContactInfoProps {
  edit: () => void;
  shippingForm: IShippingPoint;
}

export default function ContactInfo({ edit, shippingForm }: ContactInfoProps) {
  return (
    <div className={style["contact-information"]}>
      <div>
        <div>{shippingForm.displayname || ""}</div>
        <div>{shippingForm.phone || ""}</div>
        <div>{shippingForm.email || ""}</div>
        <div>{shippingForm.organization || ""}</div>
      </div>
      <div className={style["edit-icon-container"]} onClick={edit}>
        <Edit className={style["edit-icon"]} />
      </div>
    </div>
  );
}
