import Select from "../../../../components/Select/Select";
import { IAddress } from "../../../../types/customer.type";
import { IShippingPoint } from "../../../../types/shipping.types";
import style from "./customerAddressSelect.module.scss";

interface CustomerAddressSelectProps {
  addressesList: IAddress[];
  address: IShippingPoint | null;
  setAddress: (address: IShippingPoint | null) => void;
}

export default function CustomerAddressSelect({
  addressesList,
  address,
  setAddress,
}: CustomerAddressSelectProps) {
  return (
    <div className={style["address-select-container"]}>
      <label>Adresses du client</label>
      <Select
        optionsList={addressesList.map((ad) => stringifyAddress(ad))}
        value={address ? stringifyAddress(address) : ""}
        setValue={handleSetAddress}
        placeholder="Choisir une adresse..."
        className={style["address-select"]} // this is for the select container
        style={{ width: "100%" }} //this is for the dropdown
      />
    </div>
  );

  function handleSetAddress(address: string) {
    const found = addressesList.find((ad) => stringifyAddress(ad) === address);
    if (!found) {
      return;
    }
    setAddress({ ...found, address2: "" });
  }

  function stringifyAddress(address: IAddress | IShippingPoint) {
    return [
      address.address1,
      address.address2,
      address.zipCode,
      address.city,
    ].join(" ");
  }
}
