import {
  createBrowserRouter,
  Navigate,
  redirect,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from "../pages/error-page";
import { route as professionalCartsRoute } from "../routes/professionalCart/route";
import { route as cartsRoute } from "../routes/cart/route";
import { route as loginRoute } from "../routes/login/route";
import { route as usersRoute } from "../routes/users/route";
import { route as proAccountRoute } from "../routes/proAccount/route";
import { route as crossSellingRoute } from "../routes/crossSelling/route";
import { route as operationRoute } from "../routes/operations/route";
import { route as operationProRoute } from "../routes/operationsPro/route";
import { route as shippingMethodRoute } from "../routes/shippingMethods/route";
import { route as estimateRoute } from "../routes/estimates/route";
import { route as logisticRoute } from "../routes/logistics/route";
import { route as quoteRoute } from "../routes/quotes/route";
import { route as shippingRoute } from "../routes/shipping/route";
import { route as shippingProRoute } from "../routes/shippingPro/route";
import { route as shopRoute } from "../routes/shops/route";
import { route as overviewRoute } from "../routes/overview/route";
import { route as discountRoute } from "../routes/discount/route";
import { route as quickAccessRoute } from "../routes/quickAccess/route";
import { route as customersRoute } from "../routes/customers/route";
import { route as paymentsRoute } from "../routes/payments/route";
import { route as invoiceProRoute } from "../routes/invoicePro/route";
import { route as referralRoute } from "../routes/referral/route";
import { route as licensesRoute } from "../routes/licenses/route";
import { route as reportsRoute } from "../routes/reports/route";
import { route as dashboardRoute } from "../routes/dashboard/route";
import { route as chronotraceRoute } from "../routes/chronotrace/route";
import { route as devToolsRoute } from "../routes/dev_tools/route";
import Home from "../routes/home";
import { logoutRequest } from "../requests/user";
import { Layout } from "../App";
import { ProtectedComponent } from "../components/protectedComponent/protectedComponent";

export async function authloader() {
  try {
    const connected = await fetch(
      `${process.env.REACT_APP_API_URL}/auth/logged`,
      {
        credentials: "include",
      }
    );
    if (connected.status === 401) {
      logoutRequest();
      localStorage.clear();
      return redirect("/login");
    }
    return null;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export default function CustomRouter() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      errorElement: <ErrorPage />,
      loader: authloader,
      children: [
        {
          path: "/",
          element: (
            <ProtectedComponent roles={["ROLE_ADMIN"]}>
              <Home />
            </ProtectedComponent>
          ),
          errorElement: <ErrorPage />,
          loader: authloader,
        },
        professionalCartsRoute,
        dashboardRoute,
        cartsRoute,
        operationRoute,
        operationProRoute,
        shippingMethodRoute,
        estimateRoute,
        quoteRoute,
        logisticRoute,
        shippingRoute,
        usersRoute,
        overviewRoute,
        discountRoute,
        quickAccessRoute,
        customersRoute,
        proAccountRoute,
        shippingProRoute,
        crossSellingRoute,
        paymentsRoute,
        devToolsRoute,
        invoiceProRoute,
        licensesRoute,
        reportsRoute,
        chronotraceRoute,
        shopRoute,
        referralRoute,
      ],
    },
    loginRoute,
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ]);

  return <RouterProvider router={router}></RouterProvider>;
}
