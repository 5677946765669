import { FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "./create.module.scss";
import { IValidationError } from "../../../types";
import InputContainer from "../../../components/forms/inputs/inputContainer/inputContainer";
import AddressForm from "../../../components/forms/addressForm/addressForm";
import { IAddress, ICustomer } from "../../../types/customer.type";
import { IShippingPoint } from "../../../types/shipping.types";
import { Delete } from "@mui/icons-material";
import { addCustomer, updateCustomer } from "../../../requests/customer";
import Input from "../../../components/inputs/Input/Input";

export const INITIAL_CUSTOMER: ICustomer = {
  familyname: "",
  givenname: "",
  phone: "",
  email: "",
  verified: false,
  addresses: [],
};

export const INITIAL_ADDRESS: IAddress = {
  address1: "",
  address2: "",
  zipCode: "",
  countryCode: "FR",
  city: "",
  country: "France",
};

export default function Create({ data }: { data?: ICustomer }) {
  const [formValues, setFormValues] = useState<ICustomer>(
    data || INITIAL_CUSTOMER
  );
  const [selectedAddress, setSelectedAddress] = useState<number>(-1);
  const [validationError, setValidationError] = useState<IValidationError[]>(
    []
  );
  const ref = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    data && setFormValues(data);
  }, [data]);

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      const response = data
        ? await updateCustomer(formValues, data.id)
        : await addCustomer(formValues);

      navigate("../");
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <div className={style["customer"]}>
      <div ref={ref} className={style["title"]}>
        {data ? "Modifier client" : "Nouveau client"}
      </div>
      <form className={style["form"]} onSubmit={handleSubmit}>
        <div className={style["contact"]}>
          <Input
            label="Prénom"
            name="givenname"
            value={formValues.givenname}
            onChange={handleChange}
            type="text"
          />
          <Input
            label="Nom de famille"
            name="familyname"
            value={formValues.familyname}
            onChange={handleChange}
            type="text"
          />
          <Input
            label="Téléphone"
            name="phone"
            value={formValues.phone}
            onChange={handleChange}
            type="text"
          />
          <Input
            label="Email"
            name="email"
            value={formValues.email}
            onChange={handleChange}
            type="text"
          />
        </div>
        <div className={style["address-container"]}>
          <div className={style["address-list"]}>
            {formValues.addresses &&
              formValues.addresses.map((address: IAddress, index: number) => {
                return (
                  <div
                    className={style["address-miniature-container"]}
                    key={index}
                  >
                    <div
                      className={style["delete-icon"]}
                      onClick={() => removeAddress(index)}
                    >
                      <Delete />
                    </div>
                    <div
                      className={style["address-miniature"]}
                      onClick={() => handleClickOnAddress(index)}
                    >
                      <div>
                        {address.address1} {address.address2}
                      </div>
                      <div>{address.address2}</div>
                      <div>
                        {address.zipCode} {address.city} {address.country}
                      </div>
                    </div>
                  </div>
                );
              })}
            <div className={style["add-new-button"]} onClick={addAddress}>
              + Ajouter une nouvelle address
            </div>
          </div>
          {selectedAddress > -1 && (
            <AddressForm
              defaultAddress={
                formValues.addresses &&
                (formValues.addresses[selectedAddress] as IShippingPoint)
              }
              contact={{
                displayname: formValues.familyname,
                givenname: formValues.givenname,
                familyname: formValues.familyname,
                organization: "",
                phone: formValues.phone,
                email: formValues.email,
              }}
              isShippingAddress={false}
              validationError={validationError}
              setValidationError={setValidationError}
              onChange={handleAddressChange}
            />
          )}
          {selectedAddress > -1 && (
            <div
              className={style["validate-button"]}
              onClick={() => setSelectedAddress(-1)}
            >
              valider
            </div>
          )}
        </div>
        <div className={style["submit-button-container"]}>
          <button type={"submit"} className={style["add-new-button"]}>
            {data ? "Mettre à jour" : "Créer"}
          </button>
        </div>
      </form>
    </div>
  );

  function handleClickOnAddress(index: number) {
    index === selectedAddress
      ? setSelectedAddress(-1)
      : setSelectedAddress(index);
  }

  function addAddress() {
    const newFormValues = { ...formValues };
    if (newFormValues.addresses) {
      newFormValues.addresses?.push(INITIAL_ADDRESS);
      setSelectedAddress(newFormValues.addresses?.length - 1);
      setFormValues(newFormValues);
    }
  }

  function removeAddress(index: number) {
    const newFormValues = { ...formValues };
    newFormValues.addresses?.splice(index, 1);
    setFormValues(newFormValues);
    setSelectedAddress(-2);
  }

  function handleAddressChange(
    address: IShippingPoint,
    isShippingAddress: boolean
  ) {
    const newFormValues = { ...formValues };
    if (newFormValues.addresses) {
      newFormValues.addresses[selectedAddress] = address as IAddress;
    }
    setFormValues(newFormValues);
  }

  function handleChange({
    target,
  }: {
    target: HTMLSelectElement | HTMLInputElement;
  }) {
    setFormValues((prev) => ({ ...prev, [target.name]: target.value }));
  }
}
