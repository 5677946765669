import React from "react";
import style from "./inputContainer.module.scss";

export interface InputContainerProps {
  children: React.ReactNode;
  className?: string;
  size?: string;
}

export default function InputContainer({
  children,
  className,
  size
}: InputContainerProps) {

  return (
    <div className={`${className || ""} ${style["inputcontainer"]}`}>
      {children}
    </div>
  );
}
