export async function createPlanningOrder(
  // request: Request,
  { shippingId }: { shippingId: string }
): Promise<string | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/antsroute/create`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ shippingId }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }

    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
