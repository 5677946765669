import "react-international-phone/style.css";
import styles from "./phoneInput.module.scss";
import { isPhoneValid } from "../../utils/phoneValidation";
import {
  defaultCountries,
  parseCountry,
  PhoneInput,
} from "react-international-phone";
import InputContainer from "../forms/inputs/inputContainer/inputContainer";

interface PhoneInputProps {
  value: string;
  onChange: ({ target }: { target: EventTarget & HTMLInputElement }) => void;
}

export default function PhoneInputComp({ value, onChange }: PhoneInputProps) {
  const isValid = isPhoneValid(value);
  return (
    <InputContainer>
      <label>Numéro de téléphone</label>
      <PhoneInput
        defaultCountry="fr"
        value={value}
        onChange={handleOnChange}
        countries={phoneInputCountries()}
        style={{
          border: "1px solid black",
          borderRadius: "4px",
        }}
        inputStyle={{
          width: "calc(100% - 44px)",
          borderRight: "1px solid black",
        }}
      />
    </InputContainer>
  );

  function handleOnChange(phone: string) {
    if (phone === "+33" && value === "") {
      return;
    }
    onChange({
      target: { name: "phone", value: phone } as EventTarget & HTMLInputElement,
    });
  }

  function phoneInputCountries() {
    const topListCountries = defaultCountries.filter((country) => {
      const { iso2 } = parseCountry(country);
      return [
        "fr",
        "be",
        "es",
        "ch",
        "de",
        "lu",
        "gb",
        "it",
        "nl",
        "pt",
      ].includes(iso2);
    });
    const otherCountries = defaultCountries.filter((country) => {
      const { iso2 } = parseCountry(country);
      return ![
        "fr",
        "be",
        "es",
        "ch",
        "de",
        "lu",
        "gb",
        "it",
        "nl",
        "pt",
      ].includes(iso2);
    });
    return topListCountries.concat(otherCountries);
  }
}
