import { Delete } from "@mui/icons-material";
import LicenseLabel from "../../../../components/licenseLabel/licenseLabel";
import WorkshopId from "../../../../components/workshopId/workshopId";
import { IShop } from "../../../../types/shop.types";
import style from "./shopMiniature.module.scss";

interface ShopMiniatureProps {
  handleAddShop: (initialShop?: IShop) => void;
  handleDeleteShop: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  shop: IShop;
}

export default function ShopMiniature({
  handleAddShop,
  handleDeleteShop,
  shop,
}: ShopMiniatureProps) {
  return (
    <div
      className={style["shop-miniature"]}
      onClick={() => handleAddShop({ ...shop })}
    >
      <div className={style["shop-name"]}>{shop.name}</div>
      <LicenseLabel
        className={style["license-rank"]}
        rank={shop.licenseRank as string}
      />
      <WorkshopId size="xx-small" workshopId={shop.workshopPrefixId} />
      <div className={style["delete-button-container"]}>
        <div className={style["delete-button"]} onClick={handleDeleteShop}>
          <Delete />
        </div>
      </div>
    </div>
  );
}
