import { useEffect } from "react";
import { useModalComponent } from "../context/modalComponent.context";

export function useRefetchOnModalClose(fct: () => void) {
  const { modalIsOpen } = useModalComponent();

  useEffect(() => {
    !modalIsOpen && fct();
  }, [modalIsOpen]);
}
