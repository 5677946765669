import { AddCircleOutline } from "@mui/icons-material";
import styles from "./addWidget.module.scss";
import clsx from "clsx";

export default function AddWidget({
  onClick,
  disabled = false,
}: {
  onClick: () => void;
  disabled: boolean;
}) {
  return (
    <div
      className={clsx(styles["product-widget"], styles["add-widget"], {
        [styles["disabled"]]: disabled,
      })}
      onClick={() => !disabled && onClick()}
    >
      <AddCircleOutline className={clsx(styles["add-icon"])} />
    </div>
  );
}
