import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import style from "./shipping.module.scss";
import { IShop } from "../../../../types/shop.types";
import InputContainer from "../../inputs/inputContainer/inputContainer";
import { fetchShippingMethodsList } from "../../../../requests/shippingMethod";
import { IShippingMethod } from "../../../../types/accounting.types";
import { useQuery } from "react-query";

export function ShippingMethodForm({
  shop,
  setShop,
}: {
  shop: IShop;
  setShop: (shop: IShop) => void;
}) {
  const { data, isFetching, refetch } = useQuery("shipping-method-list", () =>
    fetchShippingMethodsList()
  );

  return (
    <div className={style["form"]}>
      {/* <div className={style["title"]}>Shipping:</div> */}
      <div>
        <select
          onChange={handleSelectChange}
          value={shop.shippingMethod.name}
          // defaultValue={"placeholder"}
        >
          <option value="placeholder">
            Sélectionner une méthode de livraison
          </option>
          {data &&
            data.shippingMethodsList &&
            data.shippingMethodsList.map(
              (value: IShippingMethod, index: number) => {
                return (
                  <option key={index} value={value.name}>
                    {value.name}
                  </option>
                );
              }
            )}
        </select>
      </div>
    </div>
  );

  function handleSelectChange({
    target,
  }: {
    target: EventTarget & HTMLSelectElement;
  }) {
    const newShop = { ...shop };
    const found = data?.shippingMethodsList.find(
      (sm) => sm.name === target.value
    );
    if (found) {
      newShop.shippingMethod = found;
    }
    newShop.nextIncomingShipping = {
      date: "",
      documentId: newShop?.nextIncomingShipping?.documentId || "",
    };
    newShop.nextOutgoingShipping = {
      date: "",
      documentId: newShop?.nextOutgoingShipping?.documentId || "",
    };
    setShop(newShop);
  }
}
export default ShippingMethodForm;
