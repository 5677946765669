import ClimbingWidget from "../../../components/widget/climbing/climbing";
import CobblerWidget from "../../../components/widget/cobbler/cobbler";
import LeatherWidget from "../../../components/widget/leather/leather";
import style from "./workshop.module.scss";

export default function Workshop() {
  return (
    <div className={style["workshop"]}>
      <div className={style["workshop-grid"]}>
        <CobblerWidget />
        <LeatherWidget />
        <ClimbingWidget />
      </div>
    </div>
  );
}
