import { useQuery } from "react-query";
import Widget from "../../newWidget/widget";
import { fetchShippingList } from "../../../requests/shipping";
import style from "./incomingShippingWidget.module.scss";
import { BoxSeam } from "react-bootstrap-icons";

export default function IncomingShippingWidget() {
  // const { isLoading, error, data } = useQuery("incoming-shipping-widget", () =>
  //   fetchShippingList("?direction=INCOMING&state=PENDING")
  // );

  return (
    <Widget to={"incoming-shipping"}>
      <div className={style["icon-container"]}>
        <BoxSeam className={style["icon"]} />
      </div>
      <div>Réceptionner colis</div>
    </Widget>
  );
}
