import style from "./radioItem.module.scss";

interface RadioItemProps {
  className?: string;
  checked: boolean;
  setChecked: (checked: boolean) => void;
  title: string;
}

export default function RadioItem({
  className,
  checked,
  setChecked,
  title,
}: RadioItemProps) {
  return (
    <div className={`${style["radio-item"]} ${className ? className : ""}`}>
      <input
        id={title}
        type="radio"
        onChange={() => setChecked(!checked)}
        checked={checked}
        className={style["input"]}
      />
      <label className={style["label"]} htmlFor={`${title}`}>
        {title}
      </label>
    </div>
  );
}
