import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TimeslotsForm from "../../../components/forms/timeslotsForm/timeslotsForm";
import {
  postShippingMethod,
  updateShippingMethod,
} from "../../../requests/shippingMethod";
import translate from "../../../translation";
import { IValidationError } from "../../../types";
import { IShippingMethod, IWeekDays } from "../../../types/accounting.types";
import {
  PARIS_ZIPCODES,
  SHIPPING_METHOD_DEFAULT,
} from "../../../utils/shippingMethod.init";
import style from "./create.module.scss";
import ShippingMethodChecklist from "../../../components/shippingMethodChecklist/checklist";

export async function loader({
  request,
  params,
}: {
  request: Request;
  params: any;
}) {}

interface ShippingMethodProps {
  shippingMethod?: IShippingMethod;
}

export default function Create({ shippingMethod }: ShippingMethodProps) {
  const [formValues, setFormValues] = useState<IShippingMethod>(
    shippingMethod || SHIPPING_METHOD_DEFAULT
  );

  const [validationError, setValidationError] = useState<
    IValidationError[] | string[]
  >([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (shippingMethod) {
      setFormValues(shippingMethod);
    }
  }, [shippingMethod]);

  useEffect(() => {
    console.debug("Fromvaules", formValues);
  }, [formValues]);

  return (
    <div className={style["container"]}>
      <div className={style["title"]}>Ajouter une opération</div>
      <form>
        <div className={style["inputcontainer"]}>
          <label className={isRequired("name")}>
            Nom{" "}
            <span className={style["error-message"]}>
              {IErrorMessage("name")}
            </span>
          </label>
          <input
            onChange={handleChange}
            name="name"
            placeholder="Nouveau service"
            aria-label="Nom"
            type="text"
            value={formValues.name}
            className={isRequired("name")}
            onFocus={handleStartCorrection}
          />
        </div>
        <div className={style["inputcontainer"]}>
          <label className={isRequired("shippingService")}>
            Service{" "}
            <span className={style["error-message"]}>
              {IErrorMessage("shippingService")}
            </span>
          </label>
          <select
            name="shippingService"
            onChange={handleChange}
            aria-label="Service"
            // placeholder="Sélectionnez un service"
            value={formValues.shippingService}
            defaultValue={"INTERNAL"}
          >
            <option value={""} disabled>
              Sélectionnez un service
            </option>
            <option value={"INTERNAL"}>Interne</option>
            <option value={"DROP-OR-COLLECT"}>Dépôt ou retrait boutique</option>
            <option value={"CHRONOPOST-2SHOP"}>Chronopost 2 Shop</option>
            <option value={"CHRONOPOST-PRO"}>Chronopost Pro</option>
            <option value={"CHRONOPOST-EUR"}>Chronopost Europe</option>
            <option value={"PICKUP"}>Pick Up</option>
          </select>
        </div>

        <div className={style["inputcontainer"]}>
          <label className={isRequired("shippingService")}>
            Limites géographique{" "}
            <span className={style["error-message"]}>
              {IErrorMessage("shippingService")}
            </span>
          </label>
          <select
            name="geoLimit"
            onChange={handleChange}
            aria-label="Limite géographique"
            defaultValue={"NONE"}
            value={
              formValues.zipCodeLimit && formValues.zipCodeLimit.length > 0
                ? "FRANCE"
                : formValues.countryLimit && formValues.countryLimit.length > 0
                ? formValues.countryLimit[0]
                : "NONE"
            }
          >
            <option value={""} disabled>
              Sélectionnez une limite
            </option>
            <option value={"FRANCE"}>France</option>
            <option value={"BELGIQUE"}>Belgique</option>
            <option value={"SUISSE"}>Suisse</option>
            <option value={"ITALIE"}>Italie</option>
            <option value={"LUXEMBOURG"}>Luxembourg</option>
            <option value={"MONACO"}>Monaco</option>
            <option value={"PARIS"}>Paris</option>
          </select>
        </div>

        <div className={style["inputcontainer"]}>
          <label className={isRequired("price")}>
            Prix{" "}
            <span className={style["error-message"]}>
              {IErrorMessage("price")}
            </span>
          </label>
          <input
            onChange={handleChange}
            name="price"
            placeholder="0"
            aria-label="Service price"
            type="number"
            min={0}
            value={formValues.price}
            className={isRequired("price")}
            onFocus={handleStartCorrection}
          />
        </div>

        <div className={style["inputcontainer"]}>
          <label className={isRequired("freeShippingMinimumAmount")}>
            Gratuit à partir de{" "}
            <span className={style["error-message"]}>
              {IErrorMessage("freeShippingMinimumAmount")}
            </span>
          </label>
          <input
            onChange={handleChange}
            name="freeShippingMinimumAmount"
            placeholder="0"
            aria-label="Service freeShippingMinimumAmount"
            type="number"
            min={0}
            value={formValues.freeShippingMinimumAmount}
            className={isRequired("freeShippingMinimumAmount")}
            onFocus={handleStartCorrection}
          />
        </div>

        <div className={style["inputcontainer"]}>
          <label className={isRequired("freeShippingMinimumAmount")}>
            Frais internationaux{" "}
            <span className={style["error-message"]}>
              {IErrorMessage("internationalFees")}
            </span>
          </label>
          <input
            onChange={handleChange}
            name="internationalFees"
            placeholder="0"
            aria-label="Service internationalFees"
            type="number"
            min={0}
            value={formValues.internationalFees}
            className={isRequired("internationalFees")}
            onFocus={handleStartCorrection}
          />
        </div>

        <ShippingMethodChecklist
          title="Jours de livraison"
          items={Object.entries(formValues.businessDays).map((entry) => ({
            checked: entry[1],
            name: entry[0],
          }))}
          onChange={handleBusinessDaysChange}
        />

        <TimeslotsForm
          slots={formValues.businessHours}
          onChange={handleBusinessHoursChange}
        />

        <button
          className={`button-s ${style["validationbutton"]}`}
          type="submit"
          onClick={handleSubmit}
        >
          {shippingMethod ? "Sauvegarder" : "Créer Nouveau"}
        </button>
      </form>
    </div>
  );

  function handleChange({
    target,
  }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    let { name, value }: { name: string; value: string | string[] } = target;
    let zipCodeLimit = formValues.zipCodeLimit || [];
    let countryLimit = formValues.countryLimit || [];
    if (name === "geoLimit") {
      if (value === "PARIS") {
        zipCodeLimit = PARIS_ZIPCODES;
        countryLimit = ["FRANCE"];
      } else if (value && value !== "") {
        zipCodeLimit = [];
        countryLimit = [value.toUpperCase()];
      }
      setFormValues({ ...formValues, zipCodeLimit, countryLimit });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  }

  function handleBusinessDaysChange(
    days: { checked: boolean; name: string }[]
  ): void {
    let newValues = { ...formValues.businessDays };
    days.forEach((day) => {
      newValues[day.name as keyof IWeekDays] = day.checked;
    });
    setFormValues({ ...formValues, businessDays: newValues });
  }

  function handleBusinessHoursChange(
    slots: { start: string; end: string }[]
  ): void {
    setFormValues({ ...formValues, businessHours: slots });
  }

  async function handleSubmit(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    event.preventDefault();
    console.debug("formvalues", formValues);
    try {
      const response =
        shippingMethod && shippingMethod.id
          ? await updateShippingMethod(
              new Request(""),
              formValues,
              shippingMethod.id
            )
          : await postShippingMethod(new Request(""), formValues);
      if ((response as IValidationError[]).length > 0) {
        setValidationError(response as IValidationError[]);
        return;
      }
      navigate("../");
    } catch (error) {
      console.error(error);
    }
  }

  function isRequired(name: string): string {
    if (
      (validationError as IValidationError[]).find(
        (value: IValidationError) => name === value.field
      )
    ) {
      return style["validation-error"];
    }
    return "";
  }

  function IErrorMessage(name: string): string {
    const found = (validationError as IValidationError[]).find(
      (value: IValidationError) => name === value.field
    );
    if (found) {
      return "*" + translate(found.errorType);
    }
    return "";
  }

  function handleStartCorrection({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    const newValidation = (validationError as IValidationError[]).filter(
      (value: IValidationError) => value.field !== target.name
    );
    setValidationError([...newValidation]);
  }
}
