import { useState } from "react";
import style from "./create.module.scss";
import {
  IContact,
  ICrossSellItem,
  ICrossSellLogistic,
  ILogistic,
  ILogisticFileList,
  IProduct,
} from "../../../types/logistic.types";
import {
  DEFAULT_PRODUCT,
  INITIALE_STATE,
  LOGISTIC_STATUS,
} from "../../../utils/logistic.init";
import { useNavigate } from "react-router-dom";
import { addLogistic, updateLogistic } from "../../../requests/logistic";
import { IValidationError } from "../../../types";
import ContactForm from "../../../components/forms/ContactForm/ContactForm";
import { streamFile } from "../../../requests/file";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../utils/toast.options";
import { ICrossSellQuote } from "../../../types/accounting.types";
import SelectState from "../../shipping/create/SelectState/SelectState";
import Button from "../../../components/Button/Button";
import { useModalComponent } from "../../../context/modalComponent.context";
import AddProductModal from "../../../components/modals/addProductModal/AddProductModal";
import { useConfirmModal } from "../../../context/confirmationModalContext";
import ConfirmModalComp from "../../../components/confirmationModal/ConfirmModalComp";
import SectionFormTemplate from "../../../components/forms/sectionFormTemplate/SectionFormTemplate";
import AddProductSection from "./addProductSection/AddProductSection";
import translate from "../../../translation";
import CheckboxItem from "../../../components/checkboxItem/CheckboxItem";
import ConcurrentTasksTemplate from "../../../components/concurrentTasksTemplate/ConcurrentTasksTemplate";
import AddCrossSell from "../../../components/forms/crossSellForm/addCrossSell";
import AddCrossSellLogistic from "../../../components/forms/crossSellForm/addCrossSellLogistic";
import OverviewButton from "../../../components/OverviewButton/OverviewButton";

export default function Create({ logistic }: { logistic?: ILogistic }) {
  const [fileList, setFileList] = useState<ILogisticFileList[]>(initFileList());
  const [formValues, setFormValues] = useState<ILogistic>(
    logistic ? { ...INITIALE_STATE, ...logistic } : { ...INITIALE_STATE }
  );
  const [vError, setVError] = useState<IValidationError[]>([]);
  const { setModalIsOpen, setModalChildren, setConfirmClose } =
    useModalComponent();
  const { setConfirmModalIsOpen, setConfirmModalChildren } = useConfirmModal();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [updateQuote, setUpdateQuote] = useState<boolean>(true);

  return (
    <div className={style["logistic"]}>
      <div className={style["title"]}>
        <span className={style["title-container"]}>
          {logistic
            ? "Mettre à jour document logistique"
            : "Nouveau document logistique"}
          <OverviewButton trackId={formValues.trackId} />
        </span>
        <SelectState
          label="Status du document logistique"
          state={formValues.status}
          stateOption={LOGISTIC_STATUS}
          setState={(state) => {
            setFormValues({ ...formValues, status: state });
          }}
        />
      </div>
      <SectionFormTemplate title={"Contact"}>
        <ContactForm
          contact={formValues.contact}
          setContact={handleContactChange}
          className={style["contact-form"]}
        />
      </SectionFormTemplate>
      <SectionFormTemplate title={"Commentaire"}>
        <textarea
          className={style["comment"]}
          value={formValues.comments}
          onChange={({ target }) =>
            setFormValues((prev) => ({ ...prev, comments: target.value }))
          }
        />
      </SectionFormTemplate>
      <hr className={style["separator"]} />
      <AddProductSection
        productsList={formValues?.productsList || []}
        handleAddNewProduct={handleAddNewProduct}
        handleConfirmDeleteProduct={handleConfirmDeleteProduct}
        handleOpenProductModal={handleOpenProductModal}
      />
      <SectionFormTemplate title="Articles Cross-selling">
        <AddCrossSellLogistic
          handleCrossSellChange={handleSetCrossSell}
          crossSell={formValues.crossSell}
          handleDeleteCrossSellItem={handleDeleteCrossSellItem}
          handleSetCrossSellItemState={handleSetCrossSellItemState}
        />
      </SectionFormTemplate>
      <ConcurrentTasksTemplate>
        <CheckboxItem
          checked={updateQuote}
          setChecked={setUpdateQuote}
          title="Mettre à jour le devis associé"
        />
      </ConcurrentTasksTemplate>
      <div className={style["footer"]}>
        <Button onClick={handleConfirmSubmit} isLoading={isLoading}>
          {logistic ? "Sauvegarder" : "Créer Nouveau"}
        </Button>
      </div>
    </div>
  );

  function handleSetCrossSell(crossSell: ICrossSellLogistic) {
    setFormValues((prev) => ({
      ...prev,
      crossSell,
    }));
  }

  function handleSetCrossSellItemState(index: number) {
    setFormValues((prev) => ({
      ...prev,
      crossSell: {
        ...prev.crossSell,
        crossSellItems: (
          prev.crossSell?.crossSellItems as ICrossSellItem[]
        ).map((cs: ICrossSellItem, i) => {
          return i === index ? { ...cs, isItemReady: !cs.isItemReady } : cs;
        }),
      },
    }));
  }

  function handleDeleteCrossSellItem(index: number) {
    setFormValues((prev) => ({
      ...prev,
      crossSell: {
        ...prev.crossSell,
        crossSellItems: prev.crossSell?.crossSellItems?.filter(
          (_, i) => i !== index
        ),
      },
    }));
  }

  async function handleConfirmSubmit() {
    if (!logistic) {
      return await handleSubmit();
    }

    setConfirmModalIsOpen(true);
    setConfirmModalChildren(
      <ConfirmModalComp
        confirmButtonClassName={style["validate-button"]}
        title={"Voulez-vous mettre à jour cet document logistique ?"}
        element={
          <>
            {updateQuote && (
              <ul style={{ fontSize: "14px" }}>
                Ceci entrainera les actions suivantes:
                <li>Mise à jour du devis</li>
              </ul>
            )}
          </>
        }
        confirmText="Oui"
        cancelText="Non"
        onConfirm={handleSubmit}
      />
    );
  }

  async function handleSubmit() {
    setIsLoading(true);
    try {
      await submitFiles(`_${logistic?.trackId || "no-track-id"}_logistic.`);
      const response =
        logistic && logistic.id
          ? await updateLogistic(formValues, updateQuote)
          : await addLogistic(formValues, false);
      if ((response as IValidationError[]).length > 0) {
        toast.error(
          <div>
            {`Veuillez remplir les champs requis :`}
            <br />
            {`${parseValidationError(response as IValidationError[])}`}
          </div>,
          TOAST_ERROR_OPTIONS
        );
        setVError(response as IValidationError[]);
        return;
      }
      toast.success(
        `Le document logistique à été ${
          formValues.id ? "mis à jour" : "créer"
        }.`,
        TOAST_SUCCESS_OPTIONS
      );
      // navigate(-1);
    } catch (error: any) {
      toast.error(`Un problème est survenu`, TOAST_ERROR_OPTIONS);
    } finally {
      setIsLoading(false);
    }
  }

  function handleOpenProductModal(product: IProduct, index: number) {
    setModalChildren(
      <AddProductModal
        product={product}
        setProduct={(product) => handleSetProductAtIndex(product, index)}
        fileList={fileList[index]}
        setFileList={(updatefileList) =>
          setFileListAtIndex(updatefileList, index)
        }
      />
    );
    setModalIsOpen(true);
    setConfirmClose(true);
  }

  function handleAddNewProduct() {
    setFormValues((prev) => ({
      ...prev,
      productsList: [
        ...prev.productsList,
        {
          ...DEFAULT_PRODUCT,
          productGroup: "",
          operationGroup: "",
          incomingPicturesList: [],
          outgoingPicturesList: [],
        },
      ],
    }));
    setFileList((prev) => [
      ...prev,
      { incomingPicturesList: [], outgoingPicturesList: [] },
    ]);
  }

  function handleConfirmDeleteProduct(index: number) {
    setConfirmModalChildren(
      <ConfirmModalComp
        confirmButtonClassName={style["delete-button"]}
        title={"Etes-vous sur de vouloir supprimer cette article ?"}
        confirmText="Supprimer"
        onConfirm={() => handleDeleteProduct(index)}
      />
    );
    setConfirmModalIsOpen(true);
  }

  function handleDeleteProduct(index: number) {
    setFormValues((prev) => ({
      ...prev,
      productsList: prev.productsList.filter((_, i) => i !== index),
    }));
    setFileList((prev) => prev.filter((_, i) => i !== index));
  }

  function setFileListAtIndex(
    updatefileList: ILogisticFileList,
    index: number
  ) {
    setFileList((prev) =>
      prev.map((f, i) => {
        return i === index ? updatefileList : f;
      })
    );
  }

  function handleSetProductAtIndex(product: IProduct, index: number) {
    setFormValues((prev) => ({
      ...prev,
      productsList: prev.productsList.map((p, i) => {
        return index === i ? product : p;
      }),
    }));
  }

  function handleContactChange(contact: IContact) {
    setFormValues((prev) => ({
      ...prev,
      contact,
      productsList: prev.productsList.map((p) => {
        return { ...p, contact };
      }),
    }));
  }

  async function submitFiles(slug: string) {
    try {
      for (const [index, logisticFile] of fileList.entries()) {
        if (logisticFile.incomingPicturesList) {
          for (let file of logisticFile.incomingPicturesList) {
            const response = await streamFile(slug, file);
            const newFormValues = { ...formValues };
            newFormValues.productsList[index] &&
              newFormValues.productsList[index].incomingPicturesList?.push(
                response.message
              );
            setFormValues(newFormValues);
          }
        }
        if (logisticFile.outgoingPicturesList) {
          for (let file of logisticFile.outgoingPicturesList) {
            const response = await streamFile(slug, file);
            const newFormValues = { ...formValues };
            newFormValues.productsList[index] &&
              newFormValues.productsList[index].outgoingPicturesList?.push(
                response.message
              );
            setFormValues(newFormValues);
          }
        }
      }
    } catch (error) {
      toast.error(
        "Un problème est survenu lors de l'upload des fichiers.",
        TOAST_ERROR_OPTIONS
      );
    }
  }

  function initFileList() {
    return logistic
      ? [
          ...Array(logistic.productsList.length).fill({
            incomingPicturesList: [],
            outgoingPicturesList: [],
          }),
        ]
      : [];
  }

  function parseValidationError(
    validationError: IValidationError[] | undefined
  ) {
    if (!validationError) {
      return;
    }
    let errorField: string[] = [];
    validationError.forEach((ve: IValidationError, index: number) => {
      errorField.push(translate(ve.field));
    });

    return errorField.join(" - ");
  }

  // function handleRemoveCrossSellItem(
  //   event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  //   index: number
  // ) {
  //   event.preventDefault();
  //   if (!formValues.crossSell) {
  //     return;
  //   }
  //   const newCrossSell: ICrossSellLogistic = { ...formValues.crossSell };
  //   // const newCrossSellItems = [...newCrossSell.crossSellItems];
  //   newCrossSell.crossSellItems?.splice(index, 1);
  //   setFormValues((prev) => ({ ...prev, crossSell: newCrossSell }));
  // }

  // function handleCrossSellItemsChange(crossSellItems: ICrossSellQuote[]) {
  //   const newCrossSell: ICrossSellLogistic = { ...formValues.crossSell };
  //   const newCrossSellItems: ICrossSellItem[] = crossSellItems.map((cs) => {
  //     return { ...cs, isItemReady: false };
  //   }) as ICrossSellItem[];

  //   newCrossSell.crossSellItems = newCrossSellItems;
  //   setFormValues((prev) => ({ ...prev, crossSell: newCrossSell }));
  // }

  // function handleCrossSellWorkshopIdChange({
  //   target,
  // }: {
  //   target: EventTarget & HTMLInputElement;
  // }) {
  //   const newCrossSell: ICrossSellLogistic = { ...formValues.crossSell };
  //   newCrossSell.workshopId = target.value;
  //   setFormValues((prev) => ({ ...prev, crossSell: newCrossSell }));
  // }
}
