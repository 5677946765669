import { MouseEventHandler } from "react";
import style from "./checklistItem.module.scss";

interface ChecklistItemProps {
  name: string;
  text: string;
  checked?: boolean;
  onChange?: ({ name, checked }: { name: string; checked: boolean }) => void;
}
export default function ChecklistItem({
  name,
  text,
  checked,
  onChange,
}: ChecklistItemProps) {
  function handleClick() {
    if (onChange && checked !== undefined) {
      onChange({ name, checked: !checked });
    }
  }

  let itemStyles = [style["checklist-item"]];
  if (checked) {
    itemStyles.push(style["checked"]);
  }
  return (
    <div className={itemStyles.join(" ")} onClick={handleClick}>
      {text}
    </div>
  );
}
