import styles from "./loadingWidget.module.scss";
import DashboardWidget from "../DashboardWidget";
import PictureLoadingSpinner from "../../../../components/loadingSpinner/pictureloadingSpinner";

export default function LoadingWidget() {
  return (
    <DashboardWidget className={styles["loading-widget"]}>
      <PictureLoadingSpinner />
    </DashboardWidget>
  );
}
