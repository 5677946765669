import { useState } from "react";
import { IOperation, IProduct } from "../../../../types/proCart.types";
import style from "./addProductSection.module.scss";
import { DEFAULT_PRO_PRODUCT } from "../../../../utils/booking.init";
import WorkshopProInfoForm from "../workshopInfoForm/WorkshopProInfoForm";
import ProductItem from "./productItem/ProductItem";
import { useQuery } from "react-query";
import { getShopOperationGroups, getShopProductGroups } from "../../../../requests/shops";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../../../../utils/toast.options";

interface AddProductSectionProps {
  shopId: string;
  shopRef: string;
  productsList: IProduct[];
  setProductsList: (productsList: IProduct[]) => void;
}

export default function AddProductSection({
  shopId,
  shopRef,
  productsList,
  setProductsList,
}: AddProductSectionProps) {
  const [productForm, setProductForm] = useState<IProduct | null>(null);
  const NO_PRODUCT_IS_SELECTED = -1;
  const [selectedProductIndex, setSelectedProductIndex] = useState<number>(
    NO_PRODUCT_IS_SELECTED
  );

  // const {data: productGroupData} = useQuery(["shop-product-groups", shopId], () => getShopProductGroups(shopId), {
  // select(data) {
  //   console.debug("PLiiP")
  //   if (data.productGroups && data.productGroups.length > 0) {
  //     setProductForm({...productForm, productGroup: data.productGroups[0]})
  //   }
  //   return data
  // },
  // });
  // const {data: operationGroupData} = useQuery(["shop-operation-groups", shopId], () => getShopOperationGroups(shopId, productForm?.productGroup), {
  // select(data) {
  //   console.debug("PLOOP")
  //   if (data.operationGroups && data.operationGroups.length > 0) {
  //     setProductForm({...productForm, operationGroup: data.operationGroups[0]})
  //   }
  //   return data
  // },
  // });


  return (
    <div className={style["product-section"]}>
      <div className={style["product-section-top"]}>
        <div className={style["left"]}>
          Liste des articles
          <ul className={style["product-list"]}>
            {productsList.map((p, index) => {
              return (
                <ProductItem
                  key={index}
                  product={p}
                  handleEditProduct={() => handleEditProduct(p, index)}
                  handleDeleteProduct={() => handleDeleteProduct(index)}
                />
              );
            })}
          </ul>
        </div>
        {productForm && (
          <WorkshopProInfoForm
            shopRef={shopRef}
            shopId={shopId}
            productForm={productForm}
            setProductForm={setProductForm}
            handleValidateProduct={handleValidateProduct}
          />
        )}
      </div>
      {!productForm && (
        <div className={style["add-new"]} onClick={handleAddNewProduct}>
          Ajouter un nouvel article
        </div>
      )}
    </div>
  );

  function handleAddNewProduct() {
    let operationsList: IOperation[] = []
    setProductForm({ operationsList } as IProduct);
    // setProductForm(DEFAULT_PRO_PRODUCT);
    setSelectedProductIndex(NO_PRODUCT_IS_SELECTED);
  }

  function verifyProduct() {
    return productForm && productForm.operationsList && productForm.operationsList.length > 0 && productForm.workshopId && productForm.operationGroup && productForm.productGroup
  }

  function handleValidateProduct() {
    if (!verifyProduct()) {
      return toast.error("Veuillez remplir les champs obligatoires.", TOAST_ERROR_OPTIONS)
    }
    selectedProductIndex !== NO_PRODUCT_IS_SELECTED
      ? editOperation()
      : appendProduct();
    setProductForm(null);
  }

  function handleEditProduct(product: IProduct, index: number) {
    setProductForm(product);
    setSelectedProductIndex(index);
  }

  function handleDeleteProduct(index: number) {
    setProductsList(productsList.filter((_, i) => i !== index));
  }

  function appendProduct() {
    if (!productForm) {
      return;
    }
    setProductsList([...productsList, productForm]);
  }

  function editOperation() {
    if (!productForm) {
      return;
    }
    setProductsList(
      productsList.map((op, i) => {
        return selectedProductIndex === i ? productForm : op;
      })
    );
  }
}
