import style from "./pagingComponent.module.scss";

import { ChevronLeft, ChevronRight, Replay } from "@mui/icons-material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { queryObject } from "../../types/query.types";
import { useEffect } from "react";
import { isDesktop } from "react-device-detect";

interface PagingComponentProps {
  totalCount?: number;
  query: queryObject;
  setQuery: (query: queryObject) => void;
  title?: string;
  limitOptionsList?: string[];
}

export default function PagingComponent({
  totalCount,
  query,
  setQuery,
  title,
  limitOptionsList,
}: PagingComponentProps) {
  const PAGES_NUMBER = totalCount ? Math.ceil(totalCount / query.limit) : 0;

  useEffect(() => {
    if (totalCount) {
      setQuery({ ...query, offset: 0 });
    }
  }, [totalCount]);

  return (
    <div className={style["paging-container"]}>
      <div className={style["paging-container-left"]}>
        <select
          className={style["page-limit-select"]}
          title="list-limit-number"
          value={query.limit}
          onChange={({ target }) =>
            setQuery({ ...query, limit: parseInt(target.value), offset: 0 })
          }
        >
          {limitOptionsList ? (
            <>
              {limitOptionsList.map((options, index) => {
                return (
                  <option key={index} value={options}>
                    {options}
                  </option>
                );
              })}
            </>
          ) : (
            <>
              <option value={"5"}>5</option>
              <option value={"10"}>10</option>
              <option value={"20"}>20</option>
              <option value={"50"}>50</option>
            </>
          )}
        </select>
      </div>
      {title && <div className={style["paging-container-center"]}>{title}</div>}
      <div className={style["paging-container-right"]}>
        <div
          className={`${style["arrow-button"]} ${
            isDesktop && style["arrow-button-desktop"]
          }`}
          onClick={() => handleChangePage("fast-backward")}
        >
          <KeyboardDoubleArrowLeftIcon
            className={`${!isDesktop && style["arrow-icon"]}`}
          />
        </div>
        <div
          className={`${style["arrow-button"]} ${
            isDesktop && style["arrow-button-desktop"]
          }`}
          onClick={() => handleChangePage("previous")}
        >
          <ChevronLeft className={`${!isDesktop && style["arrow-icon"]}`} />
        </div>
        <div className={style["page-number-container"]}>
          <div className={style["page-number"]}>
            {PAGES_NUMBER === 0 ? 0 : (query.offset + 1).toString()}
            {" sur "}
            {PAGES_NUMBER}
          </div>
        </div>
        <div
          className={`${style["arrow-button"]} ${
            isDesktop && style["arrow-button-desktop"]
          }`}
          onClick={() => handleChangePage("next")}
        >
          <ChevronRight className={`${!isDesktop && style["arrow-icon"]}`} />
        </div>
        <div
          className={`${style["arrow-button"]} ${
            isDesktop && style["arrow-button-desktop"]
          }`}
          onClick={() => handleChangePage("fast-forward")}
        >
          <KeyboardDoubleArrowRightIcon
            className={`${!isDesktop && style["arrow-icon"]}`}
          />
        </div>
      </div>
    </div>
  );

  function handleChangePage(action: string) {
    switch (action) {
      case "previous":
        return query.offset - 1 < 0
          ? setQuery({ ...query, offset: query.offset })
          : setQuery({ ...query, offset: query.offset - 1 });
      case "next":
        return query.offset + 1 ===
          Math.ceil((totalCount ?? 2000) / query.limit)
          ? setQuery({ ...query, offset: query.offset })
          : setQuery({ ...query, offset: query.offset + 1 });
      case "fast-forward":
        return query.offset + 20 >=
          Math.ceil((totalCount ?? 2000) / query.limit)
          ? setQuery({ ...query, offset: PAGES_NUMBER - 1 })
          : setQuery({ ...query, offset: query.offset + 20 });
      case "fast-backward":
        return query.offset - 20 <= 0
          ? setQuery({ ...query, offset: 0 })
          : setQuery({ ...query, offset: query.offset - 20 });
      default:
        return;
    }
  }
}
