import { useNavigate, useParams } from "react-router-dom";
import style from "./qualityControl.module.scss";
import { useQuery } from "react-query";
import { getDetailedProduct, updateProduct } from "../../../requests/product";
import { useEffect, useState } from "react";
import { IDetailedProduct } from "../../../types/logistic.types";
import { streamFile } from "../../../requests/file";
import PhotoControl from "./components/photoControl";
import OtherControl from "./components/otherControl";
import ContactInfoCard from "../../../components/contactInfoCard/contactInfoCard";
import CrossSellControl from "./components/crossSellControl";
import Button from "../../../components/Button/Button";
import WorkshopId from "../../../components/workshopId/workshopId";
import ColorComponent from "../../../components/colorComponent/colorComponent";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";
import ExpandableComponent from "./expandableComponent/ExpandableComponent";
import OperationControl from "./components/operationControl";
import { useConfirmModal } from "../../../context/confirmationModalContext";
import ConfirmModalComp from "../../../components/confirmationModal/ConfirmModalComp";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_OPTIONS,
} from "../../../utils/toast.options";
import Image from "../../../components/image/Image";
import QualityControlHeader from "./components/QualityControlHeader";

interface ISteps {
  operations: boolean;
  photos: boolean;
  other: boolean;
  crossSell: boolean;
}

const INITIAL_STEPS: ISteps = {
  operations: false,
  photos: false,
  other: false,
  crossSell: false,
};

export default function QualityControl() {
  const { productId } = useParams();
  const { data, isLoading: dataIsLoading } = useQuery<IDetailedProduct>(
    ["quality-control-detailedProduct", productId],
    () => getDetailedProduct(productId),
    { refetchOnWindowFocus: false, enabled: Boolean(productId) }
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<File[]>([]);
  const [steps, setSteps] = useState<ISteps>(INITIAL_STEPS);
  const { setConfirmModalIsOpen, setConfirmModalChildren } = useConfirmModal();
  const navigate = useNavigate();

  useEffect(() => {
    if (fileList.length === 0) {
      setSteps((prev) => ({ ...prev, photos: false }));
    }
  }, [fileList]);

  const CROSS_SELL =
    data?.crossSell &&
    data?.crossSell.crossSellItems &&
    data?.crossSell?.crossSellItems?.length > 0;

  if (dataIsLoading || !data || !data.product) {
    return <LoadingSpinner color="gold" />;
  }

  return (
    <div className={style["operation-control-container"]}>
      <ContactInfoCard contact={data.contact} trackId={data.trackId} />
      Contrôle de qualité
      <QualityControlHeader product={data.product} />
      {CROSS_SELL && (
        <ExpandableComponent title={"Cross-selling"} state={steps.crossSell}>
          <CrossSellControl
            crossSell={data.crossSell}
            state={steps.crossSell}
            validateControl={validateCrossSellControl}
            rejectControl={handleConfirmRejectControl}
          />
        </ExpandableComponent>
      )}
      <ExpandableComponent title={"Opérations"} state={steps.operations}>
        <OperationControl
          product={data.product}
          state={steps.operations}
          validateControl={validateOperationsControl}
          rejectControl={handleConfirmRejectControl}
        />
      </ExpandableComponent>
      <ExpandableComponent title={"Photos"} state={steps.photos}>
        <PhotoControl
          product={data.product}
          validateControl={validatePhotosControl}
          fileList={fileList}
          setFileList={setFileList}
        />
      </ExpandableComponent>
      {!!data?.product.comments && (
        <ExpandableComponent
          title={"Articles annexes (embauchoir, pochon...)"}
          state={steps.other}
        >
          <OtherControl
            product={data.product}
            state={steps.other}
            rejectControl={handleConfirmRejectControl}
            validateControl={validateOtherControl}
          />
        </ExpandableComponent>
      )}
      {allIsGood() && (
        <div className={style["operation-control-bottom"]}>
          <Button
            onClick={handleConfirmValidateProductControl}
            className={style["validate"]}
            isLoading={isLoading}
          >
            Valider
          </Button>
        </div>
      )}
    </div>
  );

  function allIsGood() {
    if (!steps.operations || !steps.photos) {
      return false;
    }
    if (data?.product.comments && !steps.other) {
      return false;
    }
    if (CROSS_SELL && !steps.crossSell) {
      return false;
    }
    return true;
  }

  function handleConfirmRejectControl() {
    setConfirmModalIsOpen(true);
    setConfirmModalChildren(
      <ConfirmModalComp
        confirmButtonClassName={style["delete-button"]}
        title={"Voulez-vous remettre cet article en réparation ?"}
        confirmText="Remettre"
        onConfirm={rejectControl}
      />
    );
  }

  function handleConfirmValidateProductControl() {
    setConfirmModalIsOpen(true);
    setConfirmModalChildren(
      <ConfirmModalComp
        confirmButtonClassName={style["validate"]}
        title={"Voulez-vous valider le contrôle de cet article ?"}
        confirmText="Valider"
        onConfirm={validateProductControl}
      />
    );
  }

  async function rejectControl() {
    navigate("../quality-control");
  }

  async function submitFiles(slug: string) {
    let outgoingPicturesList: string[] = [];
    try {
      for (let file of fileList) {
        const response = await streamFile(slug, file);
        outgoingPicturesList.push(response.message);
        toast.info(
          `Image uploadé avec succès ${response.message}`,
          TOAST_ERROR_OPTIONS
        );
      }
      return (data?.product?.outgoingPicturesList || []).concat(
        outgoingPicturesList
      );
    } catch (error) {
      toast.error(
        "Un problème est survenu lors de l'upload des images.",
        TOAST_ERROR_OPTIONS
      );
    }
  }

  async function validateProductControl() {
    setIsLoading(true);
    try {
      const outgoingPicturesList = await submitFiles(
        `_${data?.product.id}_quality-control.`
      );
      if (!outgoingPicturesList || outgoingPicturesList.length < 1) {
        toast.warning(
          "Vous devez au moins ajouter une photo !",
          TOAST_ERROR_OPTIONS
        );
        return;
      }
      await updateProduct({
        ...data?.product,
        status: "PRODUCT_CONTROLLED",
        outgoingPicturesList,
      });
      toast.success(
        "L'article a été contrôlé avec succès.",
        TOAST_ERROR_OPTIONS
      );
      navigate("../quality-control");
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    } finally {
      setIsLoading(false);
    }
  }

  function validateCrossSellControl() {
    setSteps((prev) => ({ ...prev, crossSell: true }));
  }

  function validateOperationsControl() {
    setSteps((prev) => ({ ...prev, operations: true }));
  }

  function validatePhotosControl() {
    setSteps((prev) => ({ ...prev, photos: true }));
  }

  function validateOtherControl() {
    setSteps((prev) => ({ ...prev, other: true }));
  }
}
