import { queryObject } from "../types/query.types";

export const INITIAL_QUERY: queryObject = {
  field: "productsList.workshopId",
  value: "",
  service: "",
  direction: "",
  status: "Tous",
  limit: 10,
  offset: 0,
};

// field?: string;
// value?: string;
// service?: string;
// direction?: string;
// state?: string;
// status?: string;
// limit: number;
// offset: number;
// sort: string;
// dateFrom?: string;
// dateTo?: string;
