import { createPortal } from "react-dom";
import styles from "./subMenu.module.scss";
import { useHideSideBar } from "../../../context/hideSideBar.context";
import {
  CalendarMenu,
  DocumentsMenu,
  ManagementMenu,
  OrdersMenu,
  ProfesionnalMenu,
} from "./SubMenus/SubMenus";
import { useEffect, useRef } from "react";
interface SubMenuProps {
  openMenu: boolean;
  title: string;
  onMenuClose: () => void;
  parentRef: React.MutableRefObject<HTMLDivElement | null>;
}

export default function SubMenu({
  openMenu,
  title,
  parentRef,
  onMenuClose,
}: SubMenuProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const { hideSide } = useHideSideBar();
  const MENU_WIDTH = 64;
  const EXPANDED_MENU_WIDTH = 220;
  const SUB_MENU_WIDTH = 220;

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        parentRef.current &&
        !parentRef.current.contains(event.target)
      ) {
        onMenuClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, parentRef]);

  if (!openMenu) {
    return <></>;
  }

  return (
    <>
      <div
        className={styles["sub-menu"]}
        ref={ref}
        style={{
          left: hideSide ? MENU_WIDTH : EXPANDED_MENU_WIDTH,
        }}
      >
        <SubMenuDispatch title={title} onMenuClose={onMenuClose} />
      </div>
      <div
        className={styles["background"]}
        style={{
          left: SUB_MENU_WIDTH + (hideSide ? MENU_WIDTH : EXPANDED_MENU_WIDTH),
        }}
      ></div>
    </>
  );
}

interface SubMenuDispatchProps {
  title: string;
  onMenuClose: () => void;
}

function SubMenuDispatch({ title, onMenuClose }: SubMenuDispatchProps) {
  switch (title) {
    case "Calendriers":
      return <CalendarMenu title={title} onMenuClose={onMenuClose} />;
    case "Professionels":
      return <ProfesionnalMenu title={title} onMenuClose={onMenuClose} />;
    case "Demandes":
      return <OrdersMenu title={title} onMenuClose={onMenuClose} />;
    case "Documents":
      return <DocumentsMenu title={title} onMenuClose={onMenuClose} />;
    case "Gestion":
      return <ManagementMenu title={title} onMenuClose={onMenuClose} />;
    default:
      return <></>;
  }
}
