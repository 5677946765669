import { useState } from "react";
// import { ICart, Product } from "../../../types/cart.types";
import style from "./readCartModal.module.scss";
import { ReadProductMiniatureCard } from "./readProductMiniatureCard/ReadProductMiniatureCard";
import ProductInformation from "./productInformation/ProductInformation";
import { ICart } from "../../../types/booking.type";
import { IProduct } from "../../../types/logistic.types";
import CartHeader from "./cartHeader/cartHeader.component";

interface CartModalProps {
  cart: ICart;
}

export default function ReadCartModal({ cart }: CartModalProps) {
  const [selectedProduct, setSelectedProduct] = useState<IProduct | undefined>(
    cart.productsList[0]
  );

  return (
    <div className={style["read-cart-modal"]}>
      <div className={style["read-cart-modal-container"]}>
        <CartHeader cart={cart} />
        <div className={style["title"]}>Liste des articles</div>
        <div className={style["read-cart-modal-body"]}>
          <ProductInformation
            product={selectedProduct}
            className={
              cart.productsList.length === 1 ? style["one-product"] : ""
            }
          />
          <div
            className={`${style["product-list"]} ${
              cart.productsList.length <= 1 ? style["hidden"] : ""
            }`}
          >
            {cart.productsList &&
              cart.productsList.length > 1 &&
              cart.productsList.map((product: IProduct, index: number) => {
                return (
                  <ReadProductMiniatureCard
                    isSelected={selectedProduct === product}
                    key={index}
                    product={product}
                    handleSelectProduct={() => setSelectedProduct(product)}
                    side={"left"}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
