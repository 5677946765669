import { toast } from "react-toastify";
import { IValidationError } from "../types";
import { IShippingPro } from "../types/shipping.types";
import { ICartPro } from "../types/trackerPro.type";
import { TOAST_ERROR_OPTIONS } from "../utils/toast.options";

export async function addShippingPro(
  shipping: IShippingPro
): Promise<string | IValidationError[] | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping-pro`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        body: JSON.stringify(shipping),
      }
    );
    const data = await response.json();
    if (response.status > 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }

    return data.id;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getShippingProList(
  query?: string
): Promise<{ totalCount: number; shippingProList: IShippingPro[] } | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping-pro${query}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status > 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getShippingPro(
  id?: string
): Promise<IShippingPro | undefined> {
  try {
    if (!id) {
      return;
    }
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping-pro/${id}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status > 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.shippingPro;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateShippingPro(
  shipping: IShippingPro
): Promise<string | IValidationError[] | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping-pro/updateShippingPro/${shipping.id}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(shipping),
      }
    );
    const data = await response.json();
    if (response.status > 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }

    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getShippingProByTrackId(
  trackId: string
): Promise<IShippingPro> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping-pro/tracker/${trackId}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status > 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateShippingProState(
  id: string,
  state: string
): Promise<string | IValidationError[] | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping-pro/updateState/${id}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "PUT",
        credentials: "include",
        body: JSON.stringify({ state: state }),
      }
    );
    const data = await response.json();
    if (response.status > 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }

    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function checkifShopShippingExist(
  shopId: string,
  shippingDate: string
): Promise<string | IValidationError[] | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping-pro/updateShippingPro/`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "PUT",
        credentials: "include",
        body: JSON.stringify({ shopId: shopId, shippingDate: shippingDate }),
      }
    );
    const data = await response.json();
    if (response.status > 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }

    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function reserveShippingProLabel(
  id: string
): Promise<{ externalId: string; reservationNumber: string }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping-pro/reserve-shipping-pro-label/${id}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status > 400) {
      toast.error(
        `Erreur CHRONOPOST: ${
          data?.message.split("Message: ")[1] || "inconnu"
        }`,
        TOAST_ERROR_OPTIONS
      );
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function cancelShippingProLabel(
  id?: string
): Promise<{ id: string; canceled: boolean } | undefined> {
  try {
    if (!id) {
      return;
    }
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping-pro/cancel-shipping-pro-label/${id}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status > 400) {
      toast.error(`Erreur CHRONOPOST: ${data.message}`, TOAST_ERROR_OPTIONS);
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.shippingPro;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getShippingProLabel(
  // request: Request,
  reservationNumber: string,
  mode: string
): Promise<Buffer | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping-pro/get-shipping-label`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        // signal: request?.signal,
        body: JSON.stringify({
          reservationNumber: reservationNumber,
          mode: mode,
        }),
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      if (response.status === 401) {
        return null;
      }
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    // toast.error("Un problème est survenue.", TOAST_ERROR_OPTIONS);
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function generateShippingAndChronopost(
  shipping: IShippingPro
): Promise<{ shippingPro: IShippingPro }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping-pro/generate-shipping-and-chronopost`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        body: JSON.stringify(shipping),
      }
    );
    const data = await response.json();
    if (response.status > 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }

    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function generateShippingProAndChronopostNoLinkToShop(
  shipping: IShippingPro
): Promise<{ shippingPro: IShippingPro }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping-pro/generate-shipping-and-chronopost-no-link-to-shop`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        body: JSON.stringify(shipping),
      }
    );
    const data = await response.json();
    if (response.status > 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }

    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

//generate-shipping-and-chronopost-no-link-to-shop

export async function createShippingAndLinkToShop(
  shipping: IShippingPro
): Promise<{ id: string }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping-pro/create-shipping-and-link-to-shop`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        body: JSON.stringify(shipping),
      }
    );
    const data = await response.json();
    if (response.status > 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }

    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function finishShippingPro(
  id: string,
  direction: string,
  shopId: string,
  state: string
): Promise<{ id: string }> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping-pro/finish-shipping-pro/${id}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ direction, shopId, state }),
      }
    );
    const data = await response.json();
    if (response.status > 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }

    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getShippingProReservationNumberById(id: string): Promise<{
  reservationNumber: string;
  externalId: string;
  pickUpId?: string;
}> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping-pro/shipping-reservation-number/${id}`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status > 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }

    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getCartsByShippingProId(id?: string): Promise<{
  cartsList: ICartPro[];
  totalCount: number;
}> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/shipping-pro/carts/${id || ""}`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status > 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
