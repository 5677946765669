import { useEffect, useState } from "react";
import { IShop } from "../../../types/shop.types";
import style from "./createChronopost.module.scss";
import { IShippingPoint, PickUpInfo } from "../../../types/shipping.types";
import { IShippingMethod } from "../../../types/accounting.types";
import SelectDirection from "./SelectDirection/SelectDirection";
import SelectShippingMethod from "./SelectShippingMethod/SelectShippingMethod";
import SelectShop from "./SelectShop/SelectShop";
import SelectAddress from "./SelectAddress/SelectAddress";
import GenerateLabelSection from "./GenerateLabelSection/GenerateLabelSection";
import SelectOtherShopAddress from "./SelectAddress/SelectOtherShopAddress";

export default function CreateChronopost() {
  const [direction, setDirection] = useState<string | null>(null);
  const [pickUpInfo, setPickUpInfo] = useState<PickUpInfo | null>(null);
  const [shippingMethod, setShippingMethod] = useState<IShippingMethod | null>(
    null
  );
  const [shop, setShop] = useState<IShop | null>(null);
  const [isOtherShop, setIsOtherShop] = useState<boolean>(false);
  const [shippingInfo, setShippingInfo] = useState<IShippingPoint | null>(null);
  const [document, setDocument] = useState<string | null>(null);

  useEffect(() => {
    if (shippingMethod?.shippingService === "CHRONOPOST-PRO") {
      setShippingInfo(null);
    }
    if (shippingMethod?.shippingService === "CHRONOPOST-2SHOP" || shippingMethod?.shippingService === "CHRONOPOST-EUR") {
      setShop(null);
      setIsOtherShop(false);
    }
  }, [shippingMethod]);

  function resetForm() {
    setPickUpInfo(null);
    setDirection(null);
    setShippingMethod(null);
    setShippingInfo(null);
    setShop(null);
    setDocument(null);
    setIsOtherShop(false);
  }

  return (
    <div className={style["create-chronopost"]}>
      <div className={style["create-chronopost-container"]}>
        <div className={style["title"]}>Créer un bon CHRONOPOST</div>
        {/* <div className={style["create-chronopost-form"]}> */}
        <SelectDirection
          direction={direction}
          document={document}
          setDirection={setDirection}
        />
        {direction && (
          <SelectShippingMethod
            document={document}
            shippingMethod={shippingMethod}
            setShippingMethod={setShippingMethod}
          />
        )}
        {shippingMethod?.shippingService === "CHRONOPOST-PRO" && (
          <>
            {isOtherShop ? (
              <SelectOtherShopAddress
                document={document}
                shippingInfo={shippingInfo}
                setShippingInfo={setShippingInfo}
              />
            ) : (
              <SelectShop document={document} shop={shop} setShop={setShop} />
            )}
            <div
              className={style["other-shop"]}
              onClick={() => setIsOtherShop(!isOtherShop)}
            >
              {isOtherShop ? "Sélectionner une boutique" : "autre boutique"}
            </div>
          </>
        )}
        {(shippingMethod?.shippingService === "CHRONOPOST-2SHOP" || shippingMethod?.shippingService === "CHRONOPOST-EUR") && (
          <SelectAddress
            document={document}
            shippingInfo={shippingInfo}
            setShippingInfo={setShippingInfo}
            setPickUpInfo={setPickUpInfo}
          />
        )}
        <GenerateLabelSection
          direction={direction}
          pickUpInfo={pickUpInfo}
          resetForm={resetForm}
          setDocument={setDocument}
          shippingInfo={shippingInfo}
          shippingMethod={shippingMethod}
          shop={shop}
          isOtherShop={isOtherShop}
        />
        {/* </div> */}
        {/* <div className={style["document-viewer"]}></div> */}
      </div>
    </div>
  );
}
