import { useState } from "react";
import { IProduct } from "../../types/logistic.types";
import Button from "../Button/Button";
import styles from "./printLabel.module.scss";
import ModalPortal from "../modals/ModalPortal";
import ReadModal from "../modals/ReadModal/ReadModal";
import PrintProductLabelModal from "../modals/printProductLabelModal/PrintProductLabelModal";
import { PrintOutlined } from "@mui/icons-material";
import { ModalContainer } from "../modals/ModalContainer";

interface PrintLabelButtonProps {
  product: IProduct;
}

export default function PrintLabelButton({ product }: PrintLabelButtonProps) {
  const [printLabelModal, setPrintLabelModal] = useState<boolean>(false);
  return (
    <>
      <Button
        className={styles["print-label-button"]}
        onClick={handleOpenPrintLabelModal}
      >
        <PrintOutlined className={styles["icon"]} />
      </Button>
      <ModalContainer
        isOpen={printLabelModal}
        onCancel={handleClosePrintLabelModal}
      >
        {/* <ReadModal handleCloseModal={handleClosePrintLabelModal}> */}
        <PrintProductLabelModal
          product={product}
          comments={
            product?.dueDate
              ? "à faire avant le: " +
                new Date(product.dueDate).toLocaleDateString("fr-FR")
              : ""
          }
        />
        {/* </ReadModal> */}
      </ModalContainer>
    </>
  );

  function handleClosePrintLabelModal() {
    setPrintLabelModal(false);
  }

  function handleOpenPrintLabelModal() {
    setPrintLabelModal(!printLabelModal);
  }
}
