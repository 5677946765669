import { Outlet, RouteObject } from "react-router-dom";
import ErrorPage from "../../pages/error-page";
import { authloader } from "..";
import Discount from ".";
import Create from "./create/create";

export const route: RouteObject = {
  path: "/discount",
  element: <Outlet />,
  errorElement: <ErrorPage />,
  loader: authloader,
  children: [
    {
      index: true,
      element: <Discount />,
      loader: authloader,
    },
    {
      path: "create",
      element: <Create />,
      loader: authloader,
    },
  ],
};
