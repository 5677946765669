import style from "./addCrossSellModal.module.scss";
import { useState } from "react";
import { useQuery } from "react-query";
import { getCrossSellsList } from "../../../requests/crossSelling";
import {
  ICrossSellProduct,
  ICrossSellQuote,
} from "../../../types/accounting.types";
import Button from "../../Button/Button";
import CrossSellList from "./CrossSellList/CrossSellList";
import { queryObject } from "../../../types/query.types";
import CrossSellFilters from "./CrossSellFilters/CrossSellFilters";
import LoadingSpinner from "../../loadingSpinner/loadingSpinner";

const INITIAL_QUERY: queryObject = {
  color: "",
  category: "",
  limit: 10,
  offset: 0,
};

interface AddCrossSellModalProps {
  setModalIsOpen: (bool: boolean) => void;
  handleCrossSellChange: (cs: ICrossSellQuote[]) => void;
  crossSellItems: ICrossSellQuote[] | undefined;
}

export default function AddCrossSellModal({
  setModalIsOpen,
  handleCrossSellChange,
  crossSellItems,
}: AddCrossSellModalProps) {
  const [query, setQuery] = useState<queryObject>(INITIAL_QUERY);
  const { data } = useQuery(
    ["cross-sell-list", query],
    () => getCrossSellsList(formatedQuery()),
    { keepPreviousData: true }
  );
  const [selectedCrossSells, setSelectedCrossSells] = useState<
    ICrossSellProduct[]
  >(crossSellItems || []);
  const [showSelection, setShowSelection] = useState<boolean>(false);

  return (
    <div className={style["add-cross-sell-modal"]}>
      <div className={style["add-cross-sell-modal-container"]}>
        <CrossSellFilters query={query} setQuery={setQuery} />
        <CrossSellList
          list={showSelection ? selectedCrossSells : data?.crossSells}
          selectedCrossSells={selectedCrossSells}
          setSelectedCrossSells={setSelectedCrossSells}
        />
        <div className={style["add-cross-sell-modal-bottom"]}>
          <Button onClick={() => changeShowedList()}>
            {showSelection
              ? "Tous les articles"
              : `Articles selectionnés (${selectedProductsNumber()})`}
          </Button>
          <Button onClick={handleValidate}>Valider</Button>
        </div>
      </div>
    </div>
  );

  function handleValidate() {
    handleCrossSellChange(selectedCrossSells as ICrossSellQuote[]);
    setModalIsOpen(false);
  }

  function changeShowedList() {
    setShowSelection(!showSelection);
  }

  function selectedProductsNumber() {
    return selectedCrossSells.reduce(
      (acc, cs) => acc + (cs.cartQuantity || 0),
      0
    );
  }

  function formatedQuery() {
    let formatedQuery: string = "?";

    if (query.category) {
      formatedQuery += `&category=${query.category}`;
    }
    if (query.color) {
      formatedQuery += `&color=${query.color}`;
    }

    return formatedQuery;
  }
}
