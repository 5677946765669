import TimeslotInputs from "./timeslotInputs";

interface TimeslotsForm {
  slots: Array<{ start: string; end: string }>;
  onChange: (slots: Array<{ start: string; end: string }>) => void;
}
export default function TimeslotsForm({ slots, onChange }: TimeslotsForm) {
  return (
    <div>
      <label>Créneaux</label>
      {slots.map((slot, index) => (
        <div key={`slot-${slot.start}-${slot.end}-${index}`}>
          {`De ${slot.start} à ${slot.end}`}
        </div>
      ))}
      <TimeslotInputs onChange={handleChange} />
    </div>
  );

  function handleChange(value: any) {
    onChange([...slots, value]);
  }
}
