import { CheckListItem } from "../../types";
import Input from "../inputs/Input/Input";
import style from "./checklist.module.scss";

interface ChecklistProps {
  checklist: CheckListItem[];
  setChecklist: (value: CheckListItem[]) => void;
}

export default function Checklist({ checklist, setChecklist }: ChecklistProps) {
  return (
    <ul className={style["checklist"]}>
      {checklist.map((checkItem: CheckListItem, index: number) => {
        return (
          <li
            className={style["checklist-item"]}
            key={index}
            onClick={() => handleCheck(index)}
          >
            <div className={style["description"]}>
              <span>{checkItem.description}</span>
            </div>
            <div className={style["dotted"]}></div>
            <Input
              className={style["checkbox-input"]}
              type="checkbox"
              checked={checkItem.checked}
              onChange={() => handleCheck(index)}
            />
          </li>
        );
      })}
    </ul>
  );

  function handleCheck(index: number) {
    setChecklist(
      checklist.map((item, i) => {
        return { ...item, checked: index === i ? !item.checked : item.checked };
      })
    );
  }
}
