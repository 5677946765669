import { useQuery } from "react-query";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { deleteEstimate, fetchEstimate } from "../../../requests/estimate";
import { IEstimate } from "../../../types/booking.type";
import style from "./read.module.scss";
import { authloader } from "../..";

let controller = new AbortController();

export async function loader({ params }: { params: any }) {
  try {
    const estimate = await fetchEstimate({ params });
    authloader();
    return estimate;
  } catch (error: any) {
    throw error;
  }
}

export default function Read() {
  const data: IEstimate | null = useLoaderData() as IEstimate;
  const navigate = useNavigate();

  async function handleDelete(id: string) {
    controller.abort();
    try {
      controller = new AbortController();
      await deleteEstimate(id, controller.signal);
      navigate("..");
    } catch (error) {
      console.error(error);
    }
  }

  return <div className={style["read-container"]}>{data && <></>}</div>;
}
