import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import translate from "../../../../translation";
import style from "./autoComplete.module.scss";
import InputContainer from "../../inputs/inputContainer/inputContainer";

interface AutoCompleteInputProps {
  name: string;
  dataValue: string;
  idPrefix?: string;
  index: number;
  onChange: ({ name, value }: { name: string; value: string | number }) => void;
  fetchRequest?: { (_: any): Promise<string[]> };
  hardData?: string[];
  className?: string;
  handleStartCorrection?: (name: string) => void;
}

async function emptyFt(): Promise<string[]> {
  return new Promise((resolve, reject) => resolve([]));
}

export default function AutoCompleteInput({
  name,
  dataValue,
  idPrefix,
  index,
  onChange,
  fetchRequest,
  hardData,
  className,
  handleStartCorrection,
}: AutoCompleteInputProps) {
  const [value, setValue] = useState<string>(dataValue);
  let { isLoading, error, data } = useQuery(name, fetchRequest || emptyFt);

  if (hardData) {
    data = hardData;
  }

  useEffect(() => {
    setValue(dataValue);
  }, [dataValue]);

  function startCorrection() {
    handleStartCorrection && handleStartCorrection(name);
  }

  return (
    <div className={style.autoCompleteInput}>
      <InputContainer>
        <label>{translate(name)}</label>
        <input
          list={`opts-${translate(name)}-${idPrefix}-${index}`}
          autoComplete="off"
          type="text"
          name={name}
          onChange={({ target }) => handleChange(target)}
          value={value}
          className={className}
          onFocus={startCorrection}
        />
      </InputContainer>
      <datalist id={`opts-${translate(name)}-${idPrefix}-${index}`}>
        {isLoading && <option className={style.item}>Chargement...</option>}
        {data &&
          data.map((item: string, key: number) => {
            return (
              <option
                key={`${index}-${key}`}
                className={style.item}
                onClick={() => handleClick(name, item)}
              >
                {translate(item)}
              </option>
            );
          })}
      </datalist>
    </div>
  );

  function handleChange(target: EventTarget & HTMLInputElement) {
    setValue(target.value);
    onChange({ name: target.name, value: target.value });
  }

  function handleClick(name: string, value: string | number) {
    setValue(value as string);
    onChange({ name: name, value: value });
  }
}
