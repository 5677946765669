import RearLabel from "./RearLabel";
import { splitStringsToFitCanvas } from "./useDetailedLabel";
import { NiimbotBluetoothClient } from "@mmote/niimbluelib";
import styles from "./RearLabelList.module.scss";

interface RearLabelListProps {
  operations: string[];
  workshopId: string;
  dueDate: Date | null;
}

export default function RearLabelList({
  operations,
  workshopId,
  dueDate,
}: RearLabelListProps) {
  const chunks = splitStringsToFitCanvas(operations, {
    width: 8 * 50,
    height: 8 * 30,
  });

  return (
    <div className={styles["rear-label-list"]}>
      <h3>{`Étiquette${chunks.length > 1 ? "s" : ""} verso`}</h3>
      {chunks.map((strings, index) => (
        <RearLabel
          dueDate={dueDate}
          key={index}
          strings={strings}
          workshopId={workshopId}
        />
      ))}
    </div>
  );
}
