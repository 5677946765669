import WorkshopId from "../../../components/workshopId/workshopId";
import { ICrossSellLogistic } from "../../../types/logistic.types";
import styles from "./crossSellCell.module.scss";

interface CrossSellCellProps {
  crossSell?: ICrossSellLogistic;
}

export default function CrossSellCell({ crossSell }: CrossSellCellProps) {
  if (
    !crossSell ||
    !crossSell?.crossSellItems ||
    !crossSell?.crossSellItems.length
  ) {
    return null;
  }
  return (
    <div className={styles["item-product-list"]}>
      <div className={styles["item-product"]}>
        <div className={styles["title"]}>Articles Cross-selling</div>
        <div className={styles["cross-sell-number"]}>
          {crossSell.crossSellItems.length}
        </div>
        <WorkshopId
          workshopId={crossSell.workshopId}
          size={"xxx-small"}
          type={"cross-sell"}
        />
      </div>
    </div>
  );
}
