import {
  WeekView,
  DayView,
  MonthView,
} from "@devexpress/dx-react-scheduler-material-ui";

export default function CalendarView({
  view,
  setView,
  setDate,
  date,
}: {
  view: string;
  setView: (view: string) => void;
  setDate: (date: Date) => void;
  date: Date;
}) {
  switch (view) {
    case "week":
      return (
        <WeekView
          startDayHour={7}
          endDayHour={22}
          excludedDays={[0, 6]}
          cellDuration={60}
          timeTableCellComponent={({ ...restProps }) => (
            <WeekView.TimeTableCell
              {...restProps}
              onDoubleClick={({ target }) => {
                let dayOfWeek = date.getDay();
                let currentClickedDate = new Date(date);
                currentClickedDate.setDate(
                  date.getDate() - dayOfWeek + 1 + target.cellIndex
                );
                setDate(currentClickedDate);
                setView("day");
              }}
            />
          )}
        />
      );
    case "day":
      return <DayView startDayHour={7} endDayHour={22} cellDuration={90} />;
    case "month":
      return (
        <MonthView
          timeTableCellComponent={({ ...restProps }) => (
            <MonthView.TimeTableCell
              {...restProps}
              onDoubleClick={({ target }) => {
                let dayOfWeek = date.getDay();
                let currentClickedDate = new Date(date);
                currentClickedDate.setDate(date.getDate() - dayOfWeek + 1);
              }}
            />
          )}
        />
      );
    default:
      return <></>;
  }
}
