import { IValidationError } from "../types";
import { IOperation } from "../types/accounting.types";

export async function fetchProductGroups({
  request,
}: {
  request?: Request;
}): Promise<string[]> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation/product-groups`,
      {
        credentials: "include",
        signal: request?.signal,
      }
    );

    const data = await response.json();

    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.productGroups;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchOperationGroups({
  request,
}: {
  request?: Request;
}): Promise<string[]> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation/operation-groups`,
      {
        credentials: "include",
        signal: request?.signal,
      }
    );

    const data = await response.json();

    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.operationGroups;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchOperationTypes({
  request,
}: {
  request?: Request;
}): Promise<string[]> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation/operation-types`,
      {
        credentials: "include",
        signal: request?.signal,
      }
    );

    const data = await response.json();

    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.operationTypes;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchOperation({
  request,
  params,
}: {
  request: Request;
  params: any;
}): Promise<IOperation> {
  try {
    if (!params.operationId) {
      throw new Response("Missing operation id");
    }
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation/${params.operationId}`,
      {
        credentials: "include",
        signal: request.signal,
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchOperationsList2(
  params: { key: string; value: string }[]
): Promise<IOperation[]> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation?${params
        .map((param) => param.key + "=" + param.value)
        .join("&")}`,
      {
        credentials: "include",
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.operations;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchOperationsList(
  // request: Request,
  query?: string
): Promise<{ operations: IOperation[]; totalCount: number }> {
  try {
    let queryUrl = query ? query : "";
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation?` + queryUrl,
      {
        credentials: "include",
        // signal: request?.signal,
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function postOperation(
  operation: IOperation
): Promise<string | IValidationError[] | null> {
  // const formData = await request.formData();
  // const operation = Object.fromEntries(formData);
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/operation`, {
      headers: new Headers({ "Content-Type": "application/json" }),
      credentials: "include",
      method: "POST",
      body: JSON.stringify(operation),
    });
    const data = await response.json();

    if (![201, 200, 400].includes(response.status)) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function updateOperation(
  operation: IOperation
): Promise<IOperation> {
  try {
    if (!operation.slug) {
      throw new Response("Missing operation slug");
    }
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation/${operation.slug}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "include",
        method: "PUT",
        body: JSON.stringify(operation),
      }
    );
    const data = await response.json();
    if (![201, 200, 400].includes(response.status)) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function deleteOperation({
  slug,
  signal,
}: {
  slug: string;
  signal?: AbortSignal;
}) {
  try {
    if (!slug) {
      throw new Response("Missing operation slug");
    }
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/operation/${slug}`,
      {
        credentials: "include",
        signal: signal,
        method: "DELETE",
      }
    );
    if (response.status !== 200) {
      throw new Response("Deletion failes", {
        statusText: response.statusText,
        status: response.status,
      });
    }
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
