import { Bluetooth, Usb } from "@mui/icons-material";
import styles from "./printerConnectHeader.module.scss";
import { ConnectionType, usePrinter } from "../../../context/printer.context";
import ConnectPrinter from "./ConnectPrinter";
import clsx from "clsx";

interface PrinterConnectHeaderProps {}

export default function PrinterConnectHeader({}: PrinterConnectHeaderProps) {
  const { initPrinter, connectionType } = usePrinter();

  return (
    <div className={styles["printer-connect-header"]}>
      <div className={styles["printer-connect-header-container"]}>
        <label>Connecter l'imprimante</label>
        <div className={styles["connection-type-container"]}>
          <div className={styles["connection-type"]}>
            <div
              className={clsx(
                styles["connection-type-button"],
                connectionType === "bluetooth" && styles["selected"]
              )}
              onClick={() => onChangeConnectionType("bluetooth")}
            >
              <Bluetooth className={styles["icon"]} /> Bluetooth
            </div>
            <div
              className={clsx(
                styles["connection-type-button"],
                connectionType === "usb" && styles["selected"]
              )}
              onClick={() => onChangeConnectionType("usb")}
            >
              <Usb className={styles["icon"]} /> Usb
            </div>
          </div>
          <ConnectPrinter />
        </div>
      </div>
    </div>
  );

  function onChangeConnectionType(connectionType: ConnectionType) {
    initPrinter(connectionType);
  }
}
