import { Link } from "react-router-dom";
import ContactSearch from "../../../../components/contactSearch/contactSearch";
import styles from "./overviewHeader.module.scss";
import { ITracker } from "../../../../types/tracker.types";
import { Mail, Phone } from "@mui/icons-material";
import State from "../../../../components/State/State";
import { TRACKING_STATE } from "../../../../utils/tracking.init";
import { ProtectedComponent } from "../../../../components/protectedComponent/protectedComponent";
import Button from "../../../../components/Button/Button";
import PaymentModal from "../../../../components/modals/paymentModal/paymentModal";
import { ICustomer } from "../../../../types/customer.type";
import { linkTracker } from "../../../../requests/customer";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../../../utils/toast.options";
import { updateTrackerState } from "../../../../requests/tracker";
import { useState } from "react";
import { ModalContainer } from "../../../../components/modals/ModalContainer";

interface OverviewHeaderProps {
  tracker: ITracker;
  refetch: () => void;
  isFetching: boolean;
}

export default function OverviewHeader({
  tracker,
  refetch,
  isFetching,
}: OverviewHeaderProps) {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  if (!tracker) {
    return <></>;
  }

  return (
    <>
      <div className={styles["overview-container-top"]}>
        <div className={styles["contact-info"]}>
          {tracker.contact ? (
            <>
              <div className={styles["contact-fullname"]}>
                {tracker.contact.displayname}
              </div>
              <div className={styles["contact-coordinate"]}>
                <Link
                  className={styles["mail"]}
                  to={`mailto:${tracker.contact.email}`}
                >
                  <Mail className={styles["contact-icon"]} />
                  {tracker.contact.email}
                </Link>
                <Link
                  className={styles["phone"]}
                  to={`tel:${tracker.contact.phone}`}
                >
                  <Phone className={styles["contact-icon"]} />
                  {tracker.contact.phone}
                </Link>
              </div>
            </>
          ) : (
            <ContactSearch onSelect={linkTrackerToCustomer} />
          )}
        </div>
        <div>
          <State
            state={tracker.state}
            stateList={TRACKING_STATE}
            handleSetState={handleChangeTrackerState}
            isTracker={true}
            className={styles["state"]}
            isLoading={isFetching}
          />
          {!tracker.payment && (
            <ProtectedComponent roles={["ROLE_ADMIN"]}>
              <Button
                className={styles["payment-button"]}
                onClick={openPaymentModal}
              >
                Forcer un paiement
              </Button>
            </ProtectedComponent>
          )}
        </div>
      </div>
      <ModalProvider
        modalIsOpen={modalIsOpen}
        closePaymentModal={closePaymentModal}
        tracker={tracker}
      />
    </>
  );

  async function linkTrackerToCustomer(customer: ICustomer) {
    if (!customer.id) {
      return;
    }

    try {
      await linkTracker(customer.id, tracker.id);
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    }
  }

  async function handleChangeTrackerState(state: string) {
    if (!tracker.id) {
      return;
    }
    try {
      await updateTrackerState(state, tracker.id);
      refetch();
      toast.success(
        "L'état à était mis à jour avec succès.",
        TOAST_SUCCESS_OPTIONS
      );
    } catch (error) {
      toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
    }
  }

  function openPaymentModal() {
    setModalIsOpen(true);
  }

  function closePaymentModal() {
    setModalIsOpen(!modalIsOpen);
    refetch();
  }
}

interface ModalProviderProps {
  modalIsOpen: boolean;
  closePaymentModal: () => void;
  tracker: ITracker;
}

function ModalProvider({
  modalIsOpen,
  closePaymentModal,
  tracker,
}: ModalProviderProps) {
  return (
    <ModalContainer isOpen={modalIsOpen} onCancel={closePaymentModal}>
      <PaymentModal tracker={tracker} onSuccess={closePaymentModal} />
    </ModalContainer>
  );
}
