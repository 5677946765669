import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import BrandLogo from "../../components/vectors/brand.component";
import { isLogged, loginRequest, logoutRequest } from "../../requests/user";
import style from "./index.module.scss";
import { TOAST_ERROR_OPTIONS } from "../../utils/toast.options";
import { toast } from "react-toastify";
import { useAuth } from "../../context/auth.context";
import { authloader } from "..";
import Input from "../../components/inputs/Input/Input";
import Button from "../../components/Button/Button";

export interface LoginForm {
  username: string;
  password: string;
}

export interface User {
  uid: string;
  username: string;
  roles: string[];
  isAuthenticated: boolean;
}

const INTIALE_STATE: LoginForm = {
  username: "",
  password: "",
};

export default function Login() {
  const [loginForm, setLoginForm] = useState<LoginForm>(INTIALE_STATE);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { isAuthenticated, registerUser, unregisterUser } = useAuth();
  const navigate = useNavigate();
  const { data } = useQuery("auth-check", () => isLogged());

  const loginMutation = useMutation(loginRequest, {
    onError: (error) => {
      unregisterUser();
      toast.error("Email ou mot de passe incorrect.", TOAST_ERROR_OPTIONS);
    },
    onSuccess(data) {
      registerUser(data);
      navigate("/");
    },
  });

  useEffect(() => {
    if (data?.status === 401) {
      unregisterUser();
    }
  }, [data]);

  return (
    <div className={style["login-page"]}>
      <div className={style["container"]}>
        <div className={style["inputs"]}>
          <Link onClick={() => window.scrollTo(0, 0)} to="/">
            <BrandLogo className={style["logo"]} main="#FAFAFA" />
          </Link>
          {!isAuthenticated ? (
            <>
              <form
                id={"id-form"}
                className={style["login-form"]}
                onSubmit={handlelogin}
              >
                <Input
                  className={style["login-input"]}
                  type="text"
                  name="username"
                  value={loginForm.username || ""}
                  placeholder={"Nom d'utilisateur"}
                  onChange={handleChange}
                />
                <Input
                  className={style["login-input"]}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={loginForm.password || ""}
                  placeholder={"Mot de passe"}
                  onChange={handleChange}
                />
                {!!loginForm.password && (
                  <div
                    className={style["show-password"]}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? "masquer" : "afficher"} mot de passe
                  </div>
                )}
              </form>
              <Button
                isLoading={loginMutation.isLoading}
                form="id-form"
                className={style["button"]}
              >
                Se Connecter
              </Button>
            </>
          ) : (
            <Button className={style["button"]} onClick={handlelogout}>
              Se Déconnecter
            </Button>
          )}
        </div>
      </div>
    </div>
  );

  async function handlelogin(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    loginMutation.mutate(loginForm);
  }

  function handlelogout(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    logoutRequest();
    unregisterUser();
  }

  function handleChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    setLoginForm((prev) => ({
      ...prev,
      [target.name as keyof LoginForm]: target.value,
    }));
  }
}
