import style from "./prepareCrossSellProducts.module.scss";
import {
  ICrossSellItem,
  ICrossSellLogistic,
  ILogistic,
} from "../../../../../types/logistic.types";
import PrepareCrossSellCard from "./PrepareCrossSellCard/PrepareCrossSellCard";
import WorkshopIdInput from "./WorkshopIdInput/WorkshopIdInput";

interface PrepareCrossSellProductsProps {
  logistic: ILogistic;
  setCrossSell: (value: ICrossSellLogistic) => void;
}

export default function PrepareCrossSellProducts({
  logistic,
  setCrossSell,
}: PrepareCrossSellProductsProps) {
  return (
    <div className={style["logistic-action-container"]}>
      <span className={style["message"]}>
        Veuillez mettre les articles ci-dessous dans un pochon et leur attribuer
        un ticket. Si un article n'est pas disponible veuillez le décocher.
      </span>
      <div className={style["prepare-cross-sell-products"]}>
        <div className={style["cross-sell-products-grid"]}>
          {(logistic?.crossSell?.crossSellItems || []).map(
            (cs: ICrossSellItem, index: number) => {
              return (
                <PrepareCrossSellCard
                  key={index}
                  isItemReady={cs.isItemReady}
                  item={cs}
                  handleOnClick={() => handleSelectCrossSellProduct(index)}
                />
              );
            }
          )}
        </div>
        <WorkshopIdInput
          workshopId={logistic?.crossSell?.workshopId || ""}
          setWorkshopId={handleSetWorkshopId}
        />
      </div>
    </div>
  );

  function handleSelectCrossSellProduct(index: number) {
    if (!logistic?.crossSell) {
      return;
    }
    setCrossSell({
      ...logistic.crossSell,
      crossSellItems: (logistic.crossSell.crossSellItems || []).map((cs, i) => {
        return index === i ? { ...cs, isItemReady: !cs.isItemReady } : cs;
      }),
    });
  }

  function handleSetWorkshopId(workshopId: string) {
    setCrossSell({ ...logistic?.crossSell, workshopId });
  }
}
