import style from "./miscellaneousInfoForm.module.scss";
import { IProduct } from "../../../../types/logistic.types";
import Input from "../../../inputs/Input/Input";
import FreshNewColorPicker from "../../../freshNewColorPicker/freshNewColorPicker";

interface MiscellaneousInfoFormProps {
  productForm: IProduct;
  setProductForm: (product: IProduct) => void;
}

export default function MiscellaneousInfoForm({
  productForm,
  setProductForm,
}: MiscellaneousInfoFormProps) {
  return (
    <div className={style["miscellaneous-info-form"]}>
      <div className={style["left"]}>Autres informations</div>
      <div className={style["right"]}>
        <div className={style["input-line"]}>
          <Input
            className={style["input-container"]}
            label="Taille"
            name="size"
            type="number"
            value={productForm.size}
            onChange={({ target }) =>
              setProductForm({ ...productForm, size: parseInt(target.value) })
            }
          />
          <div style={{ width: "100%" }}>
            <label>Couleur *</label>
            <FreshNewColorPicker
              color={productForm.color}
              handleColorChange={(value) =>
                setProductForm({ ...productForm, color: value })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
