import styles from "./subMenuItem.module.scss";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import clsx from "clsx";
import { useHideSideBar } from "../../../context/hideSideBar.context";
import MenuItemContainer from "../MenuItemContainer/MenuItemContainer";
import { useRef, useState } from "react";
import SubMenu from "../SubMenu/SubMenu";
import { useGetPath } from "../../../utils/utils";

interface SubMenuItemProps {
  title: string;
  Logo: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
  SelectedLogo: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
}

export default function SubMenuItem({
  title,
  Logo,
  SelectedLogo,
}: SubMenuItemProps) {
  const shadeAccent = "#e4e4e7";
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const isSelected = useGetPath(title);
  const { hideSide } = useHideSideBar();

  return (
    <>
      <MenuItemContainer
        title={title}
        style={{ backgroundColor: openMenu ? shadeAccent : "" }}
      >
        <div
          className={styles["sub-menu-item-link"]}
          ref={ref}
          onClick={handleOpenSubMenu}
        >
          <div className={styles["sub-menu-item-icon-container"]}>
            {openMenu || isSelected ? (
              <SelectedLogo className={styles["sub-menu-item-icon"]} />
            ) : (
              <Logo className={styles["sub-menu-item-icon"]} />
            )}
          </div>
          <p
            className={clsx(
              styles["sub-menu-item-title"],
              hideSide && styles["hide"]
            )}
          >
            {title}
          </p>
        </div>
      </MenuItemContainer>
      <SubMenu
        openMenu={openMenu}
        title={title}
        onMenuClose={handleCloseSubMenu}
        parentRef={ref}
      />
    </>
  );

  function handleOpenSubMenu() {
    setOpenMenu(!openMenu);
  }

  function handleCloseSubMenu() {
    setOpenMenu(false);
  }
}
