import { useRemoveBodyScroll } from "../../hooks/useRemoveBodyScroll";

interface RemoveBodyScrollProps {
  children: React.ReactNode;
}

export default function RemoveBodyScroll({ children }: RemoveBodyScrollProps) {
  useRemoveBodyScroll();

  return <>{children}</>;
}
