import styles from "./item.module.scss";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import { Delete, Edit, Launch } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import translate from "../../../translation";
import getStatusColor from "../../../translation/statusColor";
import { ICart } from "../../../types/booking.type";
import WorkshopId from "../../../components/workshopId/workshopId";
import { useContext } from "react";
import { useModalComponent } from "../../../context/modalComponent.context";
import ReadCartModal from "../../../components/modals/readCartModal/ReadCartModal";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import clsx from "clsx";
import CenterCell from "../../../components/CenterCell/CenterCell";
import State from "../../../components/StateItem/State";

export default function Item({
  data,
  onDelete,
}: {
  data: ICart;
  onDelete: (id?: string) => void;
}) {
  const { setModalIsOpen, setModalChildren } = useModalComponent();
  const navigate = useNavigate();
  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Launch />,
      name: "Ouvrir",
      onClick: handleOpenReadCartModal,
    },
    {
      icon: <Edit />,
      name: "Modifier",
      to: `/professionalCarts/edit/${data.id}`,
    },
    {
      icon: <Delete />,
      name: "Supprimer",
      onClick: handleDelete,
    },
  ];

  return (
    <tr className={styles["item"]}>
      <td className={clsx(styles["item-cell"], styles["item-coord"])}>
        <div className={styles["coordinates"]}>
          <span>{`${
            data.contact?.givenname
          } ${data.contact?.familyname?.toLocaleUpperCase("FR")}`}</span>
          <span>{data.contact?.phone}</span>
        </div>
      </td>
      <td className={styles["item-cell"]}>
        {data.createdAt && new Date(data.createdAt).toLocaleDateString()}
        {data.updatedAt && new Date(data.updatedAt).toLocaleDateString()}
      </td>
      <td
        className={clsx(styles["item-cell"], styles["product-cell"])}
        onClick={handleOpenReadCartModal}
      >
        {data.productsList[0] && (
          <WorkshopId
            size={"xx-small"}
            workshopId={data.productsList[0].workshopId}
          />
        )}
        {data.productsList.length > 1 && (
          <div className={styles["more-circle"]}>
            + {data.productsList.length - 1}
          </div>
        )}
      </td>
      <td className={clsx(styles["item-cell"], styles["status-box"])}>
        <CenterCell>
          <State state={data.state} />
        </CenterCell>
      </td>
      <td className={clsx(styles["item-cell"], styles["tools"])}>
        <ProtectedComponent roles={["ROLE_ADMIN"]}>
          <CenterCell>
            <MoreTools options={MORE_TOOLS_OPTIONS} />
          </CenterCell>
        </ProtectedComponent>
      </td>
    </tr>
  );

  function handleDelete() {
    if (window.confirm("Voulez-vous supprimer cette demande ?")) {
      onDelete(data.id);
    }
  }

  function handleOpenReadCartModal() {
    setModalChildren(<ReadCartModal cart={data} />);
    setModalIsOpen(true);
  }
}
