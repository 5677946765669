import Widget from "../../newWidget/widget";
import style from "./leather.module.scss";
import logo from "../../../assets/leather.png";

export default function LeatherWidget() {
  return (
    <Widget to={"leather"}>
      <div className={style["icon-container"]}>
        <img src={logo} className={style["icon"]} />
      </div>
      <div>Maroquinerie</div>
    </Widget>
  );
}
