import { Close, ExpandMore } from "@mui/icons-material";
import translate from "../../translation";
import style from "./filterByStatus.module.scss";
import { useEffect, useRef, useState } from "react";
import { translateTrackingState } from "../../utils/tracking.init";
import { queryObject } from "../../types/query.types";
import { isDesktop } from "react-device-detect";
import getStatusColor from "../../translation/statusColor";

interface FilterByStatusProps {
  optionsList: string[];
  query: queryObject;
  setQuery: (query: queryObject) => void;
  isTracking?: boolean;
  field?: string;
  className?: string;
}

export default function FilterByStatus({
  optionsList,
  query,
  setQuery,
  isTracking,
  className,
  field,
}: FilterByStatusProps) {
  const [expand, setExpand] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setExpand(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div className={`${style["search-by-key-component"]} ${className}`}>
      <div className={style["select-key-container"]} ref={ref}>
        <div
          className={style["select-key"]}
          onClick={handleExpand}
          style={{
            backgroundColor:
              query.state && !isTracking ? getStatusColor(query.state) : "",
            opacity: query.state && !isTracking ? "0.75" : "1",
          }}
        >
          <span className={style["placeholder"]}>
            {isTracking
              ? translateTrackingState(query.state || "choisissez un status...")
              : translate(query.state || "choisissez un status...")}
          </span>
          <div className={style["expand-icon-container"]}>
            {query.state ? <Close /> : <ExpandMore />}
          </div>
        </div>
        {expand && (
          <div className={style["options-list-container"]}>
            <div className={style["options-list"]}>
              {optionsList.map((option: string, index: number) => {
                return (
                  <div
                    className={`${style["option-item"]} ${
                      !isDesktop && style["large-item"]
                    }`}
                    onClick={() => handleChangeState(option)}
                    key={index}
                  >
                    {isTracking
                      ? translateTrackingState(option)
                      : translate(option)}
                  </div>
                );
              })}
            </div>
            <div className={style["beautiful-div"]}></div>
          </div>
        )}
      </div>
    </div>
  );

  function handleChangeState(option: string) {
    const newQuery = { ...query };
    newQuery.state = option;
    setQuery(newQuery);
    setExpand(false);
  }

  function handleExpand() {
    if (query.state) {
      const newQuery = { ...query };
      newQuery.state = "";
      setQuery(newQuery);
      return;
    }
    setExpand(!expand);
  }
}
