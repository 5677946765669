import styles from "./subMenus.module.scss";
import MenuItemContainer from "../../MenuItemContainer/MenuItemContainer";
import { Link } from "react-router-dom";

interface ProfesionnalMenuProps {
  title: string;
  onMenuClose: () => void;
}

export function ProfesionnalMenu({
  title,
  onMenuClose,
}: ProfesionnalMenuProps) {
  return (
    <div className={styles["sub-menu-container"]}>
      <p className={styles["title"]}>{title}</p>
      <ProfesionnalMenuList onMenuClose={onMenuClose} />
    </div>
  );
}

interface ProfesionnalMenuListProps {
  onMenuClose: () => void;
}

export function ProfesionnalMenuList({
  onMenuClose,
}: ProfesionnalMenuListProps) {
  return (
    <ul className={styles["sub-menu"]}>
      <MenuItemContainer isSubMenu title={"Boutiques"}>
        <Link
          onClick={onMenuClose}
          to="/shops"
          className={styles["expandable-menu-item-link"]}
        >
          <p>{"Boutiques"}</p>
        </Link>
      </MenuItemContainer>
      <MenuItemContainer isSubMenu title={"Demandes Pro"}>
        <Link
          onClick={onMenuClose}
          to="/professionalCarts"
          className={styles["expandable-menu-item-link"]}
        >
          <p>{"Demandes Pro"}</p>
        </Link>
      </MenuItemContainer>
      <MenuItemContainer isSubMenu title={"Factures"}>
        <Link
          onClick={onMenuClose}
          to="/invoice-pro"
          className={styles["expandable-menu-item-link"]}
        >
          <p>{"Factures"}</p>
        </Link>
      </MenuItemContainer>
      <MenuItemContainer isSubMenu title={"Licenses"}>
        <Link
          onClick={onMenuClose}
          to="/licenses"
          className={styles["expandable-menu-item-link"]}
        >
          <p>{"Licences"}</p>
        </Link>
      </MenuItemContainer>
    </ul>
  );
}

interface DocumentsMenuProps {
  title: string;
  onMenuClose: () => void;
}

export function DocumentsMenu({ title, onMenuClose }: DocumentsMenuProps) {
  return (
    <div className={styles["sub-menu-container"]}>
      <p className={styles["title"]}>{title}</p>
      <DocumentsMenuList onMenuClose={onMenuClose} />
    </div>
  );
}

interface DocumentsMenuListProps {
  onMenuClose: () => void;
}

export function DocumentsMenuList({ onMenuClose }: DocumentsMenuListProps) {
  return (
    <ul className={styles["sub-menu"]}>
      <MenuItemContainer isSubMenu title={"Devis"}>
        <Link
          onClick={onMenuClose}
          to="/quotes"
          className={styles["expandable-menu-item-link"]}
        >
          <p>{"Devis"}</p>
        </Link>
      </MenuItemContainer>
      <MenuItemContainer isSubMenu title={"Paiements"}>
        <Link
          onClick={onMenuClose}
          to="/payments"
          className={styles["expandable-menu-item-link"]}
        >
          <p>{"Paiements"}</p>
        </Link>
      </MenuItemContainer>
      <MenuItemContainer isSubMenu title={"Parrainage"}>
        <Link
          onClick={onMenuClose}
          to="/referral"
          className={styles["expandable-menu-item-link"]}
        >
          <p>{"Parrainage"}</p>
        </Link>
      </MenuItemContainer>
      <MenuItemContainer isSubMenu title={"Comptes clients"}>
        <Link
          onClick={onMenuClose}
          to="/customers"
          className={styles["expandable-menu-item-link"]}
        >
          <p>{"Comptes clients"}</p>
        </Link>
      </MenuItemContainer>
    </ul>
  );
}

interface ManagementMenuProps {
  title: string;
  onMenuClose: () => void;
}

export function ManagementMenu({ title, onMenuClose }: ManagementMenuProps) {
  return (
    <div className={styles["sub-menu-container"]}>
      <p className={styles["title"]}>{title}</p>
      <ManagementMenuList onMenuClose={onMenuClose} />
    </div>
  );
}

interface ManagementMenuListProps {
  onMenuClose: () => void;
}

export function ManagementMenuList({ onMenuClose }: ManagementMenuListProps) {
  return (
    <ul className={styles["sub-menu"]}>
      <MenuItemContainer isSubMenu title={"Opérations"}>
        <Link
          onClick={onMenuClose}
          to="/operation-b2c"
          className={styles["expandable-menu-item-link"]}
        >
          <p>{"Opérations"}</p>
        </Link>
      </MenuItemContainer>
      <MenuItemContainer isSubMenu title={"Opérations b2b"}>
        <Link
          onClick={onMenuClose}
          to="/operation-pro"
          className={styles["expandable-menu-item-link"]}
        >
          <p>{"Opérations b2b"}</p>
        </Link>
      </MenuItemContainer>
      <MenuItemContainer isSubMenu title={"Cross-selling"}>
        <Link
          onClick={onMenuClose}
          to="/cross-selling"
          className={styles["expandable-menu-item-link"]}
        >
          <p>{"Cross-selling"}</p>
        </Link>
      </MenuItemContainer>
      <MenuItemContainer isSubMenu title={"Méthodes de livraisons"}>
        <Link
          onClick={onMenuClose}
          to="/shipping-method"
          className={styles["expandable-menu-item-link"]}
        >
          <p>{"Méthodes de livraisons"}</p>
        </Link>
      </MenuItemContainer>
      <MenuItemContainer isSubMenu title={"Codes promos"}>
        <Link
          onClick={onMenuClose}
          to="/discount"
          className={styles["expandable-menu-item-link"]}
        >
          <p>{"Codes promos"}</p>
        </Link>
      </MenuItemContainer>
      <MenuItemContainer isSubMenu title={"Gestion d'utilisateurs"}>
        <Link
          onClick={onMenuClose}
          to="/users"
          className={styles["expandable-menu-item-link"]}
        >
          <p>{"Gestion d'utilisateurs"}</p>
        </Link>
      </MenuItemContainer>
    </ul>
  );
}

interface OrdersMenuProps {
  title: string;
  onMenuClose: () => void;
}

export function OrdersMenu({ title, onMenuClose }: OrdersMenuProps) {
  return (
    <div className={styles["sub-menu-container"]}>
      <p className={styles["title"]}>{title}</p>
      <OrdersMenuList onMenuClose={onMenuClose} />
    </div>
  );
}

interface OrdersMenuListProps {
  onMenuClose: () => void;
}

export function OrdersMenuList({ onMenuClose }: OrdersMenuListProps) {
  return (
    <ul className={styles["sub-menu"]}>
      <MenuItemContainer isSubMenu title={"Estimations"}>
        <Link
          onClick={onMenuClose}
          to="/estimate"
          className={styles["expandable-menu-item-link"]}
        >
          <p>{"Estimations"}</p>
        </Link>
      </MenuItemContainer>
      <MenuItemContainer isSubMenu title={"Paniers"}>
        <Link
          onClick={onMenuClose}
          to="/carts"
          className={styles["expandable-menu-item-link"]}
        >
          <p>{"Paniers"}</p>
        </Link>
      </MenuItemContainer>
    </ul>
  );
}

interface CalendarMenuProps {
  title: string;
  onMenuClose: () => void;
}

export function CalendarMenu({ title, onMenuClose }: CalendarMenuProps) {
  return (
    <div className={styles["sub-menu-container"]}>
      <p className={styles["title"]}>{title}</p>
      <CalendarMenuList onMenuClose={onMenuClose} />
    </div>
  );
}

interface CalendarMenuListProps {
  onMenuClose: () => void;
}

export function CalendarMenuList({ onMenuClose }: CalendarMenuListProps) {
  return (
    <ul className={styles["sub-menu"]}>
      <MenuItemContainer isSubMenu title={"Livraison & collecte"}>
        <Link
          onClick={onMenuClose}
          to="/shipping-calendar"
          className={styles["expandable-menu-item-link"]}
        >
          <p>{"Livraison & collecte"}</p>
        </Link>
      </MenuItemContainer>
      <MenuItemContainer isSubMenu title={"Chronotrace"}>
        <Link
          onClick={onMenuClose}
          to="/chronotrace"
          className={styles["expandable-menu-item-link"]}
        >
          <p>{"Chronotrace"}</p>
        </Link>
      </MenuItemContainer>
    </ul>
  );
}
