import { IQuote } from "../types/accounting.types";
import { IEstimate } from "../types/booking.type";
import { ILogistic } from "../types/logistic.types";
import { ITracker } from "../types/tracker.types";

export async function fetchTracker({ params }: { params: any }): Promise<any> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/${params.id}`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function getTracker(id: string): Promise<ITracker> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/${id}`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.tracker;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchTrackersList(query?: string): Promise<any> {
  let queryUrl = query ? query : "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/${queryUrl}`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchUnfinishedTrackersList(
  query?: string
): Promise<any> {
  let queryUrl = query ? query : "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/unfinished-trackers-to-deliver${queryUrl}`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchPayedTrackersList(query?: string): Promise<any> {
  let queryUrl = query ? query : "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/payed-trackers-to-move${queryUrl}`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchReadyChronopostList(query?: string): Promise<any> {
  let queryUrl = query ? query : "";
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/ready-chronopost-list?${queryUrl}`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function loadCart({
  params,
}: {
  params: any;
}): Promise<any | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/${params.trackerId}/new-cart`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    console.debug(error);
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function loadLogistic({
  params,
}: {
  params: any;
}): Promise<any | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/${params.trackerId}/new-logistic`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function loadQuote({
  params,
}: {
  params: any;
}): Promise<any | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/${params.trackerId}/new-quote`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(data, { status: response.status });
    }
    if (data.quote) return data.quote;
    return data;
  } catch (error: any) {
    throw new Response(error);
  }
}

export async function loadShipping({
  params,
}: {
  params: any;
}): Promise<any | null> {
  try {
    const endpoint =
      params.direction === "outgoing"
        ? "new-outgoing-shipping"
        : "new-incoming-shipping";
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/${params.trackerId}/${endpoint}`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(data, { status: response.status });
    }
    return data;
  } catch (error: any) {
    throw new Response(error);
  }
}

export async function addDropOrder(logistic: ILogistic): Promise<{
  trackId: string;
  customerId: string;
  shippingId: string;
  quoteId: string;
}> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/add-drop-order`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "include",
        method: "POST",
        body: JSON.stringify({ logistic }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw { message: data.message, status: response.status };
      // throw new Response(data.message, { status: response.status });
    }
    return data;
  } catch (error: any) {
    throw error;
  }
}

export async function fetchLogisticFromTrackerId({
  params,
}: {
  params: any;
}): Promise<ILogistic | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/${params.id}/logistic`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.logistic;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchQuoteFromTrackerId(
  trackId: string
): Promise<IQuote | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/${trackId}/quote`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.quote;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchEstimateFromTrackerId(
  trackId: string
): Promise<IEstimate | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/${trackId}/estimate`,
      { credentials: "include" }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data.estimate;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}


export async function updateTrackerState(state: string, trackerId: string) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tracker/${trackerId}`,
      {
        headers: new Headers({ "Content-Type": "application/json" }),
        credentials: "include",
        method: "PUT",
        body: JSON.stringify({ state }),
      }
    );
    const data = await response.json();
    if (response.status >= 400) {
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
