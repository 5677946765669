import { LocalOfferOutlined } from "@mui/icons-material";
import translate from "../../../../../translation";
import { IProduct } from "../../../../../types/logistic.types";
import { REFASHION_OPERATIONS_PRICE } from "../../../../../utils/operation.init";
import styles from "./productQuoteSummary.module.scss";
import { sortOperations } from "../../../../../utils/utils";
import OperationQuoteSummary from "../OperationQuoteSummary/OperationQuoteSummary";
import ProductGroupLogo from "../../../../../components/ProductGroupLogo/ProductGroupLogo";

interface ProdcutQuoteSummaryProps {
  product: IProduct;
  productsList: IProduct[] | undefined;
  productIndex: number;
  onRefashionChange?: (productsList: IProduct[]) => void;
}
export default function ProductQuoteSummary({
  product,
  productsList,
  productIndex,
  onRefashionChange,
}: ProdcutQuoteSummaryProps) {
  return (
    <div>
      <div
        className={`${styles["widget-title"]} ${
          allOperationsAreCanceled(product) && styles["operation-canceled"]
        }`}
      >
        <div className={styles["product-info"]}>
          <ProductGroupLogo
            className={styles["logo"]}
            productGroup={product.productGroup}
          />
          <p className={styles["product-info-text"]}>
            {translate(product.brand)} - {translate(product.category)}
          </p>
        </div>
        {product.workshopId && (
          <div className={styles["workshopId"]}>
            {product.workshopId}
            <LocalOfferOutlined className={styles["tag-icon"]} />
          </div>
        )}
      </div>
      <ul>
        {product.operationsList
          .sort(sortOperations)
          .map((operation, operationIndex) => {
            return (
              <OperationQuoteSummary
                key={operationIndex}
                operation={operation}
                onRefashionChange={(target) =>
                  handleChange(
                    productIndex,
                    operationIndex,
                    target,
                    productsList
                  )
                }
              />
            );
          })}
      </ul>
    </div>
  );

  function handleChange(
    productIndex: number,
    operationIndex: number,
    target: EventTarget & HTMLSelectElement,
    productsList?: IProduct[]
  ) {
    if (!productsList) {
      return;
    }
    let products = [...productsList];
    let refashionDiscount =
      REFASHION_OPERATIONS_PRICE.find((o) => o.name === target.value)?.amount ||
      0;
    let refashionOperation =
      REFASHION_OPERATIONS_PRICE.find((o) => o.name === target.value)?.name ||
      "N/A";
    products[productIndex].operationsList[operationIndex].refashionDiscount =
      refashionDiscount;
    products[productIndex].operationsList[operationIndex].refashionOperation =
      refashionOperation;
    onRefashionChange && onRefashionChange(products);
  }

  function allOperationsAreCanceled(product: IProduct): boolean {
    return product.operationsList.every((op) => op.canceled);
  }
}
