import { toast } from "react-toastify";
import { checkIfWorkshopIdExists } from "../../../../../../requests/logistic";
import style from "./workshopIdInput.module.scss";
import { TOAST_OPTIONS_LEFT } from "../../../../../../utils/toast.options";
import { LocalOfferOutlined } from "@mui/icons-material";
import { useState } from "react";
import { useDebounce } from "../../../../../../hooks/useDebounce";
import Input from "../../../../../inputs/Input/Input";

interface WorkshopIdInputProps {
  workshopId: string;
  setWorkshopId: (value: string) => void;
}

export default function WorkshopIdInput({
  workshopId,
  setWorkshopId,
}: WorkshopIdInputProps) {
  const [notValidWorkshopId, setNotValidWorkshopId] = useState<boolean>(false);

  useDebounce(() => workshopIdIsInvalid(workshopId), [workshopId]);

  return (
    <div className={style["workshop-id-input"]}>
      <div className={style["workshop-id-input-container"]}>
        <Input
          type="text"
          inputMode="numeric"
          value={workshopId}
          onChange={handleChange}
          onFocus={AddPrefixTicket}
          className={style["workshop-id-input-field"]}
          placeholder="1-1-XXX"
        />
        <div className={style["workshop-id-tag-icon"]}>
          <LocalOfferOutlined className={style["icon"]} />
        </div>
      </div>
      <div className={style["invalid-id-message-container"]}>
        {notValidWorkshopId && (
          <div className={style["invalid-id-message"]}>
            *numéro de ticket déjà existant
          </div>
        )}
      </div>
    </div>
  );

  function AddPrefixTicket({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    if (!target.value.startsWith("1-1-") && !target.value) {
      setWorkshopId("1-1-" + target.value);
    }
  }

  async function handleChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    setNotValidWorkshopId(false);
    setWorkshopId(target.value);
    // await workshopIdIsInvalid(target.value);
  }

  async function workshopIdIsInvalid(workshopId: string) {
    if (!workshopId) {
      return;
    }
    try {
      const response = await checkIfWorkshopIdExists(workshopId);
      setNotValidWorkshopId(response);
    } catch (error: any) {
      toast.error("Un problème est survenu.", TOAST_OPTIONS_LEFT);
    }
  }
}
