import SHOES_LOGO from "../../assets/shoe.png";
import LEATHER_LOGO from "../../assets/leather.png";

interface ProductGroupLogoProps {
  className?: string;
  productGroup: string;
}

export default function ProductGroupLogo({
  className,
  productGroup,
}: ProductGroupLogoProps) {
  switch (productGroup) {
    case "SHOES":
      return <img draggable={false} className={className} src={SHOES_LOGO} />;
    case "LEATHER":
      return <img draggable={false} className={className} src={LEATHER_LOGO} />;
    default:
      return <></>;
  }
}
