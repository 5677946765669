import { Close } from "@mui/icons-material";

import style from "./contactAutoComplete.module.scss";
import AutoCompleteDropDown from "../../AutoCompleteDropDown/AutoCompleteDropDown";
import { useState } from "react";
import { ICustomer } from "../../../../types/customer.type";

interface ContactAutoCompleteProps {
  autoCompleteDisable: boolean;
  expand: boolean;
  setExpand: (value: boolean) => void;
  handleAutoComplete?: (customer: ICustomer | null) => void;
}

export default function ContactAutoComplete({
  autoCompleteDisable,
  expand,
  setExpand,
  handleAutoComplete,
  ...props
}: ContactAutoCompleteProps & React.InputHTMLAttributes<HTMLInputElement>) {
  const [canReset, setCanReset] = useState<boolean>(false);
  return (
    <>
      <div className={`${style["rbc"]} ${canReset ? "" : style["hidden"]}`}>
        <div className={`${style["reset-button"]}`} onClick={resetContact}>
          <Close />
        </div>
      </div>
      {!autoCompleteDisable && expand && (
        <AutoCompleteDropDown
          name={props.name}
          value={props.value}
          handleAutoComplete={handleAutoCompleteAndReset}
          setExpand={setExpand}
        />
      )}
    </>
  );

  function handleAutoCompleteAndReset(customer: ICustomer) {
    handleAutoComplete && handleAutoComplete(customer);
    setCanReset(true);
  }

  function resetContact(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    handleAutoComplete && handleAutoComplete(null);
    setCanReset(false);
  }
}
