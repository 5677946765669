import styles from "./logisticReadSection.module.scss";
import { useState } from "react";
import { ILogistic, IProduct } from "../../../../types/logistic.types";
import ModalDetailedProduct from "./ModalDetailedProduct/ModalDetailedProduct";
import ModalCrossSellProductList from "./ModalCrossSellProductList/ModalCrossSellProductList";
import CrossSellMiniature from "./ModalCrossSellProductList/ModalCrossSellMiniature/ModalCrossSellMiniature";
import ModalProductList from "./ModalProductList/ModalProductList";

interface LogisticReadSectionProps {
  data: ILogistic;
  productIndex?: number;
}

export default function LogisticReadSection({
  data,
  productIndex,
}: LogisticReadSectionProps) {
  const [selectedProduct, setSelectedProduct] = useState<IProduct | undefined>(
    data?.productsList[productIndex || 0]
  );
  const [selectCrossSell, setSelectCrossSell] = useState<boolean>(false);
  const HAS_CROSS_SELL_ITEMS =
    data.crossSell &&
    data.crossSell.crossSellItems &&
    data.crossSell.crossSellItems?.length > 0;

  return (
    <div className={styles["body-section"]}>
      <div className={styles["modal-product-list"]}>
        <ModalProductList
          handleSelectProduct={handleSelectProduct}
          selectedId={selectedProduct?.id}
          productsList={data.productsList}
        />
        {HAS_CROSS_SELL_ITEMS && (
          <CrossSellMiniature
            className={styles["modal-product-miniature"]}
            isSelected={selectCrossSell}
            crossSell={data.crossSell}
            handleOnClick={handleSelectCrossSell}
          />
        )}
      </div>
      <div className={styles["product-detailed-info"]}>
        {selectedProduct && <ModalDetailedProduct product={selectedProduct} />}
        {selectCrossSell && HAS_CROSS_SELL_ITEMS && (
          <ModalCrossSellProductList crossSell={data.crossSell} />
        )}
      </div>
    </div>
  );

  function handleSelectCrossSell() {
    setSelectCrossSell(true);
    setSelectedProduct(undefined);
  }

  function handleSelectProduct(product: IProduct) {
    setSelectCrossSell(false);
    setSelectedProduct(product);
  }
}
