import { useLoaderData, useParams } from "react-router-dom";
import Create from "../create/create";
import { useQuery } from "react-query";
import { fetchCart } from "../../../requests/professionalCarts";

export default function Edit() {
  const { id } = useParams();
  const { data, isFetching, error, refetch } = useQuery(
    "cart",
    () => fetchCart(id),
    { refetchOnWindowFocus: false }
  );

  return <div>{data && <Create data={data} />}</div>;
}
