import { queryObject } from "../../../../types/query.types";
import RadioItem from "../../../RadioItem/RadioItem";
import FreshNewColorPicker from "../../../freshNewColorPicker/freshNewColorPicker";
import style from "./crossSellFilters.module.scss";

const CATEGORY_LIST = ["Brosses", "Cirage", "Crème surfine", "Divers", ""];

interface CrossSellFiltersProps {
  query: queryObject;
  setQuery: (value: queryObject) => void;
}

export default function CrossSellFilters({
  query,
  setQuery,
}: CrossSellFiltersProps) {
  return (
    <div className={style["cross-sell-filters"]}>
      <div className={style["category-filter-container"]}>
        <div className={style["category-list"]}>
          {CATEGORY_LIST.map((categoryItem: string, index: number) => (
            <RadioItem
              className={style["category-item"]}
              checked={categoryItem === query.category}
              setChecked={() => setQuery({ ...query, category: categoryItem })}
              key={index}
              title={categoryItem || "Tous"}
            />
          ))}
        </div>
      </div>
      <div className={style["color-picker-container"]}>
        <FreshNewColorPicker
          color={query.color}
          handleColorChange={(value) => setQuery({ ...query, category: value })}
        />
      </div>
    </div>
  );
}
