import { Edit } from "@mui/icons-material";
import ValidateButton from "../../../../components/validateButton/validateButton";
import { IShippingPoint } from "../../../../types/shipping.types";
import style from "./contactSection.module.scss";
import { IContact } from "../../../../types/logistic.types";
import { useState } from "react";
import ContactForm from "../../../../components/forms/ContactForm/ContactForm";
import { INITIAL_CONTACT } from "../../../../utils/shipping.init";
import ContactInfo from "../ContactInfo/ContactInfo";

interface ContactSectionProps {
  contact: IShippingPoint | null;
  setContact: (shippingForm: IShippingPoint | null) => void;
  shippingLabel: string | null;
}

export default function ContactSection({
  contact,
  setContact,
  shippingLabel,
}: ContactSectionProps) {
  const [contactForm, setContactForm] = useState<IContact>(
    contact || INITIAL_CONTACT
  );
  const [edit, setEdit] = useState<boolean>(false);

  return (
    <div className={style["contact-section"]}>
      {contact && (
        <ContactInfo
          shippingForm={contact}
          edit={edit}
          handleEdit={handleEdit}
        />
      )}
      {(!contact || edit) && (
        <>
          <ContactForm contact={contactForm} setContact={setContactForm} />
          <ValidateButton
            className={style["generate-label-button"]}
            title="Valider"
            handleOnClick={handleValidateContact}
          />
        </>
      )}
    </div>
  );

  function handleEdit() {
    if (shippingLabel) {
      return window.alert(
        'Vous ne pouvez pas faire de modifications. Vous devez "Annuler" le bon chronopost actuel avant de pouvoir faire des modifications.'
      );
    }
    setEdit(!edit);
  }

  function handleValidateContact() {
    if (contactForm) {
      setContact(contactForm);
      setEdit(false);
    }
  }
}
