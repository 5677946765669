import { useState } from "react";
import { useQuery } from "react-query";
import styles from "./index.module.scss";
import Item from "./listItem/item";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import { Filter } from "../../types/filter.types";
import { queryObject } from "../../types/query.types";
import { INVOICE_PRO_STATES } from "../../utils/accounting.init";
import {
  getInvoiceProListWithTotal,
  updateInvoicePro,
} from "../../requests/invoicePro";
import { IInvoicePro } from "../../types/invoicePro.types";
import {
  finalizeInvoicePro,
  generateInvoicePro,
} from "../../requests/pennylanePro";
import { toast } from "react-toastify";
import {
  TOAST_ERROR_OPTIONS,
  TOAST_SUCCESS_OPTIONS,
} from "../../utils/toast.options";
import FilterByStatus from "../../components/filterByStatus/filterByStatus";
import { IShop } from "../../types/shop.types";
import SelectAndSearchShop from "../../components/SelectAndSearchShop/SelectAndSearchShop";
import useSaveQueryStorage, {
  loadQueryStorage,
} from "../../hooks/useSaveQueryStorage";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import FiltersContainer from "../../components/Filters/FiltersContainer";
import ListPageHeader from "../referral/ListPageHeader/ListPageHeader";
import ListHeader from "./ListHeader/ListHeader";

let controller = new AbortController();

export const INITIAL_QUERY: queryObject = {
  field: "contact.phone",
  value: "",
  service: "",
  direction: "",
  state: "",
  limit: 10,
  offset: 0,
  sort: "",
  dateFrom: "",
  dateTo: "",
};

export default function InvoicePro() {
  const [query, setQuery] = useSaveQueryStorage(INITIAL_QUERY, "invoice-pro");
  const [shop, setShop] = useState<IShop | null>(null);
  const { data, isLoading, error, refetch } = useQuery(
    ["invoice-pro", query, shop],
    () => getInvoiceProListWithTotal(formatedFilter()),
    { keepPreviousData: true }
  );
  useSaveQueryStorage(query, "invoice-pro");

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  if (!data || !data.invoices) {
    return <>Error data is missing !</>;
  }

  return (
    <div className={styles["list-page"]}>
      <ListPageHeader title={"Factures b2b"} />
      <FiltersContainer>
        <SelectAndSearchShop shop={shop} setShop={setShop} />
        <FilterByStatus
          query={query}
          setQuery={setQuery}
          optionsList={INVOICE_PRO_STATES}
          field={"status"}
        />
      </FiltersContainer>
      <div className={styles["list-container"]}>
        <div className={styles["paging-component-top"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
        <div className={styles["list"]}>
          <table className={styles["table"]}>
            <ListHeader />
            <tbody>
              {data &&
                data.invoices &&
                data.invoices.map((value: IInvoicePro, index: number) => {
                  return (
                    <Item
                      key={index}
                      invoice={value}
                      onDelete={handleDelete}
                      handleGenerate={handleGenerate}
                      handleFinalize={handleFinalize}
                      handleToPayed={handleToPayed}
                      index={query.limit - index}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className={styles["paging-component"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
      </div>
    </div>
  );

  function formatedFilter() {
    let formatedFilter: Filter[] = [
      {
        key: "limit",
        value: query.limit.toString(),
      },
      {
        key: "offset",
        value: (query.offset * query.limit).toString(),
      },
    ];
    if (query.field && query.value) {
      formatedFilter.push({
        key: query.field,
        value: query.value,
      });
    }
    formatedFilter.push({
      key: "sort_field",
      value: "createdAt",
    });
    formatedFilter.push({
      key: "sort_direction",
      value: "desc",
    });
    if (query.state) {
      formatedFilter.push({
        key: "state",
        value: query.state,
      });
    }
    if (shop && shop.id) {
      formatedFilter.push({
        key: "shopId",
        value: shop.id,
      });
    }
    return formatedFilter;
  }

  async function handleDelete(invoicePro?: IInvoicePro) {
    controller.abort();
    if (!invoicePro) {
      toast.error("Document introuvable...", TOAST_ERROR_OPTIONS);
      return;
    }
    try {
      controller = new AbortController();
      if (window.confirm("Attention êtes vous sûr??")) {
        invoicePro.publicUrl = "";
        invoicePro.fileUrl = "";
        invoicePro.state = "PENDING";
        const response = await updateInvoicePro(invoicePro);
        if (response) {
          toast.success(
            "Le document est maintenant marqué comme en attente",
            TOAST_SUCCESS_OPTIONS
          );
        } else {
          toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
        }
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function handleGenerate(invoiceProId?: string) {
    controller.abort();
    try {
      controller = new AbortController();
      if (window.confirm("Voulez-vous générer cette facture ?")) {
        const response = await generateInvoicePro(invoiceProId);
        if (response) {
          toast.success("La facture à bien été généré", TOAST_SUCCESS_OPTIONS);
        } else {
          toast.error(
            "Un problème est survenu génération de la facture.",
            TOAST_ERROR_OPTIONS
          );
        }
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function handleFinalize(invoiceProId?: string) {
    controller.abort();
    try {
      controller = new AbortController();
      if (window.confirm("Voulez-vous finaliser cette facture ?")) {
        const response = await finalizeInvoicePro(invoiceProId);
        if (response) {
          toast.success(
            "La facture est maintenant marqué comme finalisé",
            TOAST_SUCCESS_OPTIONS
          );
        } else {
          toast.error(
            "Un problème est survenu lors de la finalisation de la facture.",
            TOAST_ERROR_OPTIONS
          );
        }
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function handleToPayed(invoicePro?: IInvoicePro) {
    controller.abort();
    if (!invoicePro) {
      toast.error("Document introuvable...", TOAST_ERROR_OPTIONS);
      return;
    }
    try {
      controller = new AbortController();
      if (window.confirm("Voulez-vous passer cette facture en payée?")) {
        invoicePro.state = "PAYED";
        const response = await updateInvoicePro(invoicePro);
        if (response) {
          toast.success(
            "Le document est maintenant marqué comme payé",
            TOAST_SUCCESS_OPTIONS
          );
        } else {
          toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
        }
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  }
}
