import { IProduct } from "../../../../../../types/logistic.types";
import styles from "./productDescription.module.scss";
import ColorComponent from "../../../../../colorComponent/colorComponent";
import SectionTitle from "../../../../../SectionTitle/SectionTitle";

interface ProductDescriptionProps {
  product: IProduct;
}

export default function ProductDescription({
  product,
}: ProductDescriptionProps) {
  return (
    <div className={styles["product-information-grid"]}>
      <SectionTitle title={"Catégorie"} />
      <p className={styles["category"]}>{product.category}</p>
      <SectionTitle title={"Couleur"} />
      <ColorComponent color={product.color} className={styles["color"]} />
      <SectionTitle title={"Taille"} />
      <p className={styles["category"]}>{product.size || "-"}</p>
    </div>
  );
}
