import { IValidationError } from "../types";
import { IDiscount } from "../types/accounting.types";

export async function addDiscount(
  // request: Request,
  discount: IDiscount
): Promise<string | IValidationError[] | null> {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/discount`, {
      headers: new Headers({ "Content-Type": "application/json" }),
      method: "POST",
      credentials: "include",
      // signal: request?.signal,
      body: JSON.stringify(discount),
    });
    const data = await response.json();
    if (![201, 200, 400].includes(response.status)) {
      if (response.status === 401) {
        return null;
      }
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchDiscount(
  // request: Request,
  params: any
): Promise<IDiscount | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/discount/${params.id}`,
      {
        credentials: "include",
        // signal: request?.signal,
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      if (response.status === 401) {
        return null;
      }
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function fetchDiscountList(
  // request: Request,
  query?: string
): Promise<{ discounts: IDiscount[]; totalCount: number } | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/discount?${query ? query : ""}`,
      {
        credentials: "include",
        // signal: request?.signal,
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      if (response.status === 401) {
        return null;
      }
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function substractDiscountQuantity(
  code: any,
  signal?: AbortSignal
): Promise<IDiscount | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/discount/substractQuantity/${code}`,
      {
        credentials: "include",
        signal: signal,
      }
    );
    const data = await response.json();
    if (response.status !== 200) {
      if (response.status === 401) {
        return null;
      }
      throw new Response(JSON.stringify(data), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return data;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}

export async function deleteDiscount(
  id: string,
  signal?: AbortSignal
): Promise<void | null> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/discount/${id}`,
      {
        method: "DELETE",
        credentials: "include",
        signal: signal,
      }
    );
    if (response.status !== 200) {
      if (response.status === 301) {
        return null;
      }
      throw new Response(JSON.stringify(response), {
        statusText: response.statusText,
        status: response.status,
      });
    }
    return;
  } catch (error: any) {
    throw new Response(JSON.stringify(error.body), {
      statusText: error.statusText,
      status: error.status,
    });
  }
}
