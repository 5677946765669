import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";
import { useQuery } from "react-query";
import { deleteQuote, fetchQuoteList, updateQuote } from "../../requests/quote";
import { ProtectedComponent } from "../../components/protectedComponent/protectedComponent";
import Item from "./item/item";
import PagingComponent from "../../components/pagingComponent/pagingComponent";
import ListHeaderComponent from "../../components/listHeaderComponent/listHeaderComponent";
import { IQuote } from "../../types/accounting.types";
import SearchByKeyComponent from "../../components/searchByKeyComponent/searchByKeyComponent";
import FilterByStatus from "../../components/filterByStatus/filterByStatus";
import { QUOTE_STATES } from "../../utils/accounting.init";
import { queryObject } from "../../types/query.types";
import useSaveQueryStorage, {
  loadQueryStorage,
} from "../../hooks/useSaveQueryStorage";
import { useConfirmModal } from "../../context/confirmationModalContext";
import ConfirmModalComp from "../../components/confirmationModal/ConfirmModalComp";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../../utils/toast.options";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import Button from "../../components/Button/Button";
import ListPageHeader from "../referral/ListPageHeader/ListPageHeader";
import ListHeader from "./ListHeader/ListHeader";
import FiltersContainer from "../../components/Filters/FiltersContainer";

let controller = new AbortController();

export const QUOTE_INITIAL_QUERY: queryObject = {
  field: "contact.displayname",
  value: "",
  status: "Tous",
  state: "",
  limit: 10,
  offset: 0,
};

const SEARCH_OPTIONS_LIST = ["contact.displayname", "contact.organization"];

export default function Quotes() {
  const [query, setQuery] = useSaveQueryStorage(QUOTE_INITIAL_QUERY, "quote");
  const { data, isLoading, refetch } = useQuery(
    ["quote", query],
    () => fetchQuoteList(formatedQuery()),
    { keepPreviousData: true }
  );
  const { setConfirmModalIsOpen, setConfirmModalChildren } = useConfirmModal();

  if (isLoading) {
    return <LoadingSpinner color="gold" />;
  }

  if (!data || !data.quotes) {
    return <>Error data is missing !</>;
  }

  return (
    <div className={styles["list-page"]}>
      <ListPageHeader
        title={"Devis"}
        actionSection={
          <Link onClick={() => window.scrollTo(0, 0)} to={"./create"}>
            <Button>+ Ajouter nouveau</Button>
          </Link>
        }
      />
      <FiltersContainer>
        <SearchByKeyComponent
          query={query}
          setQuery={setQuery}
          optionsList={SEARCH_OPTIONS_LIST}
        />
        <FilterByStatus
          query={query}
          setQuery={setQuery}
          optionsList={QUOTE_STATES}
          field={"status"}
        />
      </FiltersContainer>
      <div className={styles["list-container"]}>
        <div className={styles["paging-component-top"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
        <div className={styles["list"]}>
          <table className={styles["table"]}>
            <ListHeader />
            <tbody>
              {data.quotes.map((value: any, index: number) => {
                return (
                  <Item
                    key={index}
                    data={value}
                    onDelete={handleConfirmDelete}
                    handleSendQuote={handleConfirmSendQuote}
                    zIndex={data.quotes.length - index}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={styles["paging-component"]}>
          <PagingComponent
            query={query}
            setQuery={setQuery}
            totalCount={data?.totalCount}
          />
        </div>
      </div>
    </div>
  );

  function handleConfirmDelete(id?: string) {
    setConfirmModalIsOpen(true);
    setConfirmModalChildren(
      <ConfirmModalComp
        confirmButtonClassName={styles["delete-button"]}
        title={"Êtes-vous sûr de vouloir supprimer ce Devis ?"}
        confirmText="Supprimer"
        onConfirm={() => handleDelete(id)}
      />
    );
  }

  async function handleDelete(id?: string) {
    controller.abort();
    try {
      controller = new AbortController();
      await deleteQuote(id, controller.signal);
      refetch();
    } catch (error) {
      console.error(error);
    }
  }

  function handleConfirmSendQuote(data: IQuote) {
    setConfirmModalIsOpen(true);
    setConfirmModalChildren(
      <ConfirmModalComp
        confirmButtonClassName={styles["validate-button"]}
        title={"Voulez-vous envoyer le devis ?"}
        confirmText="Oui"
        onConfirm={() => handleSendQuote(data)}
      />
    );
  }

  async function handleSendQuote(data: IQuote) {
    try {
      await updateQuote({ ...data, state: "QUOTE_SENT" }, true, true);
      refetch();
    } catch (error) {
      toast.error("Un problème est survenu", TOAST_ERROR_OPTIONS);
    }
  }

  function formatedQuery() {
    let formatedQuery: string = "";

    if (query.limit) {
      formatedQuery = formatedQuery + `?limit=${query.limit}`;
    }

    formatedQuery = formatedQuery + `&offset=${query.offset * query.limit}`;

    if (query.field && query.value) {
      formatedQuery = formatedQuery + `&${query.field}=${query.value}`;
    }

    if (query.state) {
      formatedQuery += `&state=${query.state}`;
    }

    if (query.sort) {
      formatedQuery = formatedQuery + `${query.sort}`;
    } else {
      formatedQuery += `&sort_field=createdAt&sort_direction=desc`;
    }

    return formatedQuery;
  }
}
