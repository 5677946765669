import { fetchLogisticList } from "../requests/logistic";
import { FormProduct, ILogistic } from "../types/logistic.types";
import { isPhoneValid } from "./phoneValidation";

export async function isProductFormValid(product: FormProduct) {
  return (
    product.category &&
    product.category.trim() !== "" &&
    product.color &&
    product.color.trim() !== "" &&
    product.brand &&
    product.brand.trim() !== "" &&
    product.productGroup &&
    product.productGroup.trim() !== "" &&
    product.operationGroup &&
    product.operationGroup.trim() !== "" &&
    product.incomingPicturesList &&
    product.incomingPicturesList.length > 0 &&
    product.workshopId &&
    product.workshopId.length >= 7 &&
    !(await workshopIdIsInvalid(product.workshopId, product.id)) &&
    product.operationsList &&
    product.operationsList.length > 0
  );
}

export async function workshopIdIsInvalid(
  workshopId: string | undefined,
  productId: string | undefined
): Promise<boolean> {
  const response = await fetchLogisticList(
    `?productsList.workshopId=${workshopId}`
  );
  console.debug("workshopIdIsInvalid", response);
  if (response) {
    const logisticsInProgress = response.logisticsList.filter(
      (logistic) => logistic.status !== "LOGISTIC_DONE"
    );

    if (!productId && logisticsInProgress.length > 0) {
      return true;
    }

    if (
      productId &&
      logisticsInProgress.some((logistic) =>
        logistic.productsList.some(
          (product) =>
            product.id !== productId && product.workshopId === workshopId
        )
      )
    ) {
      return true;
    }
  }
  console.debug("workshopIdIsInvalid", workshopId);
  if (!workshopId || workshopId.length < 7) {
    return true;
  }
  return false;
}

export async function logisticFormValidation(logistic: ILogistic) {
  const error: string[] = [];
  if (!logistic.contact.givenname) {
    error.push("Prénom manquant.");
  }
  if (!logistic.contact.familyname) {
    error.push("Nom de famille manquant.");
  }
  if (!logistic.contact.phone) {
    error.push("Numéro de téléphone manquant.");
  } else if (!isPhoneValid(logistic.contact.phone)) {
    error.push("Numéro de téléphone incorrect.");
  }
  if (!logistic.contact.email) {
    error.push("Adresse email manquante.");
  }
  if (!logistic.productsList) {
    error.push("Liste de articles manquante.");
  }
  if (logistic.productsList && logistic.productsList.length === 0) {
    error.push("La liste de articles est vide.");
  }
  for (let [index, product] of logistic.productsList.entries()) {
    if (!product.productGroup) {
      error.push(`Groupe de article manquant pour le article [${index}].`);
    }
    if (!product.operationGroup) {
      error.push(`Groupe d'opérations manquant pour le article [${index}].`);
    }
    if (!product.color) {
      error.push(`Couleur manquante pour le article [${index}].`);
    }
    if (!product.category) {
      error.push(`Catégorie manquante pour le article [${index}].`);
    }
    if (!product.workshopId) {
      error.push(`Numéro de ticket manquant pour le article [${index}].`);
    } else if (await workshopIdIsInvalid(product.workshopId, product.id)) {
      error.push(`Numéro de ticket incorrect pour le article [${index}].`);
    }
    if (!product.incomingPicturesList) {
      error.push(
        `Liste d'images entrantes manquante pour le article [${index}].`
      );
    }
    if (
      product.incomingPicturesList &&
      product.incomingPicturesList.length === 0
    ) {
      error.push(
        `La liste d'images entrantes est vide pour le article [${index}].`
      );
    }
    if (!product.operationsList) {
      error.push(`Liste d'opérations manquante pour le article [${index}].`);
    }
    if (product.operationsList.length === 0) {
      error.push(`La liste d'opérations est vide pour le article [${index}].`);
    }
    for (let [index, operation] of product.operationsList.entries()) {
      if (!operation.description) {
        error.push(`Description manquante pour une opération [${index}].`);
      }
      if (operation.price === undefined) {
        error.push(`Prix manquant pour une opération [${index}].`);
      }
      if (!operation.operationType) {
        error.push(`Type d'opération manquant pour une opération [${index}].`);
      }
    }
  }
  return error;
}
