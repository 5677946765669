import { IProduct } from "../../../../../../types/logistic.types";
import ProductForm from "../../productSection/productForm";
import styles from "./ProductInformationSection.module.scss";

interface ProductInformationSectionProps {
  product: IProduct;
  onChange: (product: IProduct) => void;
  validationError: string[];
  setValidationError: (validationError: string[]) => void;
  trackId: string;
}

export default function ProductInformationSection({
  product,
  onChange,
  validationError,
  setValidationError,
  trackId,
}: ProductInformationSectionProps) {
  return (
    <div className={styles["product-section"]}>
      <ProductForm
        product={product}
        validationError={validationError}
        setValidationError={setValidationError}
        onChange={handleChange}
        trackId={trackId}
      />
    </div>
  );

  function handleChange(product: IProduct) {
    onChange({ ...product });
  }
}
