import style from "./showHistory.module.scss";
import { useQuery } from "react-query";
import { getCartsListByTrackIdList } from "../../../../requests/professionalCarts";
import LoadingSpinner from "../../../../components/loadingSpinner/loadingSpinner";
import { IProCart } from "../../../../types/proCart.types";
import WorkshopId from "../../../../components/workshopId/workshopId";

interface ShowHistoryProps {
  trackIdList: string[];
}

export default function ShowHistory({ trackIdList }: ShowHistoryProps) {
  const { data, isFetching } = useQuery(
    "cart-pro-shipping-history",
    () => getCartsListByTrackIdList(trackIdList),
    { keepPreviousData: true }
  );

  return (
    <div className={style["show-history"]}>
      {isFetching ? (
        <div className={style["loading-spinner-container"]}>
          <LoadingSpinner color="gold" />
        </div>
      ) : (
        <div className={style["history-cart-list"]}>
          {data &&
            data.cartsList.map((cart: IProCart, index: number) => {
              return (
                <div key={index} className={style["history-cart-card"]}>
                  <div className={style["contact-info"]}>
                    {cart.contact.familyname || "-"}
                  </div>
                  <div className={style["workshop-id-list"]}>
                    {cart.productsList.map((product, index) => {
                      return (
                        <WorkshopId
                          className={style["workshop-id"]}
                          key={index}
                          workshopId={product.workshopId}
                          size="x-small"
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}
