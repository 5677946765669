const TRANSLATION_TABLE = new Map([
  ["GENERIC_OPERATION", "Groupe générique"],
  ["SHOES_SNEAKERS", "Sneakers"],
  ["SHOES_KID", "Enfant"],
  ["SHOES_WOMAN", "Femme"],
  ["SHOES_MAN", "Homme"],
  ["SHOES_CLIMBING", "Escalade"],
  ["SHOES_AUTRE", "Cordonnerie - Autre"],
  ["LEATHER_AUTRE", "Maroquinerie - Autre"],
  ["SHOES_COLLAGE_COUTURE", "Cordonnerie - Collage / Couture"],
  ["SHOES_PATAFER", "Cordonnerie - Patin / Talon / Fer"],
  ["SHOES_RESSEMELAGE", "Cordonnerie - Ressemelage"],
  ["SHOES_ENTRETIEN", "Cordonnerie - Entretien"],
  ["CLIMBING_ENROBAGE", "Escalade - Enrobage"],
  ["CLIMBING_RESSEMELAGE", "Escalade - Ressemelage"],
  ["CLIMBING_AUTRE", "Escalade - Autre"],

  ["LEATHER_LARGE", "Grande maroquinerie"],
  ["LEATHER_SMALL", "Petite maroquinerie"],
  ["LEATHER_ENTRETIEN", "Maroquinerie - Entretien"],
  ["LEATHER_TEINTURE", "Maroquinerie - Teinture"],
  ["LEATHER_TRANCHE", "Maroquinerie - Teinture de tranches"],
  ["LEATHER_DOUBLURE", "Maroquinerie - Doublure"],
  ["LEATHER_JONC", "Maroquinerie - Jonc"],
  ["LEATHER_COUTURE", "Maroquinerie - Couture"],
  ["LEATHER_ENCHAPE", "Maroquinerie - Enchape"],
  ["LEATHER_POIGNEE", "Maroquinerie - Poignée"],
  ["LEATHER_BANDOULIERE", "Maroquinerie - Bandoulière"],
  ["LEATHER_AUTRE", "Maroquinerie - Autre"],
  ["LEATHER_FAG", "Maroquinerie - FAG"],
  ["GENERIC_PRODUCT", "Article générique"],
  ["SHOES", "Cordonnerie"],
  ["LEATHER", "Maroquinerie"],
  ["CLIMBING", "Escalade"],
  ["FABRIC", "Tissu"],

  ["SHOES_PATAFER_PATIN", "Patin"],
  ["SHOES_PATAFER_TALON", "Talon"],
  ["SHOES_PATAFER_FER", "Fer"],
  ["SHOES_MISE_EN_FORME", "Cordonnerie - Mise en forme"],
  ["SHOES_TEINTURE", "Cordonnerie - Teinture"],
  ["SHOES_COLLAGE_COUTURE_COLLAGE", "Collage"],
  ["SHOES_COLLAGE_COUTURE_COUTURE", "Couture"],
  ["SHOES_RESSEMELAGE_RESSEMELAGE", "Ressemelage"],
  ["SHOES_ENTRETIEN_ENTRETIEN", "Entretien"],

  ["CLIMBING_RESSEMELAGE_RESSEMELAGE", "Ressemelage"],
  ["CLIMBING_ENROBAGE_ENROBAGE", "Enrobage"],
  ["LEATHER_COUTURE_COUTURE", "Couture"],
  ["LEATHER_TEINTURE_TEINTURE", "Teinture"],
  ["LEATHER_ENTRETIEN_ENTRETIEN", "Entretien"],

  ["kid", "Enfant"],
  ["woman", "Femme"],
  ["man", "Homme"],

  ["mainMaterialsList", "Principaux matériaux"],
  ["secondaryMaterialsList", "Matériaux secondaires"],

  ["contact.displayname", "Nom complet"],
  ["contact.familyname", "Nom"],
  ["contact.phone", "Téléphone"],
  ["contact.email", "Email"],
  ["contact.organization", "Organisation"],
  ["productsList", "Articles"],
  ["productsList.brand", "Marque"],
  ["productsList.workshopId", "Ticket Atelier"],
  ["logistic.workshopIds", "Ticket Atelier"],

  ["externalShipping.externalId", "Numéro chronopost"],
  ["displayname", "Nom complet"],
  ["givenname", "Prénom"],
  ["familyname", "Nom de famille"],
  ["phone", "Téléphone"],
  ["email", "Email"],
  ["organization", "Organisation"],

  ["status", "Status logistique"],
  ["productsList.status", "Status article"],
  ["brand", "Marque"],
  ["category", "Catégorie"],
  ["creationDate", "Date de création"],
  ["description", "Description"],
  ["productGroup", "Groupe d'articles"],
  ["operationGroup", "Groupe d'opérations"],
  ["operationType", "Type d'opérations"],
  ["price", "Prix"],

  ["name", "Nom de la boutique"],
  ["workshopPrefixId", "Préfixe ticket (XX-XX)"],

  ["OUTGOING", "Livraison"],
  ["INCOMING", "Collecte"],
  ["PENDING", "En attente"],
  ["TRANSITING", "En transition"],
  ["DELIVERED", "Terminé"],
  ["CANCELED", "Annulé"],
  ["TRANSFORMED", "Transformé"],

  ["QUOTE_TO_GENERATE", "A générer"],
  ["QUOTE_DRAFT", "Brouillon"],
  ["QUOTE_PENDING", "En attente"],
  ["QUOTE_SENT", "Envoyé"],
  ["QUOTE_VALIDATED", "Validé"],
  ["QUOTE_CANCELED", "Annulé"],

  ["ROLE_ADMIN", "Administrateur"],
  ["ROLE_LOGISTICS", "Responsable logistique"],
  ["ROLE_ACCOUNTING", "Comptabilité"],
  ["ROLE_WORKSHOP", "Atelier"],
  ["ROLE_DEVELOPER", "Dévelopeur"],
  ["ROLE_SHIPPING", "Livraison"],
  ["ROLE_ESTIMATE", "Estimation"],
  ["ROLE_GUEST", "Invité"],

  ["PENDING_SHELVED", "En attente (étagère)"],
  ["EXTERNAL_JOB", "Travail externe"],
  ["WORKSHOP_REPAIR", "Réparations"],
  ["WORKSHOP_CARE", "Soins"],
  ["WORKSHOP_COMPLETE", "Terminé (à contrôler)"],
  ["PRODUCT_CONTROLLED", "Contrôle de qualité validé"],
  ["WORKSHOP_OUTGOING", "Prêt à livrer"],

  ["LOGISTIC_INCOMING", "En attente"],
  ["LOGISTIC_QUOTE_ESTIMATION", "Devis estimation"],
  ["LOGISTIC_QUOTE_SENT", "Devis envoyé"],
  ["LOGISTIC_WORKSHOP", "Atelier"],
  ["LOGISTIC_OUTGOING", "À contrôler"],
  ["LOGISTIC_DONE", "Terminé"],

  ["ESTIMATE_PENDING", "En attente"],
  ["ESTIMATE_REACHED", "Contacté"],
  ["ESTIMATE_SENT", "Envoyé"],
  ["ESTIMATE_TRANSFORMED", "Transformé"],
  ["ESTIMATE_CANCELED", "Annulé"],

  ["REFURBISHED", "Réparé"],

  ["STANDARD_SHIPPING", "Livraison standard"],

  ["CART_PENDING", "En attente"],
  ["CART_VALIDATED", "Validé"],
  ["CART_CANCELED", "Annulé"],
  ["CART_IN_REPAIR", "En réparation"],
  ["CART_REPAIRED", "Réparé"],
  ["CART_COMPLETE", "Terminé"],

  ["PAYED", "Payé"],
  ["TO_BE_PAYED", "À payer"],

  ["string.email", "adresse mail non valide"],
  ["string.empty", "champs obligatoire"],
  ["string.pattern.base", "numéro de téléphone non valide"],

  ["monday", "lundi"],
  ["tuesday", "mardi"],
  ["wednesday", "mercredi"],
  ["thursday", "jeudi"],
  ["friday", "vendredi"],
  ["saturday", "samedi"],
  ["sunday", "dimanche"],

  ["INTERNAL", "À domicile"],
  ["DROP-OR-COLLECT", "Dépôt / Retrait boutique"],
  ["CHRONOPOST-2SHOP", "En Point Relais (Chronopost)"],
  ["CHRONOPOST-PRO", "Chronopost Direct Pro"],
  ["CHRONOPOST-EUR", "Chronopost Europe"],

  ["QUALITY_CONTROL_DONE", "Contrôle de qualité OK."],
  ["QUALITY_CONTROL_ONGOING", "Contrôle de qualité en cours."],
  ["QUALITY_CONTROL_FAILED", "Contrôle de qualité échoué."],

  ["SURFINE_CREAM", "Crème surfine"],
  ["POLISH", "Cirage"],

  ["FREE", "GRATUIT"],
  ["BASIC", "BASIQUE"],
  ["ESSENTIAL", "ESSENTIELLE"],
  ["PREMIUM", "PREMIUM"],

  ["REFUSED", "Échoué"],
  ["AUTHORISED", "Validé"],

  ["DRAFT", "Brouillon"],
  ["TO_BE_CLAIMED", "À réclamer"],
  ["COMPLETED", "Terminé"],
]);

export default function translate(data?: string): string {
  if (!data) {
    return "";
  }
  return TRANSLATION_TABLE.get(data) ?? data;
}
