import { Launch } from "@mui/icons-material";
import { IPayment } from "../../../types/payment.type";
import styles from "./item.module.scss";
import MoreTools, {
  IMoreToolsOptions,
} from "../../../components/MoreTools/MoreTools";
import { ProtectedComponent } from "../../../components/protectedComponent/protectedComponent";
import CenterCell from "../../../components/CenterCell/CenterCell";
import State from "../../../components/StateItem/State";
import clsx from "clsx";

export default function Item({ data }: { data: IPayment }) {
  const MORE_TOOLS_OPTIONS: IMoreToolsOptions[] = [
    {
      icon: <Launch />,
      name: "Ouvrir",
      to: `/overview/${data.trackId}`,
    },
  ];

  return (
    <tr className={styles["item"]}>
      <td
        className={clsx(styles["item-cell"], styles["item-date"])}
        title={`${new Date(data?.transactionDate).toLocaleString()}`}
      >
        {data.transactionDate
          ? new Date(data.transactionDate).toLocaleDateString()
          : "indisponible"}
      </td>
      <td className={clsx(styles["item-cell"], styles["item-coord"])}>
        <div className={styles["coordinates"]}>
          <span>{`${
            data.contact?.givenname
          } ${data.contact?.familyname?.toLocaleUpperCase("FR")}`}</span>
          <span>{data.contact?.phone}</span>
        </div>
      </td>
      <td className={clsx(styles["item-cell"], styles["amount"])}>
        {data.amount / 100} €
      </td>
      <td className={clsx(styles["item-cell"], styles["status-box"])}>
        <CenterCell>
          <State state={data.transactionStatus} />
        </CenterCell>
      </td>
      <td className={clsx(styles["item-cell"], styles["tools"])}>
        <ProtectedComponent roles={["ROLE_ADMIN"]}>
          <CenterCell>
            <MoreTools options={MORE_TOOLS_OPTIONS} />
          </CenterCell>
        </ProtectedComponent>
      </td>
    </tr>
  );
}
