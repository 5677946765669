import React, { FormEvent, useState } from "react";
import InputContainer from "../../inputs/inputContainer/inputContainer";
import style from "./externalShipping.module.scss";
import { PRINTMODE } from "../../../../utils/shipping.init";
import { Link, useNavigate } from "react-router-dom";
import { Download } from "@mui/icons-material";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import SendIcon from "@mui/icons-material/Send";
import { IShipping } from "../../../../types/shipping.types";
import {
  cancelShippingLabel,
  getShippingLabel,
  reserveShippingLabel,
  updateShipping,
} from "../../../../requests/shipping";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "../../../../utils/toast.options";
import LoadingSpinner from "../../../loadingSpinner/loadingSpinner";

export default function ExternalShipping({
  shippingData,
  refetch,
  onChange,
}: {
  shippingData: IShipping;
  refetch?: () => void;
  onChange: (value: IShipping) => void;
}) {
  const [printMode, setPrintMode] = useState<string>("ZPL");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <InputContainer className={style["button-chronopost"]}>
      {shippingData.externalShipping && (
        <>
          {isLoading ? (
            <>
              <LoadingSpinner color="gold" />
            </>
          ) : (
            <>
              {!shippingData.externalShipping.externalId ? (
                <button
                  className={style["access-button"]}
                  onClick={handleChronopost}
                >
                  Génerer un bon CHRONOPOST
                  <SendIcon />
                </button>
              ) : (
                <div className={style["external-shipping-container"]}>
                  <div className={style["external-id"]}>
                    <Link
                      onClick={() => window.scrollTo(0, 0)}
                      to={`https://www.chronopost.fr/tracking-no-cms/suivi-page?listeNumerosLT=${shippingData.externalShipping.externalId}&langue=fr`}
                    >
                      {shippingData.externalShipping.externalId}
                    </Link>
                  </div>
                  <div className={style["download-container"]}>
                    <select
                      onChange={({ target }) => setPrintMode(target.value)}
                    >
                      {PRINTMODE.map((value: string[], index: number) => {
                        return (
                          <option value={value[1]} key={index}>
                            {value[0]}
                          </option>
                        );
                      })}
                    </select>
                    <div
                      className={style["download-button"]}
                      onClick={() =>
                        shippingData.externalShipping &&
                        handleGetShippingLabel(
                          printMode,
                          shippingData,
                          // shippingData.externalShipping.reservationNumber,
                          setIsLoading
                        )
                      }
                    >
                      <Download />
                    </div>
                    <div
                      className={style["cancel-button"]}
                      onClick={() => {
                        if (shippingData.id) {
                          handleCancelShippingLabel(shippingData.id);
                        }
                      }}
                    >
                      <CancelScheduleSendIcon />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </InputContainer>
  );

  async function handleCancelShippingLabel(id: string) {
    try {
      const data = await cancelShippingLabel(id);
    } catch (error: any) {
      throw new Error(error.message);
    } finally {
      refetch && refetch();
    }
  }

  async function handleChronopost(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    if (!shippingData.id) {
      toast.error("[shippingId] missing.", TOAST_ERROR_OPTIONS);
      return;
    }
    handleReserveShippingLabel(event, shippingData.id.toString());
    // shippingData.shippingSourceType === "DIRECT" &&
    // shippingData.direction === "INCOMING"
    //   ? handleReserveShippingEsdLabel(event, shippingData.id.toString())
    //   : handleReserveShippingLabel(event, shippingData.id.toString());
  }

  async function handleReserveShippingLabel(event: any, id: string) {
    event.preventDefault();
    try {
      const response = await reserveShippingLabel(id);
      if (!response) {
        return toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
      }
      if (!shippingData.externalShipping) {
        return toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
      }
      shippingData.externalShipping.reservationNumber =
        response.reservationNumber;
      shippingData.externalShipping.externalId = response.externalId;
      onChange({ ...shippingData });
      await submitUpdateForm(event, false);
      refetch && refetch();
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  // async function handleReserveShippingEsdLabel(event: any, id: string) {
  //   event.preventDefault();
  //   try {
  //     const response = await reserveShippingEsdLabel(id, shippingData);
  //     if (!response) {
  //       logoutRequest();
  //       localStorage.clear();
  //       setUser({ uid: "", username: "", roles: [""] });
  //       navigate("/login");
  //     } else {
  //       shippingData.externalShipping.reservationNumber =
  //         response.reservationNumber;
  //       shippingData.externalShipping.externalId = response.externalId;
  //     }
  //     onChange({ ...shippingData });
  //     await submitUpdateForm(event, false);
  //     refetch && refetch();
  //   } catch (error: any) {
  //     throw new Error(error.message);
  //   }
  // }

  async function submitUpdateForm(
    event: FormEvent<HTMLFormElement>,
    redirect: boolean
  ) {
    event.preventDefault();
    try {
      const id = await updateShipping(shippingData);
      if (!id) {
        return toast.error("Un problème est survenu.", TOAST_ERROR_OPTIONS);
      }
      redirect && navigate(-1);
    } catch (error: any) {
      console.error(error.cause, error.message);
    }
  }
}

export async function handleGetShippingLabel(
  mode: string,
  shippingData: IShipping,
  // reservationNumber?: string,
  setIsLoading?: (value: boolean) => void
) {
  if (!shippingData?.externalShipping?.reservationNumber) {
    toast.error("[reservationNumber] is missing.", TOAST_ERROR_OPTIONS);
    return;
  }
  if (!shippingData?.shippingMethod?.shippingService) {
    toast.error("[shippingService] is missing.", TOAST_ERROR_OPTIONS);
    return;
  }
  try {
    setIsLoading && setIsLoading(true);
    const response: any = await getShippingLabel(
      shippingData.shippingMethod.shippingService,
      shippingData.externalShipping.reservationNumber,
      mode
    );
    const uint8Array = new Uint8Array(response.data);
    const blob = new Blob([uint8Array], {
      type: mode === "ZPL" ? "x-application/zpl" : "application/pdf",
    });

    const downloadLink = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = downloadLink;
    link.download = `chronopost-${
      shippingData.externalShipping.reservationNumber
    }.${mode === "ZPL" ? "zpl" : "pdf"}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsLoading && setIsLoading(false);
  } catch (error: any) {
    throw new Error(error.message);
  }
}
